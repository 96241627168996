import { FC, useEffect, useState } from "react";
import { RoutingPageValue } from "../../../../../../../utils/redux";
import { useSelector } from "react-redux";
import SelectFiles from "../common/SelectFiles/SelectFiles";
import UploadFiles from "../common/UploadFiles";

export interface SelectFilesProps {
  active: number;
}

const ProofOIdentity: FC<SelectFilesProps> = ({ active }) => {
  const value = useSelector(RoutingPageValue);
  const [component, setComponent] = useState<"SelectFiles" | "UploadFiles">();

  useEffect(() => {
    if (value === 1) {
      setComponent("SelectFiles");
    } else if (value === 2) {
      setComponent("UploadFiles");
    }
  }, [value]);

  return (
    <>
      {component === "SelectFiles" && <SelectFiles active={active} />}
      {component === "UploadFiles" && <UploadFiles />}
    </>
  );
};

export default ProofOIdentity;
