import React, { FC, useMemo, useState } from "react";
import {
  Grid,
  TableCell,
  styled,
  tableCellClasses,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
} from "@mui/material";
import "../../CandidateProfile.scss";
import dayjs from "dayjs";
import { images } from "../../../../../../utils/constants/images";
import { identifiers } from "../../../../../../utils/constants/identifiers";
import { URLS } from "../../../../../../utils/constants/urls";
import axiosInstance from "../../../../../../utils/axios";
import PrimaryButton from "../../../../../../components/Button/PrimaryButton";
import { Document, Page } from "react-pdf";
import Modal from "../../../../../../components/modal/Modal";
import { setNotificationMessage } from "../../../../../../utils/redux";
import { useDispatch } from "react-redux";
import { Reference } from "../../../../../Common/ReferenceList";
import PageLoader from "../../../../../../components/PageLoader";

const UploadReferenceForm: FC<{ reference: Reference }> = ({ reference }) => {
  const dispatch = useDispatch();
  const [documentType, setDocumentType] = useState<"pdf" | "image">();
  const [documentUrl, setDocumentUrl] = useState<string>();
  const [viewDocument, setViewDocument] = useState(false);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [loading, setloading] = useState(false);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#f5fbff",
      color: "#727272",
      border: "none",
    },
    [`&.${tableCellClasses.body}`]: {
      color: "#263238",
      fontSize: "14px",
      fontWeight: 500,
      padding: "5px 16px",
    },
    [`&.${tableCellClasses.head}:first-of-type`]: {
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
    },
    [`&.${tableCellClasses.head}:last-of-type`]: {
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      width: "14rem",
      textAlign: "start",
      paddingLeft: "35px",
    },
    [`&.${tableCellClasses.body}:first-of-type`]: {
      borderTopLeftRadius: "12px",
      borderBottomLeftRadius: "12px",
      borderLeft: "1px solid #DADADA",
    },
    [`&.${tableCellClasses.body}:last-of-type`]: {
      borderTopRightRadius: "12px",
      borderBottomRightRadius: "12px",
      borderRight: "1px solid #DADADA",
      textAlign: "center",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&": {
      borderRadius: "8px",
      borderTop: "1px solid #DADADA",
      backgroundColor: "white",
    },
  }));

  const download = async (id: string, view: boolean) => {
    setloading(true);
    try {
      const { data } = await axiosInstance.get(
        `${URLS.document}/${id}?type=referee`
      );
      const type =
        data.mimetype.indexOf("pdf") > -1
          ? "pdf"
          : data.mimetype.indexOf("image") > -1
          ? "image"
          : null;
      setloading(false);
      if (!!type && view) {
        setDocumentType(type);
        setDocumentUrl(data.url);
        setViewDocument(true);
      } else {
        window.open(data.url, "_blank");
      }
    } catch (e) {
      setloading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in downloading",
        })
      );
    }
  };

  const viewModalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          {documentType === "pdf" && (
            <Document
              file={{ url: documentUrl }}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          )}
          {documentType === "image" && (
            <img src={documentUrl} alt={"document"} className={"img-fluid"} />
          )}
        </Grid>
      </Grid>
    );
  }, [documentType, documentUrl, numPages]);

  const openViewModal = useMemo(() => {
    return (
      <Modal
        open={viewDocument}
        setModalClose={setViewDocument}
        children={viewModalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [viewDocument, viewModalText]);

  return (
    <>
      {loading && <PageLoader />}
      {openViewModal}
      <Grid xs={12} container className="card-vetting">
        <Grid item md={12}>
          <TableContainer
            component={Paper}
            sx={{ boxShadow: "none", width: "100%" }}
          >
            <Table
              sx={{
                minWidth: 600,
                borderSpacing: "0 1rem",
                borderCollapse: "separate",
                backgroundColor: "#f5fbff",
              }}
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ minWidth: "150px" }}>
                    Document Name
                  </StyledTableCell>

                  <StyledTableCell sx={{ minWidth: "111px" }}>
                    Upload Date
                  </StyledTableCell>
                  <StyledTableCell>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody className={"documents-table-body"}>
                {reference?.referee_documents?.map((reference, index) => (
                  <>
                    <StyledTableRow
                      className={"documents-table-body-row"}
                      key={index}
                    >
                      <StyledTableCell>
                        {reference.reference_form.name}
                      </StyledTableCell>

                      <StyledTableCell sx={{ position: "relative" }}>
                        {dayjs(new Date(reference.uploaded_on)).format(
                          identifiers.date_format as string
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Grid item display={"flex"} flexWrap={"nowrap"} md={6}>
                          <PrimaryButton
                            onClick={() => download(reference.id, true)}
                            sx={{
                              cursor: "pointer",
                            }}
                            variant={"text"}
                            fullWidth={false}
                          >
                            <img
                              src={images.viewIcon}
                              alt="view"
                              className="action-icons"
                            />
                          </PrimaryButton>
                          <PrimaryButton
                            onClick={() => download(reference.id, false)}
                            sx={{
                              cursor: "pointer",
                            }}
                            variant={"text"}
                            fullWidth={false}
                          >
                            <img
                              src={images.downloadIcon}
                              alt="view"
                              className="action-icons"
                            />
                          </PrimaryButton>
                        </Grid>
                      </StyledTableCell>
                    </StyledTableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default UploadReferenceForm;
