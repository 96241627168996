import React, { Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import { Box, Grid, Paper, Typography, Stack } from "@mui/material";
import "../RequiredReferences.scss";
import PrimaryButton from "../../../../../../components/Button/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import {
  IsEditable,
  Reference,
  setReference,
} from "../../../../../../utils/redux";
import InputField from "../../../../../../components/InputField";
import { Validators } from "../../../../../../utils/validators";
import { images } from "../../../../../../utils/constants/images";
import Modal from "../../../../../../components/modal/Modal";
import PreviewReference from "./PreviewReference";

const ReferenceRequirement: FC<{
  handleNext: () => void;
  setActiveStep: Dispatch<SetStateAction<number>>;
}> = ({ handleNext, setActiveStep }) => {
  const dispatch = useDispatch();
  const reference = useSelector(Reference);
  const isEditable = useSelector(IsEditable);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState("");

  const setData = (
    value: string,
    field: string,
    error: { error: boolean; message: string } | undefined
  ) => {
    const error_data = error ? error : { error: false, message: "" };
    dispatch(
      setReference({
        ...reference,
        [field]: value,
        [field + "_error"]: error_data,
      })
    );
  };

  const validate = () => {
    let count = 2;
    if (
      reference.max_references_count_error &&
      !reference.max_references_count_error.error
    ) {
      count--;
    }
    if (
      reference.referee_connection_year_error &&
      !reference.referee_connection_year_error.error
    ) {
      count--;
    }
    return (
      count > 0 ||
      (!reference.max_references_count && !reference.referee_connection_year)
    );
  };
  const PreviewModal = useMemo(() => {
    return (
      <Grid container padding={{ xs: "2px", md: "10px" }}>
        <Grid item md={12}>
          <Typography variant="h6" sx={{ textTransform: "capitalize", mb: 2 }}>
            {type} Reference
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <PreviewReference
            type={type as "employment" | "character" | "academic"}
          />
        </Grid>
        <Grid item xs={12} md={12} textAlign="center">
          <PrimaryButton
            fullWidth={false}
            sx={{
              background: "blue",
              color: "white",
            }}
            disabled={!isEditable}
            onClick={() => {
              dispatch(setReference({ ...reference, reference_editing: true }));
              if (type === "employment") {
                setActiveStep(1);
              }
              if (type === "character") {
                setActiveStep(2);
              }
              if (type === "academic") {
                setActiveStep(3);
              }
            }}
          >
            Edit
          </PrimaryButton>
        </Grid>
      </Grid>
    );
  }, [type, isEditable, dispatch, reference, setActiveStep]);

  const PreviewReferencesModal = useMemo(() => {
    return (
      <Modal
        open={isModalOpen}
        setModalClose={setIsModalOpen}
        children={PreviewModal}
        title={undefined}
        size="md"
        padding="10px"
        className={undefined}
      />
    );
  }, [isModalOpen, PreviewModal]);

  return (
    <>
      {PreviewReferencesModal}
      <Grid container justifyContent="space-around" spacing={2}>
        <Grid item xs={12} lg={8} display={"flex"} justifyContent={"center"}>
          <Paper
            sx={{
              width: "100%",
              background: " #FFFFFF",
              border: "1px dashed #5AB9F9",
              boxShadow: "0px 4px 10px rgba(120, 144, 156, 0.1)",
              borderRadius: "12px",
              padding: "18px 26px",
              marginTop: "50px",
            }}
          >
            <Stack spacing={3}>
              <Box
                sx={{
                  display: "flex",
                  mt: "10px",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                <Typography
                  fontWeight={500}
                  fontSize={14}
                  lineHeight={2}
                  color={"#333333"}
                  data-tut={
                    !reference.reference_created
                      ? "required_ref_instrustions"
                      : ""
                  }
                >
                  {reference.reference_created
                    ? "1.Preview your Reference Request"
                    : "1.Define the references required"}
                </Typography>
              </Box>
              {!reference.reference_created && (
                <Box
                  sx={{
                    display: "flex",
                    mt: "10px",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  <Typography
                    fontWeight={500}
                    fontSize={14}
                    lineHeight={2}
                    color={"#333333"}
                  >
                    Instructions:{" "}
                    <span style={{ color: "#727272" }}>
                      You can either add the number of references or the total
                      number of years, or both.
                    </span>
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <Stack>
                  <Typography
                    variant={"body1"}
                    color={"#333333"}
                    sx={{
                      paddingTop: "10px",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    Please provide
                  </Typography>
                </Stack>
                <Stack sx={{ ml: 1 }}>
                  <InputField
                    placeholder={"123..."}
                    field={"max_references_count"}
                    inputValue={reference.max_references_count}
                    size={"small"}
                    className={"mt-1"}
                    sx={{
                      fontSize: "12px",
                      maxWidth: { xs: "100%", md: "70px" },
                    }}
                    inputProps={{
                      sx: {
                        padding: "3px 14px",
                        background: "#ffffff",
                        border: "1px solid #DADADA",
                        borderRadius: "5px",
                      },
                    }}
                    variant={"outlined"}
                    textChange={setData}
                    validators={[
                      {
                        check: Validators.number,
                        message: "Please enter a valid number",
                      },
                    ]}
                    disabled={!isEditable}
                  />
                </Stack>
                <Stack>
                  <Typography
                    variant={"body1"}
                    color={"#333333"}
                    sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                      padding: { xs: "10px 0px", md: "10px 5px" },
                    }}
                  >
                    professional references covering the last
                  </Typography>
                </Stack>
                <Stack sx={{ ml: 1 }}>
                  <InputField
                    placeholder={"123..."}
                    field={"referee_connection_year"}
                    inputValue={reference.referee_connection_year}
                    size={"small"}
                    className={"mt-1"}
                    sx={{
                      fontSize: "12px",
                      maxWidth: { xs: "100%", md: "70px" },
                    }}
                    inputProps={{
                      sx: {
                        padding: "3px 14px",
                        background: "#ffffff",
                        border: "1px solid #DADADA",
                        borderRadius: "5px",
                      },
                    }}
                    variant={"outlined"}
                    textChange={setData}
                    validators={[
                      {
                        check: Validators.number,
                        message: "Please enter a valid number",
                      },
                    ]}
                    disabled={!isEditable}
                  />
                </Stack>
                <Stack>
                  <Typography
                    variant={"body1"}
                    color={"#333333"}
                    sx={{
                      paddingTop: "10px",
                      fontSize: "14px",
                      fontWeight: 500,
                      paddingLeft: { xs: "0px", md: "12px" },
                    }}
                  >
                    years
                  </Typography>
                </Stack>
              </Box>
              <Box>
                <Typography className="comment-heading" variant="caption">
                  Notes for Applicant
                  <Typography className="optional-heading" variant="caption">
                    (optional)
                  </Typography>
                </Typography>
                <Stack>
                  <InputField
                    data-tut="required_ref_additional_notes"
                    field={"comment"}
                    inputValue={reference.comment}
                    textChange={setData}
                    placeholder="Please make sure to add details of 5 different Referees."
                    multiline
                    rows={2}
                    sx={{ borderRadius: "8px", border: "1px solid #DADADA" }}
                    disabled={!isEditable}
                  />
                </Stack>
              </Box>
              {reference.reference_created && (
                <Box
                  sx={{
                    display: "flex",
                    marginTop: "12px",
                    flexDirection: { xs: "column", md: "row" },
                    width: "100%",
                    justifyContent: { xs: "center", md: "space-evenly" },
                  }}
                >
                  <Stack width={{ xs: "100%", md: "30%" }}>
                    <PrimaryButton
                      onClick={() => {
                        setType("employment");
                        setIsModalOpen(true);
                      }}
                      fullWidth={false}
                      sx={{
                        background: "#6279F7",
                        borderRadius: "5px",
                        padding: "5px 5px 5px 5px",
                        color: "white",
                        fontSize: "13px",
                        mb: { xs: 3, md: 0 },
                        maxWidth: { xs: "100%", md: "250px" },
                      }}
                    >
                      Employment Reference form
                      <img
                        src={images.viewIcon}
                        className="pl-2"
                        alt={"arrow"}
                      />
                    </PrimaryButton>
                  </Stack>
                  <Stack width={{ xs: "100%", md: "30%" }}>
                    <PrimaryButton
                      onClick={() => {
                        setType("character");
                        setIsModalOpen(true);
                      }}
                      fullWidth={false}
                      sx={{
                        background: " #45C9D0",
                        borderRadius: "5px",
                        padding: "5px 5px 5px 5px",
                        color: "white",
                        fontSize: "13px",
                        maxWidth: { xs: "100%", md: "250px" },
                      }}
                    >
                      Character Reference form
                      <img
                        src={images.viewIcon}
                        className="pl-4"
                        alt={"arrow"}
                      />
                    </PrimaryButton>
                  </Stack>
                  <Stack width={{ xs: "100%", md: "30%" }}>
                    <PrimaryButton
                      onClick={() => {
                        setType("academic");
                        setIsModalOpen(true);
                      }}
                      fullWidth={false}
                      sx={{
                        background: " #6279F7",
                        borderRadius: "5px",
                        padding: "5px 5px 5px 5px",
                        color: "white",
                        fontSize: "13px",
                        marginTop: { xs: "24px", md: "0px" },
                        maxWidth: { xs: "100%", md: "250px" },
                      }}
                    >
                      Academic Reference form
                      <img
                        src={images.viewIcon}
                        className="pl-4"
                        alt={"arrow"}
                      />
                    </PrimaryButton>
                  </Stack>
                </Box>
              )}
            </Stack>
          </Paper>
        </Grid>
        {!reference.reference_created && (
          <Grid item xs={12} style={{ marginTop: "35px", textAlign: "center" }}>
            <PrimaryButton
              fullWidth={false}
              sx={{
                width: "212px",
                background: " #5AB9F9",
                boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
                borderRadius: "6px",
                color: "white",
                maxWidth: { xs: "100%", md: "250px" },
              }}
              disabled={validate()}
              onClick={() => {
                handleNext();
              }}
            >
              Next
            </PrimaryButton>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ReferenceRequirement;
