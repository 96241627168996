import React, { FC, useState } from "react";
import { Stack, Grid, Typography, CircularProgress } from "@mui/material";
import PrimaryButton from "../../../../../../../../../components/Button/PrimaryButton";
import CheckBox from "../../../../../../../../../components/CheckBox";
import InputField from "../../../../../../../../../components/InputField";

interface PersonalHistoryProps {
  activeStep: number;
  handleBack: () => void;
  handleNext: () => void;
  steps: string[];
  saveToDraft: (value: boolean) => void;
  isLoading:boolean;
  dbs_type:string;

}

const ConfirmPage: FC<PersonalHistoryProps> = ({
  activeStep,
  handleBack,
  saveToDraft,
  isLoading,
  dbs_type
}) => {
  const [condition1, setCondition1] = useState(false);
  const [condition2, setCondition2] = useState(false);
  const [condition3, setCondition3] = useState(false);
  const [condition4, setCondition4] = useState(false);
  const [condition5, setCondition5] = useState(false);
  const [signature, setSignature] = useState("");
console.log(dbs_type)
  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          sx={{
            p: 2,
            mt: 2,
            background: "#D9D9D933",
          }}
          xs={12}
        >
          <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
            Applicant Declaration
          </Typography>
          <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
            REVIEW ALL INFORMATION
          </Typography>
          <Typography sx={{ fontSize: "15px", color: "gray" }}>
            By providing my personal information in this application form, I
            acknowledge that you, uCheck Limited, will process my personal data
            to support my application. I understand that my personal data is
            required to enable Konect3 LTD to request one or more of the
            following checks: Disclosure and Barring Service (Enhanced Check)
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            p: 2,
            mt: 2,
            background: "#D9D9D933",
          }}
          xs={12}
        >
          <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
            Declaration by Applicant - DBS Consent
          </Typography>
          <Typography sx={{ fontSize: "15px", color: "gray" }}>
            The Disclosure & Barring Service will refer the details provided on
            this application form to government and law enforcement bodies in
            accordance with any relevant legislation. The details provided to
            these bodies will be used for identifying possible matches to
            records held by them. Where such a match is established, data may be
            released to the DBS for inclusion on any certificate issued. The
            details provided on this form may be used to update the records held
            by the bodies specified above. The details provided on this
            application form may be used to verify your identity for
            authentication purposes. The DBS may use any information provided by
            the DBS on a certificate or otherwise held by the DBS to inform any
            of its barring decisions made under its powers within the
            Safeguarding Vulnerable Groups Act 2006.
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            p: 2,
            mt: 2,
          }}
          xs={12}
        >
          <Typography sx={{ fontSize: "15px", color: "gray" }}>
            I understand and will ensure that I enter my correct address details
            on the application form. I understand that once my application has
            been processed, the DBS will send my Enhanced or Standard
            certificate to my current address as specified on my application.
          </Typography>
          <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
            Please note: If you do not wish to proceed with this application,
            please contact Konect3 LTD.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            mb: 2,
          }}
        >
          <CheckBox
            style={{ zoom: 0.8, marginBottom: "8px" }}
            type={"square"}
            checked={condition1}
            onChange={() => setCondition1(!condition1)}
          />
          <Typography
            variant={"body1"}
            display={"inline-block"}
            sx={{ pr: 2, fontWeight: 400 ,wordBreak:'break-all'}}
          >
            I have read the {dbs_type as string ==="scottish" || dbs_type as string ==="Basic DBS"? "Basic DBS": "Standard/Enhanced"} Check Processing  Privacy Policy for
            applicants{" "}
              <a
                href="https://www.gov.uk/government/publications/dbs-privacy-policies"
                target="_blank"
                rel="noreferrer"
              >
                https://www.gov.uk/government/publications/dbs-privacy-policies{" "}
              </a>
            and I  understand how DBS will process my personal data and the
            options available to me for submitting an application. (tick to
            confirm){" "}
          </Typography>
        </Grid>
        {dbs_type !=='scottish' && <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <CheckBox
            style={{ zoom: 0.8, marginBottom: "8px" }}
            type={"square"}
            checked={condition2}
            onChange={() => setCondition2(!condition2)}
          />
          <Typography
            variant={"body1"}
            display={"inline-block"}
            sx={{ pr: 2, fontWeight: 400 }}
          >
            I consent to the DBS providing an electronic result directly to
            uCheck Limited that has submitted my application. I understand that
            an electronic result contains a message that indicates either the
            certificate is blank or to await certificate which will indicate
            that my certificate contains information. In some cases, uCheck
            Limited may provide this information directly to Konect3 LTD prior
            to me receiving my certificate. (tick to confirm).
          </Typography>
        </Grid>}
       {dbs_type !=='scottish' && <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            mb: 2,
          }}
        >
          <CheckBox
            style={{ zoom: 0.8, marginBottom: "8px" }}
            type={"square"}
            checked={condition3}
            onChange={() => setCondition3(!condition3)}
          />
          <Typography
            variant={"body1"}
            display={"inline-block"}
            sx={{ pr: 2, fontWeight: 400 }}
          >
            I understand if I do not consent to an electronic result being
            issued to the registered body submitting my application that I must
            not proceed with this application and I should submit a paper
            application form. (tick to confirm){" "}
          </Typography>
        </Grid>}
        {dbs_type !=='scottish' && <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <CheckBox
            style={{ zoom: 0.8, marginBottom: "8px" }}
            type={"square"}
            checked={condition4}
            onChange={() => setCondition4(!condition4)}
          />
          <Typography
            variant={"body1"}
            display={"inline-block"}
            sx={{ pr: 2, fontWeight: 400 }}
          >
            I understand that to withdraw my consent whilst my application is in
            progress I must contact the DBS helpline 03000 200 190. My
            application will then be withdrawn. (tick to confirm){" "}
          </Typography>
        </Grid>}
        {dbs_type ==='scottish' && <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <CheckBox
            style={{ zoom: 0.8, marginBottom: "8px" }}
            type={"square"}
            checked={condition4}
            onChange={() => setCondition4(!condition4)}
          />
          <Typography
            variant={"body1"}
            display={"inline-block"}
            sx={{ pr: 2, fontWeight: 400 }}
          >
            I understand and give my express consent to Konect3 LTD receiving and viewing my basic disclosure certificate prior to me seeing it. (tick to confirm.)
          </Typography>
        </Grid>}
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            mb: 2,
          }}
        >
          <CheckBox
            style={{ zoom: 0.8, marginBottom: "8px" }}
            type={"square"}
            checked={condition5}
            onChange={() => setCondition5(!condition5)}
          />
          <Typography
            variant={"body1"}
            display={"inline-block"}
            sx={{ pr: 2, fontWeight: 400 }}
          >
            I have read and understood this declaration, the uCheck Limited{" "}
            <a
              href="https://hrplatform-cdn.s3.eu-west-2.amazonaws.com/docs/uCheck+Website+Terms+of+Use+v1.8.pdf"
              target="_blank"
              rel={"noreferrer"}
            >
              Terms of Use
            </a>{" "}
            ,{" "}
            <a
              href="https://hrplatform-cdn.s3.eu-west-2.amazonaws.com/docs/uCheck+Statement+of+Fair+Processing+v2.7.pdf"
              target="_blank"
              rel={"noreferrer"}
            >
              Statement of Fair Processing
            </a>{" "}
            and{" "}
            <a
              href="https://hrplatform-cdn.s3.eu-west-2.amazonaws.com/docs/uCheck+Applicant+Privacy+Policy+v1.3.pdf"
              target="_blank"
              rel={"noreferrer"}
            >
              Applicant Privacy Policy
            </a>{" "}
            . (tick to confirm).
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography sx={{ fontSize: "15px", fontWeight: 500, mb: 2 }}>
            Your Signature (type in your forename and surname as entered in the
            application as your digital signature):
          </Typography>
          <Stack
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            width="100%"
          >
            <InputField
              onChange={(e) => {
                setSignature(e.target.value);
              }}
              sx={{
                width: { xs: "100%", md: "80%" },
                mb: 0,
                border: "1px solid #D6EDFD",
                borderRadius: "6px",
              }}
              placeholder="Forename and Surname"
            />
           {dbs_type !=='scottish' && <PrimaryButton
              fullWidth={false}
              sx={{
                minWidth: { xs: "120px", md: "200px" },
                background: "#D6EDFD",
                color: "#333333",
                ml: { xs: 0, md: 2 },
                marginBottom: "10px",
              }}
              disabled={
                !(
                  condition1 &&
                  condition2 &&
                  condition3 &&
                  condition4 &&
                  condition5
                ) || signature === "" ||
                isLoading
              }
              onClick={() => saveToDraft(true)}
            >
            {isLoading && <CircularProgress sx={{ mr: 1 }} size={20} />}
              Sign and Submit
            </PrimaryButton>}
            {dbs_type ==='scottish' && <PrimaryButton
              fullWidth={false}
              sx={{
                minWidth: { xs: "120px", md: "200px" },
                background: "#D6EDFD",
                color: "#333333",
                ml: { xs: 0, md: 2 },
                marginBottom: "10px",
              }}
              disabled={
                !(
                  condition1 &&
                  condition4 &&
                  condition5
                ) || signature === "" ||
                isLoading
              }
              onClick={() => saveToDraft(true)}
            >
            {isLoading && <CircularProgress sx={{ mr: 1 }} size={20} />}
              Sign and Submit
            </PrimaryButton>}
          </Stack>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            pt: 2,
            mt: 2,
            borderTop: "1px solid #0000004d",
          }}
          xs={12}
        >
          <PrimaryButton
            variant={"text"}
            fullWidth={false}
            sx={{
              mr: 2,
              background: "white",
              border: "1px solid #5AB9F9",
              color: "#5AB9F9",
              fontSize: 16,
              height: "46px",
              minWidth: "100px",
            }}
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            Go Back
          </PrimaryButton>
        </Grid>
      </Grid>
    </>
  );
};

export default ConfirmPage;
