import { FC, useEffect, useState } from "react";
import UploadFiles from "../common/UploadFiles";
import { useDispatch, useSelector } from "react-redux";
import {
  RoutingPageValue,
  setName,
  setRequestData,
  VettingDocuments,
} from "../../../../../../../utils/redux";
import { useParams } from "react-router-dom";
import SelectFiles from "../common/SelectFiles";
import { identifiers } from "../../../../../../../utils/constants/identifiers";

const Cv: FC<{ active: number }> = ({ active }) => {
  const dispatch = useDispatch();
  const { id } = useParams() as any;
  const value = useSelector(RoutingPageValue);
  const documents = useSelector(VettingDocuments);
  const [component, setComponent] = useState("UploadFiles");

  useEffect(() => {
    if (value === 1) {
      dispatch(setName(identifiers.cv));
      dispatch(
        setRequestData({
          request_id: id,
          vetting_document_id: documents[active].id,
          document_repo_id: "",
        })
      );
      setComponent("UploadFiles");
    } else if (value === 0) {
      setComponent("Preview");
    }
  }, [active, dispatch, documents, id, value]);

  return (
    <>
      {component === "UploadFiles" && <UploadFiles showBack={false} />}
      {component === "Preview" && <SelectFiles active={active} />}
    </>
  );
};

export default Cv;
