import React, { FC, useCallback, useEffect } from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import "./SideBar.scss";
import { Drawer, useMediaQuery, useTheme } from "@mui/material";
import DrawerContent from "./DrawerContent";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../utils/constants/coachMarks";
import { CognitoUser } from "amazon-cognito-identity-js";
import * as Amplify from "../../utils/services/amplify";
import {
  User,
  UserRole,
  setUserData,
} from "../../utils/redux/reducer/authentication-slice";
import { useDispatch, useSelector } from "react-redux";
import { isTourCompleted, markTourAsCompleted } from "../../utils/storage/tours";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: 222,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 80,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DesktopDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: 222,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SidebarLayout: FC<{
  open: boolean;
  setOpen: (value: boolean) => void;
}> = ({ open, setOpen }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const { setIsOpen, currentStep, setCurrentStep } = useTour();
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const role = useSelector(UserRole);
  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const setCoachMarksValue = useCallback(async () => {
    //if (!!user && user["custom:reference_tutorial"] !== "true") {
    const currentUser: CognitoUser = await Amplify.UserDetail();
    const body = {
      "custom:drawer_tutorial": "true",
    };
    await Amplify.UpdateUserDetails(body, currentUser);
    await Amplify.RefreshSession();
    dispatch(setUserData({ ...user, ...body }));
    //}
  }, [user, dispatch]);

  useEffect(() => {
    if (!matches && open) {
      if (!!user && user["custom:drawer_tutorial"] !== "true") {
        setTimeout(() => {
          setCurrentStep(tourStep.mobile_drawer.index);
        }, 1000);
      }
    }
  }, [user, open, matches, setCurrentStep]);

  useEffect(() => {
    if (currentStep === tourStep.mobile_drawer.index) {
      if (!isTourCompleted('mobile_drawer')) {
        setIsOpen(true);
      }
    }
    if (role === "agency") {
      if (
        currentStep ===
        tourStep.mobile_drawer.index + tourStep.mobile_drawer.steps
      ) {
        setIsOpen(false);
        markTourAsCompleted('mobile_drawer');
        if (!!user && user["custom:drawer_tutorial"] !== "true") {
          (async () => {
            await setCoachMarksValue();
          })();
        }
      }
    } else if (role === "admin") {
      if (
        currentStep ===
        tourStep.mobile_drawer.index + tourStep.mobile_drawer.steps
      ) {
        setIsOpen(false);
        markTourAsCompleted('mobile_drawer');
        if (!!user && user["custom:drawer_tutorial"] !== "true") {
          (async () => {
            await setCoachMarksValue();
          })();
        }
      }
    } else {
      if (
        currentStep ===
        tourStep.mobile_drawer.index + (tourStep.mobile_drawer.steps - 2)
      ) {
        setIsOpen(false);
        markTourAsCompleted('mobile_drawer');
        if (!!user && user["custom:drawer_tutorial"] !== "true") {
          (async () => {
            await setCoachMarksValue();
          })();
        }
      }
    }
  }, [currentStep, role, setCoachMarksValue, setIsOpen, user]);

  return (
    <>
      {matches ? (
        <DesktopDrawer
          variant={"permanent"}
          open={open}
          sx={{
            display: "flex",
          }}
          className={"sidebar"}
          onMouseEnter={handleDrawerOpen}
          onMouseLeave={handleDrawerClose}
        >
          <DrawerContent open={open} setOpen={setOpen} isMobile={false} />
        </DesktopDrawer>
      ) : (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          className={"sidebar"}
          variant={"temporary"}
          open={open}
          onClose={handleDrawerClose}
        >
          <DrawerContent open={open} setOpen={setOpen} isMobile={true} />
        </Drawer>
      )}
    </>
  );
};

export default React.memo(SidebarLayout);
