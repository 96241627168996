import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { images } from "../../../../utils/constants/images";
import SearchIcon from "@mui/icons-material/Search";
import PrimaryButton from "../../../../components/Button/PrimaryButton";
import { Link, useNavigate } from "react-router-dom";
import { agency } from "../../../../utils/constants/routes";
import { RequestDetailsModel } from "../RequestDetails/RequestDetailsModel";
import axiosInstance from "../../../../utils/axios";
import { URLS } from "../../../../utils/constants/urls";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CopyToClipboard from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import {
  setAdditionalForms,
  setVettingDocuments,
  setNotificationMessage,
} from "../../../../utils/redux";
import PageLoader from "../../../../components/PageLoader";
import InputField from "../../../../components/InputField";
import { debounce } from "lodash";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../utils/constants/coachMarks";
import { CognitoUser } from "amazon-cognito-identity-js";
import * as Amplify from "../../../../utils/services/amplify";
import {
  User,
  setUserData,
} from "../../../../utils/redux/reducer/authentication-slice";
import { Auth } from "aws-amplify";
import { isTourCompleted, markTourAsCompleted } from "../../../../utils/storage/tours";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F5FBFF",
    color: "#727272",
    border: "none",
    maxWidth: "300px",
    width: "300px",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    color: "#263238",
    fontSize: "14px",
    fontWeight: 600,
  },
  [`&.${tableCellClasses.head}:first-of-type`]: {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
    paddingLeft: "20px",
  },
  [`&.${tableCellClasses.head}:nth-of-type(2)`]: {
    maxWidth: "200px",
    width: "200px",
  },
  [`&.${tableCellClasses.head}:nth-of-type(5)`]: {
    maxWidth: "200px",
    width: "200px",
  },
  [`&.${tableCellClasses.head}:nth-of-type(6)`]: {
    maxWidth: "180px",
    width: "180px",
  },
  [`&.${tableCellClasses.head}:last-of-type`]: {
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    maxWidth: "3em",
    width: "3em",
  },
  [`&.${tableCellClasses.body}:first-of-type`]: {
    borderTopLeftRadius: "12px",
    borderBottomLeftRadius: "12px",
    paddingLeft: "20px",
  },
  [`&.${tableCellClasses.body}:last-of-type`]: {
    borderTopRightRadius: "12px",
    borderBottomRightRadius: "12px",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&": {
    backgroundColor: "#f9fbfc",
    paddingBottom: "15px",
    paddingTop: "15px",
  },
}));

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

interface EnhancedTableProps {
  order: boolean;
  loading: boolean;
  orderBy: string;
  onRequestSort: (property: string) => void;
}

const headCells: readonly HeadCell[] = [
  {
    id: "request_id",
    numeric: true,
    disablePadding: true,
    label: "Request ID",
  },
  {
    id: "company",
    numeric: true,
    disablePadding: false,
    label: "Hiring Organization",
  },
  {
    id: "designation",
    numeric: true,
    disablePadding: false,
    label: "Job Role",
  },
  {
    id: "created_at",
    numeric: true,
    disablePadding: false,
    label: "Date Created",
  },
];

function EnhancedTableHead(props: EnhancedTableProps) {
  const { orderBy, order, onRequestSort, loading } = props;

  const createSortHandler = (property: string) => {
    onRequestSort(property);
  };

  return (
    <StyledTableRow>
      {headCells.map((headCell) => (
        <StyledTableCell
          key={headCell.id}
          align={headCell.numeric ? "left" : "right"}
          padding={headCell.disablePadding ? "none" : "normal"}
          sortDirection={order ? "asc" : "desc"}
          title={
            loading ? "Please wait until all requests are fetched" : undefined
          }
        >
          <TableSortLabel
            active={orderBy === headCell.id}
            direction={order ? "asc" : "desc"}
            onClick={() => createSortHandler(headCell.id)}
            disabled={loading}
          >
            {headCell.label}
            {orderBy === headCell.id && (
              <Box component="span" sx={visuallyHidden}>
                {order ? "sorted ascending" : "sorted descending"}
                descending
              </Box>
            )}
          </TableSortLabel>
        </StyledTableCell>
      ))}
      <StyledTableCell>No. of Applicants</StyledTableCell>
      <StyledTableCell>Status</StyledTableCell>
      <StyledTableCell></StyledTableCell>
    </StyledTableRow>
  );
}

const initial_filter_state: {
  status: "all" | "published" | "draft";
  search: string;
  category:
    | "designation_low_search"
    | "request_id_low_search"
    | "company_low_search";
  name: string;
  sort: boolean;
  index: number;
  page_size: number;
} = {
  status: "all",
  search: "",
  category: "designation_low_search",
  name: "created_at",
  sort: false,
  index: 0,
  page_size: 10,
};

const RequestList: FC = () => {
  const theme = useTheme();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { setIsOpen, currentStep, setCurrentStep } = useTour();

  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };

  const [requests, setRequests] = useState<RequestDetailsModel[]>([]);
  const [totalRequests, setTotalRequests] = useState<number>(0);
  const [totalPublishedRequests, setTotalPublishedRequests] =
    useState<number>(0);
  const [totalDraftRequests, setTotalDraftRequests] = useState<number>(0);
  const [filter, setFilter] = useState<{
    status: "all" | "published" | "draft";
    search: string;
    category:
      | "designation_low_search"
      | "request_id_low_search"
      | "company_low_search";
    name: string;
    sort: boolean;
    index: number;
    page_size: number;
  }>(initial_filter_state);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [dataFetching, setDataFetching] = useState<boolean>(false);

  const setCoachMarksValue = useCallback(async () => {
    //if (!!user && user["custom:reference_tutorial"] !== "true") {
    const currentUser: CognitoUser = await Amplify.UserDetail();
    const body = {
      "custom:dashboard": "true",
    };
    await Amplify.UpdateUserDetails(body, currentUser);
    await Amplify.RefreshSession();
    dispatch(setUserData({ ...user, ...body }));
    //}
  }, [user, dispatch]);

  useEffect(() => {
    if (isLoading !== undefined && !isLoading) {
      if (!!user && user["custom:dashboard"] !== "true") {
        setCurrentStep(tourStep.dash_board.index);
      }
    }
  }, [user, isLoading, setCurrentStep]);

  useEffect(() => {
    if (tourStep.dash_board.index === currentStep) {
      if (!isTourCompleted('dash_board')) {
        setIsOpen(true);
      }
    } else if (isDesktop) {
      if (
        currentStep ===
        tourStep.dash_board.index + tourStep.dash_board.steps
      ) {
        setIsOpen(false);
        markTourAsCompleted('dash_board');
        if (!!user && user["custom:dashboard"] !== "true") {
          (async () => {
            await setCoachMarksValue();
          })();
        }
      }
    } else if (!isDesktop) {
      if (
        currentStep ===
        tourStep.dash_board.index + (tourStep.dash_board.steps - 4)
      ) {
        setIsOpen(false);
        markTourAsCompleted('dash_board');
        if (!!user && user["custom:dashboard"] !== "true") {
          (async () => {
            await setCoachMarksValue();
          })();
        }
      }
    }
  }, [currentStep, isDesktop, setCoachMarksValue, setIsOpen, user]);

  const handleRequestSort = async (property: string) => {
    const sort = filter.name === property ? !filter.sort : true;
    await fetchData(
      filter.status,
      filter.search,
      filter.category,
      property,
      sort
    );
    setFilter((prevState) => ({
      ...prevState,
      name: property,
      sort: prevState.name === property ? !prevState.sort : true,
      index: 0,
    }));
  };

  const getRequests = async (
    publish_status: string,
    search: string,
    category: string,
    sort_column: string,
    sort: boolean
  ) => {
    const searchQuery =
      !!search && `&keyword=${search}&search_column=${category}`;
    const sortQuery =
      !!sort_column && `&asc_sort=${sort}&sort_column=${sort_column}`;
    let page_key = null;
    const request_list: RequestDetailsModel[] = [];

    try {
      setIsLoading(true);
      setDataFetching(true);
      do {
        const { data }: { status: number; data: any } =
          await axiosInstance.post(
            `${URLS.requests}/list?&page_size=${
              filter.page_size
            }&publish_status=${publish_status}${sortQuery || ""}${
              searchQuery || ""
            }`,
            page_key
          );
        setIsLoading(false);
        setTotalRequests(data.total_count);
        setTotalPublishedRequests(data.publish_count);
        setTotalDraftRequests(data.draft_count);
        request_list.push(...data.data);
        if (!!page_key && page_key !== "null") {
          setRequests((prevState) => [...prevState, ...data.data]);
        } else {
          setRequests(data.data);
        }
        page_key = JSON.parse(data.page_key);
      } while (!!page_key && page_key !== "null");
      setRequests(request_list);
      setDataFetching(false);
    } catch (e: any) {
      if (await Auth.currentUserInfo()) {
        setIsLoading(false);
        setDataFetching(false);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "Unable to Get Request List",
          })
        );
      }
    }
  };

  const delayedQuery = useRef(debounce(getRequests, 800)).current;

  const fetchData = useRef(debounce(getRequests, 100)).current;

  useEffect(() => {
    (async () => {
      await fetchData(
        initial_filter_state.status,
        initial_filter_state.search,
        initial_filter_state.category,
        initial_filter_state.name,
        initial_filter_state.sort
      );
    })();
  }, [fetchData]);

  const navigate = (request?: RequestDetailsModel) => {
    dispatch(setVettingDocuments([]));
    dispatch(setAdditionalForms([]));
    Navigate(agency.requests + (request ? "/" + request.id : "/create"));
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter((prevState) => ({
      ...prevState,
      index: newPage,
    }));
  };

  const filterRows = async (status: "all" | "published" | "draft") => {
    await fetchData(
      status,
      filter.search,
      filter.category,
      filter.name,
      filter.sort
    );
    setFilter((prevState) => ({ ...prevState, status, index: 0 }));
  };

  const setSearch = (search: string) => {
    setFilter((prevState) => ({ ...prevState, search, index: 0 }));
    delayedQuery(
      filter.status,
      search,
      filter.category,
      filter.name,
      filter.sort
    );
  };

  const selectCategory = (e: any) => {
    setFilter((prevState) => ({
      ...prevState,
      search: "",
      category: e.target.value,
      index: 0,
    }));
    delayedQuery(filter.status, "", e.target.value, filter.name, filter.sort);
  };

  return (
    <>
      {isLoading && <PageLoader />}
      <Container
        maxWidth={false}
        sx={
          matches
            ? { minHeight: "calc(100vh - 114px)", mb: 3 }
            : { minHeight: "calc(100vh - 124px)", mb: 3 }
        }
      >
        <Grid container className={"mb-3"} sx={{ mt: 3 }}>
          <Grid
            item
            xs={12}
            md={3}
            lg={6}
            textAlign={matches ? "inherit" : "center"}
            className={"pl-0 pl-lg-0"}
            sx={{
              position: { xs: "fixed", sm: "fixed", md: "relative" },
              bottom: "0",
              left: "0",
              width: "100%",
              zIndex: "99",
              padding: {
                xs: '0.5rem',
                md: 0
              },
              boxShadow: { xs: "0 0 10px #eee", md: 'none' },
              background: { xs: "white", md: "transparent" },
            }}
          >
            <PrimaryButton
              fullWidth={false}
              sx={{
                marginTop: {
                  xs: 0,
                  md: "12px"
                } ,
                background: "#5AB9F9",
                color: "#ffffff",
                fontSize: 16,
                fontWeight: 500,
                width: "100%",
                maxWidth: "302px",
                height: "10px",
                boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
                zIndex: 99999,
              }}
              data-tut="create_request_btn"
              disabled={isLoading}
              onClick={() => navigate()}
            >
              Create Request
            </PrimaryButton>
          </Grid>
          <Grid item xs={12} md={9} lg={6} className={"pl-0 pl-md-2  pl-lg-0"}>
            <Grid
              container
              spacing={2}
              justifyContent={matches ? "inherit" : "center"}
            >
              <Grid data-tut="total_requset_btn" item xs={12} md={4}>
                <Box
                  sx={{
                    background: "#F9FBFC",
                    borderRadius: "12px",
                    cursor: dataFetching ? "inherit" : "pointer",
                    p: 1,
                    boxShadow:
                      filter.status === "all"
                        ? "0px 10px 25px rgba(120, 144, 156, 0.2)"
                        : "none",
                  }}
                  title={
                    dataFetching
                      ? "Please wait until all requests are fetched"
                      : undefined
                  }
                  onClick={!dataFetching ? () => filterRows("all") : undefined}
                >
                  <Stack direction={"row"} spacing={2}>
                    <img src={images.totalRequestIcon} alt={"Total Requests"} />
                    <Stack>
                      <Typography variant={"h5"} fontWeight={500}>
                        {totalRequests}
                      </Typography>
                      <Typography
                        variant={"body2"}
                        fontSize={12}
                        fontWeight={300}
                      >
                        Total Request
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
              </Grid>
              <Grid
                data-tut="published_request_btn"
                item
                xs={12}
                sm={12}
                md={4}
              >
                <Box
                  sx={{
                    background: "#F5FBFF",
                    borderRadius: "12px",
                    cursor: dataFetching ? "inherit" : "pointer",
                    p: 1,
                    display: "flex",
                    boxShadow:
                      filter.status === "published"
                        ? "0px 10px 25px rgba(120, 144, 156, 0.2)"
                        : "none",
                  }}
                  title={
                    dataFetching
                      ? "Please wait until all requests are fetched"
                      : undefined
                  }
                  onClick={
                    !dataFetching ? () => filterRows("published") : undefined
                  }
                >
                  <Stack direction={"row"} spacing={2}>
                    <img
                      src={images.publicRequestIcon}
                      alt={"Published Requests"}
                    />
                    <Stack>
                      <Typography variant={"h5"} fontWeight={500}>
                        {totalPublishedRequests}
                      </Typography>
                      <Typography
                        variant={"body2"}
                        fontSize={12}
                        fontWeight={300}
                      >
                        Published Request
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
              </Grid>
              <Grid data-tut="draft_request_btn" item xs={12} sm={12} md={4}>
                <Box
                  sx={{
                    background: " #FEFBEC",
                    borderRadius: "12px",
                    cursor: dataFetching ? "inherit" : "pointer",
                    p: 1,
                    display: "flex",
                    boxShadow:
                      filter.status === "draft"
                        ? "0px 10px 25px rgba(120, 144, 156, 0.2)"
                        : "none",
                  }}
                  title={
                    dataFetching
                      ? "Please wait until all requests are fetched"
                      : undefined
                  }
                  onClick={
                    !dataFetching ? () => filterRows("draft") : undefined
                  }
                >
                  <Stack direction={"row"} spacing={2}>
                    <img src={images.draftRequestIcon} alt={"Draft Requests"} />
                    <Stack>
                      <Typography variant={"h5"} fontWeight={500}>
                        {totalDraftRequests}
                      </Typography>
                      <Typography
                        variant={"body2"}
                        fontSize={12}
                        fontWeight={300}
                      >
                        Draft Request
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ mt: 4 }}
          justifyContent={matches ? "start" : "space-between"}
        >
          <Grid item data-tut="sort_dropdown" xs={6} lg={2}>
            <FormControl fullWidth size={"small"} sx={{ border: "none" }}>
              <Select
                value={filter.category}
                sx={{ height: 39, py: 1, borderColor: "#dadada" }}
                onChange={selectCategory}
                disabled={dataFetching}
                inputProps={{
                  title: dataFetching
                    ? "Please wait until all requests are fetched"
                    : undefined,
                }}
              >
                <MenuItem value={"request_id_low_search"}>Request ID</MenuItem>
                <MenuItem value={"company_low_search"}>
                  Hiring Organization
                </MenuItem>
                <MenuItem value={"designation_low_search"}>Job Role</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid data-tut="search_input" item xs={6} lg={3}>
            <InputField
              sx={{ ml: 1 }}
              placeholder="Search Request..."
              value={filter.search}
              textChange={setSearch}
              inputProps={{
                sx: {
                  p: 1,
                },
                title: dataFetching
                  ? "Please wait until all requests are fetched"
                  : undefined,
              }}
              InputProps={{
                endAdornment: <SearchIcon />,
              }}
              disabled={dataFetching}
            />
          </Grid>
        </Grid>
        {!isLoading && (
          <Grid container spacing={2} className={"mb-3"}>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <TableContainer sx={{ boxShadow: "none" }}>
                <Table
                  sx={{
                    minWidth: 700,
                    borderSpacing: "0 1rem",
                    borderCollapse: "separate",
                  }}
                >
                  <TableHead>
                    <EnhancedTableHead
                      order={filter.sort}
                      orderBy={filter.name}
                      onRequestSort={handleRequestSort}
                      loading={dataFetching}
                    />
                  </TableHead>
                  <TableBody className={"request-table-body"}>
                    {requests
                      .slice(
                        filter.index * filter.page_size,
                        filter.index * filter.page_size + filter.page_size
                      )
                      .map((request, i) => (
                        <StyledTableRow
                          key={
                            requests.length -
                            (filter.index * filter.page_size + i)
                          }
                          sx={{ cursor: "pointer" }}
                          className={"request-table-body-row"}
                        >
                          <StyledTableCell onClick={() => navigate(request)}>
                            {request.request_id}
                          </StyledTableCell>
                          <StyledTableCell onClick={() => navigate(request)}>
                            {request.company}
                          </StyledTableCell>
                          <StyledTableCell onClick={() => navigate(request)}>
                            {request.designation}
                          </StyledTableCell>

                          <StyledTableCell onClick={() => navigate(request)}>
                            {dayjs(new Date(request.created_at)).format(
                              "DD-MM-YYYY"
                            )}
                          </StyledTableCell>
                          <StyledTableCell onClick={() => navigate(request)}>
                            {request.candidate_applied_count}
                          </StyledTableCell>
                          <StyledTableCell onClick={() => navigate(request)}>
                            <Button
                              fullWidth={false}
                              sx={{
                                background: request.published
                                  ? "#C5EAFD"
                                  : "#F8E592",
                                color: "#333333",
                                fontSize: 14,
                                fontWeight: 500,
                                padding: "4px 14px",
                                minWidth: "100px",
                              }}
                            >
                              {request.published ? "Published" : "Draft"}
                            </Button>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Stack direction={"row"} spacing={2}>
                              <CopyToClipboard
                                text={
                                  window.location.origin +
                                  "/requests/" +
                                  request.id
                                }
                                onCopy={() =>
                                  dispatch(
                                    setNotificationMessage({
                                      display: true,
                                      severity: "success",
                                      message:
                                        "Request Link Copied to Clipboard",
                                    })
                                  )
                                }
                              >
                                <Button variant={"text"}>
                                  <ContentCopyIcon />
                                </Button>
                              </CopyToClipboard>
                              <Button
                                variant={"text"}
                                onClick={() => navigate(request)}
                              >
                                <ChevronRightIcon />
                              </Button>
                            </Stack>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    {requests.length === 0 && !isLoading && (
                      <StyledTableRow className={"request-table-body-row"}>
                        <StyledTableCell colSpan={8}>
                          <Stack textAlign={"center"}>
                            <img
                              src={images.emptyIcon}
                              alt={"no request"}
                              className={
                                "vertical-align-middle empty-image mb-2"
                              }
                            />
                            <Stack direction={"row"} justifyContent={"center"}>
                              <Typography variant={"body2"}>
                                No Requests Found.
                              </Typography>
                              <Link to={agency.requests + "/create"}>
                                Please Create a New Request
                              </Link>
                            </Stack>
                          </Stack>
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        colSpan={8}
                        count={requests.length}
                        rowsPerPage={filter.page_size}
                        page={filter.index}
                        onRowsPerPageChange={(e) => {
                          setFilter((prevState) => ({
                            ...prevState,
                            delay: 0,
                            page_size: parseInt(e.target.value, 10),
                          }));
                        }}
                        onPageChange={handleChangePage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  );
};

export default RequestList;
