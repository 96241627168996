const SampleRefereeDetails = (
  type: 'employment' | 'character' | 'academic'
) => {
  const body: { [key: string]: string | { from: string; to: string } } = {
    referee_name: 'Tim Vaughn',
    referee_email: 'tim@ked.io',
    code: '+44',
    phone_number: '7890654321',
    relationship: 'Manager',
  };
  if (type === 'employment') {
    body.organisation = 'KED';
    body.job_title = 'UX Designer';
    body.candidate_employment_date = {
      from: '2023-02-13T00:00:00+05:30',
      to: '2023-02-13T00:00:00+05:30',
    };
  }
  if (type === 'character') {
    body.time_known = '2 Years';
  }
  if (type === 'academic') {
    body.job_title = 'Sr. Professor';
    body.course = 'Bachelor in Design';
    body.candidate_employment_date = {
      from: '2023-02-13T00:00:00+05:30',
      to: '2023-02-13T00:00:00+05:30',
    };
  }
  return body;
};

export default SampleRefereeDetails;
