import { createSlice } from '@reduxjs/toolkit';

export interface AcademicDocument {
  id: string;
  candidate_id: string;
  document_repo_id: string;
  institution_name: string;
  subject: string;
  graduation_date: number;
  created_at: number;
  request_id: string;
  is_deleted: boolean;
  document_name: string;
}

interface candidateVettingState {
  value: number;
  name: string | null;
  reload: boolean;
  request_data: {
    request_id: string;
    vetting_document_id: string;
    sub_document_id: string;
    document_repo_id: string;
  };
  candidateProfileReload: boolean;
  candidate_info: {
    name: string;
  };
  academicQualification: AcademicDocument[];
  academic_qualification_ids: string[];
  data: {
    academic_qualification_data: AcademicDocument[];
    academic_qualification_ids: string[];
    application_status: string;
  };
  activeIndex: number;
}

export const candidateRequest: candidateVettingState = {
  value: 0,
  name: '',
  reload: false,
  candidateProfileReload: false,
  request_data: {
    request_id: '',
    vetting_document_id: '',
    sub_document_id: '',
    document_repo_id: '',
  },
  candidate_info: {
    name: '',
  },
  academicQualification: [],
  academic_qualification_ids: [],
  data: {
    academic_qualification_data: [],
    academic_qualification_ids: [],
    application_status: '',
  },
  activeIndex: 0,
};

const candidateVettingSlice = createSlice({
  name: 'candidateVettingSlice',
  initialState: candidateRequest,
  reducers: {
    setValue(state, action) {
      state.value = action.payload;
    },
    setName(state, action) {
      state.name = action.payload;
    },
    setReload(state, action) {
      state.reload = action.payload;
    },
    setCandidateProfileReload(state, action) {
      state.candidateProfileReload = action.payload;
    },
    setRequestData(state, action) {
      state.request_data = action.payload;
    },
    setCandidateProfileInfo(state, action) {
      state.candidate_info = action.payload;
    },
    setAcademicQualificationData(state, action) {
      state.academicQualification = action.payload.data;
      state.academic_qualification_ids = action.payload.ids;
    },
    setCandidateProfileAcademicData(state, action) {
      state.data = action.payload;
    },
    setCandidateVettingDocActiveIndex(state, action) {
      state.activeIndex = action.payload;
    },
  },
});

export default candidateVettingSlice.reducer;

export const {
  setValue,
  setName,
  setReload,
  setRequestData,
  setCandidateProfileInfo,
  setAcademicQualificationData,
  setCandidateProfileAcademicData,
  setCandidateProfileReload,
  setCandidateVettingDocActiveIndex,
} = candidateVettingSlice.actions;

export const RoutingPageValue = (state: {
  candidateVettingSlice: candidateVettingState;
}) => state.candidateVettingSlice.value;

export const DocumentName = (state: {
  candidateVettingSlice: candidateVettingState;
}) => state.candidateVettingSlice.name;

export const Reload = (state: {
  candidateVettingSlice: candidateVettingState;
}) => state.candidateVettingSlice.reload;

export const CandidateProfileReload = (state: {
  candidateVettingSlice: candidateVettingState;
}) => state.candidateVettingSlice.candidateProfileReload;

export const RequestData = (state: {
  candidateVettingSlice: candidateVettingState;
}) => state.candidateVettingSlice.request_data;

export const CandidateInfo = (state: {
  candidateVettingSlice: candidateVettingState;
}) => state.candidateVettingSlice.candidate_info;

export const AcademicQualificationData = (state: {
  candidateVettingSlice: candidateVettingState;
}) => state.candidateVettingSlice.academicQualification;

export const AcademicQualificationIds = (state: {
  candidateVettingSlice: candidateVettingState;
}) => state.candidateVettingSlice.academic_qualification_ids;

export const AcademicData = (state: {
  candidateVettingSlice: candidateVettingState;
}) => state.candidateVettingSlice.data;

export const CandidateVettingDocActiveIndex = (state: {
  candidateVettingSlice: candidateVettingState;
}) => state.candidateVettingSlice.activeIndex;
