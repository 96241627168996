import React, { FC, useState, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Stack,
  CircularProgress,
} from "@mui/material";
import "../RequiredReference.scss";
import PrimaryButton from "../../../../../../components/Button/PrimaryButton";
import InputField from "../../../../../../components/InputField";
import { Validators } from "../../../../../../utils/validators";
import {
  Reference,
  setReference,
  setNotificationMessage,
} from "../../../../../../utils/redux";
import axiosInstance from "../../../../../../utils/axios";
import { URLS } from "../../../../../../utils/constants/urls";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../../../utils/constants/coachMarks";
import { isTourCompleted, markTourAsCompleted } from "../../../../../../utils/storage/tours";

const ReferenceRequirement: FC = () => {
  const reference = useSelector(Reference);
  const dispatch = useDispatch();
  const { id } = useParams() as any;
  const [loading, setLoading] = useState<boolean>(false);

  const { setIsOpen, currentStep, setCurrentStep } = useTour();
  //   const setCoachMarksValue = useCallback(async () => {
  //     //if (!!user && user["custom:reference_tutorial"] !== "true") {
  //       const currentUser: CognitoUser = await Amplify.UserDetail();
  //       const body = {
  //         "custom:forgot_passwor": "true",
  //       };
  //       await Amplify.UpdateUserDetails(body, currentUser);
  //       await Amplify.RefreshSession();
  //  //   }
  //   }, []);

  useEffect(() => {
    setCurrentStep(tourStep.candidate_required_refrence_instrustion_page.index);
  }, [setCurrentStep]);

  useEffect(() => {
    if (
      tourStep.candidate_required_refrence_instrustion_page.index ===
      currentStep
    ) {
      if (!isTourCompleted('candidate_required_refrence_instrustion_page')) {
        setIsOpen(true);
      }
    } else if (
      currentStep ===
      tourStep.candidate_required_refrence_instrustion_page.index +
        tourStep.candidate_required_refrence_instrustion_page.steps
    ) {
      setIsOpen(false);
      markTourAsCompleted('candidate_required_refrence_instrustion_page');
      //setCoachMarksValue()
    }
  }, [currentStep, setIsOpen]);

  const acknowledge = async () => {
    try {
      setLoading(true);
      await axiosInstance.put(
        `${URLS.candidate_request_details}${id}/acknowledge`,
        {
          acknowledged: true,
        }
      );
      dispatch(setReference({ ...reference, reference_acknowledged: true }));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in acknowledgement",
        })
      );
    }
  };

  return (
    <Grid container justifyContent="space-around" spacing={2}>
      <Grid item xs={12} lg={7} display={"flex"} justifyContent={"center"}>
        <Paper
          sx={{
            width: "100%",
            background: " #FFFFFF",
            border: "1px dashed #5AB9F9",
            boxShadow: "0px 4px 10px rgba(120, 144, 156, 0.1)",
            borderRadius: "12px",
            padding: "18px 26px",
            marginTop: "50px",
          }}
        >
          <Stack spacing={3}>
            <Box
              sx={{
                display: "flex",
                mt: "10px",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              <Typography
                fontWeight={500}
                fontSize={14}
                lineHeight={2}
                color={"#333333"}
                data-tut="candidate_required_ref_instrustion_page"
              >
                Reference requirement
              </Typography>
            </Box>
            {/* <Box
              sx={{
                display: "flex",
                mt: "10px",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              <Typography
                fontWeight={500}
                fontSize={14}
                lineHeight={2}
                color={"#333333"}
              >
                Instructions: You can either add the number of references or the
                total number of years, or both.
              </Typography>
            </Box> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Stack>
                <Typography
                  variant={"body1"}
                  color={"#333333"}
                  sx={{
                    paddingTop: "10px",
                    fontSize: "14px",
                    paddingLeft: { xs: "10px", md: "0px" },
                    fontWeight: 400,
                    display: { xs: "block", md: "inline" },
                  }}
                >
                  Please provide
                </Typography>
              </Stack>
              <Stack sx={{ ml: 1 }}>
                <InputField
                  placeholder={""}
                  field={"max_references_count"}
                  inputValue={reference.max_references_count}
                  size={"small"}
                  className={"mt-1"}
                  sx={{
                    fontSize: "12px",
                    maxWidth: { xs: "100%", md: "70px" },
                  }}
                  inputProps={{
                    sx: {
                      padding: "3px 14px",
                      background: "#F6F7FB",
                      border: "1px solid #DADADA",
                      borderRadius: "5px",
                    },
                  }}
                  variant={"outlined"}
                  validators={[
                    {
                      check: Validators.required,
                      message: "This field is required",
                    },
                    {
                      check: Validators.number,
                      message: "Please enter a valid number",
                    },
                  ]}
                  disabled
                />
              </Stack>
              <Stack>
                <Typography
                  variant={"body1"}
                  color={"#333333"}
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    textAlign: "center",
                    padding: "10px 5px",
                    display: { xs: "block", md: "inline" },
                  }}
                >
                  professional references covering the last
                </Typography>
              </Stack>
              <Stack sx={{ ml: 1 }}>
                <InputField
                  placeholder={""}
                  field={"referee_connection_year"}
                  inputValue={reference.referee_connection_year}
                  size={"small"}
                  className={"mt-1"}
                  sx={{
                    fontSize: "12px",
                    maxWidth: { xs: "100%", md: "70px" },
                  }}
                  inputProps={{
                    sx: {
                      padding: "3px 14px",
                      background: "#F6F7FB",
                      border: "1px solid #DADADA",
                      borderRadius: "5px",
                    },
                  }}
                  variant={"outlined"}
                  validators={[
                    {
                      check: Validators.required,
                      message: "This field is required",
                    },
                    {
                      check: Validators.number,
                      message: "Please enter a valid number",
                    },
                  ]}
                  disabled
                />
              </Stack>
              <Stack>
                <Typography
                  variant={"body1"}
                  color={"#333333"}
                  sx={{
                    paddingTop: "10px",
                    fontSize: "14px",
                    fontWeight: 400,
                    paddingLeft: "12px",
                  }}
                >
                  years
                </Typography>
              </Stack>
            </Box>
            {/* <Box style={{ display: "flex", marginTop: "12px" }}>
              <Stack>
                <Typography
                  variant={"body1"}
                  color={"#333333"}
                  sx={{
                    paddingTop: "10px",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  Select the minimum no. of employment references you require:
                </Typography>
              </Stack>
              <Stack sx={{ ml: 1 }}>
                <InputField
                  placeholder={"123..."}
                  field={"min_references_count"}
                  inputValue={reference.min_references_count}
                  size={"small"}
                  className={"mt-1"}
                  sx={{
                    fontSize: "12px",
                    ml: 1,
                    maxWidth: "70px",
                  }}
                  inputProps={{
                    sx: {
                      padding: "3px 14px",
                      background: "#F6F7FB",
                      border: "1px solid #DADADA",
                      borderRadius: "5px",
                    },
                  }}
                  variant={"outlined"}
                  validators={[
                    {
                      check: Validators.required,
                      message: "This field is required",
                    },
                    {
                      check: Validators.number,
                      message: "Please enter a valid number",
                    },
                  ]}
                  disabled
                />
              </Stack>
            </Box>
            <Box style={{ marginTop: "12px" }}>
              <Stack>
                <Typography
                  variant={"body1"}
                  color={"#333333"}
                  sx={{
                    paddingTop: "10px",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  In case of where the candidate does not have minimum
                  employment references, select the type of reference
                </Typography>
                <Typography
                  variant={"body1"}
                  color={"#333333"}
                  sx={{
                    paddingTop: "10px",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  you will accept :
                </Typography>
              </Stack>
              <Stack
                style={{ display: "flex", marginTop: "12px" }}
                direction={"row"}
              >
                <FormControlLabel
                  control={
                    <CheckBox
                      checked={
                        reference.types?.indexOf(
                          identifiers.character as string
                        ) > -1
                      }
                      disabled
                      type={"square"}
                    />
                  }
                  label={
                    <Typography
                      variant={"body1"}
                      color={"#333333"}
                      sx={{
                        paddingLeft: "10px",
                        paddingRight: "20px",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      Character Reference
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <CheckBox
                      checked={
                        reference.types?.indexOf(
                          identifiers.academic as string
                        ) > -1
                      }
                      disabled
                      type={"square"}
                    />
                  }
                  label={
                    <Typography
                      variant={"body1"}
                      color={"#333333"}
                      sx={{
                        paddingLeft: "10px",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      Academic Reference
                    </Typography>
                  }
                />
              </Stack>
            </Box> */}
            <Box>
              <Typography className="comment-heading" variant="caption">
                Additional Comments
                <Typography className="optional-heading" variant="caption">
                  (optional)
                </Typography>
              </Typography>
              <Stack>
                <InputField
                  data-tut="candidate_required_ref_instrustion_comments"
                  field={"comment"}
                  inputValue={reference.comment}
                  disabled
                  placeholder="Please make sure to add details of 5 different Referees."
                  multiline
                  rows={2}
                  sx={{ borderRadius: "8px", border: "1px solid #DADADA" }}
                />
              </Stack>
            </Box>
          </Stack>
        </Paper>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          marginTop: { xs: "10px", md: "35px" },
          textAlign: "center",
          paddingBottom: { xs: "20px", md: "10px" },
        }}
      >
        <PrimaryButton
          data-tut="candidate_required_ref_instrustion_add_referee_btn"
          fullWidth={false}
          sx={{
            background: "#5AB9F9",
            boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
            borderRadius: "6px",
            color: "white",
          }}
          disabled={loading}
          onClick={acknowledge}
        >
          {loading && <CircularProgress sx={{ mr: 1 }} size={30} />}Add Referee
          Now
        </PrimaryButton>
      </Grid>
    </Grid>
  );
};

export default ReferenceRequirement;
