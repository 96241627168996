import {Avatar, Badge, Button, Stack, Typography} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import {blue} from "@mui/material/colors";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import React, {FC, useRef} from "react";
import {agency} from "../../../utils/constants/routes";
import {useNavigate} from "react-router-dom";

const SubNotifications: FC<{
  handleClose: (value: boolean) => void;
  navigate: (candidateId: string, requestId: string) => void;
  notificationGroup: any;
  showArrows: boolean;
}> = ({handleClose, navigate, notificationGroup, showArrows}) => {
  const Navigate = useNavigate();
  const badgeContainerRef = useRef<HTMLDivElement>(null);

  const getTimeAgo = (timestamp: number): string => {
    console.log(timestamp)
    const date = new Date(timestamp);
    const now = new Date();
    const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    if (seconds < 60) {
      return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    }

    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    }

    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    }

    const days = Math.floor(hours / 24);
    if (days < 30) {
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    }

    const months = Math.floor(days / 30);
    if (months < 12) {
      return `${months} month${months !== 1 ? 's' : ''} ago`;
    }

    const years = Math.floor(months / 12);
    return `${years} year${years !== 1 ? 's' : ''} ago`;
  };


  const handleScrollLeft = () => {
    if (badgeContainerRef.current) {
      badgeContainerRef.current.scrollLeft -= 100;
    }
  };

  const handleScrollRight = () => {
    if (badgeContainerRef.current) {
      badgeContainerRef.current.scrollLeft += 100;
    }
  };

  return(
    <Stack alignItems={'center'} flexDirection={'row'} gap={'1rem'} justifyContent={'space-between'} position={"relative"}>
      {showArrows &&
        <Stack sx={{
          background: 'linear-gradient(270deg, rgba(153, 153, 153, 0) 2.11%, #FFFFFF 100%)',
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          padding: '0 0.5rem',
          zIndex: 8,
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Button
            onClick={handleScrollLeft}
            variant="outlined"
            sx={{
              backgroundColor: 'white',
              padding: 0,
              minWidth: 24,
              '&:hover': {
                background: '#fff'
              }
            }}
          >
            <KeyboardArrowLeftIcon />
          </Button>

        </Stack>
      }
      <Stack
        alignItems={'center'}
        flexDirection={'row'}
        padding={!showArrows ? '1rem 2rem 2rem 1rem' : '1rem 3rem'}
        gap={'1rem'}
        maxWidth={'100%'}
        ref={badgeContainerRef}
        sx={{
          overflowX: {
            xs: 'auto',
            md: 'hidden'
          },
          overflowY: {
            xs: 'scroll',
            md: 'hidden'
          },
          flexWrap: showArrows ? 'nowrap' : 'wrap'
        }}
      >
        {Object.keys(notificationGroup).length > 0 && notificationGroup.sub_notifications.map((subNotification: any, subIndex: number) => (
          subNotification.seen ? (
            <Stack
              key={subIndex}
              padding={'1rem'}
              borderRadius={'0.5rem'}
              alignItems={'center'}
              flexDirection={'row'}
              boxShadow={'0 0.2rem 0.4rem rgba(0, 0, 0, 0.1)'}
              sx={{ border: '1px solid #fff', background: 'white', flexWrap: 'nowrap', cursor: 'pointer', '&:hover': {
                  border: '1px solid #b9e3ff',
                  background: '#f2f9ff'
                }

              } }
              gap={'0.5rem'}
              onClick={() => navigate(notificationGroup.candidate_id, notificationGroup.request_id)}
            >
              <Avatar style={{ color: 'white' }}>
                {subNotification.title.includes('Academic') ? (
                  <SchoolOutlinedIcon />
                ) : subNotification.title.includes('Right to Work') || subNotification.title.includes('DBS') ? (
                  <WorkOutlineOutlinedIcon />
                ) : (
                  <ContactMailOutlinedIcon />
                )}
              </Avatar>
              <Stack>
                <Typography fontSize={'1rem'} fontWeight={450} noWrap sx={{color: '#535353'}}>{subNotification.title}</Typography>
                <Typography fontSize={'0.75rem'} noWrap sx={{color: '#939393'}}>{getTimeAgo(subNotification.created_at)}</Typography>
              </Stack>
            </Stack>
          ) : (
            <Stack key={subIndex}>
              <Badge color="primary" variant="dot" sx={{
                '& .MuiBadge-badge': {
                  position: 'relative',
                  top: '10px',
                  right: '17px',
                  background: 'red'
                }
              }}>
                <Stack
                  padding={'1rem'}
                  borderRadius={'0.5rem'}
                  alignItems={'center'}
                  flexDirection={'row'}
                  boxShadow={'0 0.2rem 0.4rem rgba(0, 0, 0, 0.1)'}
                  sx={{ border: '1px solid #2196f3', background: 'white', flexWrap: 'nowrap', cursor: 'pointer', '&:hover': {
                      border: '1px solid #b9e3ff',
                      background: '#f2f9ff'
                    }}}
                  gap={'0.5rem'}
                  onClick={() => navigate(notificationGroup.candidate_id, notificationGroup.request_id)}
                >
                  <Avatar style={{ color: 'white' }} sx={{ bgcolor: blue[500] }}>
                    {subNotification.title.includes('Academic') ? (
                      <SchoolOutlinedIcon />
                    ) : subNotification.title.includes('Right to Work') || subNotification.title.includes('DBS') ? (
                      <WorkOutlineOutlinedIcon />
                    ) : (
                      <ContactMailOutlinedIcon />
                    )}
                  </Avatar>
                  <Stack>
                    <Typography fontSize={'1rem'} fontWeight={450} noWrap>{subNotification.title}</Typography>
                    <Typography fontSize={'0.75rem'} noWrap>{getTimeAgo(subNotification.created_at)}</Typography>
                  </Stack>
                </Stack>
              </Badge>
            </Stack>
          )
        ))}
      </Stack>
      {showArrows &&
        <Stack sx={{
          background: 'linear-gradient(270deg, #f6f6f6 11.36%, rgba(153, 153, 153, 0) 100%)',
          position: 'absolute',
          right: 0,
          top: 0,
          bottom: 0,
          padding: '0 0.5rem',
          zIndex: 8,
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Button
            onClick={handleScrollRight}
            variant="outlined"
            sx={{
              backgroundColor: 'white',
              padding: 0,
              minWidth: 24,
              '&:hover': {
                background: '#fff'
              }
            }}
          >
            <KeyboardArrowRightIcon />
          </Button>

        </Stack>
      }

    </Stack>
  )
}
export default SubNotifications
