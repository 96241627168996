import React, {
  FC,
  Fragment,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { Alert, Box, Grid, Stack, TextFieldProps, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Form, { FormDataModel } from "../../../../components/Form";
import { removeErrorFieldsFromValues } from "../../../../utils/validators";
import { identifiers } from "../../../../utils/constants/identifiers";
import { RefereeFormModel } from "./RefereeFormModel";
import PrimaryButton from "../../../../components/Button/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { ReferenceData, setReferenceData } from "../../../../utils/redux";
import { routes } from "../../../../utils/constants/routes";
import dayjs, { Dayjs } from "dayjs";
import { DateRange } from "@mui/lab/DateRangePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/lab";
import InputField from "../../../../components/InputField";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../utils/constants/coachMarks";
import { isTourCompleted, markTourAsCompleted } from "../../../../utils/storage/tours";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const RefereeDetails: FC = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { type } = useParams() as any;
  let RefereeForm: RefObject<Form | null | undefined> = useRef();
  const { setIsOpen, currentStep, setCurrentStep } = useTour();

  const reference = useSelector(ReferenceData);

  const [hasError, setHasError] = useState<boolean>(false);
  const [buttonActive, setButtonActive] = useState<boolean>(true);
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);

  useEffect(() => {
    if (!reference.referee_details) {
      setCurrentStep(tourStep.referee_application_details.index);
    }
  }, [reference, setCurrentStep]);

  useEffect(() => {
    if (tourStep.referee_application_details.index === currentStep) {
      if (!isTourCompleted('referee_application_details')) {
        setIsOpen(true);
      }
    } else if (
      currentStep ===
      tourStep.referee_application_details.index +
        tourStep.referee_application_details.steps
    ) {
      setIsOpen(false);
      markTourAsCompleted('referee_application_details');
      dispatch(
        setReferenceData({ ...reference, referee_application_details: true })
      );
      //setCoachMarksValue()
    }
  }, [currentStep, dispatch, reference, setIsOpen]);

  useEffect(() => {
    if (reference.referee_details?.candidate_employment_date) {
      const { from, to } = reference.referee_details
        ?.candidate_employment_date as {
        from: string;
        to: string;
      };
      setDateRange([!!from ? dayjs(from) : null, !!to ? dayjs(to) : null]);
    }
  }, [reference.referee_details]);

  const proceed = () => {
    const { getFormData } = RefereeForm.current as {
      getFormData: () => { formData: FormDataModel; isFormValid: boolean };
    };
    const { formData, isFormValid } = getFormData();
    const body: FormDataModel = removeErrorFieldsFromValues(formData);
    if (isFormValid) {
      const active_index = reference.active_index;
      if (type === "character") {
        dispatch(
          setReferenceData({
            ...reference,
            referee_details: {
              ...body,
            },
            active_index: active_index + 1,
          })
        );
      } else if (type === "employment-gap") {
        dispatch(
          setReferenceData({
            ...reference,
            referee_details: {
              ...body,
              comments: reference.referee_details?.comments,
              candidate_employment_date: {
                from: dateRange[0]?.format(),
                to: dateRange[1]?.format(),
              },
            },
            active_index: active_index + 1,
          })
        );
      } else {
        dispatch(
          setReferenceData({
            ...reference,
            referee_details: {
              ...body,
              candidate_employment_date: {
                from: dateRange[0]?.format(),
                to: dateRange[1]?.format(),
              },
            },
            active_index: active_index + 1,
          })
        );
      }
      Navigate(`../${routes[reference.available_routes[active_index + 1]]}`);
    } else {
      setHasError(true);
    }
  };

  return (
    <Stack padding={'2rem 5rem 2rem 5rem'}>
      <>
        <Stack
          direction={"row"}
          alignItems="center"
          sx={{
            // background: '#FFFAE7',
            background: '#FFDEDE',
            color: "black",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            padding: "10px",
            border: "1px dashed white",
          }}
          gap={'1rem'}
        >
          <WarningAmberIcon sx={{ color: "red" }} />
          <Typography color={'red'} fontSize={'1.25rem'} >
            Verify {type === 'employment' ? 'Employment' : ''} Details Before Proceeding
          </Typography>
        </Stack>
        <Box sx={{
          background: "#fff",
          margin: "0",
          padding: "0.5rem",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          boxShadow: "0px 2px 4px #d9d9d9",
          fontSize: "13px"
        }}
        >
          <Typography>Please ensure that the {type === 'employment' ? 'employment' : ''} period is accurate before proceeding.</Typography>
          <Typography>Changes cannot be made later. Thank you for your attention.</Typography>
        </Box>
      </>
      <Grid
        container
        display={"flex"}
        justifyContent={"center"}
        marginTop={'2rem'}
      >
        <Grid
          item
          xs={10}
          lg={8}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                data-tut="apllication_details_page"
                variant="h6"
                fontWeight={"500"}
                fontSize={"14px"}
                sx={{ mb: 2 }}
              >
                Referee Details
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Form
              values={reference.referee_details}
              hasError={hasError}
              fieldError={identifiers.field_error as string}
              ref={RefereeForm as RefObject<Form>}
              model={RefereeFormModel(reference.type)}
              onChange={(field, value, formData, isFormValid) =>
                setButtonActive(isFormValid)
              }
            />
            {reference.type !== identifiers.character && (
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateRangePicker
                    open={open}
                    disabled={reference.type === identifiers.gap}
                    onClose={() => setOpen(false)}
                    startText={
                      reference.type === identifiers.academic
                        ? "Course start date"
                        : reference.type === identifiers.gap
                          ? "Gap from"
                          : "Employment from"
                    }
                    endText={
                      reference.type === identifiers.academic
                        ? "Course end date"
                        : reference.type === identifiers.gap
                          ? "Gap to"
                          : "Employment to"
                    }
                    calendars={2}
                    value={dateRange}
                    onChange={(newValue: any) => {
                      setDateRange(newValue);
                    }}
                    inputFormat={identifiers.date_format as string}
                    renderInput={(
                      startProps: TextFieldProps,
                      endProps: TextFieldProps
                    ) => (
                      <Fragment>
                        <InputField
                          {...startProps}
                          disabled={reference.type === identifiers.gap}
                          size={"small"}
                          autoComplete="off"
                          sx={{ background: "#FFFFFF", borderRadius: "8px" }}
                          onClick={() => {
                            if (reference.type !== identifiers.gap) {
                              setOpen(true);
                            }
                          }}
                        />

                      <Box sx={{ mx: 2 }}> to </Box>

                      <InputField
                        {...endProps}
                        size={"small"}
                        autoComplete="off"
                        sx={{ background: "#FFFFFF", borderRadius: "8px" }}
                        onClick={() => {
                          if (reference.type !== identifiers.gap) {
                            setOpen(true);
                          }
                        }}
                        disabled={reference.type === identifiers.gap}
                      />
                    </Fragment>
                  )}
                />
              </LocalizationProvider>
            </Grid>
          )}
          {reference.type === identifiers.gap &&
            reference?.is_gap_reference && (
              <Grid item xs={12} md={12}>
                {/* {isGapReference===true && */}
                <InputField
                  label={"Reason Of Gap in Employment"}
                  multiline
                  value={reference?.referee_details?.comments}
                  // textChange={(e) => {
                  //   console.log(e,);
                  // setComment(e);
                  // }}
                  disabled={true}
                  rows={6}
                  sx={{ background: "#ffffff" }}
                  placeholder={
                    "The applicant may email once they have fulfilled the requirement of this request."
                  }
                />
              </Grid>
            )}
        </Grid>
        <Grid container spacing={2}>
          <Grid
            item
            textAlign={"center"}
            xs={12}
            sx={{
              position: { xs: "fixed", sm: "fixed", md: "relative" },
              bottom: "0px",
              left: "0px",
              width: "100%",
              zIndex: "99",
              textAlign: { xs: "center", md: "center" },
              background: { xs: "white", md: "transparent" },
              boxShadow: { xs: "0px -4px 7px #e6e6e6", md: "none" },
              borderRadius: { xs: "15px 15px 0px 0px", md: "none" },
              py: 1,
              px: 3,
              mt: "20px",
            }}
          >
            <PrimaryButton
              fullWidth={false}
              sx={{
                background: "#FFFFFF",
                border: " 1px solid #5AB9F9",
                borderRadius: "5px",
                padding: "4px 44px",
                color: "#5AB9F9",
                fontSize: "14px",
                margin: "0px 20px",
              }}
              onClick={() => {
                const active_index = reference.active_index;
                dispatch(
                  setReferenceData({
                    ...reference,
                    active_index: active_index - 1,
                  })
                );
                Navigate("../");
              }}
            >
              Go Back
            </PrimaryButton>
            <PrimaryButton
              fullWidth={false}
              sx={{
                background: "#5AB9F9",
                boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
                borderRadius: "5px",
                padding: "4px 30px",
                color: "white",
              }}
              disabled={
                !buttonActive ||
                (type !== identifiers.character &&
                  !dateRange[0]?.format(identifiers.date_input as string) &&
                  !dateRange[1]?.format(identifiers.date_input as string))
              }
              onClick={proceed}
              data-tut="details_proceed_btn"
            >
              Proceed
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    </Stack>
  );
};

export default RefereeDetails;
