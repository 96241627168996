import { AnyAction, configureStore, EnhancedStore } from '@reduxjs/toolkit';
import { ThunkMiddlewareFor } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import authenticationSlice from '../reducer/authentication-slice';
import requestSlice from '../reducer/request-slice';
import candidateVettingSlice from '../reducer/candidate-vetting-slice';
import notificationDetailsSlice from '../reducer/Notification-details-slice';
import referenceSlice from '../reducer/reference-slice';
import uCheckSlice from '../reducer/u-check-slice';
import webSocketSlice from '../reducer/websocket-slice';

const store: EnhancedStore<any, AnyAction, [ThunkMiddlewareFor<any>]> =
  configureStore({
    reducer: {
      authenticationSlice,
      requestSlice,
      candidateVettingSlice,
      notificationDetailsSlice,
      referenceSlice,
      uCheckSlice,
      webSocketSlice
    },
  });

export default store;
