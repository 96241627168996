const Questions = (
  type: string
): {
  [key: string]: {
    label: string;
    value: string | boolean | number | null;
  };
} => {
  const questions: {
    [key: string]: {
      label: string;
      value: string | boolean | number | null;
    };
  } = {};
  switch (type) {
    case 'employment':
      questions.main_duties_responsibility = {
        label: 'Applicant’s main duties and responsibilities',
        value: '',
      };
      questions.relevant_skills = {
        label:
          'Relevant skills and experience gained by the applicant at the employment',
        value: '',
      };
      questions.training = {
        label: 'Training received by the applicant whilst in employment',
        value: '',
      };
      questions.rating_relationship_with_colleagues = {
        label: 'Relationship with colleagues',
        value: 5,
      };
      questions.rating_relationship_with_seniors = {
        label: 'Relationship with seniors',
        value: 5,
      };
      questions.rating_attendance_record = {
        label: 'Attendance Record',
        value: 5,
      };
      questions.rating_punctuality = {
        label: 'Punctuality / timekeeping',
        value: 5,
      };
      questions.rating_quality_of_work = {
        label: 'Quality of work',
        value: 5,
      };
      questions.rating_honesty = {
        label: 'Honesty & Integrity',
        value: 5,
      };
      questions.rating_reliability = {
        label: 'Reliability',
        value: 5,
      };
      questions.rating_flexibility = {
        label: 'Flexibility',
        value: 5,
      };
      questions.reemployment_policy = {
        label: 'Do you have a re-employment policy?',
        value: false,
      };
      questions.reemployment_yes = {
        label: 'If Yes, would you rehire this candidate?',
        value: false,
      };
      questions.rehire_no = {
        label: 'If No, please give reasons',
        value: '',
      };
      break;
    case 'character' :
      questions.referee_candidate_time_known = {
        label: 'How long have you known the Applicant?',
        value: '',
      };
      questions.referee_candidate_relationship_outline = {
        label:
          'Please outline below the relationship you have, or had, to the Applicant',
        value: '',
      };
      questions.candidate_character = {
        label: 'How would you describe Applicants character?',
        value: '',
      };
      questions.further_information = {
        label:
          'Please provide any further information here including, if possible, the main areas of responsibility and achievements to date along with any skills or potential areas of weakness you have noted.',
        value: '',
      };
      break;
      case 'employment_gap' :
      questions.referee_candidate_time_known = {
        label: 'How long have you known the Applicant?',
        value: '',
      };
      questions.referee_candidate_relationship_outline = {
        label:
          'Please outline below the relationship you have, or had, to the Applicant',
        value: '',
      };
      questions.candidate_character = {
        label: 'How would you describe Applicants character?',
        value: '',
      };
      questions.further_information = {
        label:
          'Please provide any further information here including, if possible, the main areas of responsibility and achievements to date along with any skills or potential areas of weakness you have noted.',
        value: '',
      };
      break;
    case 'academic':
      questions.referee_candidate_time_known = {
        label: 'How long have you known the Applicant?',
        value: '',
      };
      questions.candidate_assessment = {
        label:
          'According to you, please comment on the following: 1) ability to carry on advanced study and research; 2) teaching ability; 3) promise for a successful career in this field; 4) weaknesses, if any; and 5) communication skills (oral and written). Indicate the basis of your general assessment.',
        value: '',
      };
      questions.rating_past_academic_achievement = {
        label: 'Past Academic Achievement',
        value: 5,
      };
      questions.rating_scholarly_promise = {
        label: 'Scholarly promise',
        value: 5,
      };
      questions.rating_independent_research_capability = {
        label: 'Independent research / capabilities',
        value: 5,
      };
      questions.rating_written_english_proficiency = {
        label: 'English proficiency - written',
        value: 5,
      };
      questions.rating_oral_english_proficiency = {
        label: 'English proficiency - oral',
        value: 5,
      };
      questions.rating_creativity = {
        label: 'Creativity',
        value: 5,
      };
      questions.rating_resourcefulness = {
        label: 'Resourcefulness',
        value: 5,
      };
      questions.rating_ability_to_meet_deadlines = {
        label: 'Ability to meet deadlines',
        value: 5,
      };
      questions.rating_overall = {
        label: 'Overall, I would rate this student as',
        value: 5,
      };
      break;
  }
  return questions;
};

export default Questions;
