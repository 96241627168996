export const isTourCompleted = (value: string) => {
  const completedTours = JSON.parse(localStorage.getItem('completedTours') || '[]');
  return completedTours.includes(value);
}

export const markTourAsCompleted = (value: string) => {
  const completedTours = JSON.parse(localStorage.getItem('completedTours') || '[]');

  if (!completedTours.includes(value)) {
    completedTours.push(value);
    localStorage.setItem('completedTours', JSON.stringify(completedTours));
  }
};
