import React, { FC } from "react";
import { Grid } from "@mui/material";
import FileUpload from "react-material-file-upload";
import { images } from "../../../../../../../../utils/constants/images";
import { identifiers } from "../../../../../../../../utils/constants/identifiers";

const FromDevice: FC<{
  files: File[] | undefined;
  setFiles: (file: File[]) => void;
}> = ({ files, setFiles }) => {
  return (
    <Grid
      container
      position={"relative"}
      justifyContent={"center"}
      xs={12}
     // spacing={2}
      sx={{ p: {xs:1,md:3}, pt: 2 }}
    >
      <FileUpload
        sx={{
          background: `url(${images.dropZoneText}) no-repeat center bottom 20px`,
          backgroundSize: { xs: "80%", md: "auto" },
          border: "2px dashed",
          minHeight: "260px",
          width: "100%",
          backgroundColor: "white",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
        value={files as File[]}
        onChange={setFiles}
        buttonProps={{ variant: "text" }}
        buttonText={"Choose Files"}
        multiple={false}
        title={"Drop Your Image here or"}
        typographyProps={{
          fontSize: 14,
          fontWeight: 600,
        }}
        accept={identifiers.accept as string}
        maxSize={identifiers.maxFileSize as number}
      />
    </Grid>
  );
};

export default FromDevice;
