import React, { FC, useState, RefObject, useRef, useMemo } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { PersonalInformationFormModel } from "./PersonalInformationFormModel";
import Form, {
  FormDataModel,
} from "../../../../../../../../../components/Form";
import { removeErrorFieldsFromValues } from "../../../../../../../../../utils/validators";
import {
  UCheckPersonalInformation,
  setPersonalInformation,
} from "../../../../../../../../../utils/redux";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../../../../../../../../components/Button/PrimaryButton";
import Modal from "../../../../../../../../../components/modal/Modal";
import { UCheckProps } from "../UCheckModel";
import { images } from "../../../../../../../../../utils/constants/images";

const PersonalInformation: FC<UCheckProps & { dbs_type: string }> = ({
  handleNext,
  dbs_type,
}) => {
  let PersonalInformationForm: RefObject<Form | null | undefined> = useRef();
  const dispatch = useDispatch();
  const uCheckPersonalInformation = useSelector(UCheckPersonalInformation);

  const [viewPopUpModal, setViewPopUpModal] = useState<boolean>(false);

  const proceed = () => {
    if (handleNext) {
      handleNext();
    }
  };

  const onChangeHandler = (
    field: string,
    value: string,
    formData: FormDataModel,
    isFormValid: boolean
  ) => {
    let formBody: FormDataModel = removeErrorFieldsFromValues(formData);
    if (field === "birth_country") {
      if (!!formData.birth_nationality) {
        if (formData.birth_nationality !== value) {
          setViewPopUpModal(true);
        }
      }
    }
    if (field === "birth_nationality") {
      if (!!formData.birth_country) {
        if (formData.birth_country !== value) {
          setViewPopUpModal(true);
        }
      }
    }
    if (field === "has_nin") {
      if (value === "y") {
        formBody = {
          ...formBody,
          nin: "",
        };
      }
      dispatch(
        setPersonalInformation({
          ...uCheckPersonalInformation,
          ...formBody,
          form_valid: value !== "y",
        })
      );
    } else {
      dispatch(
        setPersonalInformation({ ...formBody, form_valid: isFormValid })
      );
    }
    console.log(uCheckPersonalInformation, isFormValid, formData);
  };

  const viewModalText = useMemo(() => {
    return (
      <>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              background: "#FEFBEC",
              padding: "20px ",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
            display={"flex"}
            alignItems={"center"}
          >
            <Box
              sx={{ display: "flex", direction: "row", alignItems: "center" }}
            >
              <img src={images.warningTooltip} alt={"tooltip"} />
              <Typography
                variant="h6"
                sx={{
                  ml: 2,
                  fontSize: 18,
                  fontWeight: 500,
                  color: "#000000",
                  textAlign: "center",
                  letterSpacing: "0.15px",
                }}
              >
                Alert
              </Typography>
            </Box>
            <img
              onClick={() => {
                setViewPopUpModal(false);
              }}
              style={{ width: "20px", cursor: "pointer" }}
              src={images.closeBtn}
              alt={"close button"}
            />
          </Grid>
          <Grid item padding={"10px"}>
            <Typography
              variant="h6"
              sx={{
                mb: 3,
                fontSize: 16,
                fontWeight: 500,
                color: "#727272",
              }}
            >
              Please be aware that Country of Birth and Nationality do not
              match. Please correct if necessary
            </Typography>
          </Grid>
          <Grid
            padding={"10px"}
            xs={12}
            item
            textAlign="center"
            justifyContent="center"
          >
            <PrimaryButton
              //variant={"text"}
              fullWidth={false}
              sx={{
                mt: 1,
                mr: 2,
                background: "#5AB9F9",
                color: "#ffffff",
                fontSize: 16,
                fontWeight: 500,
              }}
              onClick={() => setViewPopUpModal(false)}
              // disabled={isLoading}
            >
              I Understand
            </PrimaryButton>
          </Grid>
        </Grid>
      </>
    );
  }, []);

  const openViewModal = useMemo(() => {
    return (
      <Modal
        open={viewPopUpModal}
        setModalClose={setViewPopUpModal}
        children={viewModalText}
        title={undefined}
        size="sm"
        padding="0px"
        Radius={"5px"}
        isBackdrop={true}
        className={undefined}
      />
    );
  }, [viewPopUpModal, viewModalText]);

  return (
    <>
      {openViewModal}
      <Grid container spacing={2}>
        <Form
          values={uCheckPersonalInformation || {}}
          ref={PersonalInformationForm as RefObject<Form>}
          model={PersonalInformationFormModel(
            uCheckPersonalInformation,
            dbs_type
          )}
          onChange={(field, value, formData, isFormValid) => {
            onChangeHandler(field, value, formData, isFormValid);
          }}
        />
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            pt: 2,
            mt: 2,
            borderTop: "1px solid #0000004d",
          }}
          xs={12}
        >
          <PrimaryButton
            fullWidth={false}
            disabled={!uCheckPersonalInformation.form_valid}
            sx={{
              minWidth: "100px",
              background: "#1276D3",
              color: "white",
            }}
            onClick={proceed}
          >
            Next
          </PrimaryButton>
        </Grid>
      </Grid>
    </>
  );
};

export default PersonalInformation;
