import React, { FC } from "react";
import {
  Navigate,
  Route,
  Routes as Switch,
  useLocation,
} from "react-router-dom";
import { Grid, Paper, AlertColor } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  Authenticated,
  ProfileComplete,
  UserRole,
  NotificationDisplay,
  NotificationSeverity,
  NotificationMessage,
  setNotificationMessage,
} from "../utils/redux";
import {
  admin,
  admin_root,
  agency,
  agency_root,
  candidate,
  candidate_root,
  routes,
} from "../utils/constants/routes";
import Notification from "../components/Notification";

// Components
import Login from "../screens/Authentication/Login";
import ForgotPassword from "../screens/Authentication/ForgotPassword";
import CreateRequest from "../screens/Authenticated/Agency/CreateRequest";
import RequestDetails from "../screens/Authenticated/Agency/RequestDetails";
import AuthenticationLayout from "./AuthenticationLayout";
import PrivateRoute from "./PrivateRoute";
import AgencyProfileCompletionRoute from "./AgencyProfileCompletionRoute";
import InviteMembers from "../screens/Authenticated/Agency/InviteMembers";
import Applicants from "../screens/Authenticated/Agency/Applicants";
import DocumentRepository from "../screens/Authenticated/Agency/DocumentRepository";
import CandidateDocumentRepository from "../screens/Authenticated/Candidate/DocumentRepository";
import Dashboard from "../screens/Authenticated/Agency/Dashboard";
import RequestList from "../screens/Authenticated/Agency/RequestList";
import CandidateProfile from "../screens/Authenticated/Agency/CandidateProfile";
import SignUp from "../screens/Authentication/SignUp";
import ViewRequest from "../screens/Common/ViewRequest";
import CandidateDashboard from "../screens/Authenticated/Candidate/Dashboard";
import CandidateRequestList from "../screens/Authenticated/Candidate/RequestList";
import PageNotFound from "../screens/Common/PageNotFound";
import RequestDetailsTabs from "../screens/Authenticated/Agency/RequestDetails/RequestDetailsTabs/RequestDetailsTabs";
import Reference from "../screens/Common/Reference";
import RefereeDetails from "../screens/Common/Reference/RefereeDetails";
import StartReference from "../screens/Common/Reference/StartReference";
import KedQuestionnaire from "../screens/Common/Reference/KedQuestionnaire";
import AdditionalQuestionnaire from "../screens/Common/Reference/AdditionalQuestionnaire";
import AdditionalComments from "../screens/Common/Reference/AdditionalComments";
import AdditionalRefereeForm from "../screens/Common/Reference/AdditionalRefereeForm";
import VerifyReference from "../screens/Common/VerifyReference";
import HelpPage from "../screens/Common/HelpPage";
import PrivacyPolicy from "../screens/Common/PrivacyPolicy";
import Agencies from "../screens/Authenticated/Admin/Agency";
import RefereeAdditionalComments from "../screens/Common/Reference/StartReference/RefereeAdditionalComments"
import NotificationPage from "../screens/Common/Notification";

const Root: FC = () => {
  let location = useLocation();
  const isAuthenticated = useSelector(Authenticated);
  const isProfileComplete = useSelector(ProfileComplete);
  const userRole = useSelector(UserRole);

  console.log("user role root:",userRole);

  let { from } = location.state || {
    from: {
      pathname: userRole === "agency" ? agency.dashboard : userRole === "admin" ? admin.agencies : candidate.dashboard ,
    },
  };

  if (isAuthenticated && userRole) {
    if (from.pathname.indexOf(agency_root) === 0 && userRole !== "agency") {
      from = { pathname: userRole === 'admin' ? admin.agencies : candidate.dashboard };
    }
    if (
      from.pathname.indexOf(candidate_root) === 0 &&
      userRole !== "candidate"
    ) {
      from = { pathname: userRole === 'admin' ? admin.agencies : agency.dashboard };
    }
    if (
      from.pathname.indexOf(admin_root) === 0 &&
      userRole !== "admin"
    ) {
      from = { pathname: userRole === 'agency' ? agency.dashboard : candidate.dashboard };
    }
  }

  return isAuthenticated ? (
    userRole === "agency" ? (
      isProfileComplete ? (
        <Navigate to={from} />
      ) : (
        <Navigate to={agency.profile_completion} />
      )
    ) : (
      <Navigate to={from} />
    )
  ) : (
    userRole === "admin" ?(
      <Navigate to={admin.root} />
    ):
    <Navigate to={candidate.root} />
  );

};


const AgencyRoot: FC = () => {
  let location = useLocation();
  const isAuthenticated = useSelector(Authenticated);
  const isProfileComplete = useSelector(ProfileComplete);

  const { from } = location.state || {
    from: { pathname: agency.dashboard },
  };

  return isAuthenticated ? (
    isProfileComplete ? (
      <Navigate to={from} />
    ) : (
      <Navigate to={agency.profile_completion} />
    )
  ) : (
    <Navigate to={agency.login} />
  );
};


const AdminRoot: FC = () => {
  let location = useLocation();
  const isAuthenticated = useSelector(Authenticated);

  const { from } = location.state || {
    from: { pathname: admin.agencies },
  };
  return isAuthenticated ? <Navigate to={from} /> : <Navigate to={admin.login} />
};


const CandidateRoot: FC = () => {
  let location = useLocation();
  const isAuthenticated = useSelector(Authenticated);
  const isProfileComplete = useSelector(ProfileComplete);

  const { from } = location.state || {
    from: { pathname: candidate.dashboard },
  };

  return isAuthenticated ? (
    isProfileComplete ? (
      <Navigate to={from} />
    ) : (
      <Navigate to={candidate.profile_completion} />
    )
  ) : (
    <Navigate to={candidate.login} />
  );
};

const Routes: FC = () => {
  const dispatch = useDispatch();

  const notificationDisplay = useSelector(NotificationDisplay);
  const notificationSeverity = useSelector(NotificationSeverity);
  const notificationMessage = useSelector(NotificationMessage);

  return (
    <Grid container flexDirection="column">
      <Grid className="pageWrapper" item sx={{ width: "100%" }}>
        <Paper elevation={0} square={true} className="paperWrapper">
          <Notification
            isOpen={notificationDisplay}
            message={notificationMessage}
            severity={notificationSeverity as AlertColor}
            autoHideDuration={6000}
            closeNotification={(value) =>
              dispatch(
                setNotificationMessage({
                  display: value,
                  severity: notificationSeverity,
                  message: notificationMessage,
                })
              )
            }
          />
          <Switch>
            <Route path={routes.root} element={<Root />} />
            <Route path={routes.view_request} element={<ViewRequest />} />
            {/*Agency Routes*/}
            <Route path={agency.root} element={<AgencyRoot />} />
            <Route
              path={agency.login}
              element={
                <AuthenticationLayout Component={Login} role={"agency"} />
              }
            />
            <Route
              path={agency.forgot_password}
              element={
                <AuthenticationLayout
                  Component={ForgotPassword}
                  role={"agency"}
                />
              }
            />
            <Route
              path={agency.profile_completion}
              element={<AgencyProfileCompletionRoute />}
            />
            <Route
              path={agency.documents}
              element={
                <PrivateRoute
                  Component={DocumentRepository}
                  title={"Document Hub"}
                  role={"agency"}
                />
              }
            />
            <Route
              path={agency.dashboard}
              element={
                <PrivateRoute
                  Component={Dashboard}
                  title={"Dashboard"}
                  role={"agency"}
                />
              }
            />
            <Route
              path={agency.requests}
              element={
                <PrivateRoute
                  Component={RequestList}
                  title={"Request List"}
                  role={"agency"}
                />
              }
            />
            <Route
              path={agency.requests + "/create"}
              element={
                <PrivateRoute
                  Component={CreateRequest}
                  title={"Create Request"}
                  role={"agency"}
                />
              }
            />
            <Route
              path={agency.request}
              element={
                <PrivateRoute
                  Component={RequestDetails}
                  title={"Request Details"}
                  role={"agency"}
                />
              }
            >
              <Route path={""} element={<RequestDetailsTabs />} />
              <Route
                path={"applicants"}
                element={<Applicants showTop={false} />}
              />
            </Route>
            <Route
              path={agency.request + "/applicants/:candidate_id"}
              element={
                <PrivateRoute
                  Component={CandidateProfile}
                  title={"Candidate Profile"}
                  role={"agency"}
                />
              }
            />
            <Route
              path={agency.invite}
              element={
                <PrivateRoute
                  Component={InviteMembers}
                  title={"Invite Members"}
                  role={"agency"}
                />
              }
            />
            <Route
              path={agency.applicants}
              element={
                <PrivateRoute
                  Component={Applicants}
                  title={"List of Applicants for All Requests"}
                  role={"agency"}
                />
              }
            />
            {/*Candidate Routes*/}
            <Route path={candidate.root} element={<CandidateRoot />} />
            <Route
              path={candidate.login}
              element={
                <AuthenticationLayout Component={Login} role={"candidate"} />
              }
            />
            <Route
              path={candidate.sign_up}
              element={
                <AuthenticationLayout Component={SignUp} role={"candidate"} />
              }
            />
            <Route
              path={candidate.forgot_password}
              element={
                <AuthenticationLayout
                  Component={ForgotPassword}
                  role={"candidate"}
                />
              }
            />
            <Route
              path={candidate.request}
              element={
                <PrivateRoute
                  Component={ViewRequest}
                  title={"View Request"}
                  role={"candidate"}
                />
              }
            />
            <Route
              path={candidate.documents}
              element={
                <PrivateRoute
                  Component={CandidateDocumentRepository}
                  title={"Document Hub"}
                  role={"candidate"}
                />
              }
            />
            <Route
              path={candidate.dashboard}
              element={
                <PrivateRoute
                  Component={CandidateDashboard}
                  title={"Dashboard"}
                  role={"candidate"}
                />
              }
            />
            <Route
              path={candidate.requests}
              element={
                <PrivateRoute
                  Component={CandidateRequestList}
                  title={"My Requests"}
                  role={"candidate"}
                />
              }
            />
            <Route path={routes.reference} element={<Reference />}>
              <Route path={""} element={<StartReference />} />

              <Route
                path={routes.referee_details}
                element={<RefereeDetails />}
              />
              <Route
                path={routes.ked_questionnaire}
                element={<KedQuestionnaire />}
              />
              <Route
                path={routes.reference_documents}
                element={<AdditionalRefereeForm />}
              />
              <Route
                path={routes.additional_questionnaire}
                element={<AdditionalQuestionnaire />}
              />
              <Route
                path={routes.reference_comments}
                element={<AdditionalComments />}
              />
            </Route>
            <Route
              path={routes.verify_reference}
              element={<VerifyReference />}
            />
            <Route
              path={routes.privacy_policy}
              element={<PrivacyPolicy />}
            />
            <Route
              path={routes.referee_additional_comments}
              element={<RefereeAdditionalComments />}
            />
            <Route
              path={agency.notifications}
              element={
                <PrivateRoute
                  Component={NotificationPage}
                  title={"Notifications"}
                  role={"agency"}
                />
              }
            />
            <Route
              path={agency.help}
              element={
                <PrivateRoute
                  Component={HelpPage}
                  title={"Help"}
                  role={"agency"}
                />
              }
            />
            <Route
              path={candidate.help}
              element={
                <PrivateRoute
                  Component={HelpPage}
                  title={"Help"}
                  role={"candidate"}
                />
              }
            />
            <Route path="*" element={<PageNotFound />} />

            {/* Admin Route */}
            <Route path={admin.root} element={<AdminRoot />} />
            <Route
              path={admin.login}
              element={
                <AuthenticationLayout Component={Login} role={"admin"} />
              }
            />
            <Route
              path={admin.forgot_password}
              element={
                <AuthenticationLayout
                  Component={ForgotPassword}
                  role={"admin"}
                />
              }
            />
            <Route
              path={admin.agencies}
              element={
                <PrivateRoute
                  Component={Agencies}
                  title={"Agencies"}
                  role={"admin"}
                />
              }
            />
          </Switch>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Routes;
