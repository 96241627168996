import React, {
  FC,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
} from "react";
import {
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Box,
} from "@mui/material";
import CheckBox from "../../../../../../../components/CheckBox";
import dayjs from "dayjs";
import { identifiers } from "../../../../../../../utils/constants/identifiers";
import PrimaryButton from "../../../../../../../components/Button/PrimaryButton";
import { images } from "../../../../../../../utils/constants/images";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { styled } from "@mui/material/styles";
import {
  PreviewReference,
  Reference,
  setPreviewReference,
  setReference,
} from "../../../../../../../utils/redux";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "@formio/react";
import Modal from "../../../../../../../components/modal/Modal";

import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../../../../utils/constants/coachMarks";
import {
  User,
  setUserData,
} from "../../../../../../../utils/redux/reducer/authentication-slice";
import * as Amplify from "../../../../../../../utils/services/amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { isTourCompleted, markTourAsCompleted } from "../../../../../../../utils/storage/tours";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "#727272",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#263238",
    fontSize: "14px",
    fontWeight: 600,
    borderTop: "1px solid #DADADA",
    borderBottom: "1px solid #DADADA",
  },
  [`&.${tableCellClasses.head}:first-of-type`]: {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
    maxWidth: "1em",
    width: "1em",
  },
  [`&.${tableCellClasses.head}:nth-of-type(3)`]: {
    maxWidth: "200px",
    width: "200px",
  },
  [`&.${tableCellClasses.head}:last-of-type`]: {
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    maxWidth: "350px",
    paddingLeft: "40px",
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}:first-of-type`]: {
    borderTopLeftRadius: "12px",
    borderBottomLeftRadius: "12px",
    borderLeft: "1px solid #DADADA",
  },
  [`&.${tableCellClasses.body}:last-of-type`]: {
    borderTopRightRadius: "12px",
    borderBottomRightRadius: "12px",
    borderRight: "1px solid #DADADA",
    textAlign: "center",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&": {
    backgroundColor: "white",
    borderRadius: "8px",
    paddingBottom: "15px",
    paddingTop: "15px",
  },
}));

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "form_name_low_search",
    numeric: true,
    disablePadding: false,
    label: "Form Name",
  },
  {
    id: "created_at",
    numeric: true,
    disablePadding: false,
    label: "Upload Date",
  },
];

interface EnhancedTableProps {
  order: boolean;
  orderBy: string;
  onRequestSort: (property: string) => void;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { orderBy, order, onRequestSort } = props;

  const createSortHandler = (property: string) => {
    onRequestSort(property);
  };

  return (
    <StyledTableRow>
      <StyledTableCell></StyledTableCell>
      {headCells.map((headCell) => (
        <StyledTableCell
          key={headCell.id}
          align={headCell.numeric ? "left" : "right"}
          padding={headCell.disablePadding ? "none" : "normal"}
          sortDirection={order ? "asc" : "desc"}
        >
          <TableSortLabel
            active={orderBy === headCell.id}
            direction={order ? "asc" : "desc"}
            onClick={() => createSortHandler(headCell.id)}
          >
            {headCell.label}
            {orderBy === headCell.id && (
              <Box component="span" sx={visuallyHidden}>
                {order ? "sorted ascending" : "sorted descending"}
                descending
              </Box>
            )}
          </TableSortLabel>
        </StyledTableCell>
      ))}
      <StyledTableCell>Action</StyledTableCell>
    </StyledTableRow>
  );
}

const AdditionalQuestionnaire: FC<{
  filter: {
    search: string;
    index: number;
    page_size: number;
    delay: number;
    name: string;
    sort: boolean;
  };
  setFilter: Dispatch<
    SetStateAction<{
      search: string;
      index: number;
      page_size: number;
      delay: number;
      name: string;
      sort: boolean;
    }>
  >;
  additionalQuestionnaires: any[];
  referenceIndex: number;
}> = ({ filter, setFilter, additionalQuestionnaires, referenceIndex }) => {
  const { setIsOpen, currentStep, setCurrentStep } = useTour();

  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };
  const dispatch = useDispatch();
  const reference = useSelector(Reference);
  const previewReference = useSelector(PreviewReference);

  const [selectedForm, setSelectedForm] = useState<any>({});
  const [openQuestionnaire, setOpenQuestionnaire] = useState<boolean>(false);

  const setRequiredReferenceCoachMarksValue = useCallback(async () => {
    //if (!!user && user["custom:reference_tutorial"] !== "true") {
    const currentUser: CognitoUser = await Amplify.UserDetail();
    const body = {
      "custom:saved_forms": "true",
    };
    await Amplify.UpdateUserDetails(body, currentUser);
    await Amplify.RefreshSession();
    dispatch(setUserData({ ...user, ...body }));
    //}
  }, [user, dispatch]);

  useEffect(() => {
    if (!!user && user["custom:saved_forms"] !== "true") {
      setCurrentStep(tourStep.saved_forms.index);
    }
  }, [setCurrentStep, user]);

  useEffect(() => {
    if (tourStep.saved_forms.index === currentStep) {
      if (!isTourCompleted('saved_forms')) {
        setIsOpen(true);
      }
    } else if (
      currentStep ===
      tourStep.saved_forms.index + tourStep.saved_forms.steps
    ) {
      setIsOpen(false);
      markTourAsCompleted('saved_forms');
      if (!!user && user["custom:saved_forms"] !== "true") {
        (async () => {
          await setRequiredReferenceCoachMarksValue();
        })();
      }
    }
  }, [currentStep, setIsOpen, setRequiredReferenceCoachMarksValue, user]);

  const handleRequestSort = (property: string) => {
    setFilter((prevState) => ({
      ...prevState,
      index: 0,
      delay: 0,
      name: property,
      sort: prevState.name === property ? !prevState.sort : true,
    }));
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter((prevState) => ({ ...prevState, index: newPage, delay: 0 }));
  };

  const handleViewQuestionnaire = (question: any) => {
    setSelectedForm(question);
    setOpenQuestionnaire(true);
  };

  const questionnaireModal = useMemo(() => {
    return (
      <Grid container>
        <Grid item xs={12} textAlign={"center"}>
          <Typography variant={"h6"} sx={{ mb: 2 }}>
            {selectedForm.form_name}
          </Typography>
        </Grid>
        <Grid item xs={12} className={"referee-form"}>
          <Form
            form={{
              display: "form",
              components: selectedForm.form_data,
            }}
            options={{ readOnly: true }}
          />
        </Grid>
      </Grid>
    );
  }, [selectedForm]);

  const openQuestionnaireModal = useMemo(() => {
    return (
      <Modal
        open={openQuestionnaire}
        setModalClose={setOpenQuestionnaire}
        children={questionnaireModal}
        title={undefined}
        size="md"
        className={undefined}
      />
    );
  }, [openQuestionnaire, questionnaireModal]);

  const selectQuestionnaire = (questionnaire: any) => {
    const request_references = JSON.parse(
      JSON.stringify(reference.request_references)
    );
    const additional_questionnaires = JSON.parse(
      JSON.stringify(
        request_references[referenceIndex].additional_questionnaires
      )
    );
    if (additional_questionnaires.indexOf(questionnaire.id) > -1) {
      additional_questionnaires.splice(
        additional_questionnaires.indexOf(questionnaire.id),
        1
      );
    } else {
      additional_questionnaires.push(questionnaire.id);
    }

    request_references[referenceIndex].additional_questionnaires =
      additional_questionnaires;

    dispatch(setReference({ ...reference, request_references }));

    const preview_reference = JSON.parse(JSON.stringify(previewReference));

    const preview_additional_questionnaires = !!preview_reference[
      referenceIndex
    ].additional_questionnaire
      ? JSON.parse(
          JSON.stringify(
            preview_reference[referenceIndex].additional_questionnaires
          )
        )
      : [];

    if (
      preview_additional_questionnaires.findIndex(
        (additional_questionnaire: any) =>
          additional_questionnaire.id === questionnaire.id
      ) > -1
    ) {
      preview_additional_questionnaires.splice(
        preview_additional_questionnaires.findIndex(
          (additional_questionnaire: any) =>
            additional_questionnaire.id === questionnaire.id
        ),
        1
      );
    } else {
      preview_additional_questionnaires.push(questionnaire);
    }
    preview_reference[referenceIndex].additional_questionnaires =
      preview_additional_questionnaires;

    dispatch(setPreviewReference(preview_reference));
  };

  return (
    <>
      <Grid container>
        {openQuestionnaireModal}
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 500 }} data-tut="saved_forms">
            Select from saved forms
          </Typography>
          <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
            <Table
              sx={{
                minWidth: 500,
                borderSpacing: "0 1rem",
                borderCollapse: "separate",
              }}
            >
              <TableHead>
                <EnhancedTableHead
                  order={filter.sort}
                  orderBy={filter.name}
                  onRequestSort={handleRequestSort}
                />
              </TableHead>
              <TableBody className={"documents-table-body"}>
                {additionalQuestionnaires
                  .slice(
                    filter.index * filter.page_size,
                    filter.index * filter.page_size + filter.page_size
                  )
                  .map((questionnaire, index) => (
                    <StyledTableRow
                      className={"documents-table-body-row"}
                      key={index}
                    >
                      <StyledTableCell>
                        <CheckBox
                          sx={{ pl: 0 }}
                          type={"circle"}
                          checked={
                            reference.request_references[
                              referenceIndex
                            ].additional_questionnaires?.indexOf(
                              questionnaire.id
                            ) > -1
                          }
                          onChange={() => selectQuestionnaire(questionnaire)}
                        />
                      </StyledTableCell>
                      <StyledTableCell>{questionnaire.name}</StyledTableCell>
                      <StyledTableCell>
                        {dayjs(new Date(questionnaire.created_at)).format(
                          identifiers.date_format as string
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        <PrimaryButton
                          fullWidth={false}
                          variant={"text"}
                          sx={{ p: 0 }}
                          onClick={() => handleViewQuestionnaire(questionnaire)}
                        >
                          <img
                            src={images.viewIcon}
                            alt="view"
                            className="action-icon"
                          />
                        </PrimaryButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                {additionalQuestionnaires.length === 0 && (
                  <StyledTableRow className={"request-table-body-row"}>
                    <StyledTableCell colSpan={7}>
                      <Stack textAlign={"center"}>
                        <img
                          src={images.emptyIcon}
                          alt={"no request"}
                          className={"vertical-align-middle empty-image mb-2"}
                        />
                        <Stack direction={"row"} justifyContent={"center"}>
                          <Typography variant={"body2"}>
                            No Questionnaires found. Create Questionnaire to
                            view
                          </Typography>
                        </Stack>
                      </Stack>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
              {additionalQuestionnaires.length > 0 && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      colSpan={6}
                      count={additionalQuestionnaires.length}
                      rowsPerPage={filter.page_size}
                      page={filter.index}
                      onRowsPerPageChange={(e) => {
                        setFilter((prevState) => ({
                          ...prevState,
                          page_size: parseInt(e.target.value, 10),
                        }));
                      }}
                      onPageChange={handleChangePage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default AdditionalQuestionnaire;
