import React, { FC, ReactNode } from "react";
import { Box } from "@mui/system";
import { Container, Grid, Typography } from "@mui/material";

const MainLayout: FC<{ children: ReactNode; title: string }> = ({
  children,
  title,
}) => {
  return (
    <Container maxWidth={false} className={`page-${title.toLowerCase().replace(/\s+/g, "-")}`}>
      <Box component="main" sx={{ flexGrow: 1,position:'relative'}}>
        <Container maxWidth={false}>
          <Grid
            container
            spacing={2}
            display={{ xs: "block", sm: "block", md: "none" }}
          >
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "20px",
                  fontWeight: "500",
                  lineHeight: "26px",
                }}
                className={"page-heading"}
              >
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        {children}
      </Box>
    </Container>
  );
};

export default MainLayout;
