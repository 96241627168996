import React, { FC, ReactNode, SyntheticEvent, useState } from "react";
import {
  AlertColor,
  Box,
  Container,
  Grid,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Notification from "../../../../components/Notification";
import Documents from "./Documents";
import "./DocumentRepository.scss";
import ReferenceForms from "./ReferenceForms";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
  className?: string;
}

const TabPanel: FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const DocumentRepository: FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const [value, setValue] = useState<number>(0);
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: string;
  }>({
    display: false,
    severity: null,
    message: "",
  });

  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container
      sx={
        matches
          ? { minHeight: "calc(100vh - 94px)", mb: 3, mt: 3 }
          : { minHeight: "calc(100vh - 124px)", mb: 3, mt: 3 }
      }
      maxWidth={false}
    >
      <Grid container spacing={2}>
        {message.display && (
          <Notification
            isOpen={message.display}
            message={message.message}
            severity={message.severity as AlertColor}
            closeNotification={closeNotification}
          />
        )}
      </Grid>
      <Grid container spacing={2} sx={{ mt: 3 }}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 0 }}>
            <Tabs
              value={value}
              onChange={handleChange}
              sx={{ boxShadow: "none" }}
              TabIndicatorProps={{ sx: { display: "none" } }}
            >
              Four
              <Tab
                className={"document-repository-tabs"}
                label={<span>Documents </span>}
              />
              <Tab
                className={"document-repository-tabs"}
                label={<span>References</span>}
              />
            </Tabs>
          </Box>
          <TabPanel className="tabs-1" value={value} index={0}>
            <Documents />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ReferenceForms />
          </TabPanel>
        </Box>
      </Grid>
    </Container>
  );
};

export default DocumentRepository;
