import React, { FC, useEffect, useState } from "react";
import { Grid, Select, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import "../../ViewRequest.scss";
import {
  SubDocument,
  VettingDocument,
} from "../../../../Authenticated/Agency/RequestDetails/RequestDetailsModel";
import InputField from "../../../../../components/InputField";

export interface DocumentListProps {
  documents: VettingDocument[];
  active: number;
}

const DocumentList: FC<DocumentListProps> = ({ documents, active }) => {
  const [displayTimePeriod, setDisplayTimePeriod] = useState<boolean>(false);

  useEffect(() => {
    let hasOptions: boolean = false;
    documents[active].sub_documents.forEach((document: SubDocument) => {
      if (document.options) {
        hasOptions = true;
      }
    });
    setDisplayTimePeriod(hasOptions);
  }, [active, documents]);

  return (
    <Grid container className="card-vetting">
      <Grid container className="card-head-table">
        <Grid item xs={12} md={6} sx={{ pl: { xs: 1, md: 3 }, py: 3 }}>
          <Typography
            variant={"body2"}
            fontSize={"16px"}
            fontWeight={"bold"}
            sx={{ pl: 2 }}
          >
            {documents[active].name}
          </Typography>
        </Grid>
        {documents[active].documents_required !== "N/A" && (
          <>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                py: { xs: 0, md: 3 },
                pl: { xs: 3, md: 0 },
                textAlign: { xs: "left", md: "right" },
              }}
            >
              <Typography
                variant={"body2"}
                fontSize={"16px"}
                fontWeight={"bold"}
              >
                Number of Documents  required for this section
              </Typography>
            </Grid>
            <Grid item xs={12} md={2} sx={{ pl: { xs: 2, md: 0 } }}>
              <FormControl sx={{ minWidth: 25, px: 1, paddingY: "8px" }}>
                <InputField
                  inputValue={documents[active].documents_required}
                  size={"small"}
                  className="headerTimePeriod mt-2"
                  disabled={documents[active].checked}
                  // validators={[
                  //   {
                  //     check: Validators.required,
                  //     message: "This field is mandatory",
                  //   },
                  //   {
                  //     check: Validators.max,
                  //     message: `Required ReferenceForms cannot be greater that ${documents[active].sub_documents.length}`,
                  //     num: documents[active].sub_documents.length,
                  //   },
                  //   {
                  //     check: Validators.min,
                  //     message: "Required ReferenceForms should be at least 1",
                  //     num: 1,
                  //   },
                  //   {
                  //     check: Validators.number,
                  //     message: "Please enter a valid Number",
                  //   },
                  // ]}
                  hasError={
                    documents[active].documents_required_error?.error || false
                  }
                  fieldError={
                    documents[active].documents_required_error?.message || ""
                  }
                />
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>
      <Grid container spacing={2} sx={{ mb: 1, pl: { xs: 1, md: 3 } }}>
        <Grid item md={8}>
          <Typography variant={"body2"} sx={{ pl: 3 }}>
            Document Name
          </Typography>
        </Grid>
        {displayTimePeriod && (
          <Grid item md={4}>
            <Typography variant={"body2"} sx={{ pl: 1 }}>
              Time Period
            </Typography>
          </Grid>
        )}
      </Grid>
      {documents[active].sub_documents.map((document, index) => (
        <Grid container spacing={2} key={index} sx={{ pl: { xs: 1, md: 3 } }}>
          <Grid item md={8}>
            <div className="document-card mt-2">
              <Typography
                fontSize={"14px"}
                variant={"body2"}
                fontWeight={"bold"}
              >
                {document.name}
              </Typography>
            </div>
          </Grid>
          <Grid item md={4}>
            {displayTimePeriod && document.options && (
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  className="timePeriod"
                  sx={{
                    boxShadow: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  }}
                  value={document.options[document.value].value}
                  inputProps={{ "aria-label": "Without label" }}
                  disabled={true}
                >
                  {document.options.map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option.value}
                      sx={{ color: "#727272", fontWeight: "600" }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default DocumentList;
