import React, { FC, useEffect, useState } from "react";
import Citizenship from "./Citizenship";
import {
  Reload,
  RoutingPageValue,
  setName,
  setReload,
  setValue,
  VettingDocuments,
} from "../../../../../../../utils/redux";
import { useDispatch, useSelector } from "react-redux";
import Yoti from "../common/Yoti/Yoti";
import SelectFiles from "../common/SelectFiles";
import UploadFiles from "../common/UploadFiles";
import PrimaryButton from "../../../../../../../components/Button/PrimaryButton";
import { CircularProgress, Grid, Typography } from "@mui/material";
import InputField from "../../../../../../../components/InputField";
import axiosInstance from "../../../../../../../utils/axios";
import { URLS } from "../../../../../../../utils/constants/urls";
import { useParams } from "react-router-dom";
import { setNotificationMessage } from "../../../../../../../utils/redux";
import { identifiers } from "../../../../../../../utils/constants/identifiers";
import UpdatedServices from "../DBS/UpdatedServices";
import { Validators } from "../../../../../../../utils/validators";

export interface SelectFilesProps {
  active: number;
}

const RightToWork: FC<SelectFilesProps> = ({ active }) => {
  const dispatch = useDispatch();
  const value = useSelector(RoutingPageValue);
  const reload = useSelector(Reload);
  const documents = useSelector(VettingDocuments);
  const { id } = useParams() as any;

  const [rtwCode, setRtwCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [rtwDisabled, setRtwDisabled] = useState(false);
  const [country, setCountry] = useState("");
  const [component, setComponent] = useState<
    "citizenship" | "SelectFiles" | "UploadFiles"
  >();
  const [display, setDisplay] = useState<boolean>(true);
  const [dbs, setDbs] = useState<{
    id: string;
    comments: string | undefined;
    objective: string;
    name:string;
    display: boolean;
  }>({
    id: "",
    comments: "",
    objective: "",
    name:'',
    display: false,
  });
  const [scheme, setScheme] = useState<"RTW" | "DBS_RTW">("RTW");
 const [rtwError, setRtwError] = useState<boolean>();
  
  const setRtwNumber = (value: string, field: string, error?: {error: boolean; message: string}) => {
    if (error && error.error) {
      setRtwError(true);
    } else {
      setRtwError(false);
    }
    setRtwCode(value);
  }

  useEffect(() => {
    if (documents[active].is_british_or_irish_citizen === undefined) {
      setDisplay(false);
    } else {
      dispatch(setValue(1));
      dispatch(setName(""));
    }
    setCountry(
      documents[active].is_british_or_irish_citizen ? "british" : "other"
    );
    setRtwCode(documents[active].rtw_code);
    setRtwDisabled(documents[active].rtw_code !== undefined);
  }, [active, dispatch, documents]);

  useEffect(() => {
    if (documents[active].is_british_or_irish_citizen) {
      const dbsDocument = documents.find(
        (document) => document.name === identifiers.dbs
      );
      if (!!dbsDocument) {
        if (dbsDocument.on_updated_dbs_services === undefined) {
          setDbs({
            id: dbsDocument.id,
            comments: dbsDocument.comments,
            objective: dbsDocument.sub_documents[0].name
              .split(" ")[0]
              .toUpperCase(),
            name: dbsDocument.sub_documents[0].name,
            display: true,
          });
        } else {
          setDbs((prevState) => ({
            ...prevState,
            objective: dbsDocument.sub_documents[0].name
              .split(" ")[0]
              .toUpperCase(),
            name:dbsDocument.sub_documents[0].name,
            display: false,
          }));
          setScheme(dbsDocument.on_updated_dbs_services ? "RTW" : "DBS_RTW");
        }
      }
    }
  }, [active, documents]);

  useEffect(() => {
    if (value === 0 || value === 1) {
      setComponent("SelectFiles");
    } else if (value === 2) {
      setComponent("UploadFiles");
    }
  }, [value]);

  const saveRtwCode = async () => {
    try {
      setLoading(true);
      await axiosInstance.patch(`${URLS.requests}/${id}/document`, {
        rtw_code: rtwCode,
        vetting_doc_id: documents[active].id,
      });
      setLoading(false);
      setRtwDisabled(true);
      dispatch(setReload(!reload));
    } catch (e) {
      setLoading(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in saving RTW Code",
        })
      );
    }
  };

  const setDbsValue = (value: boolean) => {
    setDbs((prevState) => ({ ...prevState, display: false }));
    setScheme(value ? "RTW" : "DBS_RTW");
  };

  return (
    <>
      {component === "citizenship" && display && (
        <Grid container justifyContent={"center"} spacing={2} sx={{ p: 5 }}>
          <Grid
            item
            xs={8}
            className="content-card p-5"
            sx={{
              marginTop: "55px",
              marginBottom: "55px",
              background: "#FFFFFF",
              border: "1px dashed #5AB9F9",
              borderRadius: "12px",
            }}
            textAlign={"center"}
          >
            <Citizenship
              setCountry={setCountry}
              vettingDocId={documents[active].id}
            />
          </Grid>
        </Grid>
      )}
      {component === "SelectFiles" && (
        <>
          {country === "other" ? (
            <>
              <SelectFiles active={active} />
              <Grid
                container
                alignItems={"center"}
                //spacing={2}
                sx={{
                  backgroundColor: "white",
                  width: "85%",
                  margin: "20px 0px 20px 0px",
                  border: "1px dashed #DADADA",
                  borderRadius: "12px",
                }}
              >
                <Grid item xs={12} md={8}>
                  <div className="document-card mt-2">
                    <Typography
                      fontSize={"18px"}
                      variant={"body2"}
                      fontWeight={"bold"}
                      padding={"7px 0px"}
                      textAlign={"center"}
                    >
                      RTW Code
                    </Typography>
                    <InputField
                      label={"RTW Code"}
                      size={"small"}
                      sx={{ mt: 1 }}
                      textChange={setRtwNumber}
                      disabled={rtwDisabled || loading}
                      value={rtwCode}
                      validators={[{
                        check: Validators.RTW,
                        message: 'Please enter a valid RTW Number'
                      }, {
                        check: Validators.required,
                        message: 'Please enter RTW Number'
                      }]}
                    />
                    <a
                      href={identifiers.rtw_url as string}
                      target={"_blank"}
                      rel={"noreferrer"}
                    >
                      <Typography
                        fontSize={"12px"}
                        variant={"body2"}
                        fontWeight={"bold"}
                        padding={"7px 0px"}
                        sx={{ textDecoration: "underline", marginTop: "10px" }}
                        textAlign={"center"}
                        color={"#5AB9F9"}
                        data-tut="rtw_code_field"
                      >
                        Find your share code here
                      </Typography>
                    </a>
                  </div>
                </Grid>
                <Grid item xs={12} md={4} textAlign={"center"}>
                  <div className="action-btn-groups text-right ">
                    <PrimaryButton
                      fullWidth={false}
                      sx={{
                        width: "100%",
                        height: "30px",
                        background: " #5AB9F9",
                        boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
                        borderRadius: "6px",
                        color: "white",
                        mt: "5px",
                      }}
                      disabled={loading || rtwDisabled || !rtwCode || rtwError}
                      onClick={saveRtwCode}
                    >
                      {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
                      Save
                    </PrimaryButton>
                  </div>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              {dbs.display ? (
                <UpdatedServices name={dbs.name} setDbs={setDbsValue} vettingDocId={dbs.id} />
              ) : (
                <Yoti scheme={scheme} objective={dbs.objective} active={active} />
              )}
            </>
          )}
        </>
      )}
      {component === "UploadFiles" ? <UploadFiles /> : null}
    </>
  );
};

export default RightToWork;
