import React, { FC, useState, useMemo, useEffect, useCallback } from "react";
import { Grid, Typography } from "@mui/material";
import PrimaryButton from "../../../../../../../../components/Button/PrimaryButton";
import Modal from "../../../../../../../../components/modal/Modal";
import UCheckForm from "./UCheckForm";
import { uCheckStatus } from "../../../../../../../../utils/redux";
import { useDispatch, useSelector } from "react-redux";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../../../../../utils/constants/coachMarks";
import {
  User,
  setUserData,
} from "../../../../../../../../utils/redux/reducer/authentication-slice";

import { CognitoUser } from "amazon-cognito-identity-js";
import * as Amplify from "../../../../../../../../utils/services/amplify";
import { isTourCompleted, markTourAsCompleted } from "../../../../../../../../utils/storage/tours";

const UCheck: FC<{ type: string }> = ({ type }) => {
  const dispatch = useDispatch();
  const status = useSelector(uCheckStatus);

  const [viewPopUpModal, setViewPopUpModal] = useState(false);

  const { setIsOpen, currentStep, setCurrentStep } = useTour();

  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };

  const setCoachMarksValue = useCallback(async () => {
    //if (!!user && user["custom:reference_tutorial"] !== "true") {
    const currentUser: CognitoUser = await Amplify.UserDetail();
    const body = {
      "custom:ucheck_start": "true",
    };
    await Amplify.UpdateUserDetails(body, currentUser);
    await Amplify.RefreshSession();
    dispatch(setUserData({ ...user, ...body }));
    //}
  }, [user, dispatch]);

  useEffect(() => {
    if (!!user && user["custom:ucheck_start"] !== "true") {
      if (!viewPopUpModal) {
        setCurrentStep(tourStep.candidate_ucheck_start_app.index);
      }
    }
  }, [viewPopUpModal, user, setCurrentStep]);

  useEffect(() => {
    if (tourStep.candidate_ucheck_start_app.index === currentStep) {
      if (!isTourCompleted('candidate_ucheck_start_app')) {
        setIsOpen(true);
      }
    } else if (
      currentStep ===
      tourStep.candidate_ucheck_start_app.index +
        tourStep.candidate_ucheck_start_app.steps
    ) {
      setIsOpen(false);
      markTourAsCompleted('candidate_ucheck_start_app');
      if (!!user && user["custom:ucheck_start"] !== "true") {
        (async () => {
          await setCoachMarksValue();
        })();
      }
    }
  }, [currentStep, setCoachMarksValue, setIsOpen, user]);

  const viewModalText = useMemo(() => {
    return (
      <UCheckForm
        type={type.toLowerCase()}
        setViewPopUpModal={setViewPopUpModal}
        viewPopUpModal={viewPopUpModal}
      />
    );
  }, [type, viewPopUpModal]);

  const openViewModal = useMemo(() => {
    return (
      <Modal
        open={viewPopUpModal}
        setModalClose={setViewPopUpModal}
        children={viewModalText}
        title={undefined}
        size="xl"
        padding="10px"
        Radius={"15px"}
        isBackdrop={false}
        className={undefined}
      />
    );
  }, [viewPopUpModal, viewModalText]);

  return (
    <>
      {openViewModal}
      <Grid container padding={3} minHeight={"400px"} alignItems={"center"}>
        <Grid item xs={12} textAlign={"center"}>
          <Typography variant="h6" sx={{ fontWeight: 500, mb: 2 }}>
            Apply for a new certificate
          </Typography>
          <Typography>
            As you do not have a DBS certificate certificate or need to apply
            for a new one. You will be redirected to a portal and have to fill
            the application form.
          </Typography>
          <PrimaryButton
            fullWidth={false}
            sx={{ mt: 3, color: "#ffffff" }}
            onClick={() => {
              setViewPopUpModal(true);
            }}
            data-tut="candidate_ucheck_start_application"
          >
            {status === "DRAFT"
              ? "Resume Application "
              : "Start Application Now "}
          </PrimaryButton>
        </Grid>
      </Grid>
    </>
  );
};

export default UCheck;
