import React, { FC, useState } from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";
import PrimaryButton from "../../../components/Button/PrimaryButton";
import { identifiers } from "../../../utils/constants/identifiers";
import FileUpload from "react-material-file-upload";
import axiosInstance from "../../../utils/axios";
import { URLS } from "../../../utils/constants/urls";

const VerifyReference: FC = () => {
  const [files, setFiles] = useState<File[]>();
  const [isUploading, setIsUploading] = useState(false);

  const handleFileUpload = () => {
    if (files && files.length !== 0) {
      let reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = async () => {
        setIsUploading(true);
        try {
          const { status } = await axiosInstance.post(URLS.verify_reference, {
            file: reader.result,
          });
          if (status === 202) {
            alert("Reference file is Original");
          }
        } catch (e) {
          console.log(e);
          // if ()
        }
      };
      reader.onerror = (error) => {
        console.log("Error: ", error);
      };
    }
  };

  return (
    <Grid container padding={"10px"} justifyContent={"center"}>
      <Grid item xs={12} md={8} marginY={2}>
        <Typography
          variant="h6"
          sx={{
            color: "#5AB9F9",
            borderBottom: "3px solid #5AB9F9",
            display: "inline",
          }}
        >
          Verify KED Reference
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <FileUpload
          sx={{
            border: "2px dashed",
            minHeight: "260px",
            width: "100%",
            backgroundColor: "white",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
          value={files as File[]}
          onChange={setFiles}
          buttonProps={{ variant: "text" }}
          buttonText={"Choose Reference File"}
          multiple={false}
          title={"Drop Your Reference File Here or"}
          typographyProps={{
            fontSize: 14,
            fontWeight: 600,
          }}
          accept={"text/plain"}
          maxSize={identifiers.maxFileSize as number}
        />
      </Grid>
      <Grid item textAlign={"center"} xs={12} md={8}>
        <PrimaryButton
          disabled={isUploading}
          fullWidth={false}
          sx={{
            mt: 1,
            background: "#5AB9F9",
            color: "#ffffff",
            fontSize: 16,
            fontWeight: 500,
          }}
          onClick={handleFileUpload}
        >
          {isUploading && <CircularProgress sx={{ mr: 1 }} size={20} />}
          Verify Reference
        </PrimaryButton>
      </Grid>
    </Grid>
  );
};

export default VerifyReference;
