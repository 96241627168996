import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/Button/PrimaryButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { images } from "../../../utils/constants/images";
import { useMediaQuery, useTheme } from "@mui/material";

const PageNotFound: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "#F5FBFF",
        gap: "20px",
      }}
    >
      {!matches ? (
        <img
          src={images.not_found_page}
          alt={"Not Found"}
          style={{ width: "50%" }}
        />
      ) : (
        <img src={images.not_found_page} alt={"Not Found"} />
      )}
      <Typography
        variant="h6"
        sx={{ color: "#565872", fontSize: { xs: "22px", md: "30px" } }}
      >
        OOps! Page Not Found
      </Typography>
      <PrimaryButton
        fullWidth={false}
        variant="contained"
        onClick={() => navigate(-1)}
        sx={{
          p: { xs: "10px", md: "10px 80px" },
          background: "#F4D356",
          borderRadius: "30px",
          width: { xs: "150px", md: "300px" },
        }}
        startIcon={<KeyboardBackspaceIcon />}
      >
        <Typography variant="h6" sx={{ fontSize: { xs: "12px", md: "16px" } }}>
          BACK TO HOME
        </Typography>
      </PrimaryButton>
    </Box>
  );
};

export default PageNotFound;
