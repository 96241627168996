import React, { FC, ReactNode } from "react";
import { CheckboxProps } from "@mui/material/Checkbox";
import { AlertColor, Checkbox, SvgIcon } from "@mui/material";
import { images } from "../../utils/constants/images";

interface OwnProps {
  type: "circle" | "square" | "shield";
  shieldColor?: AlertColor;
}

const CheckBox: FC<CheckboxProps & OwnProps> = ({
  checked,
  sx,
  onChange,
  type,
  shieldColor = "success",
  ...props
}) => {
  let component_checked: ReactNode = (
    <SvgIcon component={images.checkBoxCircleChecked} inheritViewBox />
  );
  let component_unchecked: ReactNode = (
    <SvgIcon component={images.checkBoxCircleUnchecked} inheritViewBox />
  );

  switch (type) {
    case "square":
      component_checked = (
        <SvgIcon component={images.checkBoxSquareChecked} inheritViewBox />
      );
      component_unchecked = (
        <SvgIcon component={images.checkBoxSquareUnchecked} inheritViewBox />
      );
      break;
    case "circle":
      component_checked = (
        <SvgIcon component={images.checkBoxCircleChecked} inheritViewBox />
      );
      component_unchecked = (
        <SvgIcon component={images.checkBoxCircleUnchecked} inheritViewBox />
      );
      break;
    case "shield":
      component_checked = (
        <>
          {shieldColor === "warning" ? (
            <SvgIcon
              sx={{ fill: "none" }}
              component={images.warningShieldChecked}
              inheritViewBox
            />
          ) : shieldColor === "error" ? (
            <SvgIcon
              sx={{ fill: "none" }}
              component={images.dangerShieldChecked}
              inheritViewBox
            />
          ) : (
            <SvgIcon
              sx={{ fill: "none" }}
              component={images.successShieldChecked}
              inheritViewBox
            />
          )}
        </>
      );
      component_unchecked = (
        <SvgIcon
          component={images.shieldUnchecked}
          inheritViewBox
          className="shield-checkbox"
        />
      );
  }

  return (
    <Checkbox
      {...props}
      checked={checked}
      sx={sx}
      onChange={onChange}
      checkedIcon={component_checked}
      icon={component_unchecked}
    />
  );
};

export default CheckBox;
