import { Validators } from '../../../../utils/validators';
import { FormModel } from '../../../../components/Form';
import './InviteMembers.scss';

export const InviteMemberForm = (): FormModel[] => {
  return [
    {
      label: 'First Name',
      value: '',
      size: 'small',
      autoFocus: true,
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'first_name',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
        { check: Validators.firstName, message: 'Please enter a valid first name' },
      ],
      responsive: { lg: 6, xs: 12, sm: 12, md: 12 },
      required: true,
    },
    {
      label: 'Last Name',
      value: '',
      size: 'small',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'last_name',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
        { check: Validators.firstName, message: 'Please enter a valid last name' },
      ],
      responsive: { lg: 6, xs: 12, sm: 12, md: 12 },
      required: true,
    },
    {
      label: 'Email address',
      value: '',
      size: 'small',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'email',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
        { check: Validators.email, message: 'Enter a Valid Email' },
      ],
      responsive: { lg: 6, xs: 12, sm: 12, md: 12 },
      required: true,
    },
  ];
};
