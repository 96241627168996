import React, { FC, useState, ChangeEvent, FormEvent, useEffect, useMemo } from "react";
import { Stack, TextField, InputLabel, Grid, Divider, Button, Typography } from "@mui/material";
import PrimaryButton from "../../../components/Button/PrimaryButton";
import { Box } from "@mui/system";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import FileInput from "./FileInput";
import * as Amplify from "../../../utils/services/amplify";
import axios from "axios";
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import Modal from "../../../components/modal/Modal";
import { useSelector } from "react-redux";
import { UserRole } from "../../../utils/redux";

interface FormData {
  subject: string;
  message: string;
}

interface FormErrors {
  subject: string;
  message: string;
}
const initialFormData: FormData = {
  subject: "",
  message: ""
};

const HelpPage: FC = () => {
  const [sendingMailLoader, setSendingMailLoader] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [successModal, setSuccessModal] = useState(false)
  const [ticketNumber, setTicketNumber] = useState('')

  const role = useSelector(UserRole);

  const handleFileSelected = (selectedFiles: File[]) => {
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleFileRemove = (file: File) => {
    setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
  };

  const [formData, setFormData] = useState<FormData>(initialFormData);

  const [formErrors, setFormErrors] = useState<FormErrors>({
    subject: "",
    message: ""
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Reset the error message when the input changes
    setFormErrors({ ...formErrors, [name]: "" });
  };
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors: {
      message: string;
      subject: string;
    } = {
      message: "",
      subject: ""
    };

    if (!formData.subject) {
      errors.subject = "Subject is required";
    }

    if (!formData.message) {
      errors.message = "Message is required";
    }

    // Check for errors after validating individual fields
    if (errors.subject || errors.message) {
      setFormErrors(errors);
    } else {
      // No errors, submit the form
      setSendingMailLoader(true);
      const session = await Amplify.currentSession();
      const token = session.getIdToken().getJwtToken();

      const formRequestData = new FormData();

      formRequestData.append("subject", formData.subject);
      formRequestData.append("message", formData.message);
      for (const file of files) {
        formRequestData.append("files", file, file.name);
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_NODE_API_URL}/authenticated/issue/submit`,
        formRequestData,
        {
          headers: {
            Authorization: `${token}`
          }
        }
      );
      if(data.ticket_number !== undefined){
        setTicketNumber(data.ticket_number);
        setSuccessModal(true);
        setFormData(initialFormData);
        setFiles([])

        setSendingMailLoader(false);
      }
    }
  };

  return (
    <Stack gap={"2rem"}>
      <h4>Report Issue</h4>

      <form onSubmit={handleSubmit}>

        <Grid container spacing={1}>
          <Grid item xs={12} md={2} lg={1}>
            <InputLabel htmlFor="subject">Subject  *</InputLabel>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <TextField
              name="subject"
              label="Subject"
              value={formData.subject}
              onChange={handleInputChange}
              fullWidth
              error={!!formErrors.subject}
              helperText={formErrors.subject}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} sx={{ marginTop: "1rem" }}>
          <Grid item xs={12} md={2} lg={1}>
            <InputLabel htmlFor="message">Description *</InputLabel>

          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <TextField
              name="message"
              label="Message"
              value={formData.message}
              onChange={handleInputChange}
              fullWidth
              multiline
              rows={4}
              error={!!formErrors.message}
              helperText={formErrors.message}
            />
          </Grid>
        </Grid>


        <Grid container spacing={1} sx={{ marginTop: "1rem" }}>
          <Grid item xs={12} md={2} lg={1}>
            Files
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Stack gap={"1rem"}>
              {files.map((file) => {
                let icon = null;

                if (file.type.startsWith('image/')) {
                  icon = <ImageOutlinedIcon sx={{ color: '#b1a37c' }} />;
                } else if (file.type.startsWith('video/')) {
                  icon = <VideoLibraryOutlinedIcon sx={{ color: '#b1a37c' }} />;
                }

                return (
                  <Stack
                    key={file.name}
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                      padding: '0.5rem 1rem',
                      background: '#fff8e5',
                      borderRadius: '0.2rem',
                      border: '1px solid #ccd964',
                      fontSize: 14,
                      fontWeight: 'bold',
                      alignItems: 'center',
                    }}
                  >
                    <Stack gap="1rem" direction="row">
                      {icon}
                      {file.name}
                    </Stack>
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      type="button"
                      sx={{
                        background: '#fff'
                      }}
                      onClick={() => handleFileRemove(file)}
                    >
                      Remove
                    </Button>
                  </Stack>
                );
              })}
              {files.length < 5 && (
                <FileInput onFilesSelected={handleFileSelected} />
              )}
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ marginTop: "2rem" }}>
          <Grid item xs={12} md={2} lg={1}>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <PrimaryButton disabled={sendingMailLoader} type="submit" variant="contained" color="primary"
                           sx={{
                             color: "#ffffff",
                             fontSize: 16,
                             fontWeight: 500,
                             width: "fit-content",
                             minWidth: 180,
                             boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)"
                           }}
            >
              Submit
            </PrimaryButton>

            <Box sx={{
              border: "1px solid #4cc374b0",
              background: "rgba(227,255,237,0.69)",
              margin: "2rem 0 0",
              padding: "1rem",
              borderRadius: '0.5rem',
              fontSize: "15px",
              width: "fit-content"
            }}
            >
              You can also reach us on <a href="mailto:support@ked.io">support@ked.io</a> or call us on <strong>0207 971
              1250</strong>
            </Box>
          </Grid>
        </Grid>


      </form>

      {role === 'agency' &&
        <>
          <Divider />
          <Stack sx={{
            "& > .button": {
              background: "#44b1fb",
              textDecoration: "none",
              color: "#fff",
              padding: "0.5rem 1rem",
              borderRadius: "0.5rem",
              border: "1px solid #c6dbe9",
              display: "flex",
              alignItems: "center",
              width: "fit-content",
              textTransform: "uppercase",
              gap: "0.5rem"
            },
            "& img": {
              width: 120,
              height: 31,
              objectFit: "cover"
            }

          }}>
            <h4 style={{ margin: 0 }}>Troubleshoot DBS</h4>
            <p>For overall management of DBS Applicants</p>

            <a className={"button"} href="https://www.hr-platform.co.uk/app/login/kedio" target={"_blank"}>
              Open KED DBS Platform
              <LaunchOutlinedIcon fontSize={"small"} />
            </a>
          </Stack>
        </>
      }



      <Modal
        open={successModal}
        setModalClose={setSuccessModal}
        title={undefined}
        size="sm"
        className={undefined}
      >
        <Stack gap={"2rem"}>
          <Typography>Your issue has been submitted successfully. We will resolve your issue shortly.</Typography>
          <Typography><strong>Issue Reference No. -</strong> {ticketNumber}</Typography>

          <PrimaryButton onClick={() => setSuccessModal(false) } type="button" variant="contained" color="primary"
                         sx={{
                           color: "#ffffff",
                           fontSize: 16,
                           fontWeight: 500,
                           width: "fit-content",
                           minWidth: 180,
                           marginTop: '1rem',
                           boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)"
                         }}
          >
            Close
          </PrimaryButton>
        </Stack>
      </Modal>
    </Stack>
  );
};

export default HelpPage;
