import { createSlice } from '@reduxjs/toolkit';
import {
  VettingDocument,
  VettingDocuments as DocumentList,
} from '../../../screens/Authenticated/Agency/RequestDetails/RequestDetailsModel';
import { AdditionalFormsProps } from '../../../screens/Authenticated/Candidate/ViewRequest/AdditionalForms/AdditionalForm';
import { identifiers } from '../../constants/identifiers';

export interface RequestReferenceList {
  type: 'employment' | 'character' | 'academic';
  ked_questionnaire: boolean;
  additional_questionnaires: string[];
  reference_documents: string[];
}

export interface RequestReferenceState {
  published: boolean;
  max_references_count: string;
  max_references_count_error: { error: boolean; message: string } | undefined;
  referee_connection_year: string;
  referee_connection_year_error:
    | { error: boolean; message: string }
    | undefined;
  comment: string;
  is_video_acknowledged: boolean;
  request_references: RequestReferenceList[];
  reference_created: boolean;
  reference_editing: boolean;
  reference_acknowledged: boolean;
  reference_list: {
    count: number;
    type: string;
    completed: number;
    has_invalid_reference: boolean;
    index: number;
  }[];
}

interface RequestState {
  id: string | null;
  additionalForms: string[];
  additionalFormData: AdditionalFormsProps;
  vettingDocuments: VettingDocument[];
  vettingDocumentList: DocumentList[];
  comment: string;
  reference: RequestReferenceState;
  isEditable: boolean;
  candidate_idv: CandidateIDVInterface;
  preview_reference: {
    type: 'employment' | 'character' | 'academic';
    reference_forms: {
      agency_id: string;
      id: string;
      name: string;
      type: string;
      created_at: number;
    }[];
    additional_questionnaires: {
      id: string;
      form_data: any[];
    }[];
    ked_questionnaire: boolean;
  }[];
}

interface CandidateIDVInterface {
  check_result?: {
    checks: any[];
    tracking_id: string;
  };
  documents?: any[];
  status: string;
}

export const initialReferenceState: RequestState = {
  id: null,
  additionalForms: [],
  additionalFormData: {
    document: [],
    token: '',
    signed: false,
    sign_required: false,
    envelope_completed: false,
  },
  vettingDocuments: [],
  vettingDocumentList: [],
  isEditable: true,
  comment: '',
  candidate_idv: {
    status: 'Not Started',
  },
  reference: {
    published: false,
    max_references_count: '',
    max_references_count_error: { error: false, message: '' },
    referee_connection_year: '',
    referee_connection_year_error: { error: false, message: '' },
    comment: '',
    is_video_acknowledged: false,
    request_references: [
      {
        type: identifiers.employment as 'employment',
        ked_questionnaire: true,
        additional_questionnaires: [],
        reference_documents: [],
      },
      {
        type: identifiers.character as 'character',
        ked_questionnaire: true,
        additional_questionnaires: [],
        reference_documents: [],
      },
      {
        type: identifiers.academic as 'academic',
        ked_questionnaire: true,
        additional_questionnaires: [],
        reference_documents: [],
      },
    ],
    reference_created: false,
    reference_editing: false,
    reference_acknowledged: false,
    reference_list: [],
  },
  preview_reference: [
    {
      type: 'employment',
      reference_forms: [],
      additional_questionnaires: [],
      ked_questionnaire: true,
    },
    {
      type: 'character',
      reference_forms: [],
      additional_questionnaires: [],
      ked_questionnaire: true,
    },
    {
      type: 'academic',
      reference_forms: [],
      additional_questionnaires: [],
      ked_questionnaire: true,
    },
  ],
};

const requestSlice = createSlice({
  name: 'requestSlice',
  initialState: initialReferenceState,
  reducers: {
    setRequest(state, action) {
      state.id = action.payload.id;
    },
    setVettingDocuments(state, action) {
      state.vettingDocuments = action.payload;
    },
    setAdditionalForms(state, action) {
      state.additionalForms = action.payload;
    },
    setAdditionalFormData(state, action) {
      state.additionalFormData = action.payload;
    },
    setIsEditable(state, action) {
      state.isEditable = action.payload;
    },
    setReference(state, action) {
      state.reference = action.payload;
    },
    setComment(state, action) {
      state.comment = action.payload;
    },
    setVettingDocumentList(state, action) {
      state.vettingDocumentList = action.payload;
    },
    setCandidateIDV(state, action) {
      state.candidate_idv = action.payload;
    },
    setPreviewReference(state, action) {
      state.preview_reference = action.payload;
    },
  },
});

export default requestSlice.reducer;

export const {
  setRequest,
  setVettingDocuments,
  setAdditionalForms,
  setIsEditable,
  setReference,
  setComment,
  setAdditionalFormData,
  setVettingDocumentList,
  setCandidateIDV,
  setPreviewReference,
} = requestSlice.actions;

export const RequestId = (state: { requestSlice: RequestState }) =>
  state.requestSlice.id;

export const VettingDocuments = (state: { requestSlice: RequestState }) =>
  state.requestSlice.vettingDocuments;

export const AdditionalForms = (state: { requestSlice: RequestState }) =>
  state.requestSlice.additionalForms;

export const AdditionalFormData = (state: { requestSlice: RequestState }) =>
  state.requestSlice.additionalFormData;

export const IsEditable = (state: { requestSlice: RequestState }) =>
  state.requestSlice.isEditable;

export const Reference = (state: { requestSlice: RequestState }) =>
  state.requestSlice.reference;

export const Comment = (state: { requestSlice: RequestState }) =>
  state.requestSlice.comment;

export const VettingDocumentList = (state: { requestSlice: RequestState }) =>
  state.requestSlice.vettingDocumentList;

export const CandidateIDV = (state: { requestSlice: RequestState }) =>
  state.requestSlice.candidate_idv;

export const PreviewReference = (state: { requestSlice: RequestState }) =>
  state.requestSlice.preview_reference;
