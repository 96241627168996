import { Button, ButtonProps } from "@mui/material";
import React, { FC } from "react";
import "./styles.css";

type PrimaryButtonProps = {};

const PrimaryButton: FC<PrimaryButtonProps & ButtonProps> = ({
  variant = "contained",
  fullWidth = true,
  className,
  children,
  ...rest
}) => {
  return (
    <Button
      fullWidth={fullWidth}
      className={`${
        variant === "contained" ? "defaultButton" : "textButton"
      } ${className}`}
      variant={variant}
      {...rest}
    >
      {children}
    </Button>
  );
};
export default PrimaryButton;
