import React, { FC, useState, useEffect } from "react";
import { Card, Grid } from "@mui/material";

import "./AuthenticationLayout.scss";
import AgencyLeftLayout from "./AgencyLeftLayout";
import CandidateLeftLayout from "./CandidateLeftLayout";
import { useLocation } from "react-router-dom";
import { candidate } from "../../utils/constants/routes";
import AdminLeftLayout from "./AdminLeftLayout";

const AuthenticationLayout: FC<{
  Component: FC<{ role: string }>;
  role: string;
}> = ({ Component, role }) => {
  const location = useLocation();
  const [height, setHeight] = useState("");

  useEffect(() => {
    console.log(location.pathname);
    if (location.pathname === candidate.sign_up) {
      setHeight("400px");
    } else {
      setHeight("auto");
    }
  }, [location.pathname]);

  return (
    <Grid container spacing={0}>
      <Grid
        container
        item
        xs={12}
        lg={6}
        justifyContent="center"
        alignItems="center"
        className="left-container"
      >
        {role === "agency" ? <AgencyLeftLayout />: role === "admin" ? <AdminLeftLayout /> : <CandidateLeftLayout />}
      </Grid>
      <Grid
        container
        item
        xs={12}
        lg={6}
        justifyContent="center"
        alignItems="center"
        className="right-container"
        sx={{
          height: { xs: height, md: "100vh" },
          overflowY: { xs: "scroll", md: "visible" },
          borderRadius: { xs: "24px 24px 0px 0px ", md: "0px" },
        }}
      >
        <Grid container justifyContent="center" alignItems="center">
          <Card
            sx={{
              p: 5,
              maxWidth: "600px",
              borderRadius: { xs: "24px 24px 0px 0px ", sm: "24px" },
              boxShadow: {
                xs: "none",
                sm: "0px 10px 25px rgba(90, 185, 249, 0.25)",
              },
              width: { xs: "100%", sm: "fit-content" },
            }}
          >
            <Component role={role} />
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AuthenticationLayout;
