import { FormModel } from '../../../../../../../../../components/Form';
import { Validators } from '../../../../../../../../../utils/validators';
import { getNames, registerLocale } from 'i18n-nationality';
import { yes_no } from '../../../../../../../../../utils/constants/constants';
import { UCheckValidators } from '../../../../../../../../../utils/validators/uCheckValidators';
import {
  PersonalHistory,
  PersonalInformation,
} from '../../../../../../../../../utils/redux/reducer/u-check-slice';
import { identifiers } from '../../../../../../../../../utils/constants/identifiers';

registerLocale(require('i18n-nationality/langs/en.json'));
const nationalities = getNames('en');
const sortedNationalities = Object.fromEntries(
  Object.entries(nationalities).sort(([, a], [, b]) => a.localeCompare(b))
);

export const PersonalHistoryForm1Model: (
  personalInformation: PersonalInformation,
  personalHistory: PersonalHistory,
  disabled?:boolean,
) => FormModel[] = (personalInformation, personalHistory,disabled) => {
  const form: FormModel[] = [
    {
      label: 'Has your Nationality changed since birth ? *',
      value: '',
      size: 'small',
      type: 'drop-down',
      options: yes_no,
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'nationality_change',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
      ],
      responsive: { xs: 12 },
      required: true,
      style: { background: '#FFFFFF', borderRadius: '8px' },
      disabled:disabled
    },
  ];
  if (
    !!personalHistory.nationality_change &&
    personalHistory.nationality_change === 'y'
  ) {
    form.push({
      label: 'New Nationality*',
      value: '',
      size: 'small',
      type: 'drop-down',
      options: Object.keys(sortedNationalities).map((key: string) => ({
        label: sortedNationalities[key],
        value: key,
      })),
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'current_nationality',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
      ],
      responsive: { xs: 12 },
      required: true,
      style: { background: '#FFFFFF', borderRadius: '8px' },
      disabled:disabled
    });
  }
  form.push({
    label: 'Has your surname changed since birth?*',
    value: '',
    size: 'small',
    type: 'drop-down',
    options: yes_no,
    typeValue: 'text',
    variant: 'outlined',
    placeholder: '',
    field: 'surname_changed',
    validators: [
      { check: Validators.required, message: 'This field is mandatory' },
      {
        check: UCheckValidators.Surname,
        message: 'Provided Title suggests Surname has changed after birth',
        title: personalInformation.title,
      },
    ],
    responsive: { xs: 12 },
    required: true,
    style: { background: '#FFFFFF', borderRadius: '8px' },
    disabled:disabled
  });

  if (
    !!personalHistory.surname_changed &&
    personalHistory.surname_changed === 'y'
  ) {
    form.push(
      {
        label: 'Birth Surname*',
        value: '',
        size: 'small',
        type: 'text',
        typeValue: 'text',
        variant: 'outlined',
        placeholder: '',
        field: 'birth_surname',
        validators: [
          { check: Validators.required, message: 'This field is mandatory' },
          {
            check: UCheckValidators.Name,
            message: 'Surname should contain only Uppercase letters, spaces',
          },
          {
            check: Validators.minCharLimit,
            message: 'Surname should contain at least 1 character',
            num: 1,
          },
          {
            check: Validators.maxCharLimit,
            message: 'Surname should contain at max 60 characters',
            num: 60,
          },
        ],
        responsive: { xs: 12 },
        required: true,
        style: { background: '#FFFFFF', borderRadius: '8px' },
        disabled:disabled
      },
      {
        label: 'Birth Forename*',
        value: '',
        size: 'small',
        type: 'text',
        typeValue: 'text',
        variant: 'outlined',
        placeholder: '',
        field: 'birth_forename',
        validators: [
          { check: Validators.required, message: 'This field is mandatory' },
          {
            check: UCheckValidators.Name,
            message: 'Birth Forename should contain only Uppercase letters, spaces',
          },
          {
            check: Validators.minCharLimit,
            message: 'Birth Forename should contain at least 1 character',
            num: 1,
          },
          {
            check: Validators.maxCharLimit,
            message: 'Birth Forename should contain at max 60 characters',
            num: 60,
          },
        ],
        responsive: { xs: 12 },
        required: true,
        style: { background: '#FFFFFF', borderRadius: '8px' },
        disabled:disabled
      },
      {
        label: 'Used Until*',
        value: '',
        size: 'small',
        type: 'date',
        typeValue: 'text',
        variant: 'outlined',
        placeholder: '',
        disableFuture: true,
        minDate: new Date(personalInformation.date_of_birth),
        inputFormat: identifiers.month_format as string,
        openTo: 'year',
        views: ['year', 'month'],
        field: `birth_surname_until`,
        validators: [
          { check: Validators.required, message: 'This field is mandatory' },
        ],
        responsive: { xs: 12 },
        required: true,
        style: { background: '#FFFFFF', borderRadius: '8px' },
        disabled:disabled
      }
    );
  }
  form.push({
    label:
      'Unspent Convictions: Do you have any convictions, cautions, reprimands or final warnings which would not be filtered in line with current guidance? *',
    value: '',
    size: 'small',
    type: 'drop-down',
    options: yes_no,
    typeValue: 'text',
    variant: 'outlined',
    placeholder: '',
    field: 'unspent_convictions',
    validators: [
      { check: Validators.required, message: 'This field is mandatory' },
    ],
    responsive: { md: 12, xs: 12 },
    required: true,
    style: { background: '#FFFFFF', borderRadius: '8px' },
    disabled:disabled
  });

  return form;
};
