import React, {
  FC,
  Fragment,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { Box, Grid, TextFieldProps } from "@mui/material";
import Form from "../../../../../../components/Form";
import { identifiers } from "../../../../../../utils/constants/identifiers";
import { RefereeFormModel } from "./RefereeFormModel";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRange, DateRangePicker } from "@mui/lab";
import InputField from "../../../../../../components/InputField";
import dayjs, { Dayjs } from "dayjs";

const ReferenceForm: FC<{
  formValues: any;
  type: string;
  gap_reference?: boolean;
}> = ({ type, formValues, gap_reference }) => {
  let RefereeForm: RefObject<Form | null | undefined> = useRef();
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);

  useEffect(() => {
    if (formValues.candidate_employment_date) {
      const { from, to } = formValues.candidate_employment_date as {
        from: string;
        to: string;
      };
      setDateRange([!!from ? dayjs(from) : null, !!to ? dayjs(to) : null]);
    }
  }, [formValues]);

  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid item xs={11}>
        <Grid container spacing={2}>
          <Form
            values={formValues}
            fieldError={identifiers.field_error as string}
            ref={RefereeForm as RefObject<Form>}
            model={RefereeFormModel(type)}
          />
          {type !== identifiers.character && (
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangePicker
                  startText={
                    type === identifiers.academic
                      ? "Course start date"
                      : type === identifiers.gap
                      ? "Gap from"
                      : "Employment from"
                  }
                  endText={
                    type === identifiers.academic
                      ? "Course end date"
                      : type === identifiers.gap
                      ? "Gap to"
                      : "Employment to"
                  }
                  calendars={1}
                  value={dateRange}
                  onChange={(newValue: any) => {
                    setDateRange(newValue);
                  }}
                  inputFormat={identifiers.date_format as string}
                  disabled
                  renderInput={(
                    startProps: TextFieldProps,
                    endProps: TextFieldProps
                  ) => (
                    <Fragment>
                      <InputField
                        {...startProps}
                        size={"small"}
                        autoComplete="off"
                        sx={{ background: "#FFFFFF", borderRadius: "8px" }}
                        disabled
                      />

                      <Box sx={{ mx: 2 }}> to </Box>

                      <InputField
                        {...endProps}
                        size={"small"}
                        autoComplete="off"
                        sx={{ background: "#FFFFFF", borderRadius: "8px" }}
                        disabled
                      />
                    </Fragment>
                  )}
                />
              </LocalizationProvider>
            </Grid>
          )}
          {type === identifiers.gap && gap_reference && (
            <Grid item md={12}>
              <Grid item xs={12}>
                <InputField
                  multiline
                  label=" Reason Of Gap in Employment"
                  value={formValues.comments}
                  // textChange={(e) => {
                  //   console.log(e,);
                  // setComment(e);
                  // }}
                  disabled={true}
                  rows={6}
                  sx={{ background: "#ffffff" }}
                  placeholder={
                    "The applicant may email once they have fulfilled the requirement of this request."
                  }
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReferenceForm;
