import React, { FC, useState } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import RightSideFrame from "./RightSideFrame";
import CheckBox from "../../../../../components/CheckBox";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../../../utils/axios";
import { URLS } from "../../../../../utils/constants/urls";
import { setNotificationMessage } from "../../../../../utils/redux";
import PageLoader from "../../../../../components/PageLoader";
import PrimaryButton from "../../../../../components/Button/PrimaryButton";
import InputField from "../../../../../components/InputField";

export interface AdditionalFormsProps {
  document: {
    id: string;
    name: string;
  }[];
  token: string;
  signed: boolean;
  sign_required: boolean;
  envelope_completed: boolean;
  envelope_id?: string;
  has_candidate_signed: boolean;
  has_co_signed: boolean;
  is_candidate_sign_required: boolean;
  is_co_sign_required: boolean;
  is_rejected: boolean;
  comment: string;
}

const AdditionalForms: FC<AdditionalFormsProps> = ({
  document,
  signed,
  token,
  sign_required,
  envelope_completed,
  envelope_id,
  has_candidate_signed,
  has_co_signed,
  is_candidate_sign_required,
  is_co_sign_required,
  is_rejected,
  comment,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const download = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(
        `${URLS.requests}/${id}/envelope/${envelope_id}/download`
      );
      window.open(data.url, "_blank");
      setLoading(false);
    } catch (e) {
      setLoading(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "Unable to download Signed ReferenceForms",
        })
      );
    }
  };

  return (
    <Grid container spacing={2} sx={{ mt: 3 }}>
      {loading && <PageLoader />}
      <Grid item xs={12} md={12} lg={4}>
        <List
          dense
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            p: 0,
          }}
        >
          {document.map((document, index) => {
            return (
              <ListItem
                key={index}
                sx={{ px: { xs: 0, md: 3 } }}
                disablePadding
              >
                <ListItemIcon>
                  <CheckBox
                    checked={true}
                    disabled
                    type="shield"
                    shieldColor={is_rejected ? "error" : "success"}
                  />
                </ListItemIcon>
                <ListItemButton disableTouchRipple>
                  <ListItemText
                    sx={{
                      background: is_rejected ? "#F6D3D3" : "#E3F4FF",
                      border: "1px solid rgba(90, 185, 249, 0.25)",
                      borderRadius: "8px",
                      padding: "8px 20px",
                    }}
                    primary={
                      <Typography
                        fontSize={14}
                        fontWeight={500}
                        sx={{ color: "#212121" }}
                      >
                        {document.name}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        {envelope_completed && (
          <Grid container justifyContent={"center"} sx={{ mt: 2 }}>
            <Grid item xs={8}>
              <PrimaryButton variant={"outlined"} onClick={download}>
                Download Signed Documents
              </PrimaryButton>
            </Grid>
          </Grid>
        )}
        {comment && (
          <Grid md={12} item>
            <Grid item>
              <Grid
                item
                xs={12}
                className=" pl-2 py-3"
                display={"flex"}
                alignItems={"center"}
              >
                Additional Comments{" "}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <InputField
                multiline
                value={comment}
                // textChange={(e) => {
                //   console.log(e,);
                //  // setInputValues(e);
                // }}
                disabled={true}
                rows={10}
                sx={{ background: "#ffffff" }}
                // placeholder={
                //   "The applicant may email once they have fulfilled the requirement of this request."
                // }
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <RightSideFrame
          token={token}
          signed={signed}
          sign_required={sign_required}
          has_candidate_signed={has_candidate_signed}
          has_co_signed={has_co_signed}
          is_candidate_sign_required={is_candidate_sign_required}
          is_co_sign_required={is_co_sign_required}
          envelope_id={envelope_id}
          is_rejected={is_rejected}
        />
      </Grid>
    </Grid>
  );
};
export default AdditionalForms;
