import React, { FC, useCallback, useMemo, useState } from "react";
import {
  AlertColor,
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import ListItemContainer from "./ListItemContainer";
import { TeamMemberInterface } from "./InviteMembers";
import axiosInstance from "../../../../utils/axios";
import { URLS } from "../../../../utils/constants/urls";
import Modal from "../../../../components/modal/Modal";
import PrimaryButton from "../../../../components/Button/PrimaryButton";
import Notification from "../../../../components/Notification";
import { useDispatch } from "react-redux";
import { setNotificationMessage } from "../../../../utils/redux";

const ListComponent: FC<{
  members: TeamMemberInterface[];
  count: number;
  setOpen: (value: boolean) => void;
  getTeamMembers: () => void;
}> = ({ members, count, setOpen, getTeamMembers }) => {
  const [member, setMember] = useState<TeamMemberInterface | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: string;
  }>({
    display: false,
    severity: null,
    message: "",
  });

  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };
  const dispatch = useDispatch();

  const deleteMember = async (selectedMember: TeamMemberInterface) => {
    setMember(selectedMember);
    setOpenModal(true);
  };

  const confirmDelete = useCallback(async () => {
    try {
      setLoading(true);
      await axiosInstance.delete(`${URLS.team_members}/${member?.id}`);
      await getTeamMembers();
      setLoading(false);
      setOpenModal(false);
      // setMessage({
      //   display: true,
      //   message: "User Deleted Successfully",
      //   severity: "success",
      // })
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: "User Deleted Successfully",
        })
      );
    } catch (e) {
      setLoading(false);
      setOpenModal(false);
      // setMessage({
      //   display: true,
      //   message: "You cannot delete this user",
      //   severity: "error",
      // })
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "You cannot delete this user",
        })
      );
      console.log(e);
    }
  }, [dispatch, getTeamMembers, member?.id]);

  const deleteModalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          <Typography
            variant="h6"
            sx={{
              marginBottom: "20px",
              fontSize: 18,
              fontWeight: 500,
              color: "#000000",
              textAlign: "center",
              letterSpacing: "0.15px",
            }}
          >
            Remove User
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontSize: 16,
              fontWeight: 500,
              color: "#727272",
            }}
          >
            All access to {member?.name} will be revoked from the application.
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontSize: 16,
              fontWeight: 500,
              color: "#727272",
            }}
          >
            Are you sure?
          </Typography>
        </Grid>
        <Grid container alignItems="right" justifyContent="right">
          <Button
            sx={{
              border: "none",
              color: "#727272",
              fontSize: 16,
              fontWeight: 500,
              mr: 2,
              mt: 1,
              p: "10px",
            }}
            disabled={loading}
            onClick={confirmDelete}
          >
            {loading && <CircularProgress sx={{ mr: 1 }} size={30} />}Remove User
          </Button>
          <PrimaryButton
            disabled={loading}
            fullWidth={false}
            sx={{
              mt: 1,
              background: "#5AB9F9",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 500,
            }}
            onClick={() => {
              setOpenModal(false);
              setMember(null);
            }}
          >
            Cancel
          </PrimaryButton>
        </Grid>
      </Grid>
    );
  }, [confirmDelete, loading, member?.name]);

  const openDeleteModal = useMemo(() => {
    return (
      <Modal
        open={openModal}
        setModalClose={setOpenModal}
        children={deleteModalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [openModal, deleteModalText]);

  return (
    <Box>
      {openDeleteModal}
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      <Stack>
        <Stack data-tut="members_added" direction="row" spacing={0} sx={{ mb: 3, mt:{xs:2,md:0} }}>
          <Typography
            variant="h6"
            sx={{
              fontSize: 14,
              fontWeight: 600,
              color: "#333333",
              letterSpacing: "0.15px",
              textAlign: "right",
            }}
          >
            Members Added :
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontSize: 14,
              fontWeight: 600,
              color: "#333333",
              letterSpacing: "0.15px",
              paddingLeft: "2px",
            }}
          >
            {count || 0} / 5
          </Typography>
        </Stack>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          {members?.map((member, index) => (
            <ListItemContainer
              setOpen={setOpen}
              member={member}
              key={index}
              deleteMember={deleteMember}
            />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};

export default ListComponent;
