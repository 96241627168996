import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Typography,
  Stepper,
  Step,
  StepButton,
} from "@mui/material";
import ReferenceRequirement from "./ReferenceRequirement/ReferenceRequirement";
import Questionnaire from "./Questionnaire";
import { useDispatch, useSelector } from "react-redux";
import { Reference, setReference } from "../../../../../utils/redux";
import PrimaryButton from "../../../../../components/Button/PrimaryButton";
import { images } from "../../../../../utils/constants/images";
import Modal from "../../../../../components/modal/Modal";
import "./RequiredReferences.scss";
import Tooltip from "@mui/material/Tooltip";
import {
  User,
  setUserData,
} from "../../../../../utils/redux/reducer/authentication-slice";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../../utils/constants/coachMarks";
import * as Amplify from "../../../../../utils/services/amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { isTourCompleted, markTourAsCompleted } from "../../../../../utils/storage/tours";

const steps = ["", "", "", "", ""];

const RequiredReferences: FC = () => {
  const reference = useSelector(Reference);
  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };
  const dispatch = useDispatch();
  const [openVideo, setOpenVideo] = useState<boolean>();
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState<
    "ked-questionnaire" | "additional-questionnaire" | "upload-own-ref" | ""
  >("");
  const [activeStep, setActiveStep] = useState(0);

  const { setIsOpen, currentStep, setCurrentStep } = useTour();

  const acknowledgeVideo = useCallback(async () => {
    // if (!!user && user["custom:reference_tutorial"] !== "true") {
    const currentUser: CognitoUser = await Amplify.UserDetail();
    const body = {
      "custom:reference_tutorial": "true",
    };
    await Amplify.UpdateUserDetails(body, currentUser);
    await Amplify.RefreshSession();
    dispatch(setUserData({ ...user, ...body }));
    // }
    dispatch(setReference({ ...reference, is_video_acknowledged: true }));
  }, [dispatch, reference, user]);

  const setRequiredReferenceCoachMarksValue = useCallback(async () => {
    //if (!!user && user["custom:reference_tutorial"] !== "true") {
    const currentUser: CognitoUser = await Amplify.UserDetail();
    const body = {
      "custom:ref_first_step": "true",
    };
    await Amplify.UpdateUserDetails(body, currentUser);
    await Amplify.RefreshSession();
    dispatch(setUserData({ ...user, ...body }));
    //}
  }, [user, dispatch]);

  useEffect(() => {
    if (reference.reference_created && !reference.reference_editing) {
      setActiveStep(4);
    }
    setOpenVideo(!reference.is_video_acknowledged);
  }, [reference]);

  useEffect(() => {
    if (activeStep === 0) {
      if (reference.is_video_acknowledged) {
        if (!!user && user["custom:ref_first_step"] !== "true") {
          setCurrentStep(tourStep.required_ref_first_step.index);
        }
      }
    }
  }, [user, reference, activeStep, setCurrentStep]);

  useEffect(() => {
    if (activeStep === 0) {
      if (reference.is_video_acknowledged) {
        if (!!user && user["custom:ref_first_step"] !== "true") {
          if (tourStep.required_ref_first_step.index === currentStep) {
            if (!isTourCompleted('required_ref_first_step')) {
              setIsOpen(true);
            }
          } else if (
            currentStep ===
            tourStep.required_ref_first_step.index +
              tourStep.required_ref_first_step.steps
          ) {
            setIsOpen(false);
            markTourAsCompleted('required_ref_first_step');
            if (!!user && user["custom:ref_first_step"] !== "true") {
              (async () => {
                await setRequiredReferenceCoachMarksValue();
              })();
            }
          }
        }
      }
    }
  }, [
    currentStep,
    setIsOpen,
    setRequiredReferenceCoachMarksValue,
    user,
    activeStep,
    reference,
  ]);

  const handleNext = () => {
    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const sampleReference = useMemo(() => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              marginBottom: "20px",
              fontSize: 18,
              fontWeight: 500,
              color: "#000000",
              textAlign: "center",
              letterSpacing: "0.15px",
            }}
          >
            Sample Reference Form
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <img
            src={images.sampleReferenceForm}
            alt="sample reference form"
            className={"img-fluid"}
          />
        </Grid>
      </Grid>
    );
  }, []);

  console.log(process.env.REACT_APP_STAGE);

  const tutorialVideo = useMemo(() => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              marginBottom: "20px",
              fontSize: 18,
              fontWeight: 500,
              color: "#000000",
              textAlign: "center",
              letterSpacing: "0.15px",
            }}
          >
            Sample Video
          </Typography>
          <video controls width="100%" height="340" autoPlay>
            <source src={process.env.REACT_APP_VIDEO_URL} type={"video/mp4"} />
          </video>
        </Grid>
        <Grid item xs={12} md={12} textAlign={"center"}>
          <PrimaryButton
            fullWidth={false}
            sx={{
              background: "#FFFFFF",
              border: " 1px solid #5AB9F9",
              borderRadius: "5px",
              padding: "4px 44px",
              color: "#5AB9F9",
              fontSize: "14px",
              margin: "8px",
              maxWidth: { xs: "100%", md: "250px" },
            }}
            onClick={acknowledgeVideo}
          >
            Close
          </PrimaryButton>
        </Grid>
      </Grid>
    );
  }, [acknowledgeVideo]);

  const openSampleReference = useMemo(() => {
    return (
      <Modal
        open={open}
        setModalClose={setOpen}
        children={sampleReference}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [open, sampleReference]);

  const OpenTutorialVideo = useMemo(() => {
    return (
      <Modal
        open={openVideo}
        setModalClose={setOpenVideo}
        children={tutorialVideo}
        title={undefined}
        size="md"
        className={undefined}
        isBackdrop={true}
      />
    );
  }, [openVideo, tutorialVideo]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "20px",
        padding: {
          md: '1rem'
        },
        flexDirection: { xs: "column", lg: "row" },
      }}
    >

      <Stack
        width={"100%"}
        sx={{
          background: "#f5fbff",
          borderRadius: "17px",
          pb: { xs: 6, md: 3 },
        }}
      >
        <Grid
          container
          className="card-head-reference m-0 p-0"
          height={{ xs: "150px", md: "65px" }}
        >
          <Grid item xs={12} md={7} sx={{ pl: { xs: 0, md: 3 } }}>
            <Box sx={{ display: "flex", alignItems: "flex-end", padding: {

              xs: '1rem',
                md: 0

              } }}>
              <Typography
                variant={"body2"}
                fontSize={"16px"}
                fontWeight={"bold"}
                sx={{
                  pl: { xs: 0, md: 2 },
                  paddingTop: "20px",
                  textAlign: { xs: "center", md: "start" },
                }}
              >
                Required References
              </Typography>
              <Tooltip title={"Tap to View the Tutorial"} arrow>
                <img
                  onClick={() => setOpenVideo(true)}
                  style={{ cursor: "pointer" }}
                  src={images.infoTooltip}
                  alt={"tooltip"}
                />
              </Tooltip>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            textAlign={{ xs: "center", md: "right" }}
            sx={{ pr: 2 }}
          >
            <PrimaryButton
              data-tut="sample_reference_btn"
              fullWidth={false}
              sx={{
                background: "#FFFFFF",
                border: " 1px solid #5AB9F9",
                borderRadius: "5px",
                padding: "4px 44px",
                color: "#5AB9F9",
                fontSize: "14px",
                margin: "8px",
                maxWidth: { xs: "100%", md: "250px" },
              }}
              onClick={() => setOpen(true)}
            >
              Sample Reference
            </PrimaryButton>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={12} mt={2} mb={ { xs: '2rem', md: 0 } }>
            <Stepper alternativeLabel activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={index}>
                  <StepButton
                    color="inherit"
                    // onClick={handleStep(index)}
                  >
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            {activeStep === 0 && (
              <ReferenceRequirement
                handleNext={handleNext}
                setActiveStep={setActiveStep}
              />
            )}
            {activeStep === 1 && (
              <Questionnaire
                expanded={expanded}
                setExpanded={setExpanded}
                handleNext={handleNext}
                handleBack={handleBack}
                type={"employment"}
                activeStep={activeStep}
              />
            )}
            {activeStep === 2 && (
              <Questionnaire
                expanded={expanded}
                setExpanded={setExpanded}
                handleNext={handleNext}
                handleBack={handleBack}
                type={"character"}
                activeStep={activeStep}
              />
            )}
            {activeStep === 3 && (
              <Questionnaire
                expanded={expanded}
                setExpanded={setExpanded}
                handleNext={handleNext}
                handleBack={handleBack}
                type={"academic"}
                activeStep={activeStep}
              />
            )}
            {activeStep === 4 && (
              <ReferenceRequirement
                handleNext={handleNext}
                setActiveStep={setActiveStep}
              />
            )}
          </Grid>
        </Grid>
      </Stack>
      {open && openSampleReference}
      {openVideo && OpenTutorialVideo}
    </Box>
  );
};

export default RequiredReferences;
