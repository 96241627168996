import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
  Button,
  Stack,
  CircularProgress,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { images } from "../../../../utils/constants/images";
import TabPanel from "../../../../components/TabPanel";
import VettingDocuments from "./VettingDocuments";
import axiosInstance from "../../../../utils/axios";
import { URLS } from "../../../../utils/constants/urls";
import { VettingDocument } from "../RequestDetails/RequestDetailsModel";
import "./CandidateProfile.scss";
import PageLoader from "../../../../components/PageLoader";
import ReqReference from "./RequiredReference";
import { RequestReferenceState } from "../../../../utils/redux/reducer/request-slice";
import { setCandidateProfileInfo } from "../../../../utils/redux/reducer/candidate-vetting-slice";
import AdditionalForms from "./AdditionalForms";
import { useDispatch, useSelector } from "react-redux";
import { AdditionalFormsProps } from "./AdditionalForms/AdditionalForms";
import {
  setNotificationMessage,
  setCandidateProfileAcademicData,
  CandidateProfileReload,
  setCandidateIDV,
  AcademicData,
  setCandidateProfileReload,
  setPersonalInformation,
  setPersonalHistory,
  setCurrentAddress,
  setPreviousAddress,
  setPassPortInformation,
  setLicenceInformation,
  setUserInformation,
  setStatus,
} from "../../../../utils/redux";
import { identifiers } from "../../../../utils/constants/identifiers";
import PrimaryButton from "../../../../components/Button/PrimaryButton";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
import {
  getCurrentAddress,
  getDrivingLicenceInformation,
  getPassportDetails,
  getPersonalHistory,
  getPersonalInformation,
  getPreviousAddresses,
} from "../../../Common/ViewRequest/ViewRequestService";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../utils/constants/coachMarks";
import * as Amplify from "../../../../utils/services/amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import {
  User,
  setUserData,
} from "../../../../utils/redux/reducer/authentication-slice";
import { Auth } from "aws-amplify";
import { isTourCompleted, markTourAsCompleted } from "../../../../utils/storage/tours";

const CandidateProfile: FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const { setIsOpen, currentStep, setCurrentStep } = useTour();

  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const { candidate_id, id } = useParams();

  const reload = useSelector(CandidateProfileReload);
  const academicData = useSelector(AcademicData);

  const activeIndex = useRef(0);
  const requestReferenceActiveIndex = useRef(0);

  const [value, setValue] = useState<number>(0);
  const [vettingDocuments, setVettingDocuments] = useState<any[]>([]);
  const [profileInfo, setProfileInfo] = useState<any>();
  const [isDownloading, setIsDownloading] = useState(false);
  const [reference, setReference] = useState<RequestReferenceState | null>(
    null
  );
  const [active, setActive] = useState<number>(0);
  const [requestReferenceActive, setRequestReferenceActive] =
    useState<number>(0);
  const [loading, setLoading] = useState<boolean>();
  const [syncItrisLoading, setSyncItrisLoading] = useState<boolean>();
  const [isItrisEnabled, setIsItrisEnabled] = useState(false);
  const [sendingMailLoader, setSendingMailLoader] = useState(false);
  const [additionalForms, setAdditionalForms] = useState<AdditionalFormsProps>({
    document: [],
    token: "",
    signed: false,
    sign_required: false,
    has_candidate_signed: false,
    is_candidate_sign_required: false,
    has_co_signed: false,
    is_co_sign_required: false,
    envelope_completed: false,
    is_rejected: false,
    comment: "",
  });
  const [yotiReport, setYotiReport] = useState<{
    check_result: any;
    documents: any[];
    status: string;
  }>();

  const setActiveIndex = useCallback(() => {
    activeIndex.current = active;
    setActive(activeIndex.current);
  }, [active]);

  const setRequestReferenceActiveIndex = useCallback(() => {
    requestReferenceActiveIndex.current = requestReferenceActive;
    setRequestReferenceActive(requestReferenceActiveIndex.current);
  }, [requestReferenceActive]);

  const getRequest = useCallback(async () => {
    try {
      if (!reload || reload) {
        const { data } = await axiosInstance.get(
          `${URLS.candidate_request}/${id}/applicants/${candidate_id}?stage=${process.env.REACT_APP_YOTI_ENV}&ucheck_stage=${process.env.REACT_APP_UCHECK_ENV}`
        );
        const vetting_documents = data.vetting_documents.map(
          (document: VettingDocument) => {
            return {
              ...document,
              uploaded_on: randomDate(new Date(2022, 9, 1), new Date()),
            };
          }
        );
        setReference(
          data.reference &&
            (!!data.reference.max_references_count ||
              !!data.reference.referee_connection_year)
            ? {
              ...data.reference,
              reference_list: data.references_count_info
                .map(
                  (reference_list: {
                    count: number;
                    type: string;
                    completed: number;
                    has_invalid_reference: boolean;
                    index: number;
                  }) => {
                    if (reference_list.type === identifiers.employment) {
                      return {
                        ...reference_list,
                        index: 0,
                      };
                    } else if (
                      reference_list.type === identifiers.character
                    ) {
                      return {
                        ...reference_list,
                        index: 1,
                      };
                    } else if (reference_list.type === identifiers.academic) {
                      return {
                        ...reference_list,
                        index: 2,
                      };
                    }
                    return {
                      ...reference_list,
                      index: data.references_count_info.length,
                    };
                  }
                )
                .sort((a: any, b: any) => {
                  return a.index - b.index;
                }),
              reference_acknowledged: data.acknowledged,
            }
            : null
        );
        dispatch(setCandidateIDV(data?.candidate_idv));
        dispatch(setCandidateProfileAcademicData(data));
        setVettingDocuments(
          vetting_documents
            .sort(
              (a: VettingDocument, b: VettingDocument) =>
                a.candidate_index - b.candidate_index
            )
            .map((document: VettingDocument) => {
              if (document.name === identifiers.cv) {
                const cv_document: VettingDocument = JSON.parse(
                  JSON.stringify(document)
                );
                cv_document.sub_documents = [
                  {
                    id: "",
                    name: cv_document.name,
                    document_repository_id:
                      cv_document.cv_document_repo_id || "",
                    checked: true,
                    default: 0,
                    value: 0,
                    uploaded_at: !!cv_document.cv_document_repo_id
                      ? new Date().getTime()
                      : undefined,
                  },
                ];

                return cv_document;
              }
              if (document.name === identifiers.opc) {
                // Add Similar Logic like CV here for OPC
                const opc_document: VettingDocument = JSON.parse(
                  JSON.stringify(document)
                );
                opc_document.sub_documents = [
                  {
                    id: "",
                    name: opc_document.name,
                    document_repository_id:
                      opc_document.opc_document_repo_id || "",
                    checked: true,
                    default: 0,
                    value: 0,
                    // options: [],
                    uploaded_at: !!opc_document.opc_document_repo_id
                      ? new Date().getTime()
                      : undefined,
                    approval_status: opc_document.approval_status,
                  },
                ];
                return opc_document;
              }
              if (document.name === identifiers.dbs) {
                const dbs_document: VettingDocument = JSON.parse(
                  JSON.stringify(document)
                );
                dbs_document.sub_documents = [
                  {
                    ...dbs_document.sub_documents[0],
                    uploaded_at: dbs_document.uploaded_at as number,
                    document_repository_id:
                      dbs_document.dbs_certificate_repo_id || "",
                    approval_status: dbs_document.approval_status || undefined,
                  },
                ];
                dbs_document.is_rejected =
                  dbs_document.approval_status === "REJECTED";
                return dbs_document;
              }
              if (document.name === identifiers.academic_qualification) {
                const ac_document: VettingDocument = JSON.parse(
                  JSON.stringify(document)
                );
                ac_document.sub_documents = [
                  {
                    id: "",
                    name: ac_document.name,
                    document_repository_id:
                      ac_document.cv_document_repo_id || "",
                    checked: true,
                    default: 0,
                    value: 0,
                    uploaded_at: !!ac_document.opc_document_repo_id
                      ? new Date().getTime()
                      : undefined,
                  },
                ];
                return ac_document;
              }
              const is_rejected = !!document?.sub_documents?.find(
                (sub_document) => sub_document.approval_status === "REJECTED"
              );
              return { is_rejected, ...document };
            })
        );
        dispatch(setStatus(data?.ucheck_application?.status));
        dispatch(
          setPersonalInformation(
            getPersonalInformation(
              data.ucheck_application?.application_details,
              data.ucheck_application?.dbs_type?.toLowerCase() || ""
            )
          )
        );
        dispatch(
          setPersonalHistory(
            getPersonalHistory(data.ucheck_application?.application_details)
          )
        );
        dispatch(
          setCurrentAddress(
            getCurrentAddress(data.ucheck_application?.application_details)
          )
        );
        dispatch(
          setPreviousAddress(
            getPreviousAddresses(data.ucheck_application?.application_details)
          )
        );
        dispatch(
          setPassPortInformation(
            getPassportDetails(data?.ucheck_application?.application_details)
          )
        );
        dispatch(
          setLicenceInformation(
            getDrivingLicenceInformation(
              data?.ucheck_application?.application_details
            )
          )
        );
        dispatch(
          setUserInformation({
            ...data,
            contact_number:
              data?.ucheck_application?.application_details?.applicant_details
                ?.additional_applicant_details?.contact_number,
            email:
              data?.ucheck_application?.application_details?.applicant_details
                ?.contact_email,
            dbs_type: data?.ucheck_application?.dbs_type,
          })
        );
        setAdditionalForms(data.additional_form_data);
        setProfileInfo(data.candidate_profile);
        setYotiReport(data.candidate_idv);
        dispatch(setCandidateProfileInfo(data.candidate_profile));

        fetchItrisStatus();
        setIsLoading(false);
      }
    } catch (e: any) {
      setIsLoading(false);
      if (await Auth.currentUserInfo()) {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "There is an error in fetching request",
          })
        );
      }
    }
  }, [reload, id, candidate_id, dispatch]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getRequest();
    })();
  }, [getRequest]);

  const fetchItrisStatus = async () => {
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_NODE_ITRIS_API_URL}/authorized/itris/status`);
      setIsItrisEnabled(response.data.is_itris_enabled);
    } catch (error) {
      console.error('Error fetching Itris status:', error);
    }
  };

  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };

  const setCandidateProfileCoachMark = useCallback(async () => {
    //if (!!user && user["custom:reference_tutorial"] !== "true") {
    const currentUser: CognitoUser = await Amplify.UserDetail();
    const body = {
      "custom:candidate_profile": "true",
    };
    await Amplify.UpdateUserDetails(body, currentUser);
    await Amplify.RefreshSession();
    dispatch(setUserData({ ...user, ...body }));
    //}
  }, [dispatch, user]);

  useEffect(() => {
    if (isLoading !== undefined && !isLoading) {
      if (user["custom:candidate_profile"] !== "true") {
        setCurrentStep(tourStep.candidate_profile.index);
      }
    }
  }, [isLoading, setCurrentStep, user]);

  useEffect(() => {
    if (!isLoading) {
      if (tourStep.candidate_profile.index === currentStep) {
        if (!isTourCompleted('candidate_profile')) {
          setIsOpen(true);
        }
      } else if (
        currentStep ===
        tourStep.candidate_profile.index + tourStep.candidate_profile.steps
      ) {
        setIsOpen(false);
        markTourAsCompleted('candidate_profile');
        if (!!user && user["custom:candidate_profile"] !== "true") {
          (async () => {
            await setCandidateProfileCoachMark();
          })();
        }
      }
    }
  }, [
    currentStep,
    isLoading,
    setCandidateProfileCoachMark,
    setCurrentStep,
    setIsOpen,
    user,
  ]);

  useEffect(() => {
    setActiveIndex();
    setRequestReferenceActiveIndex();
  }, [setActiveIndex, setRequestReferenceActiveIndex]);

  const randomDate = (start: Date, end: Date) => {
    return new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    ).toDateString();
  };

  const sendEmail = async () => {
    try {
      setSendingMailLoader(true);
      await axiosInstance.post(
        `${URLS.agency_request}${id}/candidate/${candidate_id}/feedback/email`
      );
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: "Email sent successfully",
        })
      );
      setSendingMailLoader(false);
      dispatch(setCandidateProfileReload(!reload));
    } catch (e) {
      console.log(e);
      setSendingMailLoader(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error while sending the mail to the candidate",
        })
      );
    }
  };

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const downloadDocuments = async () => {
    setIsDownloading(true);
    try {
      const session = await Amplify.currentSession();
      const token = session.getIdToken().getJwtToken();
      const { data } = await axios.get(
        `${process.env.REACT_APP_DOWNLOADS_API_URL}${URLS.download_candidate_documents}${id}/candidate/${candidate_id}/document/download`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      window.open(data.download_url, "_blank");
      setIsDownloading(false);
    } catch (e: any) {
      console.log(e?.response?.status);
      setIsDownloading(false);
      if (e?.response?.status === 400) {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: e?.response?.data?.errors[0]?.message,
          })
        );
      } else {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "Unable to download zip",
          })
        );
      }
    }
  };

  const confirmApproval = async () => {
    try {
      setLoading(true);
      await axiosInstance.patch(
        `${URLS.agency_request}${id}/candidate/${candidate_id}/request-candidate`,
        {
          approve_candidate: true,
        }
      );
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: "Candiate is approved successfully",
        })
      );
      setLoading(false);
      dispatch(setCandidateProfileReload(!reload));
    } catch (e) {
      console.log(e);
      setLoading(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error while approving the candidate",
        })
      );
    }
  };

  const syncItris = async () => {
    try {
      setSyncItrisLoading(true);
      await axiosInstance.post(
        `${process.env.REACT_APP_NODE_ITRIS_API_URL}/authorized/itris/sync/request/${id}/candidate/${candidate_id}`
      );
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: "Synced Successfully",
        })
      );
      setSyncItrisLoading(false);
    } catch (e) {
      console.log(e);
      setSyncItrisLoading(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in syncing process.",
        })
      );
    }
  };

  return (
    <Container
      sx={
        matches
          ? { minHeight: "calc(100vh - 94px)", mb: 3 }
          : { minHeight: "calc(100vh - 124px)", mb: 3 }
      }
      maxWidth={false}
      className="mt-3"
    >
      {isLoading && <PageLoader />}
      {!isLoading && profileInfo && (
        <Grid container>
          <Grid item xs={12} md={3} sx={{ textAlign: "center" }}>
            <Box className="profile-card" sx={{ alignItems: "center" }}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Box className="profile-con">
                  <img
                    src={
                      profileInfo.image ||
                      images.dummyProfilePic
                    }
                    alt="profile"
                    height="100%"
                    width="100%"
                    style={{ borderRadius: "inherit" }}
                  />
                </Box>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "26px",
                    mt: "2",
                  }}
                >
                  {profileInfo.name}
                </Typography>
              </Box>
              {academicData.application_status !== "Approved" && (
                <>
                  <Typography
                    variant="h6"
                    className="sub-title"
                    sx={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "28px",
                    }}
                  >
                    Is the applicant’s profile complete ?
                  </Typography>
                  <PrimaryButton
                    data-tut="profile_completed"
                    color={"warning"}
                    variant={"contained"}
                    onClick={confirmApproval}
                  >
                    {loading && (
                      <CircularProgress
                        sx={{ mr: 1, color: "white" }}
                        size={30}
                      />
                    )}{" "}
                    Yes
                  </PrimaryButton>
                </>
              )}
            </Box>
            <Button
              onClick={sendEmail}
              sx={{ mt: 3, color: "white" }}
              variant="contained"
              fullWidth
              data-tut="send_mail"
            >
              {sendingMailLoader && (
                <CircularProgress sx={{ mr: 1, color: "white" }} size={20} />
              )}
              Email reported item to Applicant
            </Button>
            <Box className="detail-card profile-card" sx={{ mt: 3, p: 2 }}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "28px",
                  textAlign: "start",
                  color: "#333333",
                }}
              >
                {profileInfo?.name}’s Details
              </Typography>
              <>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "400",
                      fontSize: "12px",
                      lineHeight: "28px",
                      textAlign: "start",
                      color: "#727272",
                      my: { xs: 1, md: 2 },
                    }}
                  >
                    Email Address
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWieght: "500",
                      fontSize: "14px",
                      lineHeight: "28px",
                      textAlign: "start",
                      color: "#333333",
                      wordWrap: "break-word",
                      my: { xs: 1, md: 2 },
                    }}
                  >
                    {profileInfo?.email}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWieght: "400",
                      fontSize: "12px",
                      lineHeight: "28px",
                      textAlign: "start",
                      color: "#727272",
                      my: { xs: 1, md: 2 },
                    }}
                  >
                    Phone Number
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWieght: "500",
                      fontSize: "14px",
                      lineHeight: "28px",
                      textAlign: "start",
                      color: "#333333",
                      my: { xs: 1, md: 2 },
                    }}
                  >
                    {profileInfo?.phone_number}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWieght: "400",
                      fontSize: "12px",
                      lineHeight: "28px",
                      textAlign: "start",
                      color: "#727272",
                      my: { xs: 1, md: 2 },
                    }}
                  >
                    Address
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWieght: "500",
                      fontSize: "14px",
                      lineHeight: "28px",
                      textAlign: "start",
                      color: "#333333",
                      my: { xs: 1, md: 2 },
                    }}
                  >
                    {profileInfo?.address}
                  </Typography>
                </Box>
              </>
            </Box>
            {isItrisEnabled && (
              <Button
                onClick={syncItris}
                sx={{ mt: 3, color: "white" }}
                variant="contained"
                fullWidth
                disabled={syncItrisLoading}
              >
                {syncItrisLoading && (
                  <CircularProgress sx={{ mr: 1, color: "white" }} size={20} />
                )}
                Sync to Itris
              </Button>
            )}
          </Grid>
          <Grid item xs={12} md={9}>
            <Box sx={{ width: "100%", mb: 3, paddingLeft: { xs: 0, md: 3 } }}>
              <Stack direction={"row"}>
                <Box sx={{ width: { xs: "100%", md: "calc(100% - 64px)" } }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    sx={{ boxShadow: "none" }}
                    className="tab-container"
                    TabIndicatorProps={{ sx: { display: "none" } }}
                  >
                    <Tab
                      className={"request-tabs"}
                      label={
                        <span>
                          Vetting, ID & Checks{" "}
                          <img
                            src={images.infoIcon}
                            alt={"info icon"}
                            className="vertical-align-middle"
                          />
                        </span>
                      }
                    />
                    <Tab
                      className={"request-tabs"}
                      disabled={!reference}
                      label={
                        <span>
                          Required References{" "}
                          <img
                            src={images.infoIcon}
                            alt={"info icon"}
                            className="vertical-align-middle"
                          />
                        </span>
                      }
                    />
                    <Tab
                      className={"request-tabs"}
                      disabled={
                        !additionalForms.document ||
                        (additionalForms.document &&
                          additionalForms.document.length === 0)
                      }
                      label={
                        <span>
                          Forms and Declarations
                          <img
                            src={images.infoIcon}
                            alt={"info icon"}
                            className="vertical-align-middle"
                          />
                        </span>
                      }
                    />
                  </Tabs>
                </Box>
                <Box
                  sx={{
                    position: { xs: "fixed", md: "relative" },
                    bottom: "0px",
                    left: "0px",
                    width: { xs: "100%", md: "64px" },
                    zIndex: 100,
                  }}
                >
                  <PrimaryButton
                    variant={"contained"}
                    onClick={downloadDocuments}
                    sx={{
                      mt: 1,
                    }}
                  >
                    {isDownloading && (
                      <CircularProgress
                        sx={{ mr: 1, color: "white" }}
                        size={30}
                      />
                    )}
                    {!isDownloading && (
                      <>
                        <DownloadIcon sx={{ color: "#ffffff" }} />
                      </>
                    )}
                    <Typography
                      sx={{
                        pl: 2,
                        color: "#ffffff",
                        display: { xs: "inline", md: "none" },
                      }}
                    >
                      Download Candidate Documents
                    </Typography>
                  </PrimaryButton>
                </Box>
              </Stack>
              <Box sx={{ mt: 3 }}>
                <TabPanel value={value} index={0}>
                  <VettingDocuments
                    documents={vettingDocuments}
                    setDocuments={setVettingDocuments}
                    getRequest={getRequest}
                    yotiReport={
                      yotiReport as {
                        check_result: any;
                        documents: any[];
                        status: string;
                      }
                    }
                    setActive={setActive}
                    active={active}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <ReqReference
                    reference={reference}
                    setReference={setReference}
                    setActive={setRequestReferenceActive}
                    active={requestReferenceActive}
                  />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <AdditionalForms
                    document={additionalForms.document || []}
                    token={additionalForms.token}
                    signed={additionalForms.signed}
                    envelope_completed={additionalForms.envelope_completed}
                    sign_required={additionalForms.sign_required}
                    is_candidate_sign_required={
                      additionalForms.is_candidate_sign_required
                    }
                    is_co_sign_required={additionalForms.is_co_sign_required}
                    has_candidate_signed={additionalForms.has_candidate_signed}
                    has_co_signed={additionalForms.has_co_signed}
                    envelope_id={additionalForms.envelope_id}
                    is_rejected={additionalForms.is_rejected}
                    comment={additionalForms.comment}
                  />
                </TabPanel>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default CandidateProfile;
