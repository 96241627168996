import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Button, Grid,Typography,useMediaQuery, useTheme  } from "@mui/material";
import InputField from "../../../../../../../components/InputField";
import { FormBuilder } from "@formio/react";
import PrimaryButton from "../../../../../../../components/Button/PrimaryButton";
import axiosInstance from "../../../../../../../utils/axios";
import { URLS } from "../../../../../../../utils/constants/urls";
import {
  Reference,
  setNotificationMessage,
  setReference,
  PreviewReference,
  setPreviewReference
} from "../../../../../../../utils/redux";
import { useDispatch, useSelector } from "react-redux";

const CreateQuestionnaire: FC<{
  loading: boolean;
  type: string;
  setLoading: (loading: boolean) => void;
  setFilter: Dispatch<
    SetStateAction<{
      search: string;
      index: number;
      page_size: number;
      delay: number;
      name: string;
      sort: boolean;
    }>
  >;
  setExpanded: (
    expanded: "ked-questionnaire" | "additional-questionnaire" | ""
  ) => void;
  setOpen: (open: boolean) => void;
  open: boolean;
  referenceIndex: number;
}> = ({
  loading,
  setLoading,
  setFilter,
  setOpen,
  setExpanded,
  open,
  type,
  referenceIndex,
}) => {
  const dispatch = useDispatch();
  const reference = useSelector(Reference);
  const previewReference = useSelector(PreviewReference);

  const [questionnaireName, setQuestionnaireName] = useState("");
  const [form, setForm] = useState({ components: [] });
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    if (open || !open) {
      setForm((prevState) => ({ ...prevState, components: [] }));
    }
  }, [open]);

  const createQuestionnaire = async () => {
    if (questionnaireName) {
      try {
        setLoading(true);
        const { data } = await axiosInstance.post(
          URLS.additional_questionnaire,
          {
            form_name: questionnaireName,
            form_data: form.components,
            reference_type: type,
          }
        );
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "success",
            message: "Questionnaire saved Successfully",
          })
        );
        setQuestionnaireName("");
        setForm((prevState) => ({ ...prevState, components: [] }));
        const request_references = JSON.parse(
          JSON.stringify(reference.request_references)
        );
        request_references[referenceIndex].additional_questionnaires.push(
          data.id
        );
        dispatch(
          setReference({
            ...reference,
            request_references,
          })
        );

let additionalQuestData={
  id:data.id,
  name:questionnaireName,
  type:'FORM',
  reference_type:type,
  form_data: form.components,
  created_at:`${new Date().getTime()}`,
}
const preview_reference = JSON.parse(JSON.stringify(previewReference));

const preview_additional_questionnaires = JSON.parse(
  JSON.stringify(
    preview_reference[referenceIndex].additional_questionnaires
  )
);


  preview_additional_questionnaires.push(additionalQuestData);

preview_reference[referenceIndex].additional_questionnaires =
  preview_additional_questionnaires;

dispatch(setPreviewReference(preview_reference));


        setExpanded("additional-questionnaire");
        setLoading(false);
        setOpen(false);
        setFilter((prevState) => ({
          ...prevState,
          search: "",
          index: 0,
          name: "",
          sort: false,
        }));
      } catch (e) {
        setLoading(false);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "Unable to save Questionnaire",
          })
        );
      }
    } else {
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "Enter Questionnaire Name",
        })
      );
    }
  };

  return (
   <>
   <Grid container>
      {matches ?<>
        <Grid item xs={12}>
        <InputField
          label={"Questionnaire Name"}
          inputValue={questionnaireName}
          textChange={setQuestionnaireName}
        />
      </Grid>
      <Grid item xs={12}>
        <FormBuilder form={form} onChange={setForm} />
      </Grid>
      </>:<Grid item md={12}>
    <Typography variant="h6" sx={{color:'gray',fontSize:'14px',mb:3}}>”Please login through a desktop or laptop device to access the custom form feature.”</Typography>
    </Grid>}
      <Grid item xs={12} textAlign={"right"}>
        {matches &&<Button
          sx={{
            border: "none",
            color: "#727272",
            fontSize: 16,
            fontWeight: 500,
            mr: 2,
            mt: 1,
            p: "10px",
          }}
          disabled={loading}
          onClick={createQuestionnaire}
        >
          Create Questionnaire
        </Button>}
        <PrimaryButton
          disabled={loading}
          fullWidth={false}
          sx={{
            mt: 1,
            background: "#5AB9F9",
            color: "#ffffff",
            fontSize: 16,
            fontWeight: 500,
          }}
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </PrimaryButton>
      </Grid>
    </Grid>
   </>
  );
};

export default CreateQuestionnaire;
