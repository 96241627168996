import React, { ChangeEvent, FC, useState } from "react";
import { CircularProgress, Grid, Stack, Typography } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import PrimaryButton from "../../../../../../../components/Button/PrimaryButton";
import axiosInstance from "../../../../../../../utils/axios";
import { URLS } from "../../../../../../../utils/constants/urls";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setNotificationMessage,
  setValue,
} from "../../../../../../../utils/redux";

export interface SelectFilesProps {
  vettingDocId: string;
  setCountry: (value: string) => void;
}

const Citizenship: FC<SelectFilesProps> = ({ setCountry, vettingDocId }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [citizenship, setCitizenship] = useState<boolean | string>("");
  const { id } = useParams() as any;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCitizenship(event.target.value === "true");
  };

  const confirmCitizenship = async () => {
    try {
      setLoading(true);
      await axiosInstance.patch(`${URLS.requests}/${id}/document`, {
        is_britain_or_irish_citizen: citizenship,
        vetting_doc_id: vettingDocId,
      });
      setLoading(false);
      setCountry(citizenship ? "british" : "other");
      dispatch(setValue(1));
    } catch (e) {
      setLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in Confirming CitizenShip",
        })
      );
    }
  };

  return (
    <>
      <Stack justifyContent={"center"} textAlign={"center"}>
        <Typography
          variant={"body1"}
          color={"#333333"}
          textAlign={"center"}
          sx={{
            mb: 1,
            fontSize: "18px",
            fontWeight: "500",
            letterSpacing: 0.15,
          }}
        >
          Are you a British or Irish Citizen?
        </Typography>
        <FormControl>
          <RadioGroup
            sx={{ flexDirection: "row", justifyContent: "center" }}
            name="controlled-radio-buttons-group"
            value={citizenship}
            onChange={handleChange}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Stack>
      <Grid item xs={12} textAlign={"center"} sx={{ mt: 5 }}>
        <PrimaryButton
          fullWidth={false}
          sx={{
            width: "212px",
            height: "36px",
            background: " #5AB9F9",
            boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
            borderRadius: "6px",
            color: "#ffffff",
          }}
          disabled={loading || citizenship === ""}
          onClick={confirmCitizenship}
        >
          {loading && <CircularProgress sx={{ mr: 1 ,color:'white'}} size={20} />}
          Continue
        </PrimaryButton>
      </Grid>
    </>
  );
};

export default Citizenship;
