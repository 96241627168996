import React, { FC } from "react";
import { Form } from "@formio/react";
import { Stack } from "@mui/material";

const AdditionalQuestionnaire: FC<{
  additional_questionnaire_value: any[];
}> = ({ additional_questionnaire_value }) => {
  return (
    <Stack className={"view-referee-form"}>
      {additional_questionnaire_value.map(
        (questionnaire: any, index: number) => (
          <Form
            key={index}
            form={{
              display: "form",
              components: questionnaire.form_data,
            }}
            options={{ readOnly: true }}
            submission={{
              data: questionnaire.values || {},
            }}
          />
        )
      )}
    </Stack>
  );
};

export default AdditionalQuestionnaire;
