import { Validators } from '../../../utils/validators';
import { FormModel } from '../../../components/Form';

export const ChangePasswordForm = (): FormModel[] => {
  return [
    {
      label: 'New Password *',
      value: '',
      size: 'medium',
      type: 'text',
      typeValue: 'password',
      variant: 'outlined',
      placeholder: '',
      field: 'new_password',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
      ],
      responsive: { xs: 12 },
      required: true,
      style: { background: '#F6F7FB', borderRadius: '8px' },
    },
    {
      label: 'Confirm New Password *',
      value: '',
      size: 'medium',
      type: 'text',
      typeValue: 'password',
      variant: 'outlined',
      placeholder: '',
      field: 'new_password_conf',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
      ],
      responsive: { xs: 12 },
      required: true,
      style: { background: '#F6F7FB', borderRadius: '8px' },
    },
  ];
};
