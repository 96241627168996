import { createSlice } from '@reduxjs/toolkit';
import { clearStorage } from '../../storage';

interface WebSocketState {
  new_notification: boolean;
  is_tab_notification: boolean;
  total_new_notifications: number;
}

const webSocketSlice = createSlice({
  name: 'webSocketSlice',
  initialState: {
    new_notification: false,
    is_tab_notification: false,
    total_new_notifications: 0,
  },
  reducers: {
    setWebSocketData(state, action) {
      console.log(action)
      state.new_notification = action.payload.new_notification;
      state.is_tab_notification = action.payload.is_tab_notification;
      state.total_new_notifications = action.payload.total_new_notifications;
    }
  },
});

export default webSocketSlice.reducer;

export const { setWebSocketData } = webSocketSlice.actions;

export const isNewNotification = (state: { webSocketSlice: WebSocketState }) =>
  state.webSocketSlice.new_notification;

export const isTabNotification = (state: { webSocketSlice: WebSocketState }) =>
  state.webSocketSlice.is_tab_notification;

export const getTotalNewNotificationsCount = (state: { webSocketSlice: WebSocketState }) =>
  state.webSocketSlice.total_new_notifications;
