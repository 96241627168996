import React, {
  FC,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";
import {
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import CheckBox from "../../../../../../../components/CheckBox";
import dayjs from "dayjs";
import { identifiers } from "../../../../../../../utils/constants/identifiers";
import PrimaryButton from "../../../../../../../components/Button/PrimaryButton";
import { images } from "../../../../../../../utils/constants/images";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { styled } from "@mui/material/styles";
import {
  Reference,
  setReference,
  setNotificationMessage,
  PreviewReference,
  setPreviewReference,
} from "../../../../../../../utils/redux";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../../../../../components/modal/Modal";
import { Document, Page } from "react-pdf";

import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import axiosInstance from "../../../../../../../utils/axios";
import { URLS } from "../../../../../../../utils/constants/urls";

interface documentFiles {
  name: string;
  id: string;
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "#727272",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#263238",
    fontSize: "14px",
    fontWeight: 600,
    borderTop: "1px solid #DADADA",
    borderBottom: "1px solid #DADADA",
  },
  [`&.${tableCellClasses.head}:first-of-type`]: {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
    maxWidth: "1em",
    width: "1em",
  },
  [`&.${tableCellClasses.head}:nth-of-type(3)`]: {
    maxWidth: "200px",
    width: "200px",
  },
  [`&.${tableCellClasses.head}:last-of-type`]: {
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    maxWidth: "350px",
    paddingLeft: "40px",
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}:first-of-type`]: {
    borderTopLeftRadius: "12px",
    borderBottomLeftRadius: "12px",
    borderLeft: "1px solid #DADADA",
  },
  [`&.${tableCellClasses.body}:last-of-type`]: {
    borderTopRightRadius: "12px",
    borderBottomRightRadius: "12px",
    borderRight: "1px solid #DADADA",
    textAlign: "center",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&": {
    backgroundColor: "white",
    borderRadius: "8px",
    paddingBottom: "15px",
    paddingTop: "15px",
  },
}));

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "form_name_low_search",
    numeric: true,
    disablePadding: false,
    label: "Form Name",
  },
  {
    id: "created_at",
    numeric: true,
    disablePadding: false,
    label: "Upload Date",
  },
];

interface EnhancedTableProps {
  order: boolean;
  orderBy: string;
  onRequestSort: (property: string) => void;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { orderBy, order, onRequestSort } = props;

  const createSortHandler = (property: string) => {
    onRequestSort(property);
  };

  return (
    <StyledTableRow>
      <StyledTableCell></StyledTableCell>
      {headCells.map((headCell) => (
        <StyledTableCell
          key={headCell.id}
          align={headCell.numeric ? "left" : "right"}
          padding={headCell.disablePadding ? "none" : "normal"}
          sortDirection={order ? "asc" : "desc"}
        >
          <TableSortLabel
            active={orderBy === headCell.id}
            direction={order ? "asc" : "desc"}
            onClick={() => createSortHandler(headCell.id)}
          >
            {headCell.label}
            {orderBy === headCell.id && (
              <Box component="span" sx={visuallyHidden}>
                {order ? "sorted ascending" : "sorted descending"}
                descending
              </Box>
            )}
          </TableSortLabel>
        </StyledTableCell>
      ))}
      <StyledTableCell>Action</StyledTableCell>
    </StyledTableRow>
  );
}

const UploadOwnDocuments: FC<{
  filter: {
    search: string;
    index: number;
    page_size: number;
    delay: number;
    name: string;
    sort: boolean;
  };
  setFilter: Dispatch<
    SetStateAction<{
      search: string;
      index: number;
      page_size: number;
      delay: number;
      name: string;
      sort: boolean;
    }>
  >;
  additionalReferenceDocuments: any[];
  referenceIndex: number;
}> = ({ filter, setFilter, additionalReferenceDocuments, referenceIndex }) => {
  const dispatch = useDispatch();
  const reference = useSelector(Reference);
  const previewReference = useSelector(PreviewReference);

  const [documentType, setDocumentType] = useState<"pdf" | "image">();
  const [documentUrl, setDocumentUrl] = useState<string>();
  const [viewDocument, setViewDocument] = useState(false);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [document, setDocument] = useState<documentFiles | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleRequestSort = (property: string) => {
    setFilter((prevState) => ({
      ...prevState,
      index: 0,
      delay: 0,
      name: property,
      sort: prevState.name === property ? !prevState.sort : true,
    }));
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter((prevState) => ({ ...prevState, index: newPage, delay: 0 }));
  };

  const download = async (id: string) => {
    try {
      const { data } = await axiosInstance.get(
        `${URLS.document}/${id}?type=reference`
      );
      const type =
        data.mimetype.indexOf("pdf") > -1
          ? "pdf"
          : data.mimetype.indexOf("image") > -1
          ? "image"
          : null;
      if (!!type) {
        setDocumentType(type);
        setDocumentUrl(data.url);
        setViewDocument(true);
      } else {
        window.open(data.url, "_blank");
      }
    } catch (e) {
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in downloading document",
        })
      );
    }
  };

  const deleteFile = async (selectedDocument: documentFiles) => {
    setDocument(selectedDocument);
    setOpenModal(true);
  };

  const deleteDocuments = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        await axiosInstance.delete(`${URLS.document}/${id}?type=reference`);
        setLoading(false);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "success",
            message: "Document deleted successfully",
          })
        );
        setOpenModal(false);
        setFilter((prevState) => ({ ...prevState, delay: 0 }));
      } catch (e) {
        setLoading(false);
        console.log(e);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "There is an error in deleting document",
          })
        );
        setOpenModal(false);
      }
    },
    [dispatch, setFilter]
  );

  const deleteModalText = useMemo(() => {
    return (
      <Grid container>
        {!!document && (
          <>
            <Grid item>
              <Typography
                variant="h6"
                sx={{
                  marginBottom: "20px",
                  fontSize: 18,
                  fontWeight: 500,
                  color: "#000000",
                  textAlign: "center",
                  letterSpacing: "0.15px",
                }}
              >
                Delete Document Permanently
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  mb: 3,
                  fontSize: 16,
                  fontWeight: 500,
                  color: "#727272",
                }}
              >
                {document.name} will be deleted permanently.
                <br />
                Are you sure?
              </Typography>
            </Grid>
            <Grid container alignItems="right" justifyContent="right">
              <Button
                sx={{
                  border: "none",
                  color: "#727272",
                  fontSize: 16,
                  fontWeight: 500,
                  mr: 2,
                  mt: 1,
                  p: "10px",
                }}
                disabled={loading}
                onClick={async () => {
                  await deleteDocuments(document.id);
                }}
              >
                {loading && <CircularProgress sx={{ mr: 1 }} size={30} />}Delete
                Document
              </Button>
              <PrimaryButton
                disabled={loading}
                fullWidth={false}
                sx={{
                  mt: 1,
                  background: "#5AB9F9",
                  color: "#ffffff",
                  fontSize: 16,
                  fontWeight: 500,
                }}
                onClick={() => {
                  setOpenModal(false);
                  setDocument(null);
                }}
              >
                Cancel
              </PrimaryButton>
            </Grid>
          </>
        )}
      </Grid>
    );
  }, [deleteDocuments, document, loading]);

  const viewModalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          {documentType === "pdf" && (
            <Document
              file={{ url: documentUrl }}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          )}
          {documentType === "image" && (
            <img src={documentUrl} alt={"document"} className={"img-fluid"} />
          )}
        </Grid>
      </Grid>
    );
  }, [documentType, documentUrl, numPages]);

  const openDeleteModal = useMemo(() => {
    return (
      <Modal
        open={openModal}
        setModalClose={setOpenModal}
        children={deleteModalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [openModal, deleteModalText]);

  const openViewModal = useMemo(() => {
    return (
      <Modal
        open={viewDocument}
        setModalClose={setViewDocument}
        children={viewModalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [viewDocument, viewModalText]);

  const selectReferenceFile = (reference_file: any) => {
    const request_references = JSON.parse(
      JSON.stringify(reference.request_references)
    );
    const reference_documents = JSON.parse(
      JSON.stringify(request_references[referenceIndex].reference_documents)
    );
    if (reference_documents.indexOf(reference_file.id) > -1) {
      reference_documents.splice(
        reference_documents.indexOf(reference_file.id),
        1
      );
    } else {
      reference_documents.push(reference_file.id);
    }
    request_references[referenceIndex].reference_documents =
      reference_documents;
    dispatch(setReference({ ...reference, request_references }));

    const preview_reference = JSON.parse(JSON.stringify(previewReference));

    const  preview_reference_documents = !!preview_reference[referenceIndex].reference_forms ? JSON.parse(
      JSON.stringify(preview_reference[referenceIndex].reference_forms)
    ) : [];
    if (
      preview_reference_documents.findIndex(
        (additional_questionnaire: any) =>
          additional_questionnaire.id === reference_file.id
      ) > -1
    ) {
      preview_reference_documents.splice(
        preview_reference_documents.findIndex(
          (additional_questionnaire: any) =>
            additional_questionnaire.id === reference_file.id
        ),
        1
      );
    } else {
      preview_reference_documents.push(reference_file);
    }

    preview_reference[referenceIndex].reference_forms =
      preview_reference_documents;

    dispatch(setPreviewReference(preview_reference));
  };

  return (
    <>
      <Grid container>
        {openViewModal}
        {openDeleteModal}
        <Grid item xs={12}>
          <Typography sx={{fontWeight:500}}>Select from saved forms</Typography>
          <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
            <Table
              sx={{
                minWidth: 500,
                borderSpacing: "0 1rem",
                borderCollapse: "separate",
              }}
            >
              <TableHead>
                <EnhancedTableHead
                  order={filter.sort}
                  orderBy={filter.name}
                  onRequestSort={handleRequestSort}
                />
              </TableHead>
              <TableBody className={"documents-table-body"}>
                {additionalReferenceDocuments
                  .slice(
                    filter.index * filter.page_size,
                    filter.index * filter.page_size + filter.page_size
                  )
                  .map((reference_documents, index) => (
                    <StyledTableRow
                      className={"documents-table-body-row"}
                      key={index}
                    >
                      <StyledTableCell>
                        <CheckBox
                          sx={{ pl: 0 }}
                          type={"circle"}
                          checked={
                            reference.request_references[
                              referenceIndex
                            ].reference_documents.indexOf(
                              reference_documents.id
                            ) > -1
                          }
                          onChange={() =>
                            selectReferenceFile(reference_documents)
                          }
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        {reference_documents.name}
                      </StyledTableCell>
                      <StyledTableCell>
                        {dayjs(new Date(reference_documents.created_at)).format(
                          identifiers.date_format as string
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Stack justifyContent={'center'} direction={"row"}>
                          <PrimaryButton
                            fullWidth={false}
                            variant={"text"}
                            sx={{ p: 0 }}
                            onClick={() => download(reference_documents.id)}
                          >
                            <img
                              src={images.viewIcon}
                              alt="view"
                              className="action-icon"
                            />
                          </PrimaryButton>
                          <PrimaryButton
                            fullWidth={false}
                            variant={"text"}
                            sx={{ p: 0 }}
                            onClick={async () => {
                              await deleteFile(reference_documents);
                            }}
                          >
                            <img
                              src={images.deleteWIcon}
                              alt="view"
                              className="action-icon"
                            />
                          </PrimaryButton>
                        </Stack>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                {additionalReferenceDocuments.length === 0 && (
                  <StyledTableRow className={"request-table-body-row"}>
                    <StyledTableCell colSpan={7}>
                      <Stack textAlign={"center"}>
                        <img
                          src={images.emptyIcon}
                          alt={"no request"}
                          className={"vertical-align-middle empty-image mb-2"}
                        />
                        <Stack direction={"row"} justifyContent={"center"}>
                          <Typography variant={"body2"}>
                            No Reference Form found. Upload Reference Form to
                            view
                          </Typography>
                        </Stack>
                      </Stack>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
              {additionalReferenceDocuments.length > 0 && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      colSpan={6}
                      count={additionalReferenceDocuments.length}
                      rowsPerPage={filter.page_size}
                      page={filter.index}
                      onRowsPerPageChange={(e) => {
                        setFilter((prevState) => ({
                          ...prevState,
                          page_size: parseInt(e.target.value, 10),
                        }));
                      }}
                      onPageChange={handleChangePage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default UploadOwnDocuments;
