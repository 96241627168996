import React, { FC } from "react";
import { Typography, Toolbar, Box, AppBar } from "@mui/material";
import "./TitleBar.scss";

const TitleBar: FC = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        p: 0,
        position: "sticky",
        top: "0px",
        background: "white",
        zIndex: "100",
      }}
    >
      <AppBar position="static" color="transparent">
        <Toolbar disableGutters>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Request Details
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default TitleBar;
