import React, { FC, useMemo, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TableCell,
  styled,
  tableCellClasses,
  TableRow,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableBody,
  Button,
} from "@mui/material";
import { Stack } from "@mui/system";
import { AccordionSummaryProps, AccordionProps } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Checks from "./Checks";
import Tasks from "./Task";
import { images } from "../../../../../../utils/constants/images";
import axiosInstance from "../../../../../../utils/axios";
import { URLS } from "../../../../../../utils/constants/urls";
import { setNotificationMessage } from "../../../../../../utils/redux";
import { useDispatch } from "react-redux";
import PageLoader from "../../../../../../components/PageLoader";

import { Document, Page } from "react-pdf";
import Modal from "../../../../../../components/modal/Modal";

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(() => ({
  padding: "0px 5px 0px 0px",
  height: "57px",
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    {...props}
    disableGutters
    elevation={0}
    square
    children={props.children}
  />
))(() => ({
  border: 0,
  marginBottom: "40px",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "none",
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: "20px 0px",
}));

const YotiResult: FC<{
  yotiReport: { check_result: any; documents: any[]; status: string };
}> = ({ yotiReport }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [documentType, setDocumentType] = useState<"pdf" | "image">();

  const [documentUrl, setDocumentUrl] = useState<string>();

  const [viewDocument, setViewDocument] = useState(false);

  const [numPages, setNumPages] = useState<number | null>(null);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      color: "#727272",
      border: "none",
      borderBottom: "1px solid #DADADA",
    },
    [`&.${tableCellClasses.body}`]: {
      color: "#263238",
      fontSize: "14px",
      fontWeight: 500,
      padding: "5px 16px",
    },
    [`&.${tableCellClasses.head}:first-of-type`]: {
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
    },
    [`&.${tableCellClasses.head}:last-of-type`]: {
      width: "14rem",
      textAlign: "start",
      paddingLeft: "35px",
    },
    [`&.${tableCellClasses.body}:first-of-type`]: {
      borderBottom: "1px solid #DADADA",
    },
    [`&.${tableCellClasses.body}:last-of-type`]: {
      borderTopRightRadius: "12px",
      borderBottomRightRadius: "12px",
      textAlign: "center",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&": {
      borderBottom: "1px solid gray",
      backgroundColor: "white",
    },
  }));
  const download = async (id: string, view: boolean) => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(`${URLS.document}/${id}`);

      const type =
        data.mimetype.indexOf("pdf") > -1
          ? "pdf"
          : data.mimetype.indexOf("image") > -1
          ? "image"
          : null;
      if (!!type && view) {
        setDocumentType(type);
        setDocumentUrl(data.url);
        setViewDocument(true);
      } else {
        window.open(data.url, "_blank");
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in downloading document",
        })
      );
    }
  };

  const viewModalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          {documentType === "pdf" && (
            <Document
              file={{ url: documentUrl }}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          )}
          {documentType === "image" && (
            <img src={documentUrl} alt={"document"} className={"img-fluid"} />
          )}
        </Grid>
      </Grid>
    );
  }, [documentType, documentUrl, numPages]);
  const openViewModal = useMemo(() => {
    return (
      <Modal
        open={viewDocument}
        setModalClose={setViewDocument}
        children={viewModalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [viewDocument, viewModalText]);

  return (
    <>
      {loading && <PageLoader />}
      {openViewModal}
      <Grid
        container
        className="mb-3"
        display={"flex"}
        justifyContent={"center"}
        sx={{ maxHeight: "600px", overflowY: "scroll", px: 2 }}
      >
        <Grid item xs={12} md={12} sx={{ mb: 2 }}>
          <TableContainer
            component={Paper}
            sx={{ boxShadow: "none", width: "100%" }}
          >
            <Table
              sx={{
                minWidth: 400,
                borderCollapse: "separate",
              }}
            >
              <StyledTableRow>
                <StyledTableCell variant="head">State</StyledTableCell>
                <StyledTableCell>{yotiReport?.status}</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell variant="head">
                  User Tracking ID
                </StyledTableCell>
                <StyledTableCell>
                  {yotiReport?.check_result?.tracking_id}
                </StyledTableCell>
              </StyledTableRow>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="h6">Checks</Typography>

          <Stack direction={"column"} sx={{ border: "1px solid lightgray" }}>
            {yotiReport?.check_result?.checks?.map((check: any) => (
              <Accordion sx={{ background: "white", marginBottom: "0px" }}>
                <AccordionSummary
                  sx={{
                    border: "1px solid #E8EBFE",
                    background: "#F7F7F8",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#6279F7",
                        fontWeight: 500,
                        fontSize: "16px",
                        m: 2,
                      }}
                    >
                      {check.type === "ID_DOCUMENT_AUTHENTICITY"
                        ? "Authenticity Checks"
                        : check.type === "ID_DOCUMENT_TEXT_DATA_CHECK"
                        ? "Text Data Checks"
                        : check.type === "LIVENESS"
                        ? "Liveness Checks"
                        : check.type === "ID_DOCUMENT_FACE_MATCH"
                        ? "Face Match Checks"
                        : ""}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Checks check={check} />
                </AccordionDetails>
              </Accordion>
            ))}
          </Stack>
        </Grid>
        <Grid xs={12} md={12} mt={3}>
          <Typography variant="h5">ID Documents</Typography>
          <>
            {yotiReport?.documents?.map((document) => (
              <>
                <Typography variant="h5">{document.document}</Typography>
                <Stack
                  direction={"column"}
                  sx={{ border: "1px solid lightgray" }}
                >
                  <Accordion sx={{ background: "white", marginBottom: "0px" }}>
                    <AccordionSummary
                      sx={{
                        border: "1px solid #E8EBFE",
                        background: "#F7F7F8",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#6279F7",
                            fontWeight: 500,
                            fontSize: "16px",
                            m: 2,
                          }}
                        >
                          Text Extraction Tasks
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ background: "white" }}>
                      <Tasks fields={document?.fields} />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion sx={{ background: "white", marginBottom: "0px" }}>
                    <AccordionSummary
                      sx={{
                        border: "1px solid #E8EBFE",
                        background: "#F7F7F8",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#6279F7",
                            fontWeight: 500,
                            fontSize: "16px",
                            m: 2,
                          }}
                        >
                          Pages
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ background: "white" }}>
                      <TableContainer
                        component={Paper}
                        sx={{ boxShadow: "none", width: "100%" }}
                      >
                        <Table
                          sx={{
                            minWidth: 400,
                            borderSpacing: "0 1rem",
                            borderCollapse: "separate",
                            backgroundColor: "#f5fbff",
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell sx={{ minWidth: "150px" }}>
                                Page Name
                              </StyledTableCell>
                              <StyledTableCell>Action</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className={"documents-table-body"}>
                            {document?.pages?.map(
                              (page: string, index: number) => (
                                <>
                                  <StyledTableRow
                                    className={"documents-table-body-row"}
                                    key={index}
                                  >
                                    <StyledTableCell>
                                      Page {index + 1}
                                    </StyledTableCell>

                                    <StyledTableCell>
                                      <Grid
                                        item
                                        display={"flex"}
                                        flexWrap={"nowrap"}
                                        md={6}
                                      >
                                        <Button
                                          onClick={() => download(page, false)}
                                          sx={{
                                            border: "none",
                                            color: "#727272",
                                            fontSize: 16,
                                            fontWeight: 500,
                                            mr: 2,
                                            mt: 1,
                                            p: "10px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <img
                                            src={images.downloadIcon}
                                            alt="download"
                                          />
                                        </Button>
                                        <Button
                                          onClick={() => download(page, true)}
                                          sx={{
                                            border: "none",
                                            color: "#727272",
                                            fontSize: 16,
                                            fontWeight: 500,
                                            mr: 2,
                                            mt: 1,
                                            p: "10px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <img
                                            src={images.viewIcon}
                                            alt="view"
                                            className="action-icon"
                                          />
                                        </Button>
                                      </Grid>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                </>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                </Stack>
              </>
            ))}
          </>
        </Grid>
      </Grid>
    </>
  );
};

export default YotiResult;
