import { SubDocument, VettingDocument } from './RequestDetailsModel';
import dayjs from 'dayjs';

const Validate = (
  requestDetails: {
    [key: string]: string | { error: boolean; message: string } | undefined;
  },
  vettingDocuments: VettingDocument[]
): { valid: boolean; errorFields: string[]; date_error: boolean } => {
  let valid = true;
  let date_error: boolean = false;
  const errorFields: string[] = [];
  const companyError = requestDetails.companyError as
    | { error: boolean; message: string }
    | undefined;
  const designationError = requestDetails.designationError as
    | { error: boolean; message: string }
    | undefined;
  const dateError = requestDetails.dateError as
    | { error: boolean; message: string }
    | undefined;
  if (companyError && companyError.error) {
    valid = false;
  }
  if (designationError && designationError.error) {
    valid = false;
  }
  if (dateError && dateError.error) {
    valid = false;
  } else {
    if (
      new Date(requestDetails.date as string).getTime() <
      new Date(dayjs(new Date()).format('YYYY-MM-DD 00:00:00.000')).getTime()
    ) {
      valid = false;
      date_error = true;
    }
  }
  vettingDocuments.forEach((vettingDocument: VettingDocument) => {
    if (vettingDocument.checked) {
      // Check Required ReferenceForms Count
      if (vettingDocument.default_documents_required !== 'N/A') {
        if (parseInt(vettingDocument.documents_required) > 0) {
          if (
            parseInt(vettingDocument.documents_required) >
            vettingDocument.sub_documents.length
          ) {
            valid = false;
            errorFields.push(
              'Enter Valid Number of Required ReferenceForms for ' +
                vettingDocument.name
            );
          }
          // Check if Sub ReferenceForms are Checked
          let count = 0;
          vettingDocument.sub_documents.forEach((subDocument: SubDocument) => {
            if (subDocument.checked) {
              count++;
            }
          });
          if (count < parseInt(vettingDocument.documents_required)) {
            valid = false;
            errorFields.push(
              'Select Valid Number of ReferenceForms for ' +
                vettingDocument.name
            );
          }
        } else {
          valid = false;
          errorFields.push(
            'Enter Required ReferenceForms for ' + vettingDocument.name
          );
        }
      }
    }
  });
  return { valid, errorFields, date_error };
};

export default Validate;
