import React, { FC, useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableBody,
  TableFooter,
  tableCellClasses,
  TableCell,
  Button,
  TablePagination,
  Stack,
} from "@mui/material";
import "./Applicants.scss";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import axiosInstance from "../../../../utils/axios";
import { URLS } from "../../../../utils/constants/urls";
import { images } from "../../../../utils/constants/images";
import { useNavigate, useParams } from "react-router-dom";
import PageLoader from "../../../../components/PageLoader";
import dayjs from "dayjs";
import { identifiers } from "../../../../utils/constants/identifiers";
import { ApplicantsDetailsModel } from "../RequestDetails/RequestDetailsModel";
import { agency } from "../../../../utils/constants/routes";
import { debounce } from "lodash";
import InputField from "../../../../components/InputField";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationMessage } from "../../../../utils/redux";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../utils/constants/coachMarks";
import {
  User,
  setUserData,
} from "../../../../utils/redux/reducer/authentication-slice";
import * as Amplify from "../../../../utils/services/amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { isTourCompleted, markTourAsCompleted } from "../../../../utils/storage/tours";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F5FBFF",
    color: "#727272",
    border: "none",
    maxWidth: "300px",
    width: "300px",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    color: "#263238",
    fontSize: "14px",
    fontWeight: 600,
  },
  [`&.${tableCellClasses.head}:first-of-type`]: {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
    paddingLeft: "20px",
  },
  [`&.${tableCellClasses.head}:last-of-type`]: {
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    maxWidth: "2em",
    width: "2em",
  },
  [`&.${tableCellClasses.body}:first-of-type`]: {
    borderTopLeftRadius: "12px",
    borderBottomLeftRadius: "12px",
  },
  [`&.${tableCellClasses.body}:last-of-type`]: {
    borderTopRightRadius: "12px",
    borderBottomRightRadius: "12px",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&": {
    backgroundColor: "#f9fbfc",
    paddingBottom: "15px",
    paddingTop: "15px",
  },
}));

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

interface EnhancedTableProps {
  order: boolean;
  orderBy: string;
  onRequestSort: (property: string) => void;
  showTop: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Applicant Name",
  },
  {
    id: "designation",
    numeric: true,
    disablePadding: false,
    label: "Job Role",
  },
  {
    id: "request_id",
    numeric: true,
    disablePadding: false,
    label: "Request ID",
  },
  {
    id: "date",
    numeric: true,
    disablePadding: false,
    label: "Date Applied",
  },
];

const EnhancedTableHead: FC<EnhancedTableProps> = ({
  orderBy,
  order,
  onRequestSort,
  showTop,
}) => {
  const createSortHandler = (property: string) => {
    onRequestSort(property);
  };

  const render = (headCell: HeadCell) => {
    return (
      <StyledTableCell
        key={headCell.id}
        align={headCell.numeric ? "left" : "right"}
        padding={headCell.disablePadding ? "none" : "normal"}
        sortDirection={order ? "asc" : "desc"}
      >
        <TableSortLabel
          active={orderBy === headCell.id}
          direction={order ? "asc" : "desc"}
          onClick={() => createSortHandler(headCell.id)}
        >
          {headCell.label}
          {orderBy === headCell.id && (
            <Box component="span" sx={visuallyHidden}>
              {order ? "sorted ascending" : "sorted descending"}
              descending
            </Box>
          )}
        </TableSortLabel>
      </StyledTableCell>
    );
  };

  return (
    <StyledTableRow>
      {headCells.map((headCell) => (
        <>
          {headCell.id === "name" ? (
            <>{render(headCell)}</>
          ) : (
            <>
              {showTop &&
                (headCell.id === "designation" ||
                  headCell.id === "request_id") && (
                  <StyledTableCell>{headCell.label}</StyledTableCell>
                )}
              {!showTop && headCell.id === "date" && <>{render(headCell)}</>}
            </>
          )}
        </>
      ))}
      {!showTop && <StyledTableCell>Status</StyledTableCell>}
      <StyledTableCell></StyledTableCell>
    </StyledTableRow>
  );
};
interface ApplicantStatusProps {
  status: string;
  isSmall: boolean
}

const ApplicantStatus: React.FC<ApplicantStatusProps> = ({ status , isSmall = false}) => {
  return (
    <Button
      fullWidth={false}
      sx={{
        background:
          status === 'Ready for Review'
            ? '#C5EAFD'
            : status === 'Approved'
              ? '#5AB9F9'
              : status === 'In Progress'
                ? '#F8E592'
                : '#E56D6D',
        color: '#333333',
        fontSize: !isSmall ? 14 : 12,
        fontWeight: 500,
        padding: '4px 14px',
        width: 'fit-content',
        minWidth: !isSmall ? '180px' : 'fit-content',
      }}
    >
      {status}
    </Button>
  );
};
const Applicants: FC<{ showTop?: boolean }> = ({ showTop = true }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const params = useParams() as any;
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [applicants, setApplicants] = useState<any[]>([]);
  const [totalApplicants, setTotalApplicants] = useState<number>(0);
  const { id } = params;
  const [isLoading, setIsLoading] = useState<boolean>();
  const Navigate = useNavigate();
  const [filter, setFilter] = useState<{
    search: string;
    index: number;
    page_size: number;
    delay: number;
    name: string;
    sort: boolean;
  }>({
    search: "",
    index: 0,
    page_size: 10,
    delay: 0,
    name: "name",
    sort: true,
  });

  const { setIsOpen, currentStep, setCurrentStep } = useTour();
  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };

  const setCoachMarksValue = useCallback(async () => {
    //if (!!user && user["custom:reference_tutorial"] !== "true") {
    const currentUser: CognitoUser = await Amplify.UserDetail();
    const body = {
      "custom:applicant_screen": "true",
    };
    await Amplify.UpdateUserDetails(body, currentUser);
    await Amplify.RefreshSession();
    dispatch(setUserData({ ...user, ...body }));
    //}
  }, [user, dispatch]);

  useEffect(() => {
    if (isLoading !== undefined && !isLoading) {
      if (!!user && user["custom:applicant_screen"] !== "true") {
        if (showTop) {
          setCurrentStep(tourStep.applicant_screen.index);
        }
      }
    }
  }, [showTop, user, isLoading, setCurrentStep]);

  useEffect(() => {
    if (tourStep.applicant_screen.index === currentStep) {
      if (!isTourCompleted('applicant_screen')) {
        setIsOpen(true);
      }
    } else if (
      currentStep ===
      tourStep.applicant_screen.index + tourStep.applicant_screen.steps
    ) {
      setIsOpen(false);
      markTourAsCompleted('applicant_screen');
      if (!!user && user["custom:applicant_screen"] !== "true") {
        (async () => {
          await setCoachMarksValue();
        })();
      }
    }
  }, [currentStep, setCoachMarksValue, setIsOpen, user]);

  const getApplicants = async (search: string) => {
    const searchQuery = !!search && `&keyword=${search}`;
    setIsLoading(true);
    try {
      let index = filter.index;
      let total = 0;
      let applicants: any[] = [];
      do {
        if (showTop) {
          const { data } = await axiosInstance.get(
            `${URLS.agency_candidates}?page_number=${index + 1}&page_size=${
              filter.page_size
            }${searchQuery || ""}`
          );
          setIsLoading(false);
          applicants = [...applicants, ...data.data];
          if (index > 0) {
            setApplicants((prevState) => [...prevState, ...data.data]);
          } else {
            setApplicants(data.data);
          }
          index += 1;
          total = data.count;
        } else {
          const { data } = await axiosInstance.get(
            `${URLS.requests}/${id}/candidates?page_number=${
              index + 1
            }&page_size=${filter.page_size}${searchQuery || ""}`
          );
          setIsLoading(false);
          if (index > 0) {
            setApplicants((prevState) => [...prevState, ...data.data]);
          } else {
            setApplicants(data.data);
          }
          applicants = [...applicants, ...data.data];
          index += 1;
          total = data.count;
        }
      } while (total > applicants.length);
      setTotalApplicants(total);
      setApplicants(
        applicants.sort((a, b) => {
          if (a.name.toUpperCase() < b.name.toUpperCase()) {
            return -1;
          }
          if (a.name.toUpperCase() > b.name.toUpperCase()) {
            return 1;
          }
          // names must be equal
          return 0;
        })
      );
    } catch (e: any) {
      setIsLoading(false);
      if (await Auth.currentUserInfo()) {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "There is an error in fetching candidates",
          })
        );
      }
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter((prevState) => ({ ...prevState, index: newPage }));
  };

  const setSearch = async (search: string) => {
    setFilter((prevState) => ({ ...prevState, search, index: 0, delay: 500 }));
  };

  const delayedQuery = useRef(debounce(getApplicants, filter.delay)).current;

  useEffect(() => {
    (async () => {
      await delayedQuery(filter.search);
    })();
  }, [delayedQuery, filter.search]);

  const navigate = (applicant: ApplicantsDetailsModel) => {
    if (id) {
      Navigate(`${agency.requests}/${id}/applicants/${applicant.candidate_id}`);
      // } else {
      //   Navigate(`${agency.applicants}/${applicant.candidate_id}`);
    }
  };

  const handleApplicantsSort = (property: string) => {
    const sorted_applicants = applicants.sort((a: any, b: any) => {
      const sort = filter.name === property ? !filter.sort : true;
      if (sort) {
        if (property === "name") {
          if (a[property].toUpperCase() < b[property].toUpperCase()) {
            return -1;
          }
          if (a[property].toUpperCase() > b[property].toUpperCase()) {
            return 1;
          }
          // names must be equal
          return 0;
        } else {
          return (
            new Date(a[property]).getTime() - new Date(b[property]).getTime()
          );
        }
      } else {
        if (property === "name") {
          if (a[property].toUpperCase() > b[property].toUpperCase()) {
            return -1;
          }
          if (a[property].toUpperCase() < b[property].toUpperCase()) {
            return 1;
          }
          // names must be equal
          return 0;
        } else {
          return (
            new Date(b[property]).getTime() - new Date(a[property]).getTime()
          );
        }
      }
    });
    console.log(sorted_applicants);
    // setApplicants((prevState) =>
    //   prevState.sort(
    // );
    setFilter((prevState) => ({
      ...prevState,
      delay: 0,
      name: property,
      sort: prevState.name === property ? !prevState.sort : true,
    }));
  };

  return (
    <Box sx={{ p: '1rem' }}>
      {isLoading && <PageLoader />}
      <Grid>
        {showTop && (
          <Grid container spacing={2}>
            <Grid data-tut="total_aplicant" item xs={12} md={2}>
              <Box
                sx={{
                  backgroundColor: "#F5FBFF",
                  px: 2,
                  py: 1,
                  height: "84px",
                }}
              >
                <Typography
                  variant={"subtitle1"}
                  gutterBottom={true}
                  className="request-details-box-heading"
                >
                  Total Applicants
                </Typography>
                <Typography
                  variant={"h6"}
                  sx={{ fontWeight: "bold" }}
                  className="request-details-box"
                >
                  {totalApplicants}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}
        <Grid
          container
          justifyContent={matches ? "start" : "space-between"}
        >
          <Grid item xs={12} lg={3}>
            <InputField
              sx={{ mr: 1 }}
              placeholder="Search Applicants..."
              value={filter.search}
              textChange={setSearch}
              inputProps={{
                sx: {
                  p: 1,
                },
              }}
              InputProps={{
                endAdornment: <SearchIcon />,
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={"mb-3"}>
          <Grid item xs={12} md={12}>
            <TableContainer component={Paper} sx={{ boxShadow: "none", overflow: 'visible' }}>
              <Table
                sx={{
                  minWidth: {
                    md: 700
                  },
                  borderSpacing: "0 1rem",
                  borderCollapse: "separate",
                }}
              >
                <TableHead>
                  {!isMobile &&
                  <EnhancedTableHead
                    order={filter.sort}
                    orderBy={filter.name}
                    onRequestSort={handleApplicantsSort}
                    showTop={showTop}
                  />
                  }
                  {isMobile &&
                    <>
                      <StyledTableCell
                      >
                        Applicants
                      </StyledTableCell>
                      <StyledTableCell
                      >
                      </StyledTableCell>
                    </>
                  }
                </TableHead>
                <TableBody className={"request-table-body"}>
                  {applicants
                    .slice(
                      filter.index * filter.page_size,
                      filter.index * filter.page_size + filter.page_size
                    )
                    .map((request, index) => (
                      <StyledTableRow
                        key={index}
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate(request);
                        }}
                        className={"request-table-body-row"}
                      >
                        <StyledTableCell>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <img
                              height="20px"
                              width="20px"
                              src={
                                !!request.image
                                  ? request.image
                                  : images.dummyProfilePic
                              }
                              style={{ borderRadius: "50%" }}
                              alt={"Applicant Profile"}
                            />
                            {request.name}
                          </Box>
                          {isMobile &&
                            <Stack mt={"1rem"}>
                              <ApplicantStatus status={request.status} isSmall={true} />

                            </Stack>
                          }

                        </StyledTableCell>
                        {showTop && !isMobile  && (
                          <StyledTableCell>
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                gap: "0.25rem",
                              }}
                            >
                              {
                                request?.request_details
                                  .map((details: { hiring_post: string, request_id: string }, index: number) => (
                                    <React.Fragment key={index}>
                                      <a href={`requests/${details.request_id}/applicants/${request.candidate_id}`} style={{ color: '#21bcff' }}>
                                        {details.hiring_post}
                                      </a>
                                      {index < request.request_details.length - 1 ? ', ' : ""}
                                    </React.Fragment>
                                  ))
                              }
                            </Box>
                          </StyledTableCell>
                        )}
                        {!showTop && !isMobile && (
                          <StyledTableCell>
                            {dayjs(new Date(request.date)).format(
                              identifiers.date_format as string
                            )}
                          </StyledTableCell>
                        )}
                        {showTop && (
                          <StyledTableCell>
                            {
                                request?.request_details
                                  .map((details: { request_id_name: string, request_id: string }, index: number) => (
                                    <React.Fragment key={index}>
                                      <a href={`requests/${details.request_id}/applicants/${request.candidate_id}`} style={{ color: '#21bcff' }}>
                                        {details.request_id_name}
                                      </a>
                                      {index < request.request_details.length - 1 ? ', ' : ""}
                                    </React.Fragment>
                                  ))
                              }
                          </StyledTableCell>
                        )}
                        {!showTop && !isMobile && (
                          <StyledTableCell>
                            <ApplicantStatus status={request.status} isSmall={false}/>
                          </StyledTableCell>
                        )}
                        <StyledTableCell>
                          {!showTop && <ChevronRightIcon />}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  {applicants.length === 0 && !isLoading && (
                    <StyledTableRow className={"request-table-body-row"}>
                      <StyledTableCell colSpan={7}>
                        <Stack textAlign={"center"}>
                          <img
                            src={images.emptyIcon}
                            alt={"no request"}
                            className={"vertical-align-middle empty-image mb-2"}
                          />
                          <Stack direction={"row"} justifyContent={"center"}>
                            <Typography variant={"body2"}>
                              No Applicants Found.
                            </Typography>
                          </Stack>
                        </Stack>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      colSpan={7}
                      count={applicants.length}
                      rowsPerPage={filter.page_size}
                      page={filter.index}
                      onRowsPerPageChange={(e) => {
                        setFilter((prevState) => ({
                          ...prevState,
                          page_size: parseInt(e.target.value, 10),
                          delay: 0,
                        }));
                      }}
                      onPageChange={handleChangePage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Applicants;
