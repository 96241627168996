import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import {
  createTheme,
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import { Provider } from "react-redux";
import store from "./utils/redux";
import { BrowserRouter as Router } from "react-router-dom";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { pdfjs } from "react-pdf";
import { TourProvider } from "@reactour/tour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { steps } from "./utils/constants/coachMarks";
import PrimaryButton from "./components/Button/PrimaryButton";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

Amplify.configure(awsExports);

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#5AB9F9",
      light: "#fdf6f6",
    },
    secondary: {
      main: "#D7D7D7",
      contrastText: "#333",
    },
    warning: {
      main: "#F4D356",
    },
    success: {
      main: "#5AB9F9",
    },
    error: {
      main: "#EB0000",
    },
  },
  typography: {
    fontFamily: ["Roboto"].join(","),
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const disableBody = (target: any) => disableBodyScroll(target);
const enableBody = (target: any) => enableBodyScroll(target);

root.render(
  <StyledEngineProvider injectFirst>
    <CssBaseline />
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router basename="/">
          <TourProvider
            showBadge={false}
            prevButton={() => {
              return null;
            }}
            onClickMask={({
              setIsOpen
            }) =>{
              setIsOpen(false)
            } }
            showCloseButton={true}
            nextButton={({
              currentStep,
              stepsLength,
              setIsOpen,
              setCurrentStep,
            }) => {
              const last = currentStep === stepsLength - 1;
              return (
                <PrimaryButton
                  variant={"text"}
                  fullWidth={false}
                  sx={{
                    mt: 1,
                    mr: 2,
                    background: "white",
                    border: "1px solid #5AB9F9",
                    color: "#5AB9F9",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                  onClick={() => {
                    if (last) {
                      setIsOpen(false);
                    } else {
                      setCurrentStep((s) =>
                        s === steps?.length - 1 ? 0 : s + 1
                      );
                    }
                  }}
                >
                  Got it
                </PrimaryButton>
              );
            }}
            showDots={false}
            steps={steps}
            afterOpen={disableBody}
            beforeClose={enableBody}
          >
            <App />
          </TourProvider>
        </Router>
      </ThemeProvider>
    </Provider>
  </StyledEngineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
