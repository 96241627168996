import { FC, useEffect, useState } from "react";
import SelectFiles from "../common/SelectFiles";
import UploadFiles from "../common/UploadFiles";
import { useDispatch, useSelector } from "react-redux";
import {
  Reload,
  RoutingPageValue,
  setReload,
  VettingDocuments,
  setNotificationMessage,
} from "../../../../../../../utils/redux";
import PrimaryButton from "../../../../../../../components/Button/PrimaryButton";
import { Box, Grid, Typography } from "@mui/material";
import InputField from "../../../../../../../components/InputField";
import axiosInstance from "../../../../../../../utils/axios";
import { URLS } from "../../../../../../../utils/constants/urls";
import { useParams } from "react-router-dom";
import { Validators } from "../../../../../../../utils/validators";

export interface SelectFilesProps {
  active: number;
}

const NationalInsuranceProof: FC<SelectFilesProps> = ({ active }) => {
  const dispatch = useDispatch();
  const { id } = useParams() as any;
  const value = useSelector(RoutingPageValue);
  const reload = useSelector(Reload);
  const [nin, setNin] = useState("");
  const [loading, setLoading] = useState(false);
  const [ninDisabled, setNinDisabled] = useState(false);
  const documents = useSelector(VettingDocuments);
  const [component, setComponent] = useState<"SelectFiles" | "UploadFiles">();
  const [ninError, setNinError] = useState<boolean>();

  useEffect(() => {
    setNin(documents[active].nin);
    setNinDisabled(documents[active].nin !== undefined);
  }, [active, documents]);

  useEffect(() => {
    if (value === 1) {
      setComponent("SelectFiles");
    } else if (value === 2) {
      setComponent("UploadFiles");
    }
  }, [value]);

  const setNiNumber = (value: string, field: string, error?: {error: boolean; message: string}) => {
    if (error && error.error) {
      setNinError(true);
    } else {
      setNinError(false);
    }
    setNin(value);
  }

  const saveNIN = async () => {
    try {
      setLoading(true);
      await axiosInstance.patch(`${URLS.requests}/${id}/document`, {
        nin,
        vetting_doc_id: documents[active].id,
      });
      setLoading(false);
      setNinDisabled(true);
      dispatch(setReload(!reload));
    } catch (e) {
      setLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in saving NIN Code",
        })
      );
    }
  };

  return (
    <>
      {component === "SelectFiles" && (
        <>
          <SelectFiles active={active} />
          <Grid container xs={12} md={12} display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Grid
              container
              alignItems={"center"}
              //spacing={2}
              sx={{
                backgroundColor: "white",
                width: "850px",
                margin: {xs:'0px',md:'40px 0px 40px -20px'},
                border: "1px dashed #DADADA",
                borderRadius: "12px",
              }}
              md={10}
              xs={11}
            >
              <Grid item md={8} xs={12} data-tut="candidate_nin_input_field">
                <div className="document-card mt-2">
                  <Typography
                    fontSize={"18px"}
                    variant={"body2"}
                    fontWeight={"bold"}
                    padding={"7px 0px"}
                    textAlign={"center"}
                  >
                    National Insurance Number
                  </Typography>
                  <InputField
                    disabled={ninDisabled || loading}
                    textChange={setNiNumber}
                    value={nin}
                    label={"National Insurance Number"}
                    size={"small"}
                    sx={{ mt: 1 }}
                    validators={[{
                      check: Validators.NIN,
                      message: 'Please enter a valid NI Number'
                    }, {
                      check: Validators.required,
                      message: 'Please enter NI Number'
                    }]}
                  />
                </div>
              </Grid>
              <Grid item md={4} xs={12} textAlign={"center"}>
                <Box className="text-right" sx={{ m: {xs:'2px',md:2}, pt: {xs:'10px',md:'30px'} }}>
                  <PrimaryButton
                    fullWidth={false}
                    sx={{
                      width: "100%",
                      height: "36px",
                      background: " #5AB9F9",
                      boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
                      borderRadius: "6px",
                      color: "white",
                    }}
                    disabled={loading || ninDisabled || !nin || ninError}
                    onClick={saveNIN}
                  >
                    Submit
                  </PrimaryButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {component === "UploadFiles" && <UploadFiles />}
    </>
  );
};

export default NationalInsuranceProof;
