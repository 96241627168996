export const steps: any = [
  {
    selector: '[data-tut="sign_up"]',
    content: `Your basic details are collected to begin the process of registration on ked.io`,
  },
  {
    selector: '[data-tut="reset_password_step_1"]',
    content: `please enter your registered email id`,
  },
  {
    selector: '[data-tut="reset_password_step_2"]',
    content: `To ensure security, we will confirm your identity using a One Time Passcode sent to your registered email. Follow the instructions provided to reset your password.`,
  },

  {
    selector: '[data-tut="create_request_btn"]',
    content: `By clicking "Create Request," you can start a new compliance request on the platform.`,
  },
  {
    selector: '[data-tut="search_input"]',
    content: `Use the search bar to find specific requests.`,
  },
  {
    selector: '[data-tut="sort_dropdown"]',
    content: `Use the dropdown list to sort the requests.`,
  },
  {
    selector: '[data-tut="total_requset_btn"]',
    content: `This shows the total number of requests you have on this platform in draft or published form. `,
  },
  {
    selector: '[data-tut="published_request_btn"]',
    content: `Published requests are complete and ready to be shared.`,
  },
  {
    selector: '[data-tut="draft_request_btn"]',
    content: `Draft requests are incomplete and cannot be shared.`,
  },
  {
    selector: '[data-tut="notification_btn"]',
    content: `To see all your notifications within the app, click here.`,
  },
  {
    selector: '[data-tut="home_btn"]',
    content: `Click here to go to the KED applications Home page.`,
  },
  {
    selector: '[data-tut="document_repo_nav_btn"]',
    content: `Clicking here will take you to the Document Hub screen where you can add and store documents or files. `,
  },
  {
    selector: '[data-tut="applicant_nav_btn"]',
    content: `Click here to see a list of all Applicants.`,
  },
  {
    selector: '[data-tut="invite_member_nav_btn"]',
    content: `Click here to add team members to the KED application by filling in their details and sending an invite. `,
  },

  {
    selector: '[data-tut="request_details"]',
    content: `On this screen you will simply add the below headline details for the further detailed creation of a full On-boarding compliance request that is job specific.`,
  },
  {
    selector: '[data-tut="hiring_ompany"]',
    content: `This can refer to an agency, a company, or a specific address or annex for a company.`,
  },
  {
    selector: '[data-tut="job_role"]',
    content: `This is the job title for which you're creating the request. Once you complete the request details, it will be associated with this role.`,
  },
  {
    selector: '[data-tut="closing_date"]',
    content: `This is the deadline for submitting all compliance documentation related to this job request. To keep it open ended you may skip to choose a date`,
  },
  {
    selector: '[data-tut="confirm_details"]',
    content: `After confirming this request, you'll proceed to the next screen, where you can provide the details for this request.`,
  },

  {
    selector: '[data-tut="vetting_document_tab"]',
    content: `In this tab, you will request personal identification, digital right to work, criminal background checks, and other identity documents.`,
  },
  {
    selector: '[data-tut="selection_radio_btns"]',
    content: `Select or deselect to indicate whether you accept or don't accept these specifics under Vetting Documents.`,
  },
  {
    selector: '[data-tut="right_to_work_tab"]',
    content: `    Digital Check will be required for applicants from UK/Ireland. Others need to submit the required documents and the respective share code.`,
  },
  {
    selector: '[data-tut="proof_of_address_tab"]',
    content: `The author of this request will provide the Applicant with a list of acceptable documents. The Applicant must upload the requested number of documents.`,
  },
  {
    selector: '[data-tut="dbs_check_tab"]',
    content: `    The applicant will be asked to provide the required details to initiate a DBS check online.`,
  },
  {
    selector: '[data-tut="required_no_documents_input"]',
    content: `The applicant must upload the specified number of required documents for this request.`,
  },
  {
    selector: '[data-tut="address_proof_step_1"]',
    content: `Select or deselect to indicate the type of address proof you require.`,
  },
  {
    selector: '[data-tut="address_proof_step_2"]',
    content: `Select a maximum acceptable time frame for each document. For example, bank statements should normally be from within three months of today&quote;s date.`,
  },
  {
    selector: '[data-tut="dbs_check_custom_msg"]',
    content: `Here you can add a custom message for the Applicant.`,
  },
  {
    selector: '[data-tut="required_ref_tab"]',
    content: `You can request employment/work, character, and academic references here.`,
  },
  {
    selector: '[data-tut="sample_reference_btn"]',
    content: `Clicking this button will display a sample reference form that the referee completes.`,
  },
  {
    selector: '[data-tut="required_ref_instrustions"]',
    content: `Follow instructions to provide the required references.`,
  },
  {
    selector: '[data-tut="required_ref_additional_notes"]',
    content: `Include any necessary notes for the references.`,
  },
  {
    selector: '[data-tut="Ked_employment_questions"]',
    content: `Quick-use KED questionnaire template by checking this section.`,
  },
  {
    selector: '[data-tut="own_questions"]',
    content: `This section will allow you to create your own reference form that you would like to be filled in by the referee.`,
  },
  {
    selector: '[data-tut="own_form"]',
    content: `Here you can upload your own reference form that you would like to be filled in by the referee`,
  },
  {
    selector: '[data-tut="saved_forms"]',
    content: `Here you can choose from your saved forms.`,
  },
  //additional forms
  {
    selector: '[data-tut="additional_forms_tab"]',
    content: `Upload any required documents to be completed and signed by the applicant.`,
  },
  {
    selector: '[data-tut="choose_file_additional_forms"]',
    content: `Upload the forms that require applicant's signature.`,
  },
  {
    selector: '[data-tut="addtional_forms_checkbox"]',
    content: `These forms are stored in the document hub`,
  },

  //additional comment
  {
    selector: '[data-tut="additional_comments_tab"]',
    content: `Provide additional instructions for the request or job-specific details.`,
  },
  {
    selector: '[data-tut="comments_field"]',
    content: `Please click here to add your comments.`,
  },
  {
    selector: '[data-tut="publish_btn"]',
    content: `Edit or add to the request before any Applicant applies. Request cannot be edited after the first Applicant applies.`,
  },

  //document repository
  {
    selector: '[data-tut="search_bar"]',
    content: `Use the search bar to find specific documents.`,
  },
  {
    selector: '[data-tut="list_table"]',
    content: `This is the list of previously added documents in the document hub section.`,
  },
  {
    selector: '[data-tut="choose_btn"]',
    content: `Click here to upload a file from your Phone, PC or Tablet to the document Hub. `,
  },
  {
    selector: '[data-tut="total_aplicant"]',
    content: `This displays the total number of applicants for all requests.`,
  },

  //invite members
  {
    selector: '[data-tut="members_added"]',
    content: `This shows the number of team members already added to the KED application, with a maximum limit of 5.`,
  },
  {
    selector: '[data-tut="add_members_btn"]',
    content: `Click here to add a new team member to the KED application`,
  },

  //candidate profile page
  {
    selector: '[data-tut="profile_completed"]',
    content: `If you are satisfied that the applicant has completed the request, click "Yes".`,
  },
  {
    selector: '[data-tut="send_mail"]',
    content: `This will send an email to the applicant from your email address with all the collated feedback.`,
  },

  //candidaate profile required refrence page
  {
    selector: '[data-tut="employment_ref"]',
    content: `Also known as an employment reference, this reference must be completed by the applicant's direct supervisor during the stated work period.`,
  },
  {
    selector: '[data-tut="character_ref"]',
    content: `This reference will be completed by a person nominated by the applicant and will testify to their character.`,
  },
  {
    selector: '[data-tut="academic_re"]',
    content: `Also known as an Academic reference,This reference will be completed by a person nominated by the applicant and will testify to their character.`,
  },
  {
    selector: '[data-tut="employment_gap_ref"]',
    content: `This reference is to be used by the applicant when they have periods of employment with unexplained gaps in between.`,
  },
  {
    selector: '[data-tut="reference_table"]',
    content: `Here you can see all the references received by this applicant once he requests for refrences,If reference is in Pending state A reference has been requested by the applicant, but the referee has not completed it yet,If its in complete state the referee has completed the reference, and it is now available for viewing. `,
  },
  // {
  //     selector: '[data-tut="reference_pending_state"]',
  //     content: `A reference has been requested by the applicant, but the referee has not completed it yet.`
  // },
  // {
  //     selector: '[data-tut="reference_complete_state"]',
  //     content: `The referee has completed the reference, and it is now available for viewing.`
  // },

  //documents reported
  {
    selector: '[data-tut="vetting_docs_report_input_field"]',
    content: `Please provide the applicant with instructions on how to fix any issues with their uploaded document or image`,
  },
  {
    selector: '[data-tut="vetting_docs_message_save_btn"]',
    content: `Click "Save Comments" after adding your desired message for the Applicant.`,
  },
  //document download btn
  {
    selector: '[data-tut="download_all_btn"]',
    content: `Click here to download the document for this specific section.`,
  },
  //ucheck application
  {
    selector: '[data-tut="ucheck_submit_application_btn"]',
    content: `Click here to submit the applicant's DBS application.`,
  },
  {
    selector: '[data-tut="ucheck_draft_application_btn"]',
    content: `Click here to draft the applicant's DBS application.`,
  },
  {
    selector: '[data-tut="ucheck_report_application_btn"]',
    content: `Click here to report the applicant's DBS application.`,
  },
  {
    selector: '[data-tut="ucheck_report_inputfield"]',
    content: `After reporting the DBS application, add comments for the Applicantfor them to view and make corrections accordingly.`,
  },
  {
    selector: '[data-tut="ucheck_report_save_comment_btn"]',
    content: `Click "Report reference" after adding your desired message for the Applicant.`,
  },
  {
    selector: '[data-tut="ucheck_view_application_btn"]',
    content: `View the DBS application by clicking here`,
  },
  {
    selector: '[data-tut="yoti_view_application_btn"]',
    content: `View the yoti application by clicking here`,
  },
];
//candidate side steps configs
steps.push(
  //unathenticated request page step config
  {
    selector: '[data-tut="unathorized_request_page"]',
    content: `Clicking will redirect you to the KED application sign-up screen. Follow the instructions there`,
  },
  //candidate dashboard  page step config

  {
    selector: '[data-tut="search_input"]',
    content: `Use the search bar to find specific requests.`,
  },
  {
    selector: '[data-tut="sort_dropdown"]',
    content: `Use the dropdown list to sort the requests.`,
  },

  {
    selector: '[data-tut="notification_btn"]',
    content: `To see all your notifications within the app, click here.`,
  },
  {
    selector: '[data-tut="home_btn"]',
    content: `Click here to go to the KED applications Home page.`,
  },
  {
    selector: '[data-tut="document_repo_nav_btn"]',
    content: `Clicking here will take you to the Document Hub screen where you can add and store documents or files. `,
  },

  //country pop up step config
  {
    selector: '[data-tut="candidate_country_pop_up_tab"]',
    content: `For this Right to Work request, you must chooose the country.`,
  },
  //trw section step config
  {
    selector: '[data-tut="candidate_vetting_doc_tab"]',
    content: `You must undergo identity checks and upload documents to prove your eligibility for the job role.`,
  },
  {
    selector: '[data-tut="candidate_required_ref_tab"]',
    content: `In this tab, you will find the details required for references that applicants need to provide to complete their application successfully and get it approved.`,
  },
  {
    selector: '[data-tut="candidate_additional_forms_tab"]',
    content: `The forms here are specific to this request and an applicant will be required to fill in additional information and electronically sign them.`,
  },
  {
    selector: '[data-tut="candidate_additional_comments_tab"]',
    content: `These notes are pertinent to this request and should be considered as important information.`,
  },
  {
    selector: '[data-tut="candidate_rtw_tab"]',
    content: `A Right to work check is a home office process to verify that all employees and casual workers have the legal right to work in the UK before they begin their employment.If you are a UK/Irish citizen a Digital Right to work check will be conducted if not you will need to upload the required document and their Share Code`,
  },
  {
    selector: '[data-tut="candidate_required_no_docs_tab"]',
    content: `For this Right to Work request, you must upload the number of documents indicated.`,
  },

  {
    selector: '[data-tut="select_tab_btn"]',
    content: `Choose a file from the document hub or upload a file from your device ,after uploading you can view/delete/download the document.`,
  },
  {
    selector: '[data-tut="rtw_code_field"]',
    content: `Follow the link to obtain your government-issued share code. `,
  },
  //poof identity
  {
    selector: '[data-tut="candidate_proof_of_identity_tab"]',
    content: `The author of this request will provide a list of acceptable documents. You must upload the specified number of documents`,
  },
  //proof od address
  {
    selector: '[data-tut="candidate_proof_of_address_tab"]',
    content: `The author of this request will provide the Applicant with a list of acceptable documents. The You must upload the requested number of documents.`,
  },
  {
    selector: '[data-tut="candidate_proof_of_address_validity_field"]',
    content: `Please review the provided details and ensure that the document is valid before uploading.`,
  },
  //nin
  {
    selector: '[data-tut="candidate_nin_tab"]',
    content: `Add documents supporting national insurance proof.`,
  },
  {
    selector: '[data-tut="candidate_nin_input_field"]',
    content: `Click here to enter your National Insurance Number.`,
  },
  //dbs
  {
    selector: '[data-tut="candidate_dbs_tab"]',
    content: `An employer may request a DBS check during recruitment (previously known as CRB checks). These checks are processed by the Disclosure and Barring Service (DBS), and for some roles, they include information from the Children's and Adults' Barred Lists, as well as relevant data from local police forces. DBS checks help employers make safer recruitment decisions, but they are just one part of the recruitment process. Once the check is processed, the applicant receives a DBS certificate. However, DBS cannot access criminal records from overseas, so the check may not provide a complete view of an applicant's criminal history if they have lived outside of the UK. `,
  },
  {
    selector: '[data-tut="dbs_updated_pop_up"]',
    content: `The DBS Updated Service is a subscription-based online service that helps you keep your standard or enhanced DBS certificate current and allows employers to verify your certificate online.`,
  },
  //academic
  {
    selector: '[data-tut="candidate_academic_qualification_tab"]',
    content: `Please upload all required documents for your Academic, Professional, and Training certification.`,
  },
  {
    selector: '[data-tut="candidate_academic_qualification_table"]',
    content: `You will have access to your Credentials here.`,
  },
  {
    selector: '[data-tut="candidate_upload_academic_qualification_"]',
    content: `Click here to upload Credentials.`,
  },
  {
    selector: '[data-tut="candidate_add_academic_qualification"]',
    content: `Click here to add a new Credentials.`,
  },
  {
    selector: '[data-tut="candidate_academic_qualification_action"]',
    content: `You can edit, view, and delete the uploaded document by making the appropriate selection from the given options below.`,
  },

  //opc
  {
    selector: '[data-tut="candidate_opc_tab"]',
    content: `Please provide details in this section if you have stayed abroad for more than 6 months.`,
  },
  //uplaod files page
  {
    selector: '[data-tut="candidate_upload_files_tab_1"]',
    content: `Upload a file from your phone or PC to Ked Document Hub.`,
  },
  {
    selector: '[data-tut="candidate_upload_files_tab_2"]',
    content: `Choose a file from the list of files you have stored on this platform. `,
  },
  //uchekc start application
  {
    selector: '[data-tut="candidate_ucheck_start_application"]',
    content: `By clicking here u can start the Ucheck process`,
  },
  //uckeck pop up
  {
    selector: '[data-tut="candidate_ucheck_personal_information"]',
    content: `Personal information`,
  },
  {
    selector: '[data-tut="candidate_ucheck_address_information"]',
    content: `Please provide your full address history for the past 5 years on address information page. `,
  },
  {
    selector: '[data-tut="candidate_ucheck_save_draft"]',
    content: `You can save the draft at any point in the application process and revisit it whenever you want.`,
  },

  //candidate required reference instruction page
  {
    selector: '[data-tut="candidate_required_ref_instrustion_page"]',
    content: `This tab lists the number of references and the years to be covered for this compliance request.`,
  },
  {
    selector: '[data-tut="candidate_required_ref_instrustion_comments"]',
    content: `These comments provide further details for the reference request and must be followed for application completion and approval.`,
  },
  {
    selector: '[data-tut="candidate_required_ref_instrustion_add_referee_btn"]',
    content: `Click to proceed and add the referee's details.`,
  },
  //candidate required refrence list page
  {
    selector: '[data-tut="candidate_employment_ref"]',
    content: `Please provide the number of employment references requested for this request`,
  },
  {
    selector: '[data-tut="candidate_character_ref"]',
    content: `A character reference describes a candidate's personality, work ethic, and soft skills and needs to be provided by a person who knows them personally.`,
  },
  {
    selector: '[data-tut="candidate_academic_ref"]',
    content: `Academic referees are Teachers/Professors or those who supervise a candidate's education.`,
  },
  {
    selector: '[data-tut="candidate_add_ref_btn"]',
    content: `Click here to add a new referee.`,
  },
  //employment gap reference form component
  {
    selector: '[data-tut="candidate_gap_reference"]',
    content: `An employment gap is when a person isn't working for a company or earning through full-time work. To reference an employment gap, the applicant should select the "this is an employment gap reference" button on the character reference tab and confirm the period of unemployment.`,
  },
  {
    selector: '[data-tut="candidate_gap_reference_additional_comments"]',
    content: `Click here to provide an explanation for your employment gap. `,
  },
  {
    selector: '[data-tut="candidate_gap_reference_submit_btn"]',
    content: `After selecting the 'Send Request' button, KED application will send a reference link to the chosen referee. Once the referee completes the reference, it will be viewable by the requester.`,
  },
  //candidate additional forms tab
  {
    selector: '[data-tut="candidate_additional_forms_list"]',
    content: `The forms or documents under this tab are typically specific to the client or location and may require your electronic signature.`,
  },
  //candidate document repository
  {
    selector: '[data-tut="candidate_document_repo_tab"]',
    content: `This storage hub is for documents previously uploaded. You can also upload files to this section.`,
  },
  {
    selector: '[data-tut="candidate_document_repo_select_cetegory"]',
    content: `Select which category you will save your documents in.`,
  },
  {
    selector: '[data-tut="candidate_document_repo_upload_file"]',
    content: `Upload all your vetting documents at once to populate your hub. `,
  },
  //candidate vetting docs reported coach marks
  {
    selector: '[data-tut="candidate_vetting_docs_reported_tab"]',
    content: `Provided documents of this author has been reported by the Compliance Officer,`,
  },
  {
    selector: '[data-tut="candidate_document_reported"]',
    content: `Click here to see feedback from the Compliance Officer.`,
  },
  //candidate refrence reported coach marks

  {
    selector: '[data-tut="candidate_reference_reinitiate_btn"]',
    content: `Click here to restart your reference with the same or a new referee based on the compliance officer's feedback.`,
  },
  {
    selector: '[data-tut="candidate_reference_reported"]',
    content: `Click here to see feedback from the Compliance Officer.`,
  },

  //reset password page
  {
    selector: '[data-tut="reset_password"]',
    content: `Choose a strong password that you have not used before`,
  },
  {
    selector: '[data-tut="reset_password_proceed_btn"]',
    content: `By proceeding you will open a new window to login again with your new password.`,
  }
);

//referee side coach marks
steps.push(
  //start application
  {
    selector: '[data-tut="referee_details_tab"]',
    content: `Follow the sections of this automated reference to complete a reference request from KED application.`,
  },
  {
    selector: '[data-tut="start_application_btn"]',
    content: `Click here to start the KED application.`,
  },

  //application details page
  {
    selector: '[data-tut="apllication_details_page"]',
    content: `The applicant has submitted these details. You can edit or confirm them to proceed`,
  },
  {
    selector: '[data-tut="details_proceed_btn"]',
    content: `Please check that these details are correct before proceeding.`,
  },

  //rating matrix table
  {
    selector: '[data-tut="rating_table"]',
    content: `Please rate the candidate on a scale of 1 to 5, with 1 being the lowest score and 5 being the highest score.`,
  },

  //upload your own forms
  {
    selector: '[data-tut="downLoad_all_files"]',
    content: `Download all documents shared by the Compliance officer by clicking here`,
  },
  {
    selector: '[data-tut="upload_files"]',
    content: `Upload your desired file by clicking here.`,
  },

  //required reference completed state
  {
    selector: '[data-tut="required_ref_completed"]',
    content: `The reference has been completed and received by the requester.`,
  },

  //mobile drawer
  {
    selector: '[data-tut="home_btn"]',
    content: `Click here to go to the KED applications Home page.`,
  },
  {
    selector: '[data-tut="document_repo_nav_btn"]',
    content: `Clicking here will take you to the Document Hub screen where you can add and store documents or files. `,
  },
  {
    selector: '[data-tut="applicant_nav_btn"]',
    content: `Click here to see a list of all Applicants.`,
  },
  {
    selector: '[data-tut="invite_member_nav_btn"]',
    content: `Click here to add team members to the KED application by filling in their details and sending an invite.`,
  },
  //agency side set signer coach marks
  {
    selector: '[data-tut="set_signer"]',
    content: `Choose signatory type.`,
  },

  {
    selector: '[data-tut="additional_coments_field"]',
    content: `In this text box you can provide additional information on the candidate that you think might be useful for the requestor.`,
  },
  {
    selector: '[data-tut="additional_comments_procedd_btn"]',
    content: `By submitting this reference, you are completing it and it will be visible only to the requestor. Once the applicant starts with a new employer, they can request a copy of the reference. However, as a previous employer, they are not obligated to provide a copy of the reference upon request. More information can be found at https://www.gov.uk/work-reference.`,
  },
  {
    selector: '[data-tut="dbs_job_role"]',
    content: `This information will be used to support the eligibility of the application. Please insert the correct job role. No abbreviations are permissible e.g. HCA should read Healthcare Assistant.`,
  },
);

//dbs information page
// {
//     selector: '[data-tut="candidate_ucheck_view_application"]',
//     content: `You can view the application form by clicking this button.`
// },

export const tourStep = {
  signup: { steps: 1, index: 0 },

  //reset password
  reserPassword: { steps: 2, index: 1 },

  dash_board: { steps: 11, index: 3 },
  create_request: { steps: 5, index: 14 },
  vetting_docs: { steps: 6, index: 19 },
  address_proof: { steps: 2, index: 25 },
  dbs: { steps: 1, index: 27 },
  required_ref_first_step: { steps: 4, index: 28 },
  required_ref_second_step: { steps: 3, index: 32 },
  saved_forms: { steps: 1, index: 35 },
  additional_forms: { steps: 3, index: 36 },
  additional_comments: { steps: 3, index: 39 },
  document_repository: { steps: 3, index: 42 },
  applicant_screen: { steps: 1, index: 45 },
  invite_members_screen: { steps: 2, index: 46 },
  candidate_profile: { steps: 2, index: 48 },
  candidate_required_ref: { steps: 5, index: 50 },
  candidate_vetting_docs_report_field: { steps: 2, index: 55 },
  candidate_profile_download_all: { steps: 1, index: 57 },
  ucheck_application_review_state: { steps: 3, index: 58 },
  report_pop_up_modal: { steps: 2, index: 61 },
  ucheck_after_submit_state: { steps: 2, index: 63 },

  //candidate side steps

  unthorized_request_page: { steps: 1, index: 65 },
  candidate_dashboard: { steps: 5, index: 66 },
  choose_country: { steps: 1, index: 71 },
  canidate_rtw_tab: { steps: 8, index: 72 },
  candidate_proof_identity: { steps: 1, index: 80 },
  candidate_proof_address: { steps: 2, index: 81 },
  candidate_nin: { steps: 2, index: 83 },
  candidate_dbs: { steps: 2, index: 85 },
  candidate_academic: { steps: 5, index: 87 },
  canidate_opc: { steps: 1, index: 92 },
  candidate_upload_files_page: { steps: 2, index: 93 },
  candidate_ucheck_start_app: { steps: 1, index: 95 },
  candidate_ucheck_pop_up: { steps: 3, index: 96 },
  candidate_required_refrence_instrustion_page: { steps: 3, index: 99 },
  candidate_required_reference_listing_page: { steps: 4, index: 102 },
  candidate_required_reference_form: { steps: 3, index: 106 },
  candidate_additional_forms_list: { steps: 1, index: 109 },
  candidate_document_repository: { steps: 3, index: 110 },
  candidate_document_reported: { steps: 2, index: 113 },
  candidate_reference_reported: { steps: 2, index: 115 },

  //reset password
  confirm_otp: { steps: 2, index: 117 },

  //referee side coach marks

  referee_start_application: { steps: 2, index: 119 },
  referee_application_details: { steps: 2, index: 121 },
  referee_matrix_table: { steps: 1, index: 123 },
  referee_uplaod_yor_own_form: { steps: 2, index: 124 },
  required_ref_completed: { steps: 1, index: 126 },
  //mobile drawer coach mark
  mobile_drawer: { steps: 4, index: 127 },
  //agency side set signer coach marks
  set_signer: { steps: 1, index: 131 },
  //referee side additional comment side coach marks
  referee_additional_comments: { steps: 2, index: 132 },
  dbs_job_role: { steps: 1, index: 134 },
};
