import { VettingDocument } from '../../Authenticated/Agency/RequestDetails/RequestDetailsModel';
import { identifiers } from '../../../utils/constants/identifiers';
import { uCheckInitialState } from '../../../utils/redux';
import {
  AddressHistory,
  OtherNames,
} from '../../../utils/redux/reducer/u-check-slice';

export const getVettingDocumentsList = (data: any) => {
  return data.request_data.vetting_documents.map(
    (document: VettingDocument) => ({
      id: document.id,
      name: document.name,
      sub_documents:
        document.name === identifiers.dbs
          ? [{ name: identifiers.dbs }]
          : document.name === identifiers.cv
          ? [{ name: identifiers.cv }]
          : document.name === identifiers.opc
          ? [{ name: identifiers.opc }]
          : document.sub_documents?.map((sub_document) => ({
              name: sub_document.name,
            })),
    })
  );
};

export const getVettingDocuments = (data: any) => {
  return data.vetting_documents
    .sort(
      (a: VettingDocument, b: VettingDocument) =>
        a.candidate_index - b.candidate_index
    )
    .map((document: VettingDocument) => {
      if (document.name === identifiers.rtw) {
        const rtw_document: VettingDocument = JSON.parse(
          JSON.stringify(document)
        );
        if (document.is_british_or_irish_citizen) {
          rtw_document.default_documents_required = 'N/A';
        }
        return rtw_document;
      }
      if (document.name === identifiers.cv) {
        const cv_document: VettingDocument = JSON.parse(
          JSON.stringify(document)
        );
        cv_document.sub_documents = [
          {
            id: '',
            name: cv_document.name,
            document_repository_id: cv_document.cv_document_repo_id || '',
            checked: true,
            default: 0,
            value: 0,
            uploaded_at: new Date().getTime(),
          },
        ];
        return cv_document;
      }
      if (document.name === identifiers.dbs) {
        const dbs_document: VettingDocument = JSON.parse(
          JSON.stringify(document)
        );

        dbs_document.is_rejected = dbs_document.approval_status === 'REJECTED';
        return dbs_document;
      }
      if (document.name === identifiers.opc) {
        const opc_document: VettingDocument = JSON.parse(
          JSON.stringify(document)
        );
        opc_document.sub_documents = [
          {
            id: '',
            name: opc_document.name,
            document_repository_id: opc_document.opc_document_repo_id || '',
            checked: true,
            default: 0,
            value: 0,
            // options: [],
            uploaded_at: new Date().getTime(),
            approval_status: opc_document.approval_status,
          },
        ];
        return opc_document;
      }
      const is_rejected = !!document?.sub_documents?.find(
        (sub_document) => sub_document.approval_status === 'REJECTED'
      );
      return { is_rejected, ...document };
    });
};

export const getReference = (data: any) => {
  return data.reference &&
    (!!data.reference.max_references_count ||
      !!data.reference.referee_connection_year)
    ? {
        ...data.reference,
        reference_list: data.references_count_info
          .map(
            (reference_list: {
              count: number;
              type: string;
              completed: number;
              has_invalid_reference: boolean;
              index: number;
            }) => {
              if (reference_list.type === identifiers.employment) {
                return {
                  ...reference_list,
                  index: 0,
                };
              } else if (reference_list.type === identifiers.character) {
                return {
                  ...reference_list,
                  index: 1,
                };
              } else if (reference_list.type === identifiers.academic) {
                return {
                  ...reference_list,
                  index: 2,
                };
              }
              return {
                ...reference_list,
                index: data.references_count_info.length,
              };
            }
          )
          .sort((a: any, b: any) => {
            return a.index - b.index;
          }),
        reference_acknowledged: data.acknowledged,
      }
    : null;
};

export const getPersonalInformation = (form_data: any, type: string) => {
  if (!!form_data) {
    const response = {
      title: form_data?.applicant_details?.title || '',
      forename: form_data?.applicant_details?.forename || '',
      middlename_1:
        form_data?.applicant_details?.middlenames?.length > 0
          ? form_data?.applicant_details?.middlenames[0]?.middlename
          : '',
      middlename_2:
        form_data?.applicant_details?.middlenames?.length > 1
          ? form_data?.applicant_details?.middlenames[1]?.middlename
          : '',
      middlename_3:
        form_data?.applicant_details?.middlenames?.length > 2
          ? form_data?.applicant_details?.middlenames[2]?.middlename
          : '',
      present_surname: form_data?.applicant_details?.present_surname || '',
      date_of_birth: form_data?.applicant_details?.date_of_birth || '',
      gender: form_data?.applicant_details?.gender || '',
      contact_email: form_data?.applicant_details?.contact_email || '',
      birth_nationality: !!form_data?.applicant_details
        ?.additional_applicant_details
        ? form_data?.applicant_details?.additional_applicant_details
            ?.birth_nationality
        : '',
      birth_county: !!form_data?.applicant_details?.additional_applicant_details
        ? form_data?.applicant_details?.additional_applicant_details
            ?.birth_county
        : '',
      birth_country: !!form_data?.applicant_details
        ?.additional_applicant_details
        ? form_data?.applicant_details?.additional_applicant_details
            ?.birth_country
        : '',
      birth_town: !!form_data?.applicant_details?.additional_applicant_details
        ? form_data?.applicant_details?.additional_applicant_details?.birth_town
        : '',
      mother_maidenname: !!form_data?.applicant_details
        ?.additional_applicant_details
        ? form_data?.applicant_details?.additional_applicant_details
            ?.mother_maidenname
        : '',
      contact_number: !!form_data?.applicant_details
        ?.additional_applicant_details
        ? form_data?.applicant_details?.additional_applicant_details
            ?.contact_number
        : '',
      has_nin:
        !!form_data?.applicant_identity_details &&
        !!form_data?.applicant_identity_details?.ni_number
          ? 'y'
          : 'n',
      nin:
        !!form_data?.applicant_identity_details &&
        !!form_data?.applicant_identity_details?.ni_number
          ? form_data?.applicant_identity_details?.ni_number
          : '',
    };
    let form_valid = true;
    if (
      !response.title ||
      !response.forename ||
      !response.present_surname ||
      !response.gender ||
      !response.contact_email ||
      !response.contact_number ||
      !response.date_of_birth ||
      !response.birth_nationality ||
      !response.birth_country ||
      !response.birth_town ||
      (response.has_nin === 'y' && !response.nin) ||
      (type === 'SCOTTISH' && !response.mother_maidenname)
    ) {
      form_valid = false;
    }
    return { ...response, form_valid };
  } else {
    return uCheckInitialState.personal_information;
  }
};
//
export const getPersonalHistory = (form_data: any) => {
  if (!!form_data) {
    const other_names: OtherNames[] = [];
    let form2_valid = true;
    if (
      !!form_data?.applicant_details?.additional_applicant_details &&
      !!form_data?.applicant_details?.additional_applicant_details
        ?.other_forenames
    ) {
      form_data?.applicant_details?.additional_applicant_details?.other_forenames.forEach(
        (other_name: {
          forename: string;
          surname: string;
          used_from: string;
          used_to: string;
        }) => {
          other_names.push({
            ...other_name,
            other_name_type: 'forename',
            used_from: !!other_name.used_from
              ? other_name.used_from + '-01'
              : '',
            used_to: !!other_name?.used_to ? other_name?.used_to + '-01' : '',
          });
          if (
            !other_name.used_from ||
            !other_name.used_to ||
            !other_name.surname ||
            !other_name.forename
          ) {
            form2_valid = false;
          }
        }
      );
    }
    if (
      form_data?.applicant_details?.additional_applicant_details &&
      form_data?.applicant_details?.additional_applicant_details?.other_surnames
    ) {
      form_data?.applicant_details?.additional_applicant_details?.other_surnames.forEach(
        (other_name: {
          forename: string;
          surname: string;
          used_from: string;
          used_to: string;
        }) => {
          other_names.push({
            ...other_name,
            other_name_type: 'surname',
            used_from: !!other_name?.used_from
              ? other_name?.used_from + '-01'
              : '',
            used_to: !!other_name?.used_to ? other_name?.used_to + '-01' : '',
          });
          if (
            !other_name.used_from ||
            !other_name.used_to ||
            !other_name.surname ||
            !other_name.forename
          ) {
            form2_valid = false;
          }
        }
      );
    }

    const response = {
      current_nationality:
        form_data.applicant_details?.additional_applicant_details
          ?.current_nationality,
      nationality_change:
        form_data.applicant_details?.additional_applicant_details
          ?.nationality_change,
      unspent_convictions:
        form_data.applicant_details?.additional_applicant_details
          ?.unspent_convictions,
      surname_changed: !!form_data?.applicant_details
        ?.additional_applicant_details?.birth_surname
        ? 'y'
        : 'n',
      has_other_name: other_names?.length > 0 ? 'y' : 'n',
      birth_surname:
        form_data?.applicant_details?.additional_applicant_details
          ?.birth_surname,
      birth_forename:
        form_data?.applicant_details?.additional_applicant_details
          ?.birth_forename,
      birth_surname_until:
        form_data?.applicant_details?.additional_applicant_details
          ?.birth_surname_until + '-01',
      other_names,
    };

    let form1_valid = true;
    if (
      !response.nationality_change ||
      (response.nationality_change === 'y' && !response.current_nationality) ||
      !response.unspent_convictions ||
      !response.surname_changed ||
      !response.has_other_name ||
      (response.surname_changed === 'y' && !response.birth_surname) ||
      (response.surname_changed === 'y' && !response.birth_forename) ||
      (response.surname_changed === 'y' && !response.birth_surname_until)
    ) {
      form1_valid = false;
    }

    return { ...response, form1_valid, form2_valid };
  } else {
    return uCheckInitialState.personal_history;
  }
};

export const getCurrentAddress = (form_data: any) => {
  if (
    !!form_data &&
    !!form_data.applicant_details &&
    !!form_data.applicant_details.current_address
  ) {
    const {
      address_line1,
      address_line2,
      address_town,
      address_county,
      country_code,
      postcode,
    } = form_data.applicant_details.current_address.address;
    const resident_from_gyear_month =
      form_data.applicant_details.current_address.resident_from_gyear_month;
    const current_address: { address: AddressHistory; form_valid: boolean } = {
      address: {
        address_line1,
        address_line2,
        address_town,
        address_county,
        country_code,
        postcode,
        resident_from_gyear_month: !!resident_from_gyear_month
          ? resident_from_gyear_month + '-01'
          : undefined,
      },
      form_valid: true,
    };
    current_address.form_valid = validateAddress(current_address.address);
    return current_address;
  } else {
    return uCheckInitialState.current_address;
  }
};

export const getPreviousAddresses = (form_data: any) => {
  if (!!form_data && !!form_data?.applicant_details?.previous_addresses) {
    const previous_addresses: {
      address: AddressHistory[];
      form_valid: boolean;
    } = { address: [], form_valid: true };
    const address: AddressHistory[] = [];
    let form_valid = true;
    form_data?.applicant_details?.previous_addresses.forEach(
      (previous_address: {
        address: AddressHistory;
        resident_dates: {
          resident_from_gyear_month: string;
          resident_to_gyear_month: string;
        };
      }) => {
        const {
          address_line1,
          address_line2,
          address_town,
          address_county,
          country_code,
          postcode,
        } = previous_address.address;
        const { resident_from_gyear_month, resident_to_gyear_month } =
          previous_address.resident_dates;
        const value: AddressHistory = {
          address_line1,
          address_line2,
          address_town,
          address_county,
          country_code,
          postcode,
          resident_from_gyear_month: !!resident_from_gyear_month
            ? resident_from_gyear_month + '-01'
            : undefined,
          resident_to_gyear_month: !!resident_to_gyear_month
            ? resident_to_gyear_month + '-01'
            : undefined,
        };
        address.push(value);
        if (!validateAddress(value)) {
          form_valid = false;
        }
      }
    );
    previous_addresses.address = address;
    previous_addresses.form_valid = form_valid;
    return previous_addresses;
  } else {
    return uCheckInitialState.previous_address;
  }
};

export const getPassportDetails = (form_data: any) => {
  if (
    !!form_data &&
    !!form_data.applicant_details.applicant_identity_details &&
    !!form_data.applicant_details.applicant_identity_details.passport_details
  ) {
    const {
      passport_number,
      passport_dob,
      passport_issue_date,
      passport_nationality,
    } =
      form_data?.applicant_details?.applicant_identity_details
        ?.passport_details;
    const response = {
      has_passport:
        form_data?.applicant_details?.applicant_identity_details?.has_passport,
      passport_number,
      passport_dob,
      passport_issue_date,
      passport_nationality,
    };
    let form_valid = true;
    if (
      !response.has_passport ||
      !response.passport_dob ||
      !response.passport_issue_date ||
      !response.passport_number ||
      !response.passport_nationality
    ) {
      form_valid = false;
    }
    return { ...response, form_valid };
  } else if (
    !!form_data?.applicant_details?.applicant_identity_details?.has_passport
  ) {
    const response = {
      has_passport:
        form_data?.applicant_details?.applicant_identity_details?.has_passport,
    };
    let form_valid = true;
    if (!response.has_passport) {
      form_valid = false;
    }
    return { ...response, form_valid };
  } else {
    return uCheckInitialState.passport_information;
  }
};

export const getDrivingLicenceInformation = (form_data: any) => {
  if (
    !!form_data &&
    !!form_data.applicant_details.applicant_identity_details &&
    !!form_data.applicant_details.applicant_identity_details
      .driver_licence_details
  ) {
    const {
      driver_licence_number,
      driver_licence_type,
      driver_licence_dob,
      driver_licence_valid_from,
      driver_licence_issue_country,
    } =
      form_data?.applicant_details?.applicant_identity_details
        ?.driver_licence_details;
    const response = {
      uk_driving_licence:
        form_data.applicant_details?.applicant_identity_details
          ?.uk_driving_licence,
      driver_licence_number,
      driver_licence_type,
      driver_licence_dob,
      driver_licence_valid_from,
      driver_licence_issue_country,
    };
    let form_valid = true;
    if (
      !response.uk_driving_licence ||
      !response.driver_licence_dob ||
      !response.driver_licence_issue_country ||
      !response.driver_licence_number ||
      !response.driver_licence_type ||
      !response.driver_licence_valid_from
    ) {
      form_valid = false;
    }
    return { ...response, form_valid };
  } else if (
    !!form_data?.applicant_details?.applicant_identity_details
      ?.uk_driving_licence
  ) {
    const response = {
      uk_driving_licence:
        form_data.applicant_details?.applicant_identity_details
          ?.uk_driving_licence,
    };
    let form_valid = true;
    if (!response.uk_driving_licence) {
      form_valid = false;
    }
    return { ...response, form_valid };
  } else {
    return uCheckInitialState.drivingLicenceInformation;
  }
};

const validateAddress = (address: AddressHistory): boolean => {
  return !(
    !address.address_line1 ||
    !address.address_town ||
    (address.country_code !== 'GB' &&
      address.country_code !== 'IM' &&
      address.country_code !== 'JE' &&
      address.country_code !== 'GG' &&
      !address.address_county) ||
    !address.country_code ||
    ((address.country_code === 'GB' ||
      address.country_code === 'IM' ||
      address.country_code === 'JE' ||
      address.country_code === 'GG') &&
      !address.postcode) ||
    !address.resident_from_gyear_month
  );
};
