import React, { FC } from "react";
import {
  Grid,
  TableCell,
  styled,
  tableCellClasses,
  TableRow,
  TableContainer,
  Table,
  TableBody,
} from "@mui/material";
interface propss{
  fields:any
}
const Tasks: FC <propss>= ({ fields}) => {
  console.log(fields)
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      color: "#727272",
      border: "none",
    },
    [`&.${tableCellClasses.body}`]: {
      color: "#263238",
      fontSize: "14px",
      fontWeight: 500,
      padding: "5px 16px",
    },
    [`&.${tableCellClasses.head}:first-of-type`]: {
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
      //borderLeft:'1px solid lightgray'
    },
    [`&.${tableCellClasses.head}:last-of-type`]: {
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      width: "14rem",
      textAlign: "start",
      paddingLeft: "35px",
    },
    [`&.${tableCellClasses.body}:first-of-type`]: {
      borderTopLeftRadius: "12px",
      borderBottomLeftRadius: "12px",
      // borderLeft: "1px solid #DADADA",
    },
    [`&.${tableCellClasses.body}:last-of-type`]: {
      borderTopRightRadius: "12px",
      borderBottomRightRadius: "12px",
      // borderRight: "1px solid #DADADA",
      textAlign: "center",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&": {
      borderRadius: "8px",
      borderBottom: "1px solid #DADADA",
      backgroundColor: "white",
    },
  }));

  return (
    <Grid container className=" mb-3">
      <TableContainer>
        <Table>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell variant="head">Full Name</StyledTableCell>
              <StyledTableCell> {fields?.full_name} </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell variant="head">Date of Birth</StyledTableCell>
              <StyledTableCell>
                  {fields?.date_of_birth}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell variant="head">Nationality</StyledTableCell>
              <StyledTableCell> {fields?.nationality} </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell variant="head">Document Number</StyledTableCell>
              <StyledTableCell> {fields?.document_number} </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default Tasks;
