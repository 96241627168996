export const agency_root: string = '/agency';
export const candidate_root: string = '/applicant';
export const admin_root: string = '/admin';

export const agency: { [key: string]: string } = {
  root: agency_root + '',
  login: agency_root + '/login',
  forgot_password: agency_root + '/forgot-password',
  profile_completion: agency_root + '/profile-completion',
  requests: agency_root + '/requests',
  request: agency_root + '/requests/:id',
  request_applicant: agency_root + '/requests/:id/applicants/:applicant_id',
  invite: agency_root + '/invite-members',
  documents: agency_root + '/documents',
  dashboard: agency_root + '/dashboard',
  applicants: agency_root + '/applicants',
  help: agency_root + '/help',
  notifications: agency_root + '/notifications',
};

export const candidate: { [key: string]: string } = {
  root: candidate_root + '',
  login: candidate_root + '/login',
  forgot_password: candidate_root + '/forgot-password',
  sign_up: candidate_root + '/sign-up',
  request: candidate_root + '/requests/:id',
  documents: candidate_root + '/documents',
  dashboard: candidate_root + '/dashboard',
  requests: candidate_root + '/requests',
  right_to_work: candidate_root + '/right-to-work',
  select_files: candidate_root + '/right-to-work/select-files',
  upload_documents: candidate_root + '/right-to-work/upload-documents',
  help: candidate_root + '/help',
  // email_referee_flow: candidate_root + '/email-referee-flow',
};

export const admin: { [key: string]: string } = {
  root: admin_root + '',
  login: admin_root + '/login',
  forgot_password: admin_root + '/forgot-password',
  agencies: admin_root + '/agencies',
};

export const routes: { [key: string]: string } = {
  root: '/',
  view_request: '/requests/:id',
  reference: '/reference/:type/:id',
  referee_details: 'referee-details',
  ked_questionnaire: 'ked-questionnaire',
  reference_documents: 'reference-documents',
  additional_questionnaire: 'additional-questionnaire',
  reference_comments: 'comments',
  verify_reference: '/reference/verify',
  privacy_policy: '/privacy_policy',
  referee_additional_comments: '/reference/referee/decline/:id',
};
