import React, { FC, useCallback, useEffect, useState } from "react";
import { Grid, Box, Typography, Stack } from "@mui/material";
import PageLoader from "../../../../../../../../components/PageLoader";
import axios from "axios";
import { URLS } from "../../../../../../../../utils/constants/urls";
import {
  setNotificationMessage,
  setReload,
  Reload,
  VettingDocuments,
} from "../../../../../../../../utils/redux";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Amplify from "../../../../../../../../utils/services/amplify";
import YotiCompletionStatus from "./YotiCompletionStatus";



const Yoti: FC<{
  scheme: "RTW" | "DBS_RTW" | "DBS";
  objective: string;
  active: number;
}> = ({ scheme, objective, active }) => {
  const dispatch = useDispatch();
  const { id } = useParams() as any;
  const reload = useSelector(Reload);
  const documents = useSelector(VettingDocuments);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState<string>("");
  const [status, setStatus] = useState<"COMPLETED" | "ONGOING" | "SUBMITTED">(
    "ONGOING"
  );

  const getYotiUrl = useCallback(async () => {
    try {
      setLoading(true);
      const session = await Amplify.currentSession();
      const token = session.getIdToken().getJwtToken();
      const { data } = await axios.post(
        `${process.env.REACT_APP_NODE_API_URL}${URLS.yoti_rtw}/request/${id}?scheme=${scheme}&objective=${objective}&url=${process.env.REACT_APP_NODE_API_URL}`,
        null,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      setUrl(data.url);
      setStatus(data.status);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in getting YOTI Url",
        })
      );
    }
  }, [dispatch, id, objective, scheme]);
  useEffect(() => {
    (async () => {
      await getYotiUrl();
    })();
  }, [getYotiUrl]);


  const dispatchReload = () => {
    dispatch(setReload(!reload))
  }

  return (
    <Grid container>
      {loading && <PageLoader />}
      <Grid item xs={12} sx={{
        paddingBottom: {
          xs: '8rem',
          md: 0
        }
      }}>

        {status === "ONGOING" ? (
          <Box
            sx={{
              background: "#D9D9D9",
              borderRadius: "20px",
              minHeight: "550px",
              display: "flex",

            }}
            margin={3}
            marginTop={0}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <iframe
              src={url}
              style={{
                height: "550px",
                width: "100%",
                borderRadius: "20px",
                border: "2px solid lightgray",
              }}
              allow="camera"
              title={"Yoti RTW - DBS"}
            ></iframe>
          </Box>
        ) : (
          <YotiCompletionStatus activeDocument={documents[active].name} onClickNext={dispatchReload} />
        )}
      </Grid>
    </Grid>
  );
};

export default Yoti;
