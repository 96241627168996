import React, { FC, useState } from "react";
import { Grid, Typography } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import PrimaryButton from "../../../../../../../components/Button/PrimaryButton";

interface PoliceClearanceCertificateProps {
  setOpcValue: (isOpcNeeded: boolean, opcCertificate: boolean) => void;
  goBack: () => void;
}

const PoliceClearanceCertificate: FC<PoliceClearanceCertificateProps> = ({
  setOpcValue,
  goBack,
}) => {
  const [values, setValues] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues((event.target as HTMLInputElement).value);
  };

  return (
    <>
      <Grid container justifyContent={"center"} lg={12}>
        <Grid
          md={7}
          xs={11}
          className="pop-up-dotted-card-dbs content-card"
          sx={{ marginTop: "55px", marginBottom: "40px" }}
        >
          <Typography
            variant={"body1"}
            color={"#333333"}
            sx={{
              mb: 1,
              fontSize: "18px",
              fontWeight: "500",
              letterSpacing: 0.15,
              marginBottom: "30px",
            }}
          >
            Do you have the Police Clearance certificate ?
          </Typography>
          <FormControl>
            <RadioGroup
              className="radio-group"
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={values}
              onChange={handleChange}
              sx={{ display: "flex", flexDirection: "row" }}
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="No" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid
          item
          display={"flex"}
          justifyContent={{xs:'space-around',md:'center'}}
          alignItems={'center'}
          className="card-footer"
          md={12}
          xs={12}
          sx={{ p: 3,height:'65px', }}
        >
          <PrimaryButton
            fullWidth={false}
            sx={{
              width: { xs: "150px", md: "212px" },
              height: "36px",
              background: "#FFFFFF",
              border: " 1px solid #5AB9F9",
              borderRadius: "5px",
              padding: "4px 44px",
              color: "#5AB9F9",
              fontSize: "14px",
              margin: {xs:'0px',md:"0px 20px"},
            }}
            onClick={goBack}
          >
            {/* {loading && <CircularProgress sx={{ mr: 1 }} size={20} />} */}
            Go back
          </PrimaryButton>
          <PrimaryButton
            fullWidth={false}
            sx={{
              width: { xs: "150px", md: "212px" },
              height: "36px",
              background: " #5AB9F9",
              boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
              borderRadius: "6px",
              color: "white",
            }}
            disabled={!values}
            onClick={() => {
              if (values === "yes") {
                setOpcValue(true, true);
              } else {
                setOpcValue(true, false);
              }
            }}
          >
            {/* {loading && <CircularProgress sx={{ mr: 1 }} size={20} />} */}
            {values === "yes" ? "Upload Certificate" : "Submit"}
          </PrimaryButton>
        </Grid>
      </Grid>
    </>
  );
};

export default PoliceClearanceCertificate;
