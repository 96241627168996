import React, { FC, RefObject, useRef, useState, useEffect } from "react";
import { CircularProgress, Typography, Stack } from "@mui/material";
import Form, { FormDataModel } from "../../../components/Form";
import { ForgotForm } from "./ForgotForm";
import PrimaryButton from "../../../components/Button/PrimaryButton";
import { removeErrorFieldsFromValues } from "../../../utils/validators";
import { images } from "../../../utils/constants/images";
import { Link, useNavigate } from "react-router-dom";
import "./ForgotPassword.scss";
import { identifiers } from "../../../utils/constants/identifiers";

import { useDispatch } from "react-redux";
import { setNotificationMessage } from "../../../utils/redux";
import { agency, candidate } from "../../../utils/constants/routes";
import * as Amplify from "../../../utils/services/amplify";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../utils/constants/coachMarks";
import { isTourCompleted, markTourAsCompleted } from "../../../utils/storage/tours";

const ForgotPassword: FC<{ role: string }> = ({ role }) => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  let forgotForm: RefObject<Form | null | undefined> = useRef();
  const { setIsOpen, currentStep, setCurrentStep } = useTour();

  const [hasError, setHasError] = useState<boolean>(false);
  const [resetLoading, setResetLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonActive, setButtonActive] = useState<boolean>(false);
  const [state, setState] = useState<"email" | "code">("email");

  useEffect(() => {
    if (state === "email") {
      setCurrentStep(tourStep.reserPassword.index);
    } else {
      setCurrentStep(tourStep.confirm_otp.index);
    }
  }, [setCurrentStep, state]);

  useEffect(() => {
    if (tourStep.reserPassword.index === currentStep) {
      if (!isTourCompleted('resetPassword')) {
        setIsOpen(true);
      }
    } else if (
      currentStep ===
      tourStep.reserPassword.index + tourStep.reserPassword.steps
    ) {
      setIsOpen(false);
      markTourAsCompleted('resetPassword');
    }

    if (tourStep.confirm_otp.index === currentStep) {
      if (!isTourCompleted('confirm_otp')) {
        setIsOpen(true);
      }
    } else if (
      currentStep ===
      tourStep.confirm_otp.index + tourStep.confirm_otp.steps
    ) {
      setIsOpen(false);
      markTourAsCompleted('confirm_otp');
    }
  }, [currentStep, setIsOpen]);

  const handleReset = async (resend?: boolean) => {
    if (resend) {
      setResetLoading(true);
    } else {
      setLoading(true);
    }
    const { getFormData } = forgotForm.current as {
      getFormData: () => { formData: FormDataModel; isFormValid: boolean };
    };
    const { formData, isFormValid } = getFormData();
    const body: { email: string } = removeErrorFieldsFromValues(formData) as {
      email: string;
    };
    try {
      setHasError(false);
      if (isFormValid) {
        await Amplify.ForgotPassword(body.email);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "success",
            message: "OTP has been sent to your Email",
          })
        );
        setState("code");
        if (resend) {
          setResetLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        setHasError(true);
        if (resend) {
          setResetLoading(false);
        } else {
          setLoading(false);
        }
      }
    } catch (error: any) {
      if (resend) {
        setResetLoading(false);
      } else {
        setLoading(false);
      }
      if (error.message) {
        dispatch(
          setNotificationMessage({
            display: true,
            severity:
              error.code === "LimitExceededException" ? "warning" : "error",
            message: error.message,
          })
        );
      }
    }
  };

  const resetPassword = async () => {
    setLoading(true);
    const { getFormData, resetForm } = forgotForm.current as {
      getFormData: () => { formData: FormDataModel; isFormValid: boolean };
      resetForm: () => void;
    };
    const { formData, isFormValid } = getFormData();
    const body: { email: string; code: string; password: string } =
      removeErrorFieldsFromValues(formData) as {
        email: string;
        code: string;
        password: string;
      };
    try {
      setHasError(false);
      if (isFormValid) {
        await Amplify.ForgotPasswordSubmit(
          body.email,
          body.password,
          body.code
        );
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "success",
            message: "Your Password has been changed successfully",
          })
        );
        setState("email");
        resetForm();
        setLoading(false);
        Navigate(role === "agency" ? agency.login : candidate.login);
      } else {
        setHasError(true);
      }
    } catch (error: any) {
      setLoading(false);
      if (error.message) {
        dispatch(
          setNotificationMessage({
            display: true,
            severity:
              error.code === "LimitExceededException" ? "warning" : "error",
            message: error.message,
          })
        );
      }
    }
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={0}
        >
          <Typography
            variant="h6"
            sx={{
              mb: 1,
              fontSize: 24,
              fontWeight: 600,
              color: "#333333",
            }}
            data-tut={
              state === "email" ? "reset_password_step_1" : "reset_password"
            }
          >
            Forgot Password
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 5,
              fontSize: 12,
              fontWeight: 400,
              color: "#727272",
            }}
          >
            Please enter your registered email address
          </Typography>
        </Stack>
        <img src={images.kedIcon} alt="kedIcon" />
      </Stack>
      <Form
        hasError={hasError}
        fieldError={identifiers.field_error as string}
        ref={forgotForm as RefObject<Form>}
        model={ForgotForm(state)}
        values={{}}
        onChange={(field, value, formData, isFormValid) =>
          setButtonActive(isFormValid)
        }
      />
      {state !== "email" && (
        <PrimaryButton
          fullWidth={false}
          disabled={resetLoading || loading}
          sx={{
            mt: 1,
            color: "#333333",
            width: "150px",
            justifyContent: "flex-start",
            pl: 0,
          }}
          variant={"text"}
          onClick={() => handleReset(true)}
        >
          {resetLoading && <CircularProgress sx={{ mr: 1 }} size={20} />}
          <RefreshIcon /> Resend OTP
        </PrimaryButton>
      )}
      <PrimaryButton
        disabled={resetLoading || loading || !buttonActive}
        sx={{
          mt: 2,
          background: "#5AB9F9",
          color: "#ffffff",
          fontSize: 16,
          fontWeight: 500,
        }}
        data-tut={
          state === "email"
            ? "reset_password_step_2"
            : "reset_password_proceed_btn"
        }
        onClick={() => (state === "email" ? handleReset() : resetPassword())}
      >
        {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
        {state === "email" ? "Send Otp" : "Reset Password"}
      </PrimaryButton>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ mt: 4 }}
      >
        <Typography
          variant="body1"
          sx={{ color: "#727272", fontSize: 12, fontWeight: 500 }}
          align={"center"}
        >
          Already have an account?
        </Typography>
        <Link
          to={role === "agency" ? agency.login : candidate.login}
          className="redirection-link"
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: 12,
              fontWeight: 400,
              color: "#5AB9F9",
            }}
          >
            Login
          </Typography>
        </Link>
      </Stack>
    </>
  );
};

export default ForgotPassword;
