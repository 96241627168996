import { Paper, Stack, useMediaQuery, useTheme } from "@mui/material";
import AgencyHeader from "./Components/AgencyHeader";
import AgencyList from "./AgencyList/AgencyList";
import React, { useCallback, useEffect, useState } from "react";
import CreateAgency from "./Components/CreateAgency";
import { useDispatch } from "react-redux";
import { AgencyDetailsModel } from "./Interfaces/AgencyInterfaces";
import axiosInstance from "../../../../utils/axios";
import { Auth } from "aws-amplify";
import { setNotificationMessage } from "../../../../utils/redux";
import EditAgency from "./Components/EditAgency";
import CreateComplianceOfficer from "./Components/CreateComplianceOfficer";

const Agencies = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isCreateAgencyOpen, setIsCreateAgencyOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(8);
  const [openRows, setOpenRows] = useState<number | null>(null);
  const [rows, setRows] = useState<AgencyDetailsModel[]>([]);
  const [isLoading, setIsLoading] = useState(true)

  // edit agency
  const [editAgencyDialog, setEditAgencyDialog] = useState<boolean>(false);
  const [editAgencyData, setEditAgencyData] = useState<AgencyDetailsModel | null>(null)

  // to add team members
  const [addComplianceOfficerDialog, setAddComplianceOfficerDialog] = useState<boolean>(false);
  const [addComplianceOfficerId, setAddComplianceOfficerId] = useState<string>("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const getAgencyData = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_NODE_ADMIN_API_URL}/authenticated/admin/agencies`);
      setRows(response.data);
      setIsLoading(false)
    } catch (error) {
      if (await Auth.currentUserInfo()) {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: 'error',
            message: 'There is an error in fetching agencies',
          })
        );
      }
    }
  }, [dispatch]);

  useEffect(() => {
    getAgencyData();
  }, [getAgencyData]);

  const handleToggle = (index: number) => {
    setOpenRows((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const handleOpenCreateAgency = () => {
    setIsCreateAgencyOpen(true);
  };

  const handleCloseCreateAgency = () => {
    setIsCreateAgencyOpen(false);
  };

  // after complete create agency
  const handleAgencyUpdates = async () => {
    await getAgencyData();
  }

  // edit dialog handlers
  const handleOpenEditAgency = (id: string) => {
    const agency = rows.find((agency) => agency.agency_id == id);
    setEditAgencyData(agency || null);
    setEditAgencyDialog(true);
  };
  const handleCloseEditAgency = () => {
    setEditAgencyData(null);
    setEditAgencyDialog(false);
  };

  // add team member handlers
  const handleOpenAddComplianceOfficer = (id: string) => {
    setAddComplianceOfficerId(id)
    setAddComplianceOfficerDialog(true);
  };
  const handleCloseAddComplianceOfficer = () => {
    setAddComplianceOfficerId("")
    setAddComplianceOfficerDialog(false);
  };

  return (
    <>
      <Stack padding={'1rem'} component={Paper}>
        <AgencyHeader
          onSearch={handleSearch}
          searchQuery={searchQuery}
          onOpenCreateAgency={handleOpenCreateAgency}
        />
        <AgencyList
          searchQuery={searchQuery}
          rows={rows}
          openRows={openRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onToggle={handleToggle}
          onChangePage={handleChangePage}
          isLoading={isLoading}
          onClickEditAgency={handleOpenEditAgency}
          onClickAddComplianceOfficer={handleOpenAddComplianceOfficer}
          isMobile={isMobile}
        />
        {isCreateAgencyOpen && (
          <CreateAgency
            isOpen={isCreateAgencyOpen}
            onClose={handleCloseCreateAgency}
            onComplete={handleAgencyUpdates}
          />
        )}
        {editAgencyDialog &&
          <EditAgency
            onClose={handleCloseEditAgency}
            isOpen={editAgencyDialog}
            agency={editAgencyData}
            onComplete={handleAgencyUpdates}
          />
        }

        {addComplianceOfficerDialog &&
          <CreateComplianceOfficer
            isOpen={addComplianceOfficerDialog}
            onClose={handleCloseAddComplianceOfficer}
            agencyId={addComplianceOfficerId}
            onComplete={handleAgencyUpdates}
          />
        }

      </Stack>
    </>
  );
};

export default Agencies;
