import React from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import OtpInput from "react-otp-input";
import { identifiers } from "../../utils/constants/identifiers";
import { useTheme } from "@emotion/react";

const OtpInputField = ({ otpValue, handleOtpCallback }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Grid textAlign="center">
      <Typography className="verification" variant="h6" sx={{ mb: 2 }}>
        VERIFICATION CODE
      </Typography>
      <OtpInput
        isInputNum
        shouldAutoFocus
        numInputs={identifiers.otp_digit}
        value={otpValue}
        onChange={(value) => {
          handleOtpCallback(value);
        }}
        inputStyle={{
          width: matches ? "3rem" : "2rem",
          height: matches ? "3rem" : "2rem",
          margin: "0.5rem",
          fontSize: matches ? "1.5rem" : "1rem",
          borderRadius: 4,
          border: "1px solid rgba(0,0,0,0.3)",
        }}
      />
      {/*<Grid item lg={12} justifyContent='center' flexDirection='row' sx={{ display: 'flex', mt: theme.spacing(2), mb: theme.spacing(2) }}>*/}
      {/*  <Typography variant="subtitle2" color='text.secondary'>*/}
      {/*    Didn’t receive the OTP?*/}
      {/*  </Typography>*/}
      {/*  <PrimaryButton*/}
      {/*    variant='text'*/}
      {/*    fullWidth={false}*/}
      {/*    sx={{ p: 0, ml: 1, mt: -.2 }}*/}
      {/*    disabled={loading}*/}
      {/* onClick={resendOtpButtonCallback}>*/}
      {/*  Resend code*/}
      {/*</PrimaryButton>*/}
      {/*</Grid>*/}
    </Grid>
  );
};

export default OtpInputField;
