import React, { FC } from "react";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import InputField from "../../../../../../components/InputField";

const Label: FC<{ label: string }> = ({ label }) => {
  return (
    <Typography variant={"body1"} sx={{ my: 2 }}>
      {label}
    </Typography>
  );
};

const KedQuestionnaire: FC<{
  type: "employment" | "character" | "academic";
  ked_questionnaire_value: {
    [key: string]: {
      label: string;
      value: string | boolean | number;
    };
  };
}> = ({ type, ked_questionnaire_value }) => {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: "none",
      padding: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      borderBottom: "none",
      color: "#263238",
      fontSize: "14px",
      fontWeight: 600,
      padding: 0,
      background: "#F0F2FF",
    },
    [`&.${tableCellClasses.head}:not(:first-of-type)`]: {
      width: "80px",
      textAlign: "right",
      paddingRight: "5px",
    },
    [`&.${tableCellClasses.head}:first-of-type`]: {
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
      minWidth: "250px",
    },
    [`&.${tableCellClasses.head}:last-of-type`]: {
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
    },
    [`&.${tableCellClasses.body}:first-of-type`]: {
      borderTopLeftRadius: "12px",
      borderBottomLeftRadius: "12px",
      minWidth: "250px",
    },
    [`&.${tableCellClasses.body}:not(:first-of-type)`]: {
      width: "80px",
      textAlign: "right",
    },
    [`&.${tableCellClasses.body}:last-of-type`]: {
      borderTopRightRadius: "12px",
      borderBottomRightRadius: "12px",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&": {
      backgroundColor: "white",
      paddingBottom: "15px",
      paddingTop: "15px",
    },
  }));

  return (
    <Stack>
      {type === "employment" && (
        <>
          <Label
            label={ked_questionnaire_value.main_duties_responsibility?.label}
          />

          <InputField
            inputValue={
              ked_questionnaire_value.main_duties_responsibility
                ?.value as string
            }
            disabled
            multiline
            rows={3}
            sx={{ border: "none" }}
          />
          <Label label={ked_questionnaire_value.relevant_skills?.label} />
          <InputField
            inputValue={
              ked_questionnaire_value.relevant_skills?.value as string
            }
            disabled
            multiline
            rows={3}
          />
          <Label label={ked_questionnaire_value.training?.label} />
          <InputField
            inputValue={ked_questionnaire_value.training?.value as string}
            disabled
            multiline
            rows={3}
          />
        </>
      )}
      {type !== "employment" && (
        <>
          <Label
            label={ked_questionnaire_value?.referee_candidate_time_known?.label}
          />
          <InputField
            inputValue={
              ked_questionnaire_value?.referee_candidate_time_known
                ?.value as string
            }
            disabled
            multiline
            rows={3}
          />
        </>
      )}
      {type === "character" && (
        <>
          <Label
            label={
              ked_questionnaire_value.referee_candidate_relationship_outline
                ?.label
            }
          />
          <InputField
            inputValue={
              ked_questionnaire_value.referee_candidate_relationship_outline
                ?.value as string
            }
            disabled
            multiline
            rows={3}
          />
          <Label label={ked_questionnaire_value.candidate_character?.label} />
          <InputField
            inputValue={
              ked_questionnaire_value.candidate_character?.value as string
            }
            disabled
            multiline
            rows={3}
          />
          <Label label={ked_questionnaire_value.further_information?.label} />
          <InputField
            inputValue={
              ked_questionnaire_value.further_information?.value as string
            }
            disabled
            multiline
            rows={3}
          />
        </>
      )}
      {type === "academic" && (
        <>
          <Label label={ked_questionnaire_value.candidate_assessment?.label} />
          <InputField
            inputValue={
              ked_questionnaire_value.candidate_assessment?.value as string
            }
            disabled
            multiline
            rows={3}
          />
        </>
      )}
      {type !== "character" && (
        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", background: "none" }}
        >
          <Table
            sx={{
              minWidth: 400,
              borderSpacing: "0 1rem",
              borderCollapse: "separate",
            }}
          >
            <TableHead>
              <TableRow className="table_heading_rows">
                <StyledTableCell>Metric Based Performance</StyledTableCell>
                <StyledTableCell>
                  <Typography
                    variant={"body2"}
                    color={"#DADADA"}
                    fontWeight={500}
                  >
                    01
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    variant={"body2"}
                    color={"#B1AEAE"}
                    fontWeight={500}
                  >
                    02
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    variant={"body2"}
                    color={"#797979"}
                    fontWeight={500}
                  >
                    03
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    variant={"body2"}
                    color={"#1E1E1E"}
                    fontWeight={500}
                  >
                    04
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant={"body2"} fontWeight={500}>
                    05
                  </Typography>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody className={"request-table-body"}>
              {Object.keys(ked_questionnaire_value)
                .filter((a) => a.indexOf("rating") > -1)
                .map((question: string, index: number) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>
                      <FormLabel>
                        {ked_questionnaire_value[question].label}
                      </FormLabel>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Radio
                        checked={ked_questionnaire_value[question].value === 1}
                        disabled
                        value={1}
                        sx={{ pr: 0 }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Radio
                        checked={ked_questionnaire_value[question].value === 2}
                        disabled
                        value={2}
                        sx={{ pr: 0 }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Radio
                        checked={ked_questionnaire_value[question].value === 3}
                        disabled
                        value={3}
                        sx={{ pr: 0 }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Radio
                        checked={ked_questionnaire_value[question].value === 4}
                        disabled
                        value={4}
                        sx={{ pr: 0 }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Radio
                        checked={ked_questionnaire_value[question].value === 5}
                        disabled
                        value={5}
                        sx={{ pr: 0 }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {type === "employment" && (
        <>
          <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <Stack>
              <Label
                label={ked_questionnaire_value.reemployment_policy?.label}
              />
            </Stack>
            <Box sx={{ mt: 1 }}>
              <Radio
                value={true}
                checked={
                  ked_questionnaire_value.reemployment_policy?.value as boolean
                }
                disabled
              />
              <Typography
                variant={"body1"}
                display={"inline-block"}
                sx={{ pr: 2 }}
              >
                Yes
              </Typography>
              <Radio
                value={false}
                checked={
                  !ked_questionnaire_value.reemployment_policy?.value as boolean
                }
                disabled
              />
              <Typography variant={"body1"} display={"inline-block"}>
                No
              </Typography>
            </Box>
          </Stack>
          {ked_questionnaire_value?.reemployment_policy?.value && (
            <>
              <Stack flexDirection={"row"} justifyContent={"space-between"}>
                <Stack>
                  <Label
                    label={ked_questionnaire_value.reemployment_yes?.label}
                  />
                </Stack>
                <Box sx={{ mt: 1 }}>
                  <Radio
                    value={true}
                    disabled
                    checked={
                      ked_questionnaire_value.reemployment_yes?.value as boolean
                    }
                  />
                  <Typography
                    variant={"body1"}
                    display={"inline-block"}
                    sx={{ pr: 2 }}
                  >
                    Yes
                  </Typography>
                  <Radio
                    value={false}
                    disabled
                    checked={
                      !ked_questionnaire_value.reemployment_yes
                        ?.value as boolean
                    }
                  />
                  <Typography variant={"body1"} display={"inline-block"}>
                    No
                  </Typography>
                </Box>
              </Stack>
              {!ked_questionnaire_value.reemployment_yes?.value && (
                <>
                  <Label label={ked_questionnaire_value.rehire_no?.label} />
                  <InputField
                    inputValue={
                      ked_questionnaire_value.rehire_no?.value as string
                    }
                    multiline
                    rows={2}
                    disabled
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </Stack>
  );
};

export default KedQuestionnaire;
