import React, { ChangeEvent, FC, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import PrimaryButton from "../../../../components/Button/PrimaryButton";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  ReferenceData,
  setNotificationMessage,
  setReferenceData,
} from "../../../../utils/redux";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import InputField from "../../../../components/InputField";
import { routes } from "../../../../utils/constants/routes";
import { useNavigate } from "react-router-dom";
import { identifiers } from "../../../../utils/constants/identifiers";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../utils/constants/coachMarks";
import { isTourCompleted, markTourAsCompleted } from "../../../../utils/storage/tours";

const Label: FC<{ label: string }> = ({ label }) => {
  return (
    <Typography variant={"body1"} sx={{ my: 2 }}>
      {label}
    </Typography>
  );
};

const KedQuestionnaire: FC = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const reference = useSelector(ReferenceData);

  const { setIsOpen, currentStep, setCurrentStep } = useTour();

  useEffect(() => {
    if (!reference.referee_matrix_table) {
      if (
        reference.type !== "character" &&
        reference.type !== identifiers.gap
      ) {
        setCurrentStep(tourStep.referee_matrix_table.index);
      }
    }
  }, [reference, setCurrentStep]);

  useEffect(() => {
    if (reference.type !== "character" && reference.type !== identifiers.gap) {
      if (tourStep.referee_matrix_table.index === currentStep) {
        if (!isTourCompleted('referee_matrix_table')) {
          setIsOpen(true);
        }
      } else if (
        currentStep ===
        tourStep.referee_matrix_table.index +
          tourStep.referee_matrix_table.steps
      ) {
        setIsOpen(false);
        markTourAsCompleted('referee_matrix_table');
        dispatch(
          setReferenceData({ ...reference, referee_matrix_table: true })
        );
        //setCoachMarksValue()
      }
    }
  }, [currentStep, dispatch, reference, setIsOpen]);

  const proceed = () => {
    let valid = true;
    switch (reference.type) {
      case "employment":
        if (
          !reference.ked_questionnaire.main_duties_responsibility.value ||
          !reference.ked_questionnaire.relevant_skills.value ||
          !reference.ked_questionnaire.training.value ||
          (reference.ked_questionnaire.reemployment_policy.value &&
            !reference.ked_questionnaire.reemployment_yes.value &&
            !reference.ked_questionnaire.rehire_no.value)
        ) {
          valid = false;
        }
        break;
      case "character":
        if (
          !reference.ked_questionnaire.referee_candidate_time_known.value ||
          !reference.ked_questionnaire.referee_candidate_relationship_outline
            .value ||
          !reference.ked_questionnaire.candidate_character.value ||
          !reference.ked_questionnaire.further_information.value
        ) {
          valid = false;
        }
        break;

      case "employment_gap":
        if (
          !reference.ked_questionnaire.referee_candidate_time_known.value ||
          !reference.ked_questionnaire.referee_candidate_relationship_outline
            .value ||
          !reference.ked_questionnaire.candidate_character.value ||
          !reference.ked_questionnaire.further_information.value
        ) {
          valid = false;
        }
        break;
      case "academic":
        if (
          !reference.ked_questionnaire.referee_candidate_time_known.value ||
          !reference.ked_questionnaire.candidate_assessment.value
        ) {
          valid = false;
        }
        break;
    }
    if (valid) {
      const active_index = reference.active_index;
      dispatch(
        setReferenceData({
          ...reference,
          active_index: active_index + 1,
        })
      );
      Navigate(`../${routes[reference.available_routes[active_index + 1]]}`);
    } else {
      dispatch(
        setNotificationMessage({
          display: true,
          message: "Please enter all values in form",
          severity: "error",
        })
      );
    }
  };

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: "none",
      padding: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      borderBottom: "none",
      color: "#263238",
      fontSize: "14px",
      fontWeight: 600,
      padding: 0,
    },
    [`&.${tableCellClasses.head}:not(:first-of-type)`]: {
      width: "80px",
      textAlign: "right",
      paddingRight: "5px",
    },
    [`&.${tableCellClasses.head}:first-of-type`]: {
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
      minWidth: "250px",
    },
    [`&.${tableCellClasses.head}:last-of-type`]: {
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
    },
    [`&.${tableCellClasses.body}:first-of-type`]: {
      borderTopLeftRadius: "12px",
      borderBottomLeftRadius: "12px",
      minWidth: "250px",
    },
    [`&.${tableCellClasses.body}:not(:first-of-type)`]: {
      width: "80px",
      textAlign: "right",
    },
    [`&.${tableCellClasses.body}:last-of-type`]: {
      borderTopRightRadius: "12px",
      borderBottomRightRadius: "12px",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&": {
      backgroundColor: "white",
      paddingBottom: "15px",
      paddingTop: "15px",
    },
  }));

  return (
    <Grid container justifyContent={"center"} sx={{ mt: 3 }}>
      <Grid item xs={10} lg={8}>
        {reference.type === "employment" && (
          <>
            <Label
              label={
                reference.ked_questionnaire.main_duties_responsibility?.label
              }
            />
            <InputField
              inputValue={
                reference.ked_questionnaire.main_duties_responsibility
                  ?.value as string
              }
              multiline
              rows={3}
              sx={{ background: "white" }}
              textChange={(value) =>
                dispatch(
                  setReferenceData({
                    ...reference,
                    ked_questionnaire: {
                      ...reference.ked_questionnaire,
                      main_duties_responsibility: {
                        ...reference.ked_questionnaire
                          .main_duties_responsibility,
                        value,
                      },
                    },
                  })
                )
              }
            />
            <Label label={reference.ked_questionnaire.relevant_skills?.label} />
            <InputField
              inputValue={
                reference.ked_questionnaire.relevant_skills?.value as string
              }
              multiline
              rows={3}
              sx={{ background: "white" }}
              textChange={(value) =>
                dispatch(
                  setReferenceData({
                    ...reference,
                    ked_questionnaire: {
                      ...reference.ked_questionnaire,
                      relevant_skills: {
                        ...reference.ked_questionnaire.relevant_skills,
                        value,
                      },
                    },
                  })
                )
              }
            />
            <Label label={reference.ked_questionnaire.training?.label} />
            <InputField
              inputValue={reference.ked_questionnaire.training?.value as string}
              multiline
              rows={3}
              sx={{ background: "white" }}
              textChange={(value) =>
                dispatch(
                  setReferenceData({
                    ...reference,
                    ked_questionnaire: {
                      ...reference.ked_questionnaire,
                      training: {
                        ...reference.ked_questionnaire.training,
                        value,
                      },
                    },
                  })
                )
              }
            />
          </>
        )}
        {reference.type !== "employment" && (
          <>
            <Label
              label={
                reference.ked_questionnaire.referee_candidate_time_known?.label
              }
            />
            <InputField
              inputValue={
                reference.ked_questionnaire.referee_candidate_time_known
                  ?.value as string
              }
              multiline
              rows={3}
              sx={{ background: "white" }}
              textChange={(value) =>
                dispatch(
                  setReferenceData({
                    ...reference,
                    ked_questionnaire: {
                      ...reference.ked_questionnaire,
                      referee_candidate_time_known: {
                        ...reference.ked_questionnaire
                          .referee_candidate_time_known,
                        value,
                      },
                    },
                  })
                )
              }
            />
          </>
        )}
        {(reference.type === "character" ||
          reference.type === identifiers.gap) && (
          <>
            <Label
              label={
                reference.ked_questionnaire
                  .referee_candidate_relationship_outline?.label
              }
            />
            <InputField
              inputValue={
                reference.ked_questionnaire
                  .referee_candidate_relationship_outline?.value as string
              }
              multiline
              rows={3}
              sx={{ background: "white" }}
              textChange={(value) =>
                dispatch(
                  setReferenceData({
                    ...reference,
                    ked_questionnaire: {
                      ...reference.ked_questionnaire,
                      referee_candidate_relationship_outline: {
                        ...reference.ked_questionnaire
                          .referee_candidate_relationship_outline,
                        value,
                      },
                    },
                  })
                )
              }
            />
            <Label
              label={reference.ked_questionnaire.candidate_character?.label}
            />
            <InputField
              inputValue={
                reference.ked_questionnaire.candidate_character?.value as string
              }
              multiline
              rows={3}
              sx={{ background: "white" }}
              textChange={(value) =>
                dispatch(
                  setReferenceData({
                    ...reference,
                    ked_questionnaire: {
                      ...reference.ked_questionnaire,
                      candidate_character: {
                        ...reference.ked_questionnaire.candidate_character,
                        value,
                      },
                    },
                  })
                )
              }
            />
            <Label
              label={reference.ked_questionnaire.further_information?.label}
            />
            <InputField
              inputValue={
                reference.ked_questionnaire.further_information?.value as string
              }
              multiline
              rows={3}
              sx={{ background: "white" }}
              textChange={(value) =>
                dispatch(
                  setReferenceData({
                    ...reference,
                    ked_questionnaire: {
                      ...reference.ked_questionnaire,
                      further_information: {
                        ...reference.ked_questionnaire.further_information,
                        value,
                      },
                    },
                  })
                )
              }
            />
          </>
        )}
        {reference.type === "academic" && (
          <>
            <Label
              label={reference.ked_questionnaire.candidate_assessment?.label}
            />
            <InputField
              inputValue={
                reference.ked_questionnaire.candidate_assessment
                  ?.value as string
              }
              multiline
              rows={3}
              sx={{ background: "white" }}
              textChange={(value) =>
                dispatch(
                  setReferenceData({
                    ...reference,
                    ked_questionnaire: {
                      ...reference.ked_questionnaire,
                      candidate_assessment: {
                        ...reference.ked_questionnaire.candidate_assessment,
                        value,
                      },
                    },
                  })
                )
              }
            />
          </>
        )}
        {reference.type !== "character" &&
          reference.type !== identifiers.gap && (
            <TableContainer
              component={Paper}
              sx={{ boxShadow: "none", background: "none" }}
            >
              <Table
                sx={{
                  minWidth: 400,
                  borderSpacing: "0 1rem",
                  borderCollapse: "separate",
                }}
              >
                <TableHead>
                  <TableRow className="table_heading_rows">
                    <StyledTableCell data-tut="rating_table">
                      Metric Based Performance
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant={"body2"}
                        color={"#DADADA"}
                        fontWeight={500}
                      >
                        01
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant={"body2"}
                        color={"#B1AEAE"}
                        fontWeight={500}
                      >
                        02
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant={"body2"}
                        color={"#797979"}
                        fontWeight={500}
                      >
                        03
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant={"body2"}
                        color={"#1E1E1E"}
                        fontWeight={500}
                      >
                        04
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography variant={"body2"} fontWeight={500}>
                        05
                      </Typography>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={"request-table-body"}>
                  {Object.keys(reference.ked_questionnaire)
                    .filter((a) => a.indexOf("rating") > -1)
                    .map((question: string, index: number) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell>
                          <FormLabel>
                            {reference.ked_questionnaire[question].label}
                          </FormLabel>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Radio
                            checked={
                              reference.ked_questionnaire[question].value === 1
                            }
                            value={1}
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                              dispatch(
                                setReferenceData({
                                  ...reference,
                                  ked_questionnaire: {
                                    ...reference.ked_questionnaire,
                                    [question]: {
                                      ...reference.ked_questionnaire[question],
                                      value: parseInt(event.target.value),
                                    },
                                  },
                                })
                              )
                            }
                            sx={{ pr: 0 }}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <Radio
                            checked={
                              reference.ked_questionnaire[question].value === 2
                            }
                            value={2}
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                              dispatch(
                                setReferenceData({
                                  ...reference,
                                  ked_questionnaire: {
                                    ...reference.ked_questionnaire,
                                    [question]: {
                                      ...reference.ked_questionnaire[question],
                                      value: parseInt(event.target.value),
                                    },
                                  },
                                })
                              )
                            }
                            sx={{ pr: 0 }}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <Radio
                            checked={
                              reference.ked_questionnaire[question].value === 3
                            }
                            value={3}
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                              dispatch(
                                setReferenceData({
                                  ...reference,
                                  ked_questionnaire: {
                                    ...reference.ked_questionnaire,
                                    [question]: {
                                      ...reference.ked_questionnaire[question],
                                      value: parseInt(event.target.value),
                                    },
                                  },
                                })
                              )
                            }
                            sx={{ pr: 0 }}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <Radio
                            checked={
                              reference.ked_questionnaire[question].value === 4
                            }
                            value={4}
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                              dispatch(
                                setReferenceData({
                                  ...reference,
                                  ked_questionnaire: {
                                    ...reference.ked_questionnaire,
                                    [question]: {
                                      ...reference.ked_questionnaire[question],
                                      value: parseInt(event.target.value),
                                    },
                                  },
                                })
                              )
                            }
                            sx={{ pr: 0 }}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <Radio
                            checked={
                              reference.ked_questionnaire[question].value === 5
                            }
                            value={5}
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                              dispatch(
                                setReferenceData({
                                  ...reference,
                                  ked_questionnaire: {
                                    ...reference.ked_questionnaire,
                                    [question]: {
                                      ...reference.ked_questionnaire[question],
                                      value: parseInt(event.target.value),
                                    },
                                  },
                                })
                              )
                            }
                            sx={{ pr: 0 }}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        {reference.type === "employment" && (
          <>
            <Stack
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={"space-between"}
            >
              <Stack sx={{ width: { xs: "100%", md: "50%" } }}>
                <Label
                  label={reference.ked_questionnaire.reemployment_policy?.label}
                />
              </Stack>
              <Box sx={{ mt: 1, width: { xs: "100%", md: "50%" } }}>
                <Radio
                  value={true}
                  checked={
                    reference.ked_questionnaire.reemployment_policy
                      ?.value as boolean
                  }
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    dispatch(
                      setReferenceData({
                        ...reference,
                        ked_questionnaire: {
                          ...reference.ked_questionnaire,
                          reemployment_policy: {
                            ...reference.ked_questionnaire.reemployment_policy,
                            value: event.target.value === "true",
                          },
                        },
                      })
                    )
                  }
                />
                <Typography
                  variant={"body1"}
                  display={"inline-block"}
                  sx={{ pr: 2 }}
                >
                  Yes
                </Typography>
                <Radio
                  value={false}
                  checked={
                    !reference.ked_questionnaire.reemployment_policy
                      ?.value as boolean
                  }
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    dispatch(
                      setReferenceData({
                        ...reference,
                        ked_questionnaire: {
                          ...reference.ked_questionnaire,
                          reemployment_policy: {
                            ...reference.ked_questionnaire.reemployment_policy,
                            value: event.target.value === "true",
                          },
                        },
                      })
                    )
                  }
                />
                <Typography variant={"body1"} display={"inline-block"}>
                  No
                </Typography>
              </Box>
            </Stack>
            {reference.ked_questionnaire?.reemployment_policy?.value && (
              <>
                <Stack
                  flexDirection={{ xs: "column", md: "row" }}
                  justifyContent={"space-between"}
                >
                  <Stack sx={{ width: { xs: "100%", md: "50%" } }}>
                    <Label
                      label={
                        reference.ked_questionnaire.reemployment_yes?.label
                      }
                    />
                  </Stack>
                  <Box sx={{ mt: 1, width: { xs: "100%", md: "50%" } }}>
                    <Radio
                      value={true}
                      checked={
                        reference.ked_questionnaire.reemployment_yes
                          ?.value as boolean
                      }
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        dispatch(
                          setReferenceData({
                            ...reference,
                            ked_questionnaire: {
                              ...reference.ked_questionnaire,
                              reemployment_yes: {
                                ...reference.ked_questionnaire.reemployment_yes,
                                value: event.target.value === "true",
                              },
                            },
                          })
                        )
                      }
                    />
                    <Typography
                      variant={"body1"}
                      display={"inline-block"}
                      sx={{ pr: 2 }}
                    >
                      Yes
                    </Typography>
                    <Radio
                      value={false}
                      checked={
                        !reference.ked_questionnaire.reemployment_yes
                          ?.value as boolean
                      }
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        dispatch(
                          setReferenceData({
                            ...reference,
                            ked_questionnaire: {
                              ...reference.ked_questionnaire,
                              reemployment_yes: {
                                ...reference.ked_questionnaire.reemployment_yes,
                                value: event.target.value === "true",
                              },
                            },
                          })
                        )
                      }
                    />
                    <Typography variant={"body1"} display={"inline-block"}>
                      No
                    </Typography>
                  </Box>
                </Stack>
                {!reference.ked_questionnaire.reemployment_yes?.value && (
                  <>
                    <Label
                      label={reference.ked_questionnaire.rehire_no?.label}
                    />
                    <InputField
                      inputValue={
                        reference.ked_questionnaire.rehire_no?.value as string
                      }
                      multiline
                      rows={2}
                      textChange={(value) =>
                        dispatch(
                          setReferenceData({
                            ...reference,
                            ked_questionnaire: {
                              ...reference.ked_questionnaire,
                              rehire_no: {
                                ...reference.ked_questionnaire.rehire_no,
                                value,
                              },
                            },
                          })
                        )
                      }
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
        <Box
          display={"flex"}
          justifyContent={"space-around"}
          sx={{
            position: { xs: "fixed", sm: "fixed", md: "relative" },
            bottom: "0px",
            left: "0px",
            width: "100%",
            zIndex: "99",
            textAlign: { xs: "center", md: "right" },
            background: { xs: "white", md: "transperant" },
            boxShadow: { xs: "0px -4px 7px #e6e6e6", md: "none" },
            borderRadius: { xs: "15px 15px 0px 0px", md: "none" },
            padding: 1,
            my: { xs: 0, md: 2 },
          }}
        >
          <PrimaryButton
            fullWidth={false}
            sx={{
              width: "235px",
              height: "36px",
              background: "#FFFFFF",
              border: " 1px solid #5AB9F9",
              borderRadius: "5px",
              padding: "4px 44px",
              color: "#5AB9F9",
              fontSize: "14px",
              margin: "0px 20px",
            }}
            onClick={() => {
              const active_index = reference.active_index;
              dispatch(
                setReferenceData({
                  ...reference,
                  active_index: active_index - 1,
                })
              );
              Navigate(
                `../${routes[reference.available_routes[active_index - 1]]}`
              );
            }}
          >
            Go back
          </PrimaryButton>
          <PrimaryButton
            disabled={false}
            fullWidth={false}
            sx={{
              background: "#5AB9F9",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 500,
              p: "2px 80px",
            }}
            onClick={proceed}
          >
            Proceed
          </PrimaryButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default KedQuestionnaire;
