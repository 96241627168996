import React, {
  FC,
  useCallback,
  useEffect,
  useState,
  SyntheticEvent,
  useMemo,
} from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { images } from "../../../utils/constants/images";
import dayjs, { Dayjs } from "dayjs";
import TabPanel from "../../../components/TabPanel";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../utils/axios";
import { URLS } from "../../../utils/constants/urls";
import { identifiers } from "../../../utils/constants/identifiers";
import PrimaryButton from "../../../components/Button/PrimaryButton";
import { agency, candidate } from "../../../utils/constants/routes";
import { useDispatch, useSelector } from "react-redux";
import VettingDocuments from "./VettingDocuments";
import AuthenticatedVettingDoc from "../../Authenticated/Candidate/ViewRequest/VettingDocuments";
import TitleBar from "./TitleBar";
import {
  AdditionalFormData,
  Authenticated,
  Comment,
  initialReferenceState,
  Reference,
  setAdditionalFormData,
  setComment,
  setNotificationMessage,
  setReference,
  setRequest,
  setVettingDocuments,
  UserRole,
  Reload,
  setReload,
  VettingDocuments as VettingDocumentsData,
  setVettingDocumentList,
  setAcademicQualificationData,
  setCandidateIDV,
  setPersonalInformation,
  setPersonalHistory,
  setUCheckFormID,
  setCurrentAddress,
  setPreviousAddress,
  setPassPortInformation,
  setLicenceInformation,
  setStatus,
  setUserInformation,
} from "../../../utils/redux";
import AdditionalForms from "../../Authenticated/Candidate/ViewRequest/AdditionalForms";
import RequiredReference from "../../Authenticated/Candidate/ViewRequest/RequiredReference";
import { VettingDocument } from "../../Authenticated/Agency/RequestDetails/RequestDetailsModel";
import PageLoader from "../../../components/PageLoader";
import AdditionalComments from "../../Authenticated/Candidate/ViewRequest/AdditionalComments";
import Modal from "../../../components/modal/Modal";
import Citizenship from "../../Authenticated/Candidate/ViewRequest/VettingDocuments/DocumentList/RightToWrok/Citizenship";
import {
  getCurrentAddress,
  getDrivingLicenceInformation,
  getPassportDetails,
  getPersonalHistory,
  getPersonalInformation,
  getPreviousAddresses,
  getReference,
  getVettingDocuments,
  getVettingDocumentsList,
} from "./ViewRequestService";

import "./ViewRequest.scss";
import { tourStep } from "../../../utils/constants/coachMarks";
import { useTour } from "@reactour/tour";
import { CognitoUser } from "amazon-cognito-identity-js";
import * as Amplify from "../../../utils/services/amplify";
import {
  User,
  setUserData,
} from "../../../utils/redux/reducer/authentication-slice";
import { isTourCompleted, markTourAsCompleted } from "../../../utils/storage/tours";

const ViewRequest: FC = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { id } = useParams() as any;
  const { setIsOpen, currentStep, setCurrentStep } = useTour();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const isAuthenticated = useSelector(Authenticated);
  const reload = useSelector(Reload);
  const userRole = useSelector(UserRole);
  const vettingDocuments = useSelector(VettingDocumentsData);
  const additionalForms = useSelector(AdditionalFormData);
  const comment = useSelector(Comment);
  const references = useSelector(Reference);
  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };

  const [value, setValue] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [isApplied, setIsApplied] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [requestId, setRequestId] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [designation, setDesignation] = useState<string>("");
  const [date, setDate] = useState<Dayjs | null>(null);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [underReview, setUnderReview] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      if (userRole === "candidate") {
        (async () => {
          await axiosInstance.put(`${URLS.requests}/${id}/map`);
          Navigate(`${candidate.requests}/${id}`);
        })();
      } else if (userRole === "agency") {
        Navigate(`${agency.requests}/${id}`);
      }
    }
  }, [Navigate, id, isAuthenticated, userRole]);

  const getRequest = useCallback(async () => {
    try {
      setLoading(true);
      if (isAuthenticated) {
        if (!reload || reload) {
          const { data } = await axiosInstance.get(
            `${URLS.candidate_request}/${id}?stage=${process.env.REACT_APP_YOTI_ENV}&ucheck_stage=${process.env.REACT_APP_UCHECK_ENV}`
          );
          setIsApplied(data.is_applied);
          if (data.is_applied) {
            setRequestId(data.request_data.request_id);
            setCompany(data.request_data.company);
            setDesignation(data.request_data.designation);
            if (data.request_data.date) {
              setDate(dayjs(data.request_data.date));
            } else {
              setDate(null);
            }
            dispatch(setVettingDocumentList(getVettingDocumentsList(data)));
            dispatch(setVettingDocuments(getVettingDocuments(data)));
            dispatch(setAdditionalFormData(data.additional_form_data));
            dispatch(setCandidateIDV(data.candidate_idv));
            dispatch(setReference(getReference(data)));
            setUnderReview(data.under_review);
            dispatch(setComment(data.request_data.comment));
            dispatch(
              setAcademicQualificationData({
                data: data.academic_qualification_data,
                ids: data.academic_qualification_ids,
              })
            );
            // uCheck
            dispatch(setUCheckFormID(data.ucheck_application?.id));
            dispatch(setStatus(data?.ucheck_application?.status));
            dispatch(
              setUserInformation({
                ...data,
                comment: data?.ucheck_application?.comments,
              })
            );
            dispatch(
              setPersonalInformation(
                getPersonalInformation(
                  data?.ucheck_application?.application_details,
                  data?.ucheck_application?.dbs_type?.toLowerCase() || ""
                )
              )
            );
            dispatch(
              setPersonalHistory(
                getPersonalHistory(
                  data?.ucheck_application?.application_details
                )
              )
            );
            dispatch(
              setCurrentAddress(
                getCurrentAddress(data?.ucheck_application?.application_details)
              )
            );
            dispatch(
              setPreviousAddress(
                getPreviousAddresses(
                  data?.ucheck_application?.application_details
                )
              )
            );
            dispatch(
              setPassPortInformation(
                getPassportDetails(
                  data?.ucheck_application?.application_details
                )
              )
            );
            dispatch(
              setLicenceInformation(
                getDrivingLicenceInformation(
                  data?.ucheck_application?.application_details
                )
              )
            );
            if (
              !!data.vetting_documents.find(
                (doc: VettingDocument) => doc.name === identifiers.rtw
              )
            ) {
              if (
                data.vetting_documents.find(
                  (doc: VettingDocument) => doc.name === identifiers.rtw
                ).is_british_or_irish_citizen === undefined
              ) {
                setOpenModal(true);
              }
            }
          } else {
            setRequestId(data.request_id);
            setCompany(data.company);
            setDesignation(data.designation);
            if (data.date) {
              setDate(dayjs(data.date));
            } else {
              setDate(null);
            }
          }
        }
      } else {
        const { data } = await axiosInstance.get(
          `${URLS.public_requests}/${id}`,
          { noAuth: true } as any
        );
        setRequestId(data.request_id);
        setDesignation(data.designation);
        if (data.date) {
          setDate(dayjs(data.date));
        } else {
          setDate(null);
        }
        dispatch(
          setVettingDocuments(
            data.vetting_documents.sort(
              (a: VettingDocument, b: VettingDocument) => a.index - b.index
            )
          )
        );
        dispatch(
          setAdditionalFormData({
            document: [{ id: "", name: "" }],
            token: "",
            signed: false,
          })
        );
        dispatch(
          setReference(
            data.reference && !!data.reference.max_references_count
              ? {
                  ...data.reference,
                  reference_acknowledged: data.acknowledged,
                }
              : initialReferenceState
          )
        );
        dispatch(setComment(data.comment));
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in fetching requests",
          autoHideDuration: 6000,
        })
      );
    }
  }, [dispatch, id, isAuthenticated, reload]);

  useEffect(() => {
    (async () => {
      await getRequest();
    })();
  }, [getRequest]);

  const navigate = () => {
    dispatch(setRequest({ id }));
    Navigate(candidate.sign_up);
  };

  const apply = async () => {
    try {
      setLoading(true);
      await axiosInstance.post(`${URLS.requests}/${id}/apply`);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: "You have Successfully applied on this request",
          autoHideDuration: 6000,
        })
      );
      setConfirm((prevState) => !prevState);
      await getRequest();
      setLoading(false);
    } catch (e) {
      console.log(e);
      setConfirm(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "error in applying on this request",
          autoHideDuration: 6000,
        })
      );
      setLoading(false);
    }
  };

  const submitRequest = async () => {
    try {

      setIsLoading(true);
      let payload = { under_review: true };
      await axiosInstance.patch(
        `${URLS.candidate_request_details}${id}/request-candidate`,
        payload
      );
      await axiosInstance.put(`${process.env.REACT_APP_NODE_V3_API_URL}/notification/save`,
        {
          request_id: id,
          type: 'applicant_submitted'
        }
      );
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: "You have Successfully applied on this request",
          autoHideDuration: 3000,
        })
      );
      dispatch(setReload(!reload));
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "error in applying on this request",
          autoHideDuration: 3000,
        })
      );
      setIsLoading(false);
    }
  };

  const handleChange = async (event: SyntheticEvent, newValue: number) => {
    if (!isAuthenticated && newValue !== 0) {
      setOpenPopUp(true);
    } else {
      setValue(newValue);
    }
  };

  const setCountry = useCallback(async () => {
    setOpenModal(false);
    await getRequest();
  }, [getRequest]);

  // useEffect(() => {
  //   if(!loading){
  //     let a=vettingDocuments.find((document) => document.name === identifiers.rtw)
  //     console.log(a)
  //     if(a?.is_british_or_irish_citizen===undefined){
  //       setCurrentStep(tourStep.choose_country.index)
  //     }
  //   }
  // }, [vettingDocuments,loading])

  // useEffect(() => {
  //     if(tourStep.choose_country.index===currentStep){
  //       setIsOpen(true)
  //     }else if(currentStep===tourStep.choose_country.index+tourStep.choose_country.steps){
  //       setIsOpen(false)
  //   }
  //   console.log(currentStep)
  // }, [currentStep])

  const setCoachMarksValue = useCallback(async () => {
    //if (!!user && user["custom:reference_tutorial"] !== "true") {
    const currentUser: CognitoUser = await Amplify.UserDetail();
    const body = {
      "custom:vetting_docs": "true",
    };
    await Amplify.UpdateUserDetails(body, currentUser);
    await Amplify.RefreshSession();
    dispatch(setUserData({ ...user, ...body }));
    //}
  }, [user, dispatch]);

  useEffect(() => {
    if (!loading) {
      let vettingDocument = vettingDocuments.find(
        (document) => document.name === identifiers.rtw
      );
      if (!!user && user["custom:vetting_docs"] !== "true") {
        if (
          !!vettingDocument &&
          vettingDocument?.name === identifiers.rtw &&
          vettingDocument?.is_british_or_irish_citizen !== undefined
        ) {
          setCurrentStep(tourStep.canidate_rtw_tab.index);
        }
      }
    }
  }, [vettingDocuments, loading, user, setCurrentStep]);

  useEffect(() => {
    let vettingDocument = vettingDocuments.find(
      (document) => document.name === identifiers.rtw
    );
    if (tourStep.canidate_rtw_tab.index === currentStep) {
      if (!isTourCompleted('canidate_rtw_tab')) {
        setIsOpen(true);
      }
    } else {
      if (vettingDocument?.is_british_or_irish_citizen === false) {
        if (
          currentStep ===
          tourStep.canidate_rtw_tab.index + tourStep.canidate_rtw_tab.steps
        ) {
          setIsOpen(false);
          markTourAsCompleted('canidate_rtw_tab');
          if (!!user && user["custom:vetting_docs"] !== "true") {
            (async () => {
              await setCoachMarksValue();
            })();
          }
        }
      } else {
        if (
          currentStep ===
          tourStep.canidate_rtw_tab.index +
            (tourStep.canidate_rtw_tab.steps - 3)
        ) {
          setIsOpen(false);
          markTourAsCompleted('canidate_rtw_tab');
          if (!!user && user["custom:vetting_docs"] !== "true") {
            (async () => {
              await setCoachMarksValue();
            })();
          }
        }
      }
    }
  }, [currentStep, setCoachMarksValue, setIsOpen, user, vettingDocuments]);

  const modalText = useMemo(() => {
    return (
      <Box data-tut="candidate_country_pop_up_tab">
        <Citizenship
          vettingDocId={
            vettingDocuments.find(
              (document) => document.name === identifiers.rtw
            )?.id as string
          }
          setCountry={setCountry}
        />
      </Box>
    );
  }, [setCountry, vettingDocuments]);

  const britishIrishCitizenModal = useMemo(() => {
    return (
      <Modal
        open={openModal}
        setModalClose={setOpenModal}
        children={modalText}
        title={undefined}
        size={"sm"}
        className={undefined}
        isBackdrop={true}
      />
    );
  }, [openModal, modalText]);

  const mobileContainer = {
    padding: 0
  }

  return (
    <>
      {britishIrishCitizenModal}
      {openPopUp ? (
        <Grid container sx={{ height: "100vh", position: "relative" }}>
          <img
            alt={"Blur Background"}
            src={images.dummyBackground}
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
          <Grid
            height={"fit-content"}
            minWidth={"600px"}
            item
            className="dotted-card pt-3 pb-3"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              textAlign: "center",
              px: 3,
            }}
          >
            <PrimaryButton
              fullWidth={false}
              sx={{
                marginTop: "12px",
                background: "#5AB9F9",
                color: "#ffffff",
                fontSize: 16,
                fontWeight: 500,
                width: "100%",
                maxWidth: "302px",
                height: "10px",
                boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
                zIndex: 99999,
                mb: 2,
              }}
              onClick={() => {
                // setOpenPoPUp(false);
                navigate();
              }}
            >
              Register
            </PrimaryButton>
            <Typography variant="h6" fontSize={"12px"}>
              Please click on register and create your account to proceed
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Container
          sx={{
            minHeight: {
              xs: "calc(100vh - 124px)",
              md: "calc(100vh - 94px)"
            },
            p: !isAuthenticated ? '2rem': '0 !important',
            mb: 3,
            ...(!matches ? mobileContainer : null),
          }}
          maxWidth={false}
        >
          {!isAuthenticated && <TitleBar />}
          <Grid container spacing={2} className={"page-top-section"}>
            <Grid item md={12} lg={8}>
              <Grid
                container
                sx={{
                  flexDirection: "row",
                  flexWrap: { xs: "nowrap", md: "nowrap" },
                  width: { xs: "88vw", sm: "90vw", md: "90vw", lg: "100%" },
                  overflowX: { xs: "scroll", md: "scroll", lg: "visible" },
                }}
                className={"page-top-section__items"}
                spacing={2}
              >
                <Grid item md={3} mr={2}>
                  <Box
                    sx={{
                      backgroundColor: "#F5FBFF",
                      px: 2,
                      paddingTop: "13px",
                      paddingBottom: "13px",
                      minWidth: { xs: "200px", md: "100px" },
                    }}
                  >
                    <Typography
                      variant={"subtitle1"}
                      gutterBottom={true}
                      fontWeight={400}
                      fontSize={"14px"}
                      color={"#727272"}
                    >
                      Request ID
                    </Typography>
                    <Typography
                      variant={"h6"}
                      fontWeight={500}
                      fontSize={"18px"}
                      letterSpacing={"0.15px"}
                      color={"#212121"}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      title={requestId}
                    >
                      {requestId}
                    </Typography>
                  </Box>
                </Grid>
                {isAuthenticated && (
                  <Grid item md={3} mr={2}>
                    <Box
                      sx={{
                        backgroundColor: "#F5FBFF",
                        px: 2,
                        paddingTop: "13px",
                        paddingBottom: "13px",
                        borderRadius: "6px",
                        whiteSpace: "nowrap",
                        width: "100%",
                        maxWidth: { xs: "200px", md: "unset" },
                        overflowX: "hidden",
                      }}
                    >
                      <Typography
                        variant={"subtitle1"}
                        gutterBottom={true}
                        fontWeight={400}
                        fontSize={"14px"}
                        color={"#727272"}
                      >
                        Hiring Company
                      </Typography>
                      <Typography
                        variant={"h6"}
                        fontWeight={500}
                        fontSize={"18px"}
                        letterSpacing={"0.15px"}
                        color={"#212121"}
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        title={company}
                      >
                        {company}
                      </Typography>
                    </Box>
                  </Grid>
                )}
                <Grid item md={3} mr={2}>
                  <Box
                    sx={{
                      backgroundColor: "#F5FBFF",
                      px: 2,
                      paddingTop: "13px",
                      paddingBottom: "13px",
                      borderRadius: "6px",
                      whiteSpace: "nowrap",
                      width: "100%",
                      maxWidth: { xs: "200px", md: "unset" },
                      overflowX: "hidden",
                    }}
                  >
                    <Typography
                      variant={"subtitle1"}
                      gutterBottom={true}
                      fontWeight={400}
                      fontSize={"14px"}
                      color={"#727272"}
                    >
                      Job Role
                    </Typography>
                    <Typography
                      variant={"h6"}
                      fontWeight={500}
                      fontSize={"18px"}
                      letterSpacing={"0.15px"}
                      color={"#212121"}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      title={designation}
                    >
                      {designation}
                    </Typography>
                  </Box>
                </Grid>
                {date && (
                  <Grid item md={3} mr={2}>
                    <Box
                      sx={{
                        backgroundColor: "#F5FBFF",
                        px: 2,
                        paddingTop: "13px",
                        paddingBottom: "13px",
                        borderRadius: "6px",
                        whiteSpace: "nowrap",
                        width: "100%",
                        maxWidth: { xs: "200px", md: "unset" },
                        minWidth: { xs: "200px", md: "unset" },
                        overflowX: "hidden",
                      }}
                    >
                      <Typography
                        variant={"subtitle1"}
                        gutterBottom={true}
                        fontWeight={400}
                        fontSize={"14px"}
                        color={"#727272"}
                      >
                        Last Date
                      </Typography>
                      <Typography
                        variant={"h6"}
                        fontWeight={500}
                        fontSize={"18px"}
                        letterSpacing={"0.15px"}
                        color={"#212121"}
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        title={date?.format(identifiers.date_format as string)}
                      >
                        {date?.format(identifiers.date_format as string)}
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                position: { xs: "fixed", sm: "fixed", md: "relative" },
                bottom: "0px",
                left: "0px",
                width: "100%",
                zIndex: "99",
                textAlign: { xs: "center", md: "right" },
                background: { xs: "white", md: "transperant" },
                boxShadow: { xs: "0px -4px 7px #e6e6e6", md: "none" },
                borderRadius: { xs: "15px 15px 0px 0px", md: "0" },
              }}
              md={12}
              lg={4}
              className={'top-section__buttons'}
            >
              {userRole !== "candidate" && (
                <PrimaryButton
                  disabled={loading}
                  fullWidth={false}
                  sx={{
                    background: "#5AB9F9",
                    color: "#ffffff",
                    fontSize: 16,
                    fontWeight: 500,
                    width: "100%",
                    maxWidth: "302px",
                    height: "10px",
                    boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
                    zIndex: 99999,
                  }}
                  data-tut="unathorized_request_page"
                  onClick={() => navigate()}
                >
                  Create a KED account to Apply
                </PrimaryButton>
              )}
              {isAuthenticated ? (
                <>
                  {isApplied ? (
                    <>
                      {!underReview ? (
                        <PrimaryButton
                          disabled={isLoading || underReview}
                          fullWidth={false}
                          sx={{
                            background: "#5AB9F9",
                            color: "#ffffff",
                            fontSize: 16,
                            fontWeight: 500,
                            width: "100%",
                            maxWidth: "200px",
                            height: "10px",
                            boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
                          }}
                          onClick={() => submitRequest()}
                        >
                          {isLoading && (
                            <CircularProgress sx={{ mr: 1 }} size={30} />
                          )}{" "}
                          Submit Request
                        </PrimaryButton>
                      ) : null}
                    </>
                  ) : null}
                </>
              ) : null}
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mt: !isAuthenticated ? 3 : 0 }}>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 0 }}>
                <Tabs
                  className={!isAuthenticated ? "tab-container" : "tab-main-con"}
                  value={value}
                  onChange={handleChange}
                  sx={{ boxShadow: "none" }}
                  TabIndicatorProps={{ sx: { display: "none" } }}
                >
                  <Tab
                    data-tut="candidate_vetting_doc_tab"
                    className={!isAuthenticated ? "request-tabs" : ""}
                    label={
                      <span>
                        Vetting, ID & Checks{" "}
                        <img
                          src={
                            value === 0
                              ? images.infoIconActive
                              : images.infoIcon
                          }
                          alt={"info icon"}
                          className="vertical-align-middle"
                        />
                      </span>
                    }
                  />
                  <Tab
                    data-tut="candidate_required_ref_tab"
                    className={!isAuthenticated ? "request-tabs" : ""}
                    disabled={!references || !isApplied || !isAuthenticated}
                    label={
                      <span>
                        Required References{" "}
                        <img
                          src={
                            value === 1
                              ? images.infoIconActive
                              : images.infoIcon
                          }
                          alt={"info icon"}
                          className="vertical-align-middle"
                        />
                      </span>
                    }
                  />
                  <Tab
                    data-tut="candidate_additional_forms_tab"
                    className={!isAuthenticated ? "request-tabs" : ""}
                    disabled={
                      !additionalForms.document ||
                      !isAuthenticated ||
                      !isApplied ||
                      (additionalForms.document &&
                        additionalForms.document.length === 0)
                    }
                    label={
                      <span>
                        Forms and Declarations
                        <img
                          src={
                            value === 2
                              ? images.infoIconActive
                              : images.infoIcon
                          }
                          alt={"info icon"}
                          className="vertical-align-middle"
                        />
                      </span>
                    }
                  />
                  <Tab
                    data-tut="candidate_additional_comments_tab"
                    className={!isAuthenticated ? "request-tabs" : ""}
                    disabled={!comment || !isAuthenticated || !isApplied}
                    label={
                      <span>
                        Notes to Request{" "}
                        <img
                          src={
                            value === 3
                              ? images.infoIconActive
                              : images.infoIcon
                          }
                          alt={"info icon"}
                          className="vertical-align-middle"
                        />
                      </span>
                    }
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                {isAuthenticated ? (
                  <>
                    {isApplied ? (
                      <AuthenticatedVettingDoc />
                    ) : (
                      <>
                        {loading ? (
                          <PageLoader />
                        ) : (
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                              width: "100%",
                              ml: 0,
                            }}
                            className={
                              isAuthenticated && !isApplied
                                ? "bg mb-5 mb-md-0"
                                : ""
                            }
                          >
                            <Grid
                              item
                              xs={10}
                              md={5}
                              textAlign={"center"}
                              sx={{
                                background: "#FFFFFF",
                                border: "1px dashed #5AB9F9",
                                borderRadius: "12px",
                                p: "40px 30px",
                              }}
                            >
                              <Stack alignItems={"center"}>
                                <Typography
                                  variant={"body1"}
                                  color={"#333333"}
                                  sx={{ fontSize: "12px" }}
                                >
                                  We request your consent to share certain
                                  personal data with recruiters, agencies, and
                                  referees involved in the verification process.
                                  This data sharing is crucial for verifying
                                  qualifications, conducting background checks,
                                  and obtaining references to ensure the
                                  integrity of our verification process. To
                                  provide your consent, please check the box
                                  below:
                                </Typography>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      value={confirm}
                                      sx={{ mb: { xs: 2, md: 0 } }}
                                      onChange={() =>
                                        setConfirm(
                                          (prevState: any) => !prevState
                                        )
                                      }
                                    />
                                  }
                                  label={
                                    <Typography
                                      variant={"body1"}
                                      color={"#333333"}
                                      sx={{ fontSize: "12px" }}
                                    >
                                      I hereby confirm that the details I upload
                                      here can be shared with the recruiters,
                                      agencies and referees as described above
                                    </Typography>
                                  }
                                  sx={{ my: 2 }}
                                />
                                <Typography
                                  variant={"body1"}
                                  color={"#333333"}
                                  sx={{ fontSize: "12px" }}
                                >
                                  By checking the box, you acknowledge that you have read and accept our <a href="/privacy_policy" target={"_blank"}  style={{color:'#21bcff'}}>Privacy Policy</a>, and you voluntarily grant us permission to proceed.
                                </Typography>
                                <Typography
                                  variant={"body1"}
                                  color={"#333333"}
                                  sx={{ fontSize: "12px", mt: 2 }}
                                >
                                  Please note that your personal data will be
                                  shared securely and in accordance with
                                  applicable data protection laws
                                </Typography>
                                <PrimaryButton
                                  variant="contained"
                                  disabled={loading || !confirm}
                                  fullWidth={false}
                                  onClick={apply}
                                  sx={{
                                    boxShadow:
                                      "0 10px 25px rgba(90, 185, 249, 0.25)",
                                    borderRadius: "6px",
                                    color: "#ffffff",
                                    fontWeight: 500,
                                    fontSize: "16px",
                                    maxWidth: "200px",
                                    mt: 2,
                                  }}
                                >
                                  {loading && (
                                    <CircularProgress
                                      sx={{ mr: 1 }}
                                      size={20}
                                    />
                                  )}
                                  Start Application
                                </PrimaryButton>
                              </Stack>
                            </Grid>
                          </Grid>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <VettingDocuments
                    isLoading={loading}
                    setOpenPopUp={setOpenPopUp}
                  />
                )}
              </TabPanel>
              <TabPanel value={value} index={1}>
                <RequiredReference />
              </TabPanel>
              <TabPanel value={value} index={2}>
                {isAuthenticated && isApplied && (
                  <AdditionalForms
                    document={additionalForms.document || []}
                    comment={additionalForms.comment}
                    token={additionalForms.token}
                    signed={additionalForms.signed}
                    sign_required={additionalForms.sign_required}
                    envelope_completed={additionalForms.envelope_completed}
                    envelope_id={additionalForms.envelope_id}
                    is_rejected={additionalForms.is_rejected}
                  />
                )}
              </TabPanel>
              <TabPanel value={value} index={3}>
                <AdditionalComments comment={comment} />
              </TabPanel>
            </Box>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default ViewRequest;
