import { FormModel } from '../../../../../../../../../components/Form';
import { Validators } from '../../../../../../../../../utils/validators';
import { name_type } from '../../../../../../../../../utils/constants/constants';
import {
  PersonalHistory,
  PersonalInformation,
} from '../../../../../../../../../utils/redux/reducer/u-check-slice';
import { UCheckValidators } from '../../../../../../../../../utils/validators/uCheckValidators';
import { identifiers } from '../../../../../../../../../utils/constants/identifiers';

export const PersonalHistoryForm2Model: (
  uCheckPersonalInformation: PersonalInformation,
  personalHistory: PersonalHistory,
  count: number,
  disabled?:boolean
) => FormModel[] = (personalInformation, personalHistory, count,disabled) => {
  const form: FormModel[] = [
    {
      label: 'Have you ever been known by any other names ? *',
      value: '',
      size: 'small',
      type: 'drop-down',
      options: [
        { label: 'Yes', value: 'y' },
        { label: 'No', value: 'n' },
      ],
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'has_other_name',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
      ],
      responsive: { md: 12, xs: 12 },
      required: true,
      style: { background: '#FFFFFF', borderRadius: '8px' },
      disabled:disabled
    },
  ];
  for (let i = 0; i < count; ++i) {
    form.push({
      label: 'Other Name type *',
      value: '',
      size: 'small',
      type: 'drop-down',
      options: name_type,
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: `other_name_type_${i + 1}`,
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
      ],
      responsive: { md: 12, xs: 12 },
      required: true,
      style: { background: '#FFFFFF', borderRadius: '8px' },
      disabled:disabled
    });
    if (
      !!personalHistory.other_names[i] &&
      personalHistory.other_names[i].other_name_type
    ) {
      form.push(
        {
          label: `${
            personalHistory.other_names[i].other_name_type === 'surname'
              ? 'Surname'
              : 'Forename'
          }*`,
          value: '',
          size: 'small',
          type: 'text',
          typeValue: 'text',
          variant: 'outlined',
          placeholder: '',
          field: `${personalHistory.other_names[i].other_name_type}_${i + 1}`,
          validators: [
            { check: Validators.required, message: 'This field is mandatory' },
            {
              check: UCheckValidators.Name,
              message: `Other ${
                personalHistory.other_names[i].other_name_type === 'surname'
                  ? 'Surname'
                  : 'Forename'
              } should contain only Uppercase letters, spaces`,
            },
            {
              check: Validators.minCharLimit,
              message: `Other ${
                personalHistory.other_names[i].other_name_type === 'surname'
                  ? 'Surname'
                  : 'Forename'
              } should contain only Uppercase letters, spaces`,
              num: 1,
            },
            {
              check: Validators.maxCharLimit,
              message: `Other ${
                personalHistory.other_names[i].other_name_type === 'surname'
                  ? 'Surname'
                  : 'Forename'
              } should contain only Uppercase letters, spaces`,
              num: 60,
            },
          ],
          responsive: { md: 12, xs: 12 },
          required: true,
          style: { background: '#FFFFFF', borderRadius: '8px' },
          disabled:disabled
        },
        {
          label: `${
            personalHistory.other_names[i].other_name_type === 'surname'
              ? 'Forename'
              : 'Surname'
          } during this period*`,
          value: '',
          size: 'small',
          type: 'text',
          typeValue: 'text',
          variant: 'outlined',
          placeholder: '',
          field: `${
            personalHistory.other_names[i].other_name_type === 'surname'
              ? 'forename'
              : 'surname'
          }_${i + 1}`,
          validators: [
            { check: Validators.required, message: 'This field is mandatory' },
            {
              check: UCheckValidators.Name,
              message: `${
                personalHistory.other_names[i].other_name_type === 'surname'
                  ? 'Forename'
                  : 'Surname'
              } should contain only Uppercase letters, spaces`,
            },
            {
              check: Validators.minCharLimit,
              message: `${
                personalHistory.other_names[i].other_name_type === 'surname'
                  ? 'Forename'
                  : 'Surname'
              } should contain only Uppercase letters, spaces`,
              num: 1,
            },
            {
              check: Validators.maxCharLimit,
              message: `${
                personalHistory.other_names[i].other_name_type === 'surname'
                  ? 'Forename'
                  : 'Surname'
              } should contain only Uppercase letters, spaces`,
              num: 60,
            },
          ],
          responsive: { md: 12, xs: 12 },
          required: true,
          style: { background: '#FFFFFF', borderRadius: '8px' },
          disabled:disabled
        },
        {
          label: 'Used From*',
          value: '',
          size: 'small',
          type: 'date',
          typeValue: 'text',
          variant: 'outlined',
          placeholder: '',
          disableFuture: true,
          minDate: new Date(personalInformation.date_of_birth),
          inputFormat: identifiers.month_format as string,
          openTo: 'year',
          views: ['year', 'month'],
          field: `used_from_${i + 1}`,
          validators: [
            { check: Validators.required, message: 'This field is mandatory' },
          ],
          responsive: { md: 6, xs: 12 },
          required: true,
          style: { background: '#FFFFFF', borderRadius: '8px' },
          disabled:disabled
        },
        {
          label: 'Used To*',
          value: '',
          size: 'small',
          type: 'date',
          typeValue: 'text',
          variant: 'outlined',
          placeholder: '',
          disableFuture: true,
          minDate: new Date(personalHistory.other_names[i].used_from),
          inputFormat: identifiers.month_format as string,
          openTo: 'year',
          views: ['year', 'month'],
          field: `used_to_${i + 1}`,
          validators: [
            { check: Validators.required, message: 'This field is mandatory' },
          ],
          responsive: { md: 6, xs: 12 },
          required: true,
          style: { background: '#FFFFFF', borderRadius: '8px' },
          disabled:disabled
        }
      );
    }
  }
  return form;
};
