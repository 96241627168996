
import React from 'react';
import { Stack, Box, Typography } from '@mui/material';

interface CustomComponentProps {
    title: string;
  }

const BulletPoint : React.FC<CustomComponentProps> = ({
    title
  }) =>  {
  return (
    <Stack direction={'row'} gap={'1rem'} alignItems={'center'}>
      <Box
        sx={{
          width: '0.25rem',
          height: '0.25rem',
          borderRadius: '0.5rem',
          backgroundColor: '#00B2FF',
        }}
      ></Box>
      <Typography
        sx={{
          fontSize: '0.875rem',
          lineHeight: 'normal',
          color:'#5E5E5E',
        }}
      >
        {title}
      </Typography>
    </Stack>
  );
};

export default BulletPoint;
