const SampleKedQuestionnaire = (
  type: 'employment' | 'character' | 'academic'
): {
  [key: string]: {
    label: string;
    value: string | boolean | number;
  };
} => {
  if (type === 'employment') {
    return {
      main_duties_responsibility: {
        label: 'Applicant’s main duties and responsibilities',
        value:
          'Consulting with clients to understand their goals, Explaining user research results to internal and external stakeholders, Developing personas and usage scenarios, Conducting usability testing',
      },
      relevant_skills: {
        label:
          'Relevant skills and experience gained by the applicant at the employment',
        value:
          'Negotiation skills, time management, stakeholder management, design skulls',
      },
      training: {
        label: 'Training received by the applicant whilst in employment',
        value:
          'Attended multiple conferences, participated in educational workshops',
      },
      rating_relationship_with_colleagues: {
        label: 'Relationship with colleagues',
        value: 4,
      },
      rating_relationship_with_seniors: {
        label: 'Relationship with seniors',
        value: 5,
      },
      rating_attendance_record: {
        label: 'Attendance Record',
        value: 5,
      },
      rating_punctuality: {
        label: 'Punctuality / timekeeping',
        value: 5,
      },
      rating_quality_of_work: {
        label: 'Quality of work',
        value: 4,
      },
      rating_honesty: {
        label: 'Honesty & Integrity',
        value: 5,
      },
      rating_reliability: {
        label: 'Reliability',
        value: 5,
      },
      rating_flexibility: {
        label: 'Flexibility',
        value: 5,
      },
      reemployment_policy: {
        label: 'Do you have a re-employment policy?',
        value: true,
      },
      reemployment_yes: {
        label: 'If Yes, would you rehire this candidate?',
        value: false,
      },
      rehire_no: {
        label: 'If No, please give reasons',
        value: 'Applicant expectation for salary is way too high',
      },
    };
  } else if (type === 'character') {
    return {
      referee_candidate_time_known: {
        label: 'How long have you known the Applicant?',
        value: 'I have known the candidate since past 2 years',
      },
      referee_candidate_relationship_outline: {
        label:
          'Please outline below the relationship you have, or had, to the Applicant',
        value: "I used to be the candidate's therapist",
      },
      candidate_character: {
        label: 'How would you describe Applicants character?',
        value:
          'The Applicant used to show tensed behaviors many times, which now has been overcome',
      },
      further_information: {
        label:
          'Please provide any further information here including, if possible, the main areas of responsibility and achievements to date along with any skills or potential areas of weakness you have noted.',
        value: 'N/A',
      },
    };
  } else {
    return {
      referee_candidate_time_known: {
        label: 'How long have you known the Applicant?',
        value: 'I have known the candidate since past 7 years',
      },
      candidate_assessment: {
        label:
          'According to you, please comment on the following: 1) ability to carry on advanced study and research; 2) teaching ability; 3) promise for a successful career in this field; 4) weaknesses, if any; and 5) communication skills (oral and written). Indicate the basis of your general assessment.',
        value:
          "The candidate has a very high potential to carry on advanced study and research, however the candidate doesn't have great teaching skills",
      },
      rating_past_academic_achievement: {
        label: 'Past Academic Achievement',
        value: 3,
      },
      rating_scholarly_promise: {
        label: 'Scholarly promise',
        value: 3,
      },
      rating_independent_research_capability: {
        label: 'Independent research / capabilities',
        value: 2,
      },
      rating_written_english_proficiency: {
        label: 'English proficiency - written',
        value: 3,
      },
      rating_oral_english_proficiency: {
        label: 'English proficiency - oral',
        value: 5,
      },
      rating_creativity: {
        label: 'Creativity',
        value: 2,
      },
      rating_resourcefulness: {
        label: 'Resourcefulness',
        value: 3,
      },
      rating_ability_to_meet_deadlines: {
        label: 'Ability to meet deadlines',
        value: 2,
      },
      rating_overall: {
        label: 'Overall, I would rate this student as',
        value: 5,
      },
    };
  }
};

export default SampleKedQuestionnaire;
