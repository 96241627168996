export const URLS: { [key: string]: string } = {
  authenticated: '/authenticated/',
  requests: '/authenticated/request',
  team_members: '/authenticated/users',
  file_upload: '/authenticated/file/upload',
  candidate_file_upload: '/authenticated/candidate/file/upload',
  referee_file_upload: '/api/referee/file/upload',
  referee_file_download: '/api/referee/request',
  document: '/authenticated/document',
  public_requests: '/api/request',
  candidate_request: '/authenticated/request-candidate',
  reference_request: '/authenticated/agency/reference-request',
  agency_refrence: '/authenticated/agency/reference',
  approve_reference: '/authenticated/agency/references/referee/',
  candidate_requests: '/authenticated/requests',
  agency_candidates: '/authenticated/agency/candidates',
  agency_candidate: '/authenticated/agency/candidate',
  link_document: '/authenticated/request/link-document',
  unlink_document: '/authenticated/request/unlink-document',
  document_categories: '/authenticated/category',
  reference_forms: '/authenticated/agency/reference-forms',
  additional_questionnaire: '/authenticated/agency/additional-questionnaire',
  agency_request: '/authenticated/agency/request/',
  candidate_request_details: '/authenticated/candidate/request/',
  candidate_reference: '/authenticated/reference',
  referee_reference: '/api/references/referee',
  get_referee_details: '/api/referee/references',
  yoti_rtw: `/authenticated/candidate/yoti/${process.env.REACT_APP_YOTI_ENV}`,
  static_web_url: 'https://ked.io/',
  notification: '/authenticated/user/notification',
  notification_count: '/authenticated/user/notification/count',
  candidate_academic: '/authenticated/candidate/academic-qualification/',
  download_vetting_documents: `/${process.env.REACT_APP_ENV_NAME}/authenticated/agency/candidate/vetting-document/download`,
  download_candidate_documents: `/${process.env.REACT_APP_ENV_NAME}/authenticated/agency/request/`,
  download_candidate_referee_documents: `/${process.env.REACT_APP_ENV_NAME}/authenticated/referee/`,
  download_referee_documents: `/${process.env.REACT_APP_ENV_NAME}/api/referee/`,
  verify_reference: '/api/ots/verify',
  submit_issue: '/authenticated/issue/submit',
};
