import React, { FC, useState } from "react";
import {
  Grid,
  Typography,
  AccordionSummaryProps,
  AccordionProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import PrimaryButton from "../../../../../../components/Button/PrimaryButton";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AdditionalQuestionnaire from "../AdditionalQuestionnaire";
import KedQuestionnaire from "../KedQuestionnaire";
import { Box } from "@mui/system";
import ReferenceForm from "../ReferenceForm";
import { Reference } from "../../../../../Common/ReferenceList";
import UploadReferenceForm from "../UploadedReferenceForm";
import { URLS } from "../../../../../../utils/constants/urls";
import { useDispatch, useSelector } from "react-redux";
import {
  setNotificationMessage,
  UserRole,
} from "../../../../../../utils/redux";
import PageLoader from "../../../../../../components/PageLoader";
import axiosInstance from "../../../../../../utils/axios";

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(() => ({
  padding: "0px 5px 0px 0px",
  height: "57px",
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    {...props}
    disableGutters
    elevation={0}
    square
    children={props.children}
  />
))(() => ({
  border: 0,
  marginBottom: "40px",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "none",
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: "20px 0px",
}));

const ViewReference: FC<{ type: string; reference: Reference }> = ({
  type,
  reference,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const userRole = useSelector(UserRole);
  const downloadDocuments = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `${URLS.document}/${reference.id}?type=pdf`
      );
      window.open(data.url, "_blank");
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "Unable to download candidate documents",
        })
      );
    }
  };

  return (
    <Grid container display={"flex"} justifyContent={"center"}>
      {loading && <PageLoader />}
      {userRole === "agency" && reference.is_approved && (
        <Grid
          item
          md={12}
          display={"flex"}
          justifyContent={"flex-end"}
          pr={2}
          pb={3}
        >
          <PrimaryButton
            fullWidth={false}
            sx={{ color: "white" }}
            onClick={downloadDocuments}
          >
            Download Section 1 to 3
          </PrimaryButton>
        </Grid>
      )}
      <Grid item xs={11} className={"view-reference"}>
        <Accordion sx={{ background: "#F5FBFF" }}>
          <AccordionSummary
            sx={{
              boxShadow: "0px 4px 10px rgba(120, 144, 156, 0.1)",
              borderRadius: "8px",
              border: "1px solid #E8EBFE",
              background: "white",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <PrimaryButton
                fullWidth={false}
                sx={{
                  background: "#5AB9F9",
                  borderRadius: "5px",
                  padding: "14px 12px",
                  color: "white",
                  fontSize: "16px",
                  minWidth: "100px",
                  maxHeight: "57px",
                }}
              >
                Section I
              </PrimaryButton>
              <Typography
                sx={{
                  color: "#6279F7",
                  fontWeight: 500,
                  fontSize: "16px",
                  m: 2,
                }}
              >
                Referee Details
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <ReferenceForm formValues={reference.referee_details} type={type} gap_reference={reference.is_gap_reference} />
          </AccordionDetails>
        </Accordion>
        {!!reference.ked_questionnaire_value && (
          <Accordion sx={{ background: "#F5FBFF" }}>
            <AccordionSummary
              sx={{
                boxShadow: "0px 4px 10px rgba(120, 144, 156, 0.1)",
                borderRadius: "8px",
                border: "1px solid #E8EBFE",
                background: "white",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <PrimaryButton
                  fullWidth={false}
                  sx={{
                    background: "#6279F7",
                    borderRadius: "5px",
                    padding: "14px 12px",
                    color: "white",
                    fontSize: "16px",
                    minWidth: "100px",
                    maxHeight: "57px",
                  }}
                >
                  Section II
                </PrimaryButton>
                <Typography
                  sx={{
                    color: "#6279F7",
                    fontWeight: 500,
                    fontSize: "16px",
                    m: 2,
                  }}
                >
                  KED Questionnaire
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ background: "#F5FBFF" }}>
              <KedQuestionnaire
                type={reference.type as "employment" | "character" | "academic"}
                ked_questionnaire_value={reference.ked_questionnaire_value}
              />
            </AccordionDetails>
          </Accordion>
        )}
        {!!reference.additional_questionnaire_value &&
          reference.additional_questionnaire_value.length > 0 && (
            <Accordion sx={{ background: "#F5FBFF" }}>
              <AccordionSummary
                sx={{
                  boxShadow: "0px 4px 10px rgba(120, 144, 156, 0.1)",
                  borderRadius: "8px",
                  border: "1px solid #E8EBFE",
                  background: "white",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <PrimaryButton
                    fullWidth={false}
                    sx={{
                      background: "#45C9D0",
                      borderRadius: "5px",
                      padding: "14px 12px",
                      color: "white",
                      fontSize: "16px",
                      minWidth: "100px",
                      maxHeight: "57px",
                    }}
                  >
                    Section III
                  </PrimaryButton>
                  <Typography
                    sx={{
                      color: "#6279F7",
                      fontWeight: 500,
                      fontSize: "16px",
                      m: 2,
                    }}
                  >
                    Additional Question
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ background: "#F5FBFF" }}>
                <AdditionalQuestionnaire
                  additional_questionnaire_value={
                    reference.additional_questionnaire_value
                  }
                />
              </AccordionDetails>
            </Accordion>
          )}
        {reference?.referee_documents && (
          <Accordion sx={{ background: "#F5FBFF" }}>
            <AccordionSummary
              sx={{
                boxShadow: "0px 4px 10px rgba(120, 144, 156, 0.1)",
                borderRadius: "8px",
                border: "1px solid #E8EBFE",
                background: "white",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <PrimaryButton
                  fullWidth={false}
                  sx={{
                    background: "#6279F7",
                    borderRadius: "5px",
                    padding: "14px 12px",
                    color: "white",
                    fontSize: "16px",
                    minWidth: "100px",
                    maxHeight: "57px",
                  }}
                >
                  Section IV
                </PrimaryButton>
                <Typography
                  sx={{
                    color: "#6279F7",
                    fontWeight: 500,
                    fontSize: "16px",
                    m: 2,
                  }}
                >
                  Uploaded Reference Forms
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ background: "#F5FBFF" }}>
              <UploadReferenceForm reference={reference} />
            </AccordionDetails>
          </Accordion>
        )}
      </Grid>
    </Grid>
  );
};

export default ViewReference;
