import {
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserSession,
  ISignUpResult,
} from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

export const Login = (body: {
  email: string;
  password: string;
}): Promise<CognitoUser | any> => {
  return Auth.signIn(body.email, body.password);
};

export const Register = (body: {
  state: string;
  country: string;
  password: string;
  email: string;
  family_name: string;
  given_name: string;
  code: string;
  phone_number: string;
  postcode: string;
  confirm_password: string;
}): Promise<ISignUpResult> => {
  return Auth.signUp({
    username: body.email,
    password: body.password,
    attributes: {
      given_name: body.given_name,
      family_name: body.family_name,
      name: `${body.given_name} ${body.family_name}`,
      'custom:role': 'candidate',
      'custom:phone_code': body.code,
      address: body.state + ', ' + body.country + ' - ' + body.postcode,
      email: body.email,
      phone_number: body.code + body.phone_number,
    },
  });
};

export const Logout = (opts?: { global: boolean }): Promise<any> => {
  return Auth.signOut(opts);
};

export const ForgotPassword = (username: string): Promise<any> => {
  return Auth.forgotPassword(username);
};

export const ResendConfirmationCode = (body: {
  email: string;
}): Promise<any> => {
  return Auth.resendSignUp(body.email);
};

export const ConfirmUser = (
  verificationCode: string,
  body: { email: string }
): Promise<any> => {
  return Auth.confirmSignUp(body.email, verificationCode);
};

export const ChangePassword = (
  body: { new_password: string; sessionAttributes: CognitoUserAttribute },
  user: CognitoUser
): Promise<CognitoUserSession> => {
  return Auth.completeNewPassword(
    user,
    body.new_password,
    body.sessionAttributes
  );
};

export const ForgotPasswordSubmit = (
  username: string,
  password: string,
  code: string
): Promise<any> => {
  return Auth.forgotPasswordSubmit(username, code, password);
};

export const UserDetail = (): Promise<any> => {
  return Auth.currentAuthenticatedUser();
};

export const UpdateUserDetails = (
  body: any,
  user: CognitoUser
): Promise<any> => {
  return Auth.updateUserAttributes(user, body);
};

export const VerifyAttributeUpdate = (
  attribute: string,
  code: string
): Promise<string> => {
  return Auth.verifyCurrentUserAttributeSubmit(attribute, code);
};

export const SendVerificationCode = (attribute: string): Promise<void> => {
  return Auth.verifyCurrentUserAttribute(attribute);
};

export const resendConfirmationCode = (username: string): Promise<any> => {
  return Auth.resendSignUp(username);
};

export const currentSession = (): Promise<CognitoUserSession> => {
  return Auth.currentSession();
};

export const IsAuthenticated = (): Promise<any> => {
  return Auth.currentUserInfo();
};

export const RefreshSession = async () => {
  const cognitoUser = await Auth.currentAuthenticatedUser({
    bypassCache: true,
  });
  const currentSession = await Auth.currentSession();
  await cognitoUser.refreshSession(currentSession.getRefreshToken(), () => {});
};
