import React, {FC, useEffect, useState, useMemo} from "react";
import {
  Grid,
  Typography,
  AccordionSummaryProps,
  AccordionProps,
  TableCell,
  styled,
  tableCellClasses,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
} from "@mui/material";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import PrimaryButton from "../../../../../../../components/Button/PrimaryButton";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Box, Stack} from "@mui/system";
import ReferenceForm from "../../../../CandidateProfile/RequiredReference/ReferenceForm";
import KedQuestionnaire from "../../../../CandidateProfile/RequiredReference/KedQuestionnaire";
import AdditionalQuestionnaire from "../../../../CandidateProfile/RequiredReference/AdditionalQuestionnaire";
import {identifiers} from "../../../../../../../utils/constants/identifiers";
import dayjs from "dayjs";
import {images} from "../../../../../../../utils/constants/images";
import {
  PreviewReference,
  setNotificationMessage,
} from "../../../../../../../utils/redux";
import {useDispatch, useSelector} from "react-redux";
import axiosInstance from "../../../../../../../utils/axios";
import {Document, Page} from "react-pdf";
import {URLS} from "../../../../../../../utils/constants/urls";
import Modal from "../../../../../../../components/modal/Modal";
import SampleRefereeDetails from "./SampleRefereeDetails";
import SampleKedQuestionnaire from "./SampleKedQuestionnaire";
import PageLoader from "../../../../../../../components/PageLoader";

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon/>} {...props} />
))(() => ({
  padding: "0px 5px 0px 0px",
  height: "57px",
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    {...props}
    disableGutters
    elevation={0}
    square
    children={props.children}
  />
))(() => ({
  border: 0,
  marginBottom: "40px",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "none",
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: theme.spacing(2),
  margin: "20px 0px",
}));

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f5fbff",
    color: "#727272",
    border: "none",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#263238",
    fontSize: "14px",
    fontWeight: 500,
    padding: "5px 16px",
  },
  [`&.${tableCellClasses.head}:first-of-type`]: {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
  },
  [`&.${tableCellClasses.head}:last-of-type`]: {
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    width: "14rem",
    textAlign: "start",
    paddingLeft: "35px",
  },
  [`&.${tableCellClasses.body}:first-of-type`]: {
    borderTopLeftRadius: "12px",
    borderBottomLeftRadius: "12px",
    borderLeft: "1px solid #DADADA",
  },
  [`&.${tableCellClasses.body}:last-of-type`]: {
    borderTopRightRadius: "12px",
    borderBottomRightRadius: "12px",
    borderRight: "1px solid #DADADA",
    textAlign: "center",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&": {
    borderRadius: "8px",
    borderTop: "1px solid #DADADA",
    backgroundColor: "white",
  },
}));

const ViewReference: FC<{ type: "employment" | "character" | "academic" }> = ({
                                                                                type,
                                                                              }) => {
  const dispatch = useDispatch();
  const previewReference = useSelector(PreviewReference);

  const [refIndex, setRefIndex] = useState<number>(0);
  const [documentType, setDocumentType] = useState<"pdf" | "image">();
  const [documentUrl, setDocumentUrl] = useState<string>();
  const [viewDocument, setViewDocument] = useState(false);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (previewReference) {
      const index = previewReference.findIndex(
        (request_reference: any) => request_reference.type === type
      );
      setRefIndex(index);
    }
  }, [previewReference, type]);

  const download = async (id: string) => {
    setLoading(true);
    try {
      const {data} = await axiosInstance.get(
        `${URLS.document}/${id}?type=reference`
      );
      const type =
        data.mimetype.indexOf("pdf") > -1
          ? "pdf"
          : data.mimetype.indexOf("image") > -1
            ? "image"
            : null;
      if (!!type) {
        setDocumentType(type);
        setDocumentUrl(data.url);
        setViewDocument(true);
      } else {
        window.open(data.url, "_blank");
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in downloading document",
        })
      );
    }
  };

  const viewModalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          {documentType === "pdf" && (
            <Document
              file={{url: documentUrl}}
              onLoadSuccess={({numPages}) => setNumPages(numPages)}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1}/>
              ))}
            </Document>
          )}
          {documentType === "image" && (
            <img src={documentUrl} alt={"document"} className={"img-fluid"}/>
          )}
        </Grid>
      </Grid>
    );
  }, [documentType, documentUrl, numPages]);

  const openViewModal = useMemo(() => {
    return (
      <Modal
        open={viewDocument}
        setModalClose={setViewDocument}
        children={viewModalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [viewDocument, viewModalText]);

  const additionalQuestionnaireValue = () => {
    return previewReference[refIndex].additional_questionnaires.map(
      (additional_questionnaire) => {
        const values: any = {};
        additional_questionnaire.form_data.forEach((question) => {
          if (question.type === "number") {
            values[question.key] = 9874392832;
          }
          if (question.type === "textfield" || question.type === "textarea") {
            values[question.key] =
              "<The Referee will provide their answers here.>";
          }
          if (question.type === "email") {
            values[question.key] = "tim@ked.io";
          }
          if (question.type === "select") {
            values[question.key] = question.data.values[0].value;
          }
          if (question.type === "checkbox") {
            values[question.key] = true;
          }
          if (question.type === "radio") {
            values[question.key] = question.values[0].value;
          }
          if (question.type === "datetime") {
            values[question.key] = new Date();
          }
          if (question.type === "phoneNumber") {
            values[question.key] = "9211420420";
          }
          if (question.type === "selectboxes") {
            values[question.key] = {};
            question.values.forEach(
              (value: { label: string; value: string }) => {
                values[question.key][value.value] = true;
              }
            );
          }
          if (question.type === "survey") {
            values[question.key] = {};
            question.questions.forEach((question_value: { label: string; value: string }) => {
              values[question.key][question_value.value] =
                question.values[0].value;
            });
          }
        });
        return {
          ...additional_questionnaire,
          values,
        };
      }
    );
  };

  return (
    <>
      {loading && <PageLoader/>}
      {openViewModal}
      <Grid container display={"flex"} justifyContent={"center"}>
        <Grid item xs={12} className={"view-reference"}>
          <Accordion sx={{background: "#F5FBFF"}}>
            <AccordionSummary
              sx={{
                boxShadow: "0px 4px 10px rgba(120, 144, 156, 0.1)",
                borderRadius: "8px",
                border: "1px solid #E8EBFE",
                background: "white",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <PrimaryButton
                  fullWidth={false}
                  sx={{
                    background: "#5AB9F9",
                    borderRadius: "5px",
                    padding: "14px 12px",
                    color: "white",
                    fontSize: "16px",
                    minWidth: "100px",
                    maxHeight: "57px",
                  }}
                >
                  Section I
                </PrimaryButton>
                <Stack direction={"column"} ml={2}>
                  <Typography
                    sx={{
                      color: "#45C9D0",
                      fontWeight: 500,
                      fontSize: {xs: "12px", md: "16px"},
                      m: {xs: "5px", md: 1},
                    }}
                  >
                    Referee Details
                  </Typography>
                  {/* <Typography
                                  sx={{
                                    color: "#000000",
                                    fontWeight: 300,
                                    fontSize: { xs: "12px", md: "12px" },
                                    mx: { xs: '5px', md: 1 },
                                    display:{xs:'none',md:'inline-block'},
                                    mb:1,
                                  }}
                                >
                                    Upload your own employment reference form for referee to complete.
                                </Typography> */}
                </Stack>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <ReferenceForm
                formValues={SampleRefereeDetails(type)}
                type={type}
              />
            </AccordionDetails>
          </Accordion>
          {previewReference[refIndex].ked_questionnaire && (
            <Accordion sx={{background: "#F5FBFF"}}>
              <AccordionSummary
                sx={{
                  boxShadow: "0px 4px 10px rgba(120, 144, 156, 0.1)",
                  borderRadius: "8px",
                  border: "1px solid #E8EBFE",
                  background: "white",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <PrimaryButton
                    fullWidth={false}
                    sx={{
                      background: "#6279F7",
                      borderRadius: "5px",
                      padding: "14px 12px",
                      color: "white",
                      fontSize: "16px",
                      minWidth: "100px",
                      maxHeight: "57px",
                    }}
                  >
                    Section II
                  </PrimaryButton>
                  <Stack direction={"column"} ml={2}>
                    <Typography
                      sx={{
                        color: "#45C9D0",
                        fontWeight: 500,
                        fontSize: {xs: "12px", md: "16px"},
                        m: {xs: "5px", md: 1},
                      }}
                    >
                      KED Questionnaire
                    </Typography>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontWeight: 300,
                        fontSize: {xs: "12px", md: "12px"},
                        mx: {xs: "5px", md: 1},
                        mb: 1,
                        display: {xs: "none", md: "inline-block"},
                      }}
                    >
                      If selected, Referee will be asked questions as per KED
                      standardized questionnaire
                    </Typography>
                  </Stack>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{background: "#F5FBFF"}}>
                <KedQuestionnaire
                  type={type}
                  ked_questionnaire_value={SampleKedQuestionnaire(type)}
                />
              </AccordionDetails>
            </Accordion>
          )}
          {!!previewReference[refIndex].additional_questionnaires &&
            previewReference[refIndex].additional_questionnaires.length > 0 && (
              <Accordion sx={{background: "#F5FBFF"}}>
                <AccordionSummary
                  sx={{
                    boxShadow: "0px 4px 10px rgba(120, 144, 156, 0.1)",
                    borderRadius: "8px",
                    border: "1px solid #E8EBFE",
                    background: "white",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <PrimaryButton
                      fullWidth={false}
                      sx={{
                        background: "#45C9D0",
                        borderRadius: "5px",
                        padding: "14px 12px",
                        color: "white",
                        fontSize: "16px",
                        minWidth: "100px",
                        maxHeight: "57px",
                      }}
                    >
                      Section III
                    </PrimaryButton>
                    <Stack direction={"column"} ml={2}>
                      <Typography
                        sx={{
                          color: "#45C9D0",
                          fontWeight: 500,
                          fontSize: {xs: "12px", md: "16px"},
                          m: {xs: "5px", md: 1},
                        }}
                      >
                        Additional Question
                      </Typography>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontWeight: 300,
                          fontSize: {xs: "12px", md: "12px"},
                          mx: {xs: "5px", md: 1},
                          display: {xs: "none", md: "inline-block"},
                          mb: 1,
                        }}
                      >
                        Create your own questions on this digital platform.
                      </Typography>
                    </Stack>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{background: "#F5FBFF"}}>
                  <AdditionalQuestionnaire
                    additional_questionnaire_value={additionalQuestionnaireValue()}
                  />
                </AccordionDetails>
              </Accordion>
            )}
          {!!previewReference[refIndex].reference_forms &&
            previewReference[refIndex].reference_forms.length > 0 && (
              <Accordion sx={{background: "#F5FBFF"}}>
                <AccordionSummary
                  sx={{
                    boxShadow: "0px 4px 10px rgba(120, 144, 156, 0.1)",
                    borderRadius: "8px",
                    border: "1px solid #E8EBFE",
                    background: "white",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <PrimaryButton
                      fullWidth={false}
                      sx={{
                        background: "#6279F7",
                        borderRadius: "5px",
                        padding: "14px 12px",
                        color: "white",
                        fontSize: "16px",
                        minWidth: "100px",
                        maxHeight: "57px",
                      }}
                    >
                      Section IV
                    </PrimaryButton>
                    <Stack direction={"column"} ml={2}>
                      <Typography
                        sx={{
                          color: "#45C9D0",
                          fontWeight: 500,
                          fontSize: {xs: "12px", md: "16px"},
                          m: {xs: "5px", md: 1},
                        }}
                      >
                        Upload your own reference form
                      </Typography>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontWeight: 300,
                          fontSize: {xs: "12px", md: "12px"},
                          mx: {xs: "5px", md: 1},
                          display: {xs: "none", md: "inline-block"},
                          mb: 1,
                        }}
                      >
                        Upload your own employment reference form for referee to
                        complete.
                      </Typography>
                    </Stack>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{background: "#F5FBFF"}}>
                  <TableContainer
                    component={Paper}
                    sx={{boxShadow: "none", width: "100%"}}
                  >
                    <Table
                      sx={{
                        minWidth: 700,
                        borderSpacing: "0 1rem",
                        borderCollapse: "separate",
                        backgroundColor: "#f5fbff",
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell sx={{minWidth: "150px"}}>
                            Document Name
                          </StyledTableCell>

                          <StyledTableCell sx={{minWidth: "111px"}}>
                            Upload Date
                          </StyledTableCell>
                          <StyledTableCell>Action</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={"documents-table-body"}>
                        {!!previewReference[refIndex].reference_forms &&
                          previewReference[refIndex].reference_forms.map(
                            (reference: any, index: number) => (
                              <>
                                <StyledTableRow
                                  className={"documents-table-body-row"}
                                  key={index}
                                >
                                  <StyledTableCell>
                                    {reference.name}
                                  </StyledTableCell>

                                  <StyledTableCell
                                    sx={{position: "relative"}}
                                  >
                                    {dayjs(
                                      new Date(reference.created_at)
                                    ).format(identifiers.date_format as string)}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <Grid
                                      item
                                      display={"flex"}
                                      flexWrap={"nowrap"}
                                      md={6}
                                    >
                                      <PrimaryButton
                                        onClick={() => download(reference.id)}
                                        sx={{
                                          cursor: "pointer",
                                        }}
                                        variant={"text"}
                                        fullWidth={false}
                                      >
                                        <img
                                          src={images.viewIcon}
                                          alt="view"
                                          className="action-icons"
                                        />
                                      </PrimaryButton>
                                    </Grid>
                                  </StyledTableCell>
                                </StyledTableRow>
                              </>
                            )
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            )}
        </Grid>
      </Grid>
    </>
  );
};

export default ViewReference;
