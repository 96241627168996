import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Authenticated, ProfileComplete } from "../../utils/redux";
import { Navigate } from "react-router-dom";
import { agency } from "../../utils/constants/routes";
import AuthenticationLayout from "../AuthenticationLayout";
import ProfileCompletion from "../../screens/Authentication/ProfileCompletion";

const AgencyProfileCompletionRoute: FC = () => {
  const isAuthenticated = useSelector(Authenticated);
  const isProfileComplete = useSelector(ProfileComplete);

  return isAuthenticated ? (
    isProfileComplete ? (
      <Navigate to={agency.dashboard} />
    ) : (
      <AuthenticationLayout role={"agency"} Component={ProfileCompletion} />
    )
  ) : (
    <Navigate to={agency.login} />
  );
};

export default AgencyProfileCompletionRoute;
