import React from "react";
import {
  Stack,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from '@mui/icons-material/Add';
import InputField from "../../../../../components/InputField";
import PrimaryButton from "../../../../../components/Button/PrimaryButton";
import { AgencyHeaderProps } from "../Interfaces/AgencyInterfaces";

const AgencyHeader: React.FC<AgencyHeaderProps> = ({ onSearch, searchQuery, onOpenCreateAgency, }) => {
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    onSearch(query);
  };

  return (
    <Stack
      padding={'1rem'}
      gap={'0.5remrem'}
      flexDirection={{
        XS: 'column',
        md: 'row'
      }}
      justifyContent={'space-between'}>
      <InputField
        sx={{ alignItems: 'center', width: '18.75rem' }}
        placeholder="Search by agency name"
        InputProps={{ endAdornment: <SearchIcon /> }}
        value={searchQuery}
        onChange={handleSearchChange}
      />
      <PrimaryButton
        fullWidth={false}
        sx={{
          background: "#5AB9F9",
          color: "#ffffff",
          fontSize: 16,
          fontWeight: 500,
          flexWrap: 'wrap',
          height: '10px',
          boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
          '@media (max-width: 600px)': {
            width: '85%',
          },
        }}
        startIcon={<AddIcon />}
        onClick={onOpenCreateAgency}
      >
        CREATE NEW AGENCY
      </PrimaryButton>
    </Stack>
  );
};

export default AgencyHeader;
