import React, { FC, useCallback, useMemo, useState } from "react";
import { Grid, Typography, Box, Stack, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  setNotificationMessage,
  setCandidateProfileReload,
  CandidateProfileReload,
  AcademicData,
} from "../../../../../utils/redux";
import axiosInstance from "../../../../../utils/axios";
import { URLS } from "../../../../../utils/constants/urls";
import PrimaryButton from "../../../../../components/Button/PrimaryButton";
import InputField from "../../../../../components/InputField";
import Modal from "../../../../../components/modal/Modal";

const RightSideFrame: FC<{
  token: string;
  signed: boolean;
  sign_required: boolean;
  has_candidate_signed: boolean;
  has_co_signed: boolean;
  is_candidate_sign_required: boolean;
  is_co_sign_required: boolean;
  envelope_id?: string;
  is_rejected: boolean;
}> = ({
  token,
  has_candidate_signed,
  has_co_signed,
  is_candidate_sign_required,
  is_co_sign_required,
  envelope_id,
  is_rejected,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams() as any;
  const ProfileData = useSelector(AcademicData);
  const CandidateProfile = useSelector(CandidateProfileReload);
  const [loading, setLoading] = useState<boolean>(false);
  const [report, setReport] = useState(false);
  const [inputValues, setInputValues] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const createEnvelope = async () => {
    try {
      setLoading(true);
      await axiosInstance.post(
        `${URLS.requests}/${id}/additional-forms/create-envelope?stage=${process.env.REACT_APP_YOTI_ENV}&webhook_base_url=${process.env.REACT_APP_BASE_API_URL}`
      );
      setLoading(false);
      dispatch(setCandidateProfileReload(!CandidateProfile));
    } catch (e) {
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in creating envelope",
        })
      );
      setLoading(false);
    }
  };

  const reportReference = useCallback(async () => {
    setIsLoading(true);
    try {
      let payload = {
        comment: inputValues,
      };

      await axiosInstance.post(
        `${URLS.authenticated}envelope/${envelope_id}/reject`,
        payload
      );
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: "Additional Form rejected  successfully",
        })
      );
      setInputValues("");
      setReport(false);
      setIsLoading(false);
      dispatch(setCandidateProfileReload(!CandidateProfile));
    } catch (e) {
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in rejecting the Additiona Form",
        })
      );
      setReport(false);
      setIsLoading(false);
    }
  }, [inputValues, envelope_id, dispatch, CandidateProfile]);

  const reportModalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          <Typography
            variant="h6"
            sx={{
              marginBottom: "20px",
              fontSize: 18,
              fontWeight: 500,
              color: "#000000",
              textAlign: "center",
              letterSpacing: "0.15px",
            }}
          >
            Report Additional Form?
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontSize: 16,
              fontWeight: 500,
              color: "#727272",
            }}
          >
            Additional Form will be rejected and Candidate will have to create a
            new Additional Form.
            <br />
            Are you sure?
          </Typography>
          <Grid md={12} container>
            <Grid item>
              <Grid
                item
                xs={12}
                className=" pl-2 py-3"
                display={"flex"}
                alignItems={"center"}
              >
                Additional Comments{" "}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <InputField
                multiline
                value={inputValues}
                textChange={(e) => {
                  console.log(e, inputValues);
                  setInputValues(e);
                }}
                rows={10}
                sx={{ background: "#ffffff" }}
                placeholder={
                  "The applicant may email once they have fulfilled the requirement of this request."
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="right"
          justifyContent="right"
          sx={{ flexWrap: "noWrap" }}
        >
          <PrimaryButton
            variant={"text"}
            fullWidth={false}
            sx={{
              mt: 1,
              mr: 2,
              background: "white",
              border: "1px solid #5AB9F9",
              color: "#5AB9F9",
              fontSize: 16,
              fontWeight: 500,
            }}
            onClick={reportReference}
            disabled={isLoading || inputValues === ""}
          >
            {isLoading && <CircularProgress sx={{ mr: 1 }} size={20} />}
            Report Additional Form
          </PrimaryButton>
          <PrimaryButton
            disabled={isLoading}
            fullWidth={false}
            sx={{
              mt: 1,
              background: "#5AB9F9",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 500,
            }}
            onClick={() => {
              setReport(false);
            }}
          >
            Cancel
          </PrimaryButton>
        </Grid>
      </Grid>
    );
  }, [inputValues, reportReference, isLoading]);

  const openReportModal = useMemo(() => {
    return (
      <Modal
        open={report}
        setModalClose={setReport}
        children={reportModalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [report, reportModalText]);

  return (
    <>
      {openReportModal}
      <Grid container className="card-vetting pb-3 pb-md-1">
        <Grid container className="card-head-table">
          <Grid
            item
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            xs={12}
            md={12}
            sx={{ px: { xs: 1, md: 3 }, py: 3 }}
          >
            <Typography
              variant={"body2"}
              fontSize={"16px"}
              fontWeight={"bold"}
              sx={{ pl: 2 }}
            >
              {!!token
                ? "Forms and Declarations for Request"
                : "Instructions for Forms and Declarations"}
            </Typography>
            {ProfileData.application_status !== "Approved" &&
              is_candidate_sign_required &&
              has_candidate_signed &&
              !is_rejected && (
                <PrimaryButton
                  sx={{
                    borderRadius: "8px",
                    p: "0px 21px",
                    ml: 2,
                    mt: "6px",
                    height: "40px",
                    fontSize: "14px",
                    fontWeight: "400",
                    border: "none",
                    background: "white",
                    color: "black",
                    minWidth: "100px",
                  }}
                  variant={"text"}
                  onClick={() => setReport(true)}
                  fullWidth={false}
                >
                  Report
                </PrimaryButton>
              )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              background: "#D9D9D9",
              borderRadius: "20px",
              minHeight: "550px",
              display: "flex",
            }}
            margin={3}
            marginTop={0}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {is_co_sign_required ? (
              <>
                {is_candidate_sign_required && !has_candidate_signed ? (
                  <Stack>
                    <Typography
                      variant={"body1"}
                      sx={{ color: "#3a3a3a", mb: 3 }}
                    >
                      Candidate has not yet initiated E-Sign Process
                    </Typography>
                  </Stack>
                ) : (
                  <>
                    {!token ? (
                      <Stack>
                        <Typography
                          variant={"body1"}
                          sx={{ color: "#3a3a3a", mb: 3 }}
                        >
                          You will be taken to Yoti to perform the E-Sign on
                          documents given by the Agency
                        </Typography>
                        <Typography
                          variant={"body1"}
                          sx={{ color: "#3a3a3a", mb: 3 }}
                        >
                          Document signing will be a one time process and the
                          state might not be saved.
                        </Typography>
                        <PrimaryButton
                          disabled={loading}
                          fullWidth={false}
                          sx={{
                            mt: 1,
                            background: "#5AB9F9",
                            color: "#ffffff",
                            fontSize: 16,
                            fontWeight: 500,
                          }}
                          onClick={createEnvelope}
                        >
                          {loading && (
                            <CircularProgress sx={{ mr: 1 }} size={20} />
                          )}
                          Proceed to E-Sign
                        </PrimaryButton>
                      </Stack>
                    ) : (
                      <>
                        {!has_co_signed ? (
                          <iframe
                            src={`${process.env.REACT_APP_YOTI_SIGN_URL}${token}`}
                            style={{
                              height: "550px",
                              width: "100%",
                              borderRadius: "20px",
                              border: "2px solid lightgray",
                            }}
                            title={"Yoti E-Sign"}
                          ></iframe>
                        ) : (
                          <Typography
                            variant={"h5"}
                            sx={{ color: "#3a3a3a" }}
                            fontWeight={700}
                          >
                            All Documents have been Signed
                          </Typography>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <Stack>
                <Typography variant={"body1"} sx={{ color: "#3a3a3a", mb: 3 }}>
                  E-Signing Not Required for Request
                </Typography>
              </Stack>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default RightSideFrame;
