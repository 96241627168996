import React, { FC, useEffect, useState } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  Stack,
} from "@mui/material";
import ReferenceRequirement from "./ReferenceRequirement";
import CheckBox from "../../../../../components/CheckBox";
import { Reference } from "../../../../../utils/redux";
import { useSelector } from "react-redux";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import ReferenceList from "../../../../Common/ReferenceList";
import ReferenceForm from "./ReferenceForm";
import PrimaryButton from "../../../../../components/Button/PrimaryButton";
// import { useTour } from "@reactour/tour";
// import { tourStep } from "../../../../../utils/constants/coachMarks";

const LinearProgressWithLabel: FC<
  { value: number; reqNumber: number } & LinearProgressProps
> = ({ value, reqNumber, ...props }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          value={(value / reqNumber) * 100}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {`${Math.round(value)}`}/{reqNumber}
        </Typography>
      </Box>
    </Box>
  );
};

const RequiredReference: FC = () => {
  const reference = useSelector(Reference);

  const [active, setActive] = useState<number>(0);
  const [showAddForm, setShowAddForm] = useState<boolean>(false);
  const [showForm, setShowForm] = useState(false);
  const [reInitiateReference, setReInitiateReference] = useState(false);
  const [referenceId, setReferenceId] = useState("");

  useEffect(() => {
    if (reference) {
      setShowForm(!reference.reference_acknowledged);
    }
  }, [reference]);

  return (
    <>
      {showForm ? (
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            flexDirection: { xs: "column", lg: "row" },
          }}
        >
          <Stack
            width={"100%"}
            sx={{
              background: "#f5fbff",
              borderRadius: "17px",
              pb: 3,
            }}
          >
            <Grid
              container
              className="card-head-reference m-0 p-0"
              height={"68px"}
            >
              <Grid item md={7} sx={{ pl: 3 }}>
                <Typography
                  variant={"body2"}
                  fontSize={"16px"}
                  fontWeight={"bold"}
                  sx={{ pl: 2, paddingTop: "20px" }}
                >
                  Required References
                </Typography>
              </Grid>
            </Grid>
            <ReferenceRequirement />
          </Stack>
        </Box>
      ) : (
        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item xs={12} md={12} lg={4}>
            <List
              dense
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                p: 0,
              }}
            >
              {reference && (
                <>
                  {reference.reference_list.map((referenceType, index) => (
                    <Box key={index}>
                      <ListItem
                        sx={{ px: { xs: 0, md: 3 } }}
                        disablePadding
                        onClick={() => {
                          setShowAddForm(false);
                          setActive(index);
                        }}
                      >
                        <ListItemIcon>
                          <CheckBox
                            checked={referenceType.count > 0}
                            disabled
                            type="shield"
                            sx={{ marginBottom: "8px" }}
                            shieldColor={
                              referenceType.has_invalid_reference
                                ? "error"
                                : referenceType.count ===
                                  referenceType.completed
                                ? "success"
                                : referenceType.count > 0
                                ? "warning"
                                : "success"
                            }
                          />
                        </ListItemIcon>
                        <ListItemButton>
                          <ListItemText
                            data-tut={
                              referenceType.type === "employment"
                                ? "candidate_employment_ref"
                                : referenceType.type === "character"
                                ? "candidate_character_ref"
                                : referenceType.type === "academic"
                                ? "candidate_academic_ref"
                                : ""
                            }
                            sx={{
                              background:
                                index === active
                                  ? "#E3F4FF"
                                  : referenceType.has_invalid_reference
                                  ? "#F6D3D3"
                                  : referenceType.count > 0 &&
                                    referenceType.count !==
                                      referenceType.completed
                                  ? "#FEFBEC"
                                  : referenceType.count ===
                                      referenceType.completed &&
                                    referenceType.count > 0
                                  ? "#E3F4FF"
                                  : "#FFFFFF",
                              border: "1px solid rgba(90, 185, 249, 0.25)",
                              borderRadius: "8px",
                              margin: "0 0 10px 0",
                              padding: "8px 20px",
                            }}
                            primary={
                              <Typography
                                variant={"body2"}
                                fontWeight={500}
                                color={"#212121"}
                                fontSize={14}
                                textTransform={"capitalize"}
                              >
                                {referenceType.type} Reference
                              </Typography>
                            }
                            secondary={
                              <>
                                <span className="lower-text">
                                  Add {referenceType.type} reference
                                </span>
                                <LinearProgressWithLabel
                                  variant="determinate"
                                  value={referenceType.completed}
                                  color={
                                    index === active
                                      ? "primary"
                                      : referenceType.count > 0
                                      ? referenceType.has_invalid_reference
                                        ? "error"
                                        : referenceType.count >
                                          referenceType.completed
                                        ? "warning"
                                        : "primary"
                                      : "secondary"
                                  }
                                  reqNumber={referenceType.count}
                                />
                              </>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    </Box>
                  ))}
                </>
              )}
            </List>
          </Grid>
          <Grid item xs={12} lg={8}>
            {showAddForm ? (
              <ReferenceForm
                setShowAddForm={setShowAddForm}
                type={reference?.reference_list[active].type}
                setReInitiateRef={setReInitiateReference}
                reInitiate={reInitiateReference}
                referenceId={referenceId}
              />
            ) : (
              <ReferenceList
                role={"candidate"}
                type={reference?.reference_list[active]?.type}
                setShowAddForm={setShowAddForm}
                showAddForm={showAddForm}
                setReInitiateRef={setReInitiateReference}
                setReferenceId={setReferenceId}
              />
            )}
          </Grid>
          <Grid item textAlign={"center"} xs={12} sx={{
            marginBottom: {
              xs: '8rem',
              md: 0
            }
          }}>
            <PrimaryButton
              fullWidth={false}
              sx={{
                background: "#5AB9F9",
                boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
                borderRadius: "6px",
                color: "white",
                maxHeight: "40px",
                minWidth: "180px",
              }}
              onClick={() => {
                setShowForm(true);
              }}
            >
              Go Back
            </PrimaryButton>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default RequiredReference;
