import React, {
  FC,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import {
  Box,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import PrimaryButton from "../../../../components/Button/PrimaryButton";
import {useDispatch, useSelector} from "react-redux";
import {
  ReferenceData,
  setNotificationMessage,
  setReferenceData,
} from "../../../../utils/redux";
import InputField from "../../../../components/InputField";
import Modal from "../../../../components/modal/Modal";
import axiosInstance from "../../../../utils/axios";
import {images} from "../../../../utils/constants/images";
import {useTour} from "@reactour/tour";
import {tourStep} from "../../../../utils/constants/coachMarks";
import PageLoader from "../../../../components/PageLoader";
import LeftSection from "../LeftSection";
import { useLocation } from "react-router-dom";

const RefereeAdditionalComments: FC = () => {
  const dispatch = useDispatch();
  const {id} = useParams();
  const Navigate = useNavigate();
  const location = useLocation();

  const {setIsOpen, currentStep, setCurrentStep} = useTour();

  const reference = useSelector(ReferenceData);

  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const [isDirect, setIsDirect] = useState<boolean>(false);

  useEffect(() => {
    if (tourStep.referee_additional_comments.index === currentStep) {
      // setIsOpen(true);
    } else {
      dispatch(
        setReferenceData({...reference, referee_additional_comments: true,})
      );
    }
  }, [currentStep, dispatch, reference, setIsOpen]);

  useEffect(() => {
    // Create a URLSearchParams object from the current URL's query string
    const searchParams = new URLSearchParams(location.search);

    // Check if the query parameter 't' has the value 'd'
    const hasTd = searchParams.get('t') === 'direct';

    // Set the state to true if t=d
    setIsDirect(hasTd);
  }, [location.search]);

  const setComments = (value: string | null) => {
    const comments = value === null ? '' : value;
    dispatch(
      setReferenceData({
        ...reference,
        referee_comments: comments,
        is_declined: true,
        invalid: true,
      })
    );
  };

  const submitReference = useCallback(
    async () => {
      setLoading(true);

      try {
        const body = {
          referee_comments: reference.referee_comments || '',
        };
        await axiosInstance.post(`${process.env.REACT_APP_NODE_V3_API_URL}/referee/declined/${id}`, body);
        setLoading(false);
        setCompleted(true)
        dispatch(
          setReferenceData({
            ...reference,
            referee_comments: reference.referee_comments,
            is_declined: true,
            invalid: true,
          })
        );
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "success",
            message: "Successfully submitted the your response.",
            autoHideDuration: 6000,
          })
        );

      } catch (e) {
        setLoading(false);
        console.log(e);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "Failed to submit response. Please try again.",
            autoHideDuration: 6000,
          })
        );
      }
    },
    [dispatch, id, reference]
  );

  return (

    <Grid container>
      <Grid
        item
        xs={12}
        md={4}
        display={"flex"}
        flexDirection="column"
        sx={{
          backgroundColor: "#f5fbff",
          minHeight: {xs: "auto", md: "100vh"},
          pt: {xs: "20px", md: "100px"},
        }}
      >
        <LeftSection/>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        display={"flex"}
        alignItems={{xs: "start", md: "center"}}
        justifyContent={"center"}
        sx={{minHeight: "calc(100vh - 120px)"}}
      >

        {completed &&
          <Typography
            variant="h6"
            fontWeight={"500"}
            fontSize={"14px"}
            sx={{mb: 2}}
          >
            Thank you for your time.
          </Typography>
        }

        {!completed &&
          <Grid
            item
            xs={10}
            lg={8}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
          >

            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  fontWeight={"500"}
                  fontSize={"24px"}
                  sx={{mb: 4}}
                >
                  Decline Reference Request
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  fontWeight={"500"}
                  fontSize={"14px"}
                  sx={{mb: 2}}
                >
                  Additional Comments (Optional)
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <InputField
                  inputValue={reference.referee_comments}
                  textChange={setComments}
                  multiline
                  sx={{background: "white"}}
                  rows={5}
                />
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                position: {xs: "fixed", sm: "fixed", md: "relative"},
                bottom: "0px",
                left: "0px",
                width: "100%",
                display: "flex",
                gap: '2rem',
                zIndex: "99",
                textAlign: {xs: "center", md: "right"},
                background: {xs: "white", md: "transperant"},
                boxShadow: {xs: "0px -4px 7px #e6e6e6", md: "none"},
                borderRadius: {xs: "15px 15px 0px 0px", md: "none"},
              }}
              marginTop={'1rem'}
            >
              {!isDirect &&
              <PrimaryButton
                variant={"text"}
                disabled={loading}
                onClick={() => {
                  Navigate(-1)
                }}
                sx={{
                  background: "#fff",
                  boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
                  borderRadius: "5px",
                  padding: "4px 30px",
                  color: "#000",
                  width: '100px'
                }}
              >
                Back
              </PrimaryButton>
              }
              <PrimaryButton
                fullWidth={false}
                disabled={loading}
                sx={{
                  background: "#5AB9F9",
                  boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
                  borderRadius: "5px",
                  padding: "4px 40px",
                  color: "white",
                }}
                onClick={submitReference}
              >
                Submit
              </PrimaryButton>


            </Grid>
          </Grid>
        }


      </Grid>
    </Grid>
  );
};

export default RefereeAdditionalComments;
