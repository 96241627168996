import React, { FC, useState } from "react";
import { Box, Grid } from "@mui/material";
import PrimaryButton from "../../../../components/Button/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import {
  ReferenceData,
  setNotificationMessage,
  setReferenceData,
} from "../../../../utils/redux";
import { routes } from "../../../../utils/constants/routes";
import { useNavigate } from "react-router-dom";
import { Form } from "@formio/react";

const AdditionalQuestionnaire: FC = () => {
  let formIo: any;
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const reference = useSelector(ReferenceData);
  const [active, setActive] = useState<number>(0);

  const proceed = (submission: any) => {
    console.log(submission);
    if (formIo.errors && formIo.errors.length > 0) {
      const errors = formIo.errors.map(
        (error: { message: string }) => error.message
      );
      dispatch(
        setNotificationMessage({
          display: true,
          message: errors.join(", "),
          severity: "error",
        })
      );
    } else {
      const reference_forms = JSON.parse(
        JSON.stringify(reference.additional_questionnaire_forms)
      );
      //   console.log(formIo.submission);
      reference_forms[active].values = submission.data;
      console.log(reference_forms);
      if (active + 1 === reference_forms.length) {
        const active_index = reference.active_index;
        dispatch(
          setReferenceData({
            ...reference,
            additional_questionnaire_forms: reference_forms,
            active_index: active_index + 1,
          })
        );
        Navigate(`../${routes[reference.available_routes[active_index + 1]]}`);
      } else {
        formIo.emit("resetForm");
        dispatch(
          setReferenceData({
            ...reference,
            additional_questionnaire_forms: reference_forms,
          })
        );
        setActive((prevState) => prevState + 1);
      }
    }
  };

  return (
    <Grid container justifyContent={"center"} sx={{ mt: 3,minHeight:'100vh' }}>
      <Grid item xs={10} lg={8} className={"referee-form"}>
        <Form
          key={active}
          form={{
            display: "form",
            components:
              reference.additional_questionnaire_forms[active]?.form_data,
          }}
          formReady={(formInstance: any) => (formIo = formInstance)}
          submission={{
            data: reference.additional_questionnaire_forms[active].values || {},
          }}
          onSubmit={(submission: any) => proceed(submission)}
        />
        <Box
          display={"flex"}
          sx={{
            position:  "relative" ,
            bottom: { xs: "45px", md: "45px" },
            left: "0px",
            width: { xs: "50%", md: "50%" },
            zIndex: "99",
            textAlign: { xs: "center", md: "right" },
            background: { xs: "transparent", md: "transparent" },
            boxShadow: "none" ,
            borderRadius:"none" ,
          }}
        >
          <PrimaryButton
            fullWidth={false}
            sx={{
              width: {xs:'150px',md:"212px"},
              height: "36px",
              background: "#FFFFFF",
              border: " 1px solid #5AB9F9",
              borderRadius: "5px",
              padding: "4px 44px",
              color: "#5AB9F9",
              fontSize: "14px",
              margin: {xs:"0px 5px",md:"0px 20px"},
            }}
            onClick={() => {
              const active_index = reference.active_index;
              dispatch(
                setReferenceData({
                  ...reference,
                  active_index: active_index - 1,
                })
              );
              Navigate(
                `../${routes[reference.available_routes[active_index - 1]]}`
              );
            }}
          >
            Go back
          </PrimaryButton>
          {/*<PrimaryButton*/}
          {/*  disabled={false}*/}
          {/*  fullWidth={false}*/}
          {/*  sx={{*/}
          {/*    background: "#5AB9F9",*/}
          {/*    color: "#ffffff",*/}
          {/*    fontSize: 16,*/}
          {/*    fontWeight: 500,*/}
          {/*    p: "2px 80px",*/}
          {/*  }}*/}
          {/*  onClick={proceed}*/}
          {/*>*/}
          {/*  Proceed*/}
          {/*</PrimaryButton>*/}
        </Box>
      </Grid>
    </Grid>
  );
};

export default AdditionalQuestionnaire;
