import { Box, Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import { images } from "../../utils/constants/images";

const CandidateLeftLayout: FC<any> = () => {
  return (
    <Grid>
      <img
        src={images.demoLayout}
        alt="demoLayout"
        className="left-content-dek-image"
      />
      <img
        src={images.mobileLoginBanner}
        alt="demoLayout"
        className="left-content-mob-image"
      />

      <Box textAlign="center">
        <Typography
          variant="subtitle1"
          className="left-content-1"
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          Manage All Your Requests At One Place
        </Typography>
      </Box>
      <Box textAlign="center">
        <Typography
          variant="subtitle2"
          className="left-content-2"
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          Get a seamless experience with ked
        </Typography>
      </Box>
    </Grid>
  );
};

export default CandidateLeftLayout;
