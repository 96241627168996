import React, { ChangeEvent, FC, useState } from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import PrimaryButton from "../../../../../../../components/Button/PrimaryButton";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../../../../../utils/axios";
import { URLS } from "../../../../../../../utils/constants/urls";
import { useDispatch, useSelector } from "react-redux";
import { Reload, setReload } from "../../../../../../../utils/redux";
import { Box, Stack } from "@mui/system";
import { images } from "../../../../../../../utils/constants/images";

export interface UpdatedServicesProps {
  vettingDocId: string;
  setDbs: (value: boolean) => void;
  comments?: string;
  name?: string;
}

const UpdatedServices: FC<UpdatedServicesProps> = ({
  setDbs,
  vettingDocId,
  comments,
  name,
}) => {
  const dispatch = useDispatch();
  const reload = useSelector(Reload);
  const { id } = useParams() as any;

  const [loading, setLoading] = useState<boolean>(false);
  const [updatedServices, setUpdatedServices] = useState<boolean | string>("");
  const [step2UpdatedServices, setStep2UpdatedServices] = useState<boolean | string>("");

  const [currentStep, setCurrentStep] = useState("step1");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (currentStep === "step1") {
      setUpdatedServices(event.target.value === "true");
    } else {
      setStep2UpdatedServices(event.target.value === "true");
    }
  };

  const confirmUpdatedServices = async () => {
    try {
      setLoading(true);
      await axiosInstance.patch(`${URLS.requests}/${id}/document`, {
        on_updated_dbs_services: currentStep === "step1" ? updatedServices : step2UpdatedServices,
        on_updated_dbs_services_agency: currentStep === "step2" ? true : false,
        vetting_doc_id: vettingDocId,
      });
      setLoading(false);
      dispatch(setReload(!reload));
      setDbs(currentStep === "step1" ? updatedServices as boolean : step2UpdatedServices as boolean);
    } catch (e: any) {
      console.log(e);
    }
  };

  const handleStepChange = (value: string) => {
    setCurrentStep(value);
  };

  return (
    <Grid container justifyContent={"center"} spacing={2} sx={{ p: 5 }}>
      <Grid
        xs={12}
        md={7}
        className="pop-up-dotted-card-dbs content-card"
        sx={{ marginTop: "55px", marginBottom: "40px" }}
        data-tut="dbs_updated_pop_up"
      >
        <Typography
          variant={"body1"}
          color={"#333333"}
          sx={{
            mb: 1,
            fontSize: "18px",
            fontWeight: "500",
            letterSpacing: 0.15,
            marginBottom: "30px",
          }}
        >
          {currentStep === "step1"
            ? `Is your ${name} on Update Service?`
            : `Is your ${name} application under process through any other agency currently?`}
        </Typography>
        <FormControl>
          <RadioGroup
            className="radio-group"
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={currentStep === "step1" ? updatedServices : step2UpdatedServices}
            onChange={handleChange}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Grid>
      {!!comments && (
        <Grid
          xs={8}
          className="custom-card-dbs content-card"
          sx={{ marginTop: "25px" }}
        >
          <Typography
            variant={"body1"}
            color={"#333333"}
            sx={{
              mb: 1,
              fontSize: "14px",
              fontWeight: "500",
              letterSpacing: 0.15,
              marginBottom: "8px",
              textAlign: "center",
            }}
          >
            Custom Message
          </Typography>
          <Typography
            variant={"body1"}
            color={"#727272"}
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              letterSpacing: 0.15,
              marginBottom: "10px",
              lineHeight: "24px",
            }}
          >
            {comments}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} textAlign={"center"} sx={{ mt: 5 }}>
        <PrimaryButton
          fullWidth={false}
          sx={{
            width: "212px",
            height: "36px",
            background: " #5AB9F9",
            boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
            borderRadius: "6px",
            color: "#ffffff",
          }}
          disabled={loading || (currentStep === "step1" ? updatedServices : step2UpdatedServices) === ""}
          onClick={() => {
            if (currentStep === "step1") {
              if (updatedServices === true) {
                confirmUpdatedServices();
              } else {
                handleStepChange("step2");
              }
            } else {
              confirmUpdatedServices();
            }
          }}
        >
          {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
          Continue
        </PrimaryButton>

        <>
          <Stack
            direction={"row"}
            alignItems="center"
            sx={{
              background: "#fff4d1b0",
              color: "black",
              borderTopRightRadius: "10px",
              borderTopLeftRadius: "10px",
              padding: "10px",
              border: "1px dashed #d0a545",
              marginTop: '32px',
            }}
          >
            <img
              src={images.infoTooltip}
              alt="success icon"
              width={"64px"}
            />
            <Box sx={{ ml: 2 }}>
              <strong>You will not be able to return to this screen after choosing.</strong>
              <p>The Update Service is an online subscription that allows you to keep your standard or enhanced certificates up-to-date and allows employers to check a certificate online.
              </p>
            </Box>
          </Stack>
          <Box sx={{
            background: "#fff",
            margin: "0",
            padding: "0.5rem",
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            boxShadow: "0px 2px 4px #d9d9d9",
            fontSize: "13px"
          }}
          >
            If you have any questions, please do call us on <strong>0207 971 1250</strong> or
            email us at <a href="mailto:support@ked.io">support@ked.io</a>
          </Box>
        </>
      </Grid>
    </Grid>
  );
};

export default UpdatedServices;
