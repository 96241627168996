import { ICountry } from 'country-state-city/lib/interface';
import { Country } from 'country-state-city';
import { FormModel } from '../../../../../../components/Form';
import { Validators } from '../../../../../../utils/validators';
import { identifiers } from '../../../../../../utils/constants/identifiers';

const countries: ICountry[] = Country.getAllCountries()
  .map((country, index) => {
    if (country.isoCode === 'GB') {
      return { index: 0, ...country };
    } else if (country.isoCode === 'IE') {
      return { index: 1, ...country };
    }
    return { index: index + 2, ...country };
  })
  .sort((a, b) => a.index - b.index);

export const RefereeFormModel: (type: string) => FormModel[] = (type) => {
  const form: FormModel[] = [
    {
      label: 'Referee Full Name*',
      value: '',
      size: 'small',
      autoFocus: true,
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'referee_name',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
        { check: Validators.Name, message: 'Enter Valid Name' },
      ],
      responsive: { lg: 6, sm: 6, xs: 12 },
      required: true,
      style: { background: '#FFFFFF', borderRadius: '8px' },
    },
    {
      label: 'Referee Email*',
      value: '',
      size: 'small',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'referee_email',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
        { check: Validators.email, message: 'Enter a Valid Email' },
        { check: Validators.domainNames, message: 'Enter a Valid domain Email' },

      ],
      responsive: { md: 6, xs: 12 },
      required: true,
      style: { background: '#FFFFFF', borderRadius: '8px' },
    },
  ];
  if (type === identifiers.employment) {
    form.push(
      {
        label: 'Organisation Name*',
        value: '',
        size: 'small',
        type: 'text',
        typeValue: 'text',
        variant: 'outlined',
        placeholder: '',
        field: 'organisation',
        validators: [
          { check: Validators.required, message: 'This field is mandatory' },
          { check: Validators.BusinessName, message: 'Enter Valid Organization Name' },
        ],
        responsive: { sm: 6, xs: 12 },
        required: true,
        style: { background: '#FFFFFF', borderRadius: '8px' },
      },
      {
        label: 'Organisation Address*',
        value: '',
        size: 'small',
        type: 'text',
        typeValue: 'text',
        variant: 'outlined',
        placeholder: '',
        field: 'organisation_address',
        validators: [
          { check: Validators.required, message: 'This field is mandatory' },
        ],
        responsive: { lg: 6, sm: 6, xs: 12 },
        required: true,
        style: { background: '#FFFFFF', borderRadius: '8px' },
      }
    );
  }
  form.push(
    {
      label: 'Referee Phone Code',
      value: '',
      size: 'small',
      type: 'drop-down',
      options: countries.map((country) => ({
        label:
          country.phonecode.indexOf('+') > -1
            ? country.phonecode + ` (${country.isoCode})`
            : '+' + country.phonecode + ` (${country.isoCode})`,
        value:
          country.phonecode.indexOf('+') > -1
            ? country.phonecode
            : '+' + country.phonecode,
        icon: country.isoCode.toLowerCase(),
      })),
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'code',
      validators: [
        // { check: Validators.required, message: 'This field is mandatory' },
      ],
      responsive: { md: 2, xs: 4 },
      required: false,
      style: { background: '#FFFFFF', borderRadius: '8px' },
    },
    {
      label: 'Referee Phone Number (optional)',
      value: '',
      size: 'small',
      type: 'text',
      typeValue: 'tel',
      variant: 'outlined',
      placeholder: '',
      field: 'phone_number',
      validators: [
        // { check: Validators.required, message: 'This field is mandatory' },
        {
          check: Validators.mobile,
          message: 'Please enter a valid phone number',
        },
      ],
      responsive: { md: 4, xs: 8 },
      required: false,
      style: { background: '#FFFFFF', borderRadius: '8px' },
    }
  );
  if (type === identifiers.academic) {
    form.push({
      label: 'Referee Job Title*',
      value: '',
      size: 'small',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'job_title',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
      ],
      responsive: { lg: 6, sm: 6, xs: 12 },
      required: true,
      style: { background: '#FFFFFF', borderRadius: '8px' },
    });
  }
  form.push({
    label: 'Your relationship with Referee*',
    value: '',
    size: 'small',
    type: 'text',
    typeValue: 'text',
    variant: 'outlined',
    placeholder: '',
    field: 'relationship',
    validators: [
      { check: Validators.required, message: 'This field is mandatory' },
    ],
    responsive: { lg: 6, sm: 6, xs: 12 },
    required: true,
    style: { background: '#FFFFFF', borderRadius: '8px' },
  });
  if (type === identifiers.employment) {
    form.push({
      label: 'Your Job Title*',
      value: '',
      size: 'small',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'job_title',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
      ],
      responsive: { lg: 6, sm: 6, xs: 12 },
      required: true,
      style: { background: '#FFFFFF', borderRadius: '8px' },
    });
  }
  if (type === identifiers.character) {
    form.push({
      label: 'How long have you known the referee?*',
      value: '',
      size: 'small',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'time_known',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
      ],
      responsive: { lg: 6, sm: 6, xs: 12 },
      required: true,
      style: { background: '#FFFFFF', borderRadius: '8px' },
    });
  }
  if (type === identifiers.academic) {
    form.push({
      label: 'Course Name*',
      value: '',
      size: 'small',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'course',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
      ],
      responsive: { lg: 6, sm: 6, xs: 12 },
      required: true,
      style: { background: '#FFFFFF', borderRadius: '8px' },
    });
  }

  return form;
};
