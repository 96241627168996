import { Stack, Accordion } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";

const DocumentListInfoAccordion = () => {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Typography sx={{ fontSize: "1rem", fontWeight: 600, marginBottom: "1rem" }}>
        Information
      </Typography>
      <Accordion expanded={expanded === "workforce"} onChange={handleChange("workforce")}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="workforce-content"
          id="workforce-header"
        >
          <Typography>
            Workforce Type
          </Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Stack gap={"0.75rem"}>

            <Typography sx={{ fontSize: "0.875rem" }}><strong>Child Workforce</strong>. Use this for any position that
              involves working/volunteering with children</Typography>
            <Typography sx={{ fontSize: "0.875rem" }}><strong>Adult Workforce</strong>. Use this for any position that
              involves working/volunteering with adults.</Typography>
            <Typography sx={{ fontSize: "0.875rem" }}><strong>Child and Adult Workforce</strong>. Use this for any
              position that involves working/volunteering with both children and adults.</Typography>
            <Typography sx={{ fontSize: "0.875rem" }}><strong>Other Workforce</strong>. Use this for any position that
              does not involve working/volunteering with Children or Adults e.g. security guard.</Typography>
          </Stack>


        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === "Position Applied For"} onChange={handleChange("Position Applied For")}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="workforce-content"
          id="workforce-header"
        >
          <Typography>
            Job Role
          </Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Typography sx={{ fontSize: "0.875rem" }}>This information will be used to support the eligibility of the
            application.
            Please insert the correct job role. No abbreviations are permissible e.g. HCA should read Healthcare
            Assistant.</Typography>


        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === "Disclosure Type"} onChange={handleChange("Disclosure Type")}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="Disclosure-Type-content"
          id="Disclosure-Type-header"
        >
          <Typography>
            Disclosure Type
          </Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Typography sx={{ fontSize: "0.875rem" }}>This is the level of criminal record check required for the
            application</Typography>


        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === "Working with Adults"} onChange={handleChange("Working with Adults")}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="Disclosure-Type-content"
          id="Disclosure-Type-header"
        >
          <Typography>
            Working with Adults
          </Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Stack gap={"0.75rem"}>
            <Typography sx={{ fontSize: "0.875rem" }}>Regulated Activity. Please select 'Yes' if the position involves
              contact with
              adults within the definition of regulated activity.</Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>The definition of regulated activity in relation to adults focuses
              on those
              activities which, should they be needed by any adult, mean that an adult is considered vulnerable at the
              point of receiving them. The definition applies to individuals undertaking the following
              activities:</Typography>

            <ul style={{ margin: 0, display: "flex", gap: "1rem", flexDirection: "column" }}>
              <li><Typography sx={{ fontSize: "0.875rem" }}>healthcare for adults provided by, or under the direction or
                supervision of a regulated health care professional</Typography></li>
              <li><Typography sx={{ fontSize: "0.875rem" }}>personal care for adults involving hands-on physical
                assistance with washing and dressing, eating, drinking and toileting; prompting and supervising an adult
                with any of these tasks because of their age, illness or disability; or teaching someone to do one of
                these tasks</Typography></li>
              <li><Typography sx={{ fontSize: "0.875rem" }}>social work - provision by a social care worker of social
                work which is required in connection with any health services or social services</Typography></li>
              <li><Typography sx={{ fontSize: "0.875rem" }}>assistance with an adult's cash, bills or shopping because
                of their age, illness or disability arranged via a third party</Typography></li>
              <li><Typography sx={{ fontSize: "0.875rem" }}>assisting in the conduct of an adult's own affairs under a
                formal appointment</Typography></li>
              <li><Typography sx={{ fontSize: "0.875rem" }}>conveying adults for reasons of age, illness or disability
                to, from, or between places, where they receive healthcare, personal care or social work arranged via a
                third party</Typography></li>
              <li><Typography sx={{ fontSize: "0.875rem" }}>day to day management or supervision of anyone carrying out
                any of the activities listed above</Typography></li>
            </ul>
          </Stack>


        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === "Working with Children"} onChange={handleChange("Working with Children")}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="Disclosure-Type-content"
          id="Disclosure-Type-header"
        >
          <Typography>
            Working with Children
          </Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Stack gap={"0.75rem"}>

            <Typography sx={{ fontSize: "0.875rem" }}>Regulated Activity. Please select 'Yes' if the position involves
              working with children within the definition of regulated activity.</Typography>

            <Typography sx={{ fontSize: "0.875rem" }}>The definition of regulated activity relating to children
              comprises only:</Typography>

            <ul style={{ margin: 0, display: "flex", gap: "1rem", flexDirection: "column" }}>
              <li><Typography sx={{ fontSize: "0.875rem" }}>(i) Unsupervised activities: teach, train, instruct, care
                for or supervise children, or provide advice/guidance on well-being, or drive a vehicle only for
                children;</Typography></li>
              <li><Typography sx={{ fontSize: "0.875rem" }}>(ii) Work for a limited range of establishments ('specified
                places'), with opportunity for contact: for example, schools, children's homes, childcare premises. Not
                work by supervised volunteers;</Typography></li>
            </ul>

            <Typography sx={{ fontSize: "0.875rem" }}>Work under (i) or (ii) is regulated activity only if done
              regularly.</Typography>

            <ul style={{ margin: 0, display: "flex", gap: "1rem", flexDirection: "column" }}>
              <li><Typography sx={{ fontSize: "0.875rem" }}>(iii) Health care for children provided by, or under the
                direction or supervision of a regulated health care professional. This is regulated activity even if the
                activity is only carried out once.</Typography></li>
              <li><Typography sx={{ fontSize: "0.875rem" }}>(iv) Personal care for children involving hands-on physical
                assistance with washing and dressing, eating, drinking and toileting; prompting and supervising a child
                with any of these tasks because of their age, illness or disability; or teaching someone to do one of
                these tasks.</Typography></li>
              <li><Typography sx={{ fontSize: "0.875rem" }}>(v) Registered childminding.</Typography></li>
              <li><Typography sx={{ fontSize: "0.875rem" }}>(vi) Registered foster-carers.</Typography></li>
              <li><Typography sx={{ fontSize: "0.875rem" }}>(vii) The day-to-day management or supervision of any person
                engaging in regulated activity, is also in regulated activity.</Typography></li>
            </ul>

          </Stack>

        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === "Working at own Address"} onChange={handleChange("Working at own Address")}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="Disclosure-Type-content"
          id="Disclosure-Type-header"
        >
          <Typography>
            Working at own Address
          </Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Stack gap={"0.75rem"}>

            <Typography sx={{ fontSize: "0.875rem" }}>Will the applicant be in working in Regulated Activity with
              vulnerable Adults
              and/or Children at the applicant's own HOME address? Or with regard to Children only, will they be living
              in the household of someone who is in Regulated Activity with Children at their own home address (for
              example, a member of the household where children are living with foster carers; childminding,
              etc)?</Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>For further information and guidance please see:</Typography>

            <Stack sx={{ display: "inline-block", '& a': {lineBreak: 'anywhere'} }}>
              <a
                 href="https://www.gov.uk/government/publications/dbs-home-based-positions-guide/home-based-position-definition-and-guidance"
                 target="_blank" rel="noreferrer">Home based position definition and guidance</a>
              <LaunchOutlinedIcon fontSize={"tiny"} />
            </Stack>

          </Stack>

        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === "Volunteers"} onChange={handleChange("Volunteers")}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="Volunteers-Type-content"
          id="Volunteers-Type-header"
        >
          <Typography>
            Volunteers
          </Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Stack gap={"0.75rem"}>

            <Typography sx={{ fontSize: "0.875rem" }}>A volunteer is a person who is engaged in any activity which
              involves
              spending time, unpaid (except for traveling and other approved out-of-pocket expenses), doing something
              which aims to benefit someone (individuals or groups) other than or in addition to close
              relatives.</Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>Below are some Examples of Volunteer Situations.</Typography>

            <Typography sx={{ fontSize: "0.875rem" }}><strong>Case Study 1</strong></Typography>

            <Typography sx={{ fontSize: "0.875rem" }}>Jan has enrolled in a teacher-training course. One element of the
              course is
              to complete teaching placements at various schools; for which she will receive credits towards her
              qualification. She will not receive any payments while on these placements. Is the university entitled to
              apply a free of charge Enhanced Disclosure for Jan?</Typography>


            <Typography sx={{ fontSize: "0.875rem" }}><strong>No.</strong> Whilst she is not receiving a payment, she is
              gaining credits towards her qualification, which is a benefit, and therefore does not meet the DBS
              definition of a volunteer.</Typography>

            <Typography sx={{ fontSize: "0.875rem" }}>During a break in her studies, Jan applies to become a volunteer
              with ChildLine. This is not a requirement of her course, and she will not receive any payments. Would this
              be eligible for a free of charge Disclosure?
              Yes. She is volunteering for a third party, and it is of no financial benefit to her.</Typography>


            <Typography sx={{ fontSize: "0.875rem" }}><strong>Case Study 2</strong></Typography>

            <Typography sx={{ fontSize: "0.875rem" }}>Mark has accepted a job as a teaching assistant, initially on a
              three-month unpaid probationary period. Subject to successful completion, Mark will become a paid
              employee. Does Mark meet the DBS’s eligibility for a free of charge Disclosure?</Typography>

            <Typography sx={{ fontSize: "0.875rem" }}><strong>No.</strong> Whilst Mark is not receiving payment for this
              initial period, it is clear that successful completion of this probationary period could result in paid
              employment and, therefore, it will benefit Mark financially.</Typography>

          </Stack>

        </AccordionDetails>
      </Accordion>


      <Accordion expanded={expanded === "Additional Links"} onChange={handleChange("Additional Links")}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="Additional-Links-Type-content"
          id="Additional-Links-Type-header"
        >
          <Typography>
            Additional Links
          </Typography>
        </AccordionSummary>
        <AccordionDetails>

          <Stack gap={"0.75rem"}>

            <Stack sx={{ display: "inline-block", '& a': {lineBreak: 'anywhere', fontSize: 14} }}>
              <a
                href="https://www.gov.uk/government/publications/dbs-workforce-guidance"
                target="_blank" rel="noreferrer">https://www.gov.uk/government/publications/dbs-workforce-guidance</a>
              <LaunchOutlinedIcon fontSize={"tiny"} />
            </Stack>
            <Stack sx={{ display: "inline-block", '& a': {lineBreak: 'anywhere', fontSize: 14} }}>
              <a
                href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/1168236/Standards_eligibility_guide__002_.pdf"
                target="_blank"
                rel="noreferrer">https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/1168236/Standards_eligibility_guide__002_.pdf</a>
              <LaunchOutlinedIcon fontSize={"tiny"} />
            </Stack>

          </Stack>

        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default DocumentListInfoAccordion;
