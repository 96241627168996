import React, { FC } from "react";
import {
  Grid,
  TableCell,
  styled,
  tableCellClasses,
  TableRow,
  TableContainer,
  Table,
  TableBody,
} from "@mui/material";

const Checks: FC<{ check: any }> = ({ check }) => {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      color: "#727272",
    },
    [`&.${tableCellClasses.body}`]: {
      color: "#263238",
      fontSize: "14px",
      fontWeight: 500,
      padding: "5px 16px",
    },
    [`&.${tableCellClasses.head}:first-of-type`]: {
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
      borderLeft: "1px solid lightgray",
    },
    [`&.${tableCellClasses.head}:last-of-type`]: {
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      width: "14rem",
      textAlign: "start",
      paddingLeft: "35px",
    },
    [`&.${tableCellClasses.body}:first-of-type`]: {
      borderTopLeftRadius: "12px",
      borderBottomLeftRadius: "12px",
      borderLeft: "1px solid #DADADA",
    },
    [`&.${tableCellClasses.body}:last-of-type`]: {
      borderTopRightRadius: "12px",
      borderBottomRightRadius: "12px",
      borderRight: "1px solid #DADADA",
      textAlign: "center",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&": {
      borderRadius: "8px",
      borderTop: "1px solid #DADADA",
      backgroundColor: "white",
    },
  }));

  return (
    <Grid container className=" mb-3">
      <TableContainer>
        <Table>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell variant="head">ID</StyledTableCell>
              <StyledTableCell> {check.id} </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell variant="head">State</StyledTableCell>
              <StyledTableCell>{check.state}</StyledTableCell>
            </StyledTableRow>
            {check.report && (
              <>
                <StyledTableRow>
                  <StyledTableCell variant="head">
                    Recommendation
                  </StyledTableCell>
                  <StyledTableCell>
                    <Table>
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell variant="head">
                            Value
                          </StyledTableCell>
                          <StyledTableCell>
                            {check.report.recommendation.value}
                          </StyledTableCell>
                        </StyledTableRow>
                        {!!check.report.recommendation.reason && (
                          <StyledTableRow>
                            <StyledTableCell variant="head">
                              Reason
                            </StyledTableCell>
                            <StyledTableCell>
                              {check.report.recommendation.reason}
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                        {!!check.report.recommendation.recoverySuggestion && (
                          <StyledTableRow>
                            <StyledTableCell variant="head">
                              Recovery Suggestion
                            </StyledTableCell>
                            <StyledTableCell>
                              {check.report.recommendation.recoverySuggestion}
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                      </TableBody>
                    </Table>
                  </StyledTableCell>
                </StyledTableRow>
                {check.report.breakdown && (
                  <StyledTableRow>
                    <StyledTableCell>Breakdown</StyledTableCell>
                    <StyledTableCell>
                      {check.report.breakdown.map((breakdown: any) => (
                        <Table>
                          <TableBody>
                            <StyledTableRow>
                              <StyledTableCell variant="head">
                                Sub Check
                              </StyledTableCell>
                              <StyledTableCell>
                                {breakdown.subCheck}
                              </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledTableCell variant="head">
                                Result
                              </StyledTableCell>
                              <StyledTableCell>
                                {breakdown?.result}
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      ))}
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default Checks;
