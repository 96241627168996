import React, { FC } from "react";
import {Button, Stack, Typography } from "@mui/material";
import { images } from "../../../utils/constants/images";
import BulletPoint from "./BulletPoint";


const PrivacyPolicy: FC = () => {

  const handleButtonClick = () => {
    window.location.href=window.location.origin;
  };

  return (
    <Stack gap={'1rem'} padding={'2.5rem'} sx={{ backgroundColor: "#F5FBFF", '& a': {color: "#21BCFF"} }} >
      <Stack direction={'row'} justifyContent={'space-between'}>
        <img
          src={images.kedExpandIcon}
          alt="ked.io"
        />
        <Button variant="contained" component="label" className="Start_Application" onClick={handleButtonClick}>
          <Typography color={'#fff'} fontWeight={'500'}>Get Started</Typography>
        </Button>
      </Stack>
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Typography
          variant="h6"
          sx={{ color: "#565872", fontSize: { xs: "22px", md: "30px" } }}
        >
          APPLICANT PRIVACY POLICY
        </Typography>
        <Stack gap={'1rem'}>
          <Typography color={'#5E5E5E'} fontSize={'1rem'} fontWeight={'bold'}>This privacy policy covers the following:<a href="www.ked.io">www.ked.io</a></Typography>
          <Typography>
            <p>
              We value your trust when you share personal data with us.
              This privacy policy explains how we handle your information when you visit our websites and systems,
              no matter where you access them from. It also outlines your privacy rights and legal protections.
              In this policy, "you" refers to the person sharing their data, and "your" refers to your personal information and legal rights.
            </p>
            <p>
              This policy covers our website <a href="www.ked.io" > www.ked.io </a> When you use our website,
              or provide us with your personal information, this policy applies to how we handle your data.
              You must read and acknowledge this policy before submitting an application.
              If you disagree with how we handle your data, we recommend not using our website and systems.
            </p>
            <p>Our goal with this policy is to inform you about how KED.IO collects and processes your personal data, whether provided by you or obtained through your use of our website and systems. Please note that our website and systems are not intended for children, and we do not knowingly collect data related to children.</p>
            <p>It's essential to read this privacy policy alongside any fair processing statements we may provide on specific occasions when collecting or processing your personal data. This will help you understand how and why we use your data.</p>
            <p>Be aware that our website and systems may have links to third-party websites, plugins, and applications. Clicking on these links or enabling connections may allow third parties to collect or share data about you. We don't control these third-party websites and are not responsible for their privacy policies or data handling. When you leave our website or systems, we recommend reviewing the privacy policy of every website you visit.</p>
            <p>In summary, this policy explains how we handle your data, ensuring your trust is respected and protected when you use our website and systems.</p>
            <h6>What is personal data?</h6>
            <p>Personal data refers to any information that can be used to identify a specific individual. This information can be about a person's characteristics, behaviour’s, preferences, or any other aspect of their identity.</p>
            <h6>Personal Details:</h6>
            <Stack gap={'0.5rem'}>
              <BulletPoint title="Name: Your title, forename, middle name(s), and surname." />
              <BulletPoint title="Date of Birth: Your date of birth." />
              <BulletPoint title="Nationality at Birth: Your birth nationality." />
              <BulletPoint title="Place of Birth: Your country, county, and town of birth." />
              <BulletPoint title="Gender: Your gender." />
              <BulletPoint title="Mother's Maiden Name: Your mother's maiden name." />
            </Stack>
            <h6>Personal History:</h6>
            <Stack gap={'0.5rem'}>
              <BulletPoint title="Nationality Changes: Any change in your nationality." />
              <BulletPoint title="Surname Changes: Any change in your surname." />
              <BulletPoint title="Other Known Names: Any other names you are known by." />
              <BulletPoint title="Criminal Record: Confirmation of any unspent convictions (respond with 'yes' or 'no')." />
            </Stack>
            <h6>Address Information:</h6>
            <Stack gap={'0.5rem'}>
              <BulletPoint title="5-Year Address History: Your residential addresses for the past five years." />
            </Stack>
            <h6>ID Information:</h6>
            <Stack gap={'0.5rem'}>
              <BulletPoint title="National Insurance Number: Your national insurance number." />
              <BulletPoint title="National Identity Card: Details of your national identity card." />
              <BulletPoint title="Passport: Passport details." />
              <BulletPoint title="Driving License: Driving license details." />
              <BulletPoint title="Special Category Data: Note that specific data categories may be processed depending on the type of ID you provide, especially when a Right to Work check is requested." />
            </Stack>
            <h6>Contact Information:</h6>
            <Stack gap={'0.5rem'}>
              <BulletPoint title="Email Address: Your email address." />
              <BulletPoint title="Telephone Number(s): Your contact phone number(s)." />
            </Stack>
            <h6>Technical Data:</h6>
            <Stack gap={'0.5rem'}>
              <BulletPoint title="Internet Protocol (IP) Address: Your IP address." />
              <BulletPoint title="Browser Information: Browser type and version." />
              <BulletPoint title="Location: Time zone setting and location." />
              <BulletPoint title="Browser Plug-ins: Browser plug-in types and versions." />
              <BulletPoint title="Operating System: Your operating system and platform." />
              <BulletPoint title="Device Technology: Other technology details related to the devices you use to access our website and/or systems." />
            </Stack>
            <h6>Usage Data:</h6>
            <Stack gap={'0.5rem'}>
              <BulletPoint title="Information on how you use our website, systems, products, and services." />
            </Stack>
            <h6>Profile and Transaction Data (for payment purposes):</h6>
            <Stack gap={'0.5rem'}>
              <BulletPoint title="Payment History: Your payment and purchase history with us (excluding financial details like card payment information)." />
              <BulletPoint title="Preferences and Interests: Your interests and preferences." />
              <BulletPoint title="Feedback and Surveys: Your feedback and responses to surveys." />
            </Stack>
            <h6>Medical Data:</h6>
            <Stack gap={'0.5rem'}>
              <BulletPoint title="Vaccination Records (at client's request): Information related to vaccines you have received, including the type of vaccine, vaccine provider, vaccination dates, medical exemption details, proof of vaccination, or evidence of medical exemption." />
            </Stack>
            <h6>Results Data:</h6>
            <Stack gap={'0.5rem'}>
              <BulletPoint title='Outcome Notice: An indicative notice confirming either a "clear" or "see disclosure" / "wait" result following a check requested by our controller client. In some cases, if requested by our controller client, this may also include criminal record data from Disclosure Scotland applications included in certificates.' />
            </Stack>
          </Typography>
          <Typography color={'#5E5E5E'} fontSize={'2rem'} fontWeight={'bold'}>Ked.io – our role</Typography>
          <Typography>
            <p>In most cases, KED.IO acts as a data processor of all applicant personal data it receives and our clients (who request checks on an applicant’s behalf) are data controllers of that data. Our clients include employers, recruitment and volunteer agencies, as common examples.</p>
            <h6>Data we collect about you.</h6>
            <p>We may collect, use, store and/or transfer the following types of personal data about you where you are an applicant. The following is dependent on the checks requested by our client.</p>
            <h6>Outcome Notice:</h6>
            <p>An indicative notice confirming either a "clear" or "see disclosure" / "wait" result following a check requested by our controller client. In some cases, if requested by our controller client, this may also include criminal record data from Disclosure Scotland applications included in certificates.</p>
            <p>We gather personal data from you through various methods, including:</p>
            <p>Direct Interactions: You may be requested to provide specific identity and contact information when filling out forms on our website or when communicating with us via telephone or email. The following activities necessitate the provision of certain personal data.</p>
            <p>Automated Technologies or Interactions: While you engage with our website, we automatically collect technical data related to your equipment, browsing actions, and usage patterns. We obtain this personal data through the use of cookies, server logs, and similar technologies. For more detailed information, please refer to our cookies policy, available on our website.</p>
            <p>Contact Data: Information provided to us by your employer or potential employer.</p>
            <h5>Purposes for Data Usage</h5>
            <p>For a detailed description of the purposes for which we process your data as a data processor and the legal grounds for doing so, please refer to 1 to 3 below. It's important to note that we may process your data based on multiple legal grounds, depending on the specific purpose for which your data is being used. If you have any questions, please don't hesitate to contact us at info@ked.io or refer to the additional contact information provided in this policy.</p>
            <h6>1.Purpose</h6>
            <p>Providing an online vetting and screening service on behalf of our clients (including troubleshooting, data analysis, support, system improvements, reporting, hosting of data, and, when requested by our clients for Disclosure Scotland applications, posting certificates we receive on behalf of our clients, and in some cases, scanning application results and uploading them to our secure client portal).</p>
            <p>Providing an employee vaccine recording service on behalf of our clients (including troubleshooting, support, system improvements, reporting, and data hosting).</p>
            <h6>2.Data Type</h6>
            <p>Personal information, Personal history, Address information, ID information, Technical data, Usage data, Results data.</p>
            <h6>3.Legal basis for processing</h6>
            <p>KED.IO acts as a data processor, performing services on behalf of our clients (the data controllers). We rely on our clients' lawful basis to process your personal data, in accordance with their instructions. To understand the lawful basis for sharing your personal data with us for vetting and screening checks, please refer to the privacy notices of your employer/prospective employer, recruitment agency, or other relevant party. If you are an  applicant, your relationship and any contractual agreements are with our client, not KED.IO.</p>
            <h5>General items</h5>
            <p>How we disclose your personal data to third parties.</p>
            <Stack gap={'0.5rem'}>
              <BulletPoint title='System administration services, Service providers located in the United Kingdom: These providers act as processors or sub-processors and offer IT and ' />
              <BulletPoint title="Professional advisers based in the United Kingdom: These advisers, including lawyers, bankers, auditors, and insurers, act as processors or joint controllers. They provide consultancy, banking, legal, insurance, and accounting services." />
              <BulletPoint title="Third-party card payment providers: These providers process your card details for check payments and also function as data controllers in their own capacity." />
              <BulletPoint title="HM Revenue & Customs, regulatory bodies, and other authorities in the United Kingdom: These entities act as processors or joint controllers in specific situations, and they may require the reporting of processing activities." />
              <BulletPoint title="Service providers acting as processors: These providers conduct external identity verification and authentication solutions for applicants in connection with a DBS or Right to Work check." />
              <BulletPoint title="Third parties involved in business transactions: We may choose to sell, transfer, or merge parts of our business or assets. Alternatively, we may consider acquiring other businesses or merging with them. If such changes occur within our business, the new owners may utilize your personal data in the same manner outlined in this privacy policy." />
            </Stack>
            <p>We mandate that all third parties uphold the security and legal compliance of your personal data. Our third-party service providers, when acting as sub-processors, are not allowed to use your personal data for their own purposes. They are only permitted to process your personal data for specific purposes in accordance with our instructions.</p>
            <h5>Change of Purpose</h5>
            <p>We will exclusively employ your personal data for the purposes we specify during its collection, unless we reasonably determine that there is a need to use it for another purpose that aligns with the original one. If you ever seek clarification on how a new processing purpose aligns with our initial intent, please don't hesitate to contact us at info@ked.io refer to the additional contact information provided in this policy.</p>
            <p>In cases where we must utilize your personal data for a purpose unrelated to the original one, we will promptly inform you and elucidate the legal basis permitting us to do so.</p>
            <p>Please be aware that, in accordance with the aforementioned regulations, we may process your personal data without your explicit knowledge or consent when mandated or permitted by the law.</p>
            <h6>Marketing</h6>
            <p>We do not, at this time, use your personal data for marketing purposes. We will not use your personal data for marketing purposes unless we have a valid lawful basis to do so.</p>
            <h6>Third-party marketing</h6>
            <p>We will obtain your prior, express opt-in consent before we share your personal data with any third party for marketing purposes.</p>
            <h6 id="cookie_policy">Cookies</h6>
            <p>You have the option to configure your web browser to decline all cookies or selectively notify you when websites attempt to set or access cookies. Be mindful that disabling or rejecting cookies may render certain sections of the website and/or systems inaccessible or impair their functionality. For detailed information about the cookies we employ, please refer to our cookies policy, which is accessible on our website.</p>
            <h6>Data Security</h6>
            <p>Ensuring the security of your data is a matter of paramount importance to us. To safeguard and protect the personal data collected through our website and/or systems, we have implemented comprehensive physical, electronic, and managerial protocols.</p>
            <p>We've instituted robust security measures to prevent accidental loss, unauthorized access, improper use, alteration, or disclosure of your personal data. Furthermore, we restrict access to your personal data to individuals such as employees, agents, contractors, and third parties who have a legitimate business need to access it. These authorized personnel only process your personal data as per our explicit instructions and are bound by confidentiality obligations.</p>
            <p>Procedures are in place to promptly address any suspicions of a personal data breach, and we will inform you and any relevant regulatory authorities if legal obligations necessitate such notification.</p>
            <p>For added security, we utilize AES 256-bit encryption for all personal data, ensuring its safety and integrity. Additionally, we maintain redundant mirrored database servers to provide comprehensive data protection for both our organization and all our customers, with daily backups being performed routinely.</p>
            <p>Despite the extensive security measures we employ, it's important to acknowledge that internet data transmission may not be entirely impervious. Thus, we advise you to take suitable precautions when transmitting data to us over the internet.</p>
            <p>We employ appropriate methods for data collection, storage, and processing, coupled with security measures to defend against unauthorized access, alteration, disclosure, or destruction of your personal information, transaction data, and information stored on our website. All our pages are secured with robust SSL encryption, and personal data is encrypted when stored.</p>
            <p>All data pertaining to you is stored on our secure servers or secure servers operated by a third party within the United Kingdom.</p>
            <h5>Your Legal Rights</h5>
            <p>Under specific circumstances, you possess rights in accordance with data protection laws regarding your personal data. These rights include the ability to:</p>
            <p>Request access to your personal data, commonly referred to as a "data subject access request." This right always applies, though there are exceptions, and you might not always receive all the information we process. Further details about this right can be found on the ICO's website <a href="https://ico.org.uk/your-data-matters/your-right-of-access/">https://ico.org.uk/your-data-matters/your-right-of-access/</a>.</p>
            <h6>In relation to this right:</h6>
            <Stack gap={'0.5rem'}>
              <BulletPoint title="Access Management: When KED.IO acts as a data processor (please see the section titled 'Our Role' above), we are obliged to assist the data controller in responding to access requests related to your rights as a data subject. As a result, data subject access requests received from applicants will be forwarded to the controller (KED.IO's client), and we will aid them in their response." />
              <BulletPoint title="Fee: Normally, you won't be required to pay a fee to access your personal data or to exercise any other rights outlined here. However, we may levy a reasonable fee if your access request is evidently unfounded, repetitive, or excessive. Alternatively, in such cases, we may choose not to fulfil your request." />
              <BulletPoint title="Information from You: We might need specific information from you to verify your identity and establish your right to access your personal data or exercise any other rights stated here. This serves as a security measure to prevent the disclosure of personal data to individuals who are not entitled to it. We may also reach out to you for additional information related to your request to expedite our response." />
              <BulletPoint title="Response Time: We make every effort to address all legitimate requests within one month. In exceptional situations, particularly when the request is intricate or numerous, it might take us longer than a month. In such instances, we will inform you and keep you updated." />
            </Stack>
            <h6>Data Retention</h6>
            <p>We will keep your personal data only for as long as it's reasonably necessary to fulfil the purposes for which we collected it, which includes meeting any legal, regulatory, tax, accounting, or reporting requirements. In cases of complaints or when there's a reasonable expectation of litigation regarding our relationship with you, we may retain your personal data for a longer duration.</p>
            <p>Applications initiated within our systems but left incomplete are automatically removed from our systems after 3 months from their creation date.</p>
            <p>For specific details on how long different aspects of your personal data are retained, you can refer to our retention policy. You can request a copy of this policy from us by reaching out to us at info@ked.io using the additional contact details provided in this policy.</p>
            <p>Additionally, you have the right to request the correction of your personal data that we hold. This enables you to rectify any incomplete or inaccurate data we have concerning you, though we might need to verify the accuracy of the new data you provide to us. This right is always applicable. For more information on this right, you can refer to the ICO's website <a href="https://ico.org.uk/your-data-matters/your-right-to-get-your-data-corrected/">https://ico.org.uk/your-data-matters/your-right-to-get-your-data-corrected/</a>.</p>
            <h6>Important Note Regarding Correction:</h6>
            <p>Once you have submitted an application using our system, any alterations to the information within that application can no longer be made without withdrawing the entire application. Consequently, correcting information already submitted via our system would necessitate the submission of a new application, along with a new application fee. After you submit an application, the sole personal data retained by KED.IO pertains to the record of the submitted application. Should you have any questions regarding this process, please do not hesitate to reach out to us at info@ked.io using the additional contact details provided in this policy.</p>
            <h6>Request for Data Erasure:</h6>
            <p>You also have the right to request the deletion or removal of your personal data. This right allows you to ask us to erase personal data where there is no valid reason for us to continue processing it. Furthermore, you can request the deletion or removal of your personal data in instances where you have exercised your right to object to the processing (as discussed below), when we might have processed your data unlawfully, or when erasing your personal data is necessary to comply with local laws.</p>
            <p>However, please be aware that we may not always be able to fulfil your request for data erasure due to specific legal reasons. If this is the case, we will notify you of the reasons, if applicable, at the time of your request. For more information on this right, you can refer to the ICO's website <a href="https://ico.org.uk/your-data-matters/your-right-to-get-your-data-deleted/">https://ico.org.uk/your-data-matters/your-right-to-get-your-data-deleted/</a>.</p>
            <h6>Right to Object to Data Processing:</h6>
            <p>You have the right to object to the processing of your personal data when we are relying on a legitimate interest (or that of a third party) as the basis for processing, and you believe that our processing impacts your fundamental rights and freedoms. Additionally, you retain the right to object to the processing of your personal data by KED.IO for direct marketing purposes. However, please note that this right doesn't apply since we do not engage in direct marketing activities. In certain situations, we may demonstrate that we have compelling legitimate reasons to process your data, which outweigh your rights and freedoms. For more information on this right, you can visit the ICO's website <a href="https://ico.org.uk/your-data-matters/the-right-to-object-to-the-use-of-your-data">https://ico.org.uk/your-data-matters/the-right-to-object-to-the-use-of-your-data</a>.</p>
            <h6>Request for Processing Restriction:</h6>
            <p>You can request the suspension or restriction of the processing of your personal data in specific circumstances, allowing you more control over your data. Further information on this right is available on the ICO's website <a href="https://ico.org.uk/your-data-matters/your-right-to-limit-how-organisations-use-your-data/">https://ico.org.uk/your-data-matters/your-right-to-limit-how-organisations-use-your-data/</a>.</p>
            <h6>Data Portability:</h6>
            <p>This right pertains only to information you have provided to us. You have the option to request the transfer of this information from our organization to another or to receive it yourself. This right applies when we are processing information based on your consent, or when data processing is necessary for the fulfilment of a contract and involves <a href="https://ico.org.uk/your-data-matters/your-right-to-data-portability/">https://ico.org.uk/your-data-matters/your-right-to-data-portability/</a>.</p>
            <h6>Withdrawing Your Consent:</h6>
            <p>You have the ability to withdraw your consent at any time, especially when we rely on your consent for processing your personal data. However, it's important to note that such withdrawal does not affect the legality of any processing that occurred before your consent was revoked. Please be aware that in certain instances, withdrawing your consent may result in the unavailability of specific products or services. If such a situation arises when you decide to withdraw your consent, we will promptly notify you.</p>
            <h6>Automated Decision-Making:</h6>
            <p>If we employ your personal data for automated decision-making that has a legal or similarly significant impact on you, you possess the right to challenge these decisions. You can also request human intervention, express your perspective, and obtain an explanation of the decision from us.</p>
            <p>If you wish to exercise any of the rights mentioned above, please don't hesitate to reach out to us at info@ked.io by using the additional contact details provided in this policy.</p>
          </Typography>
          <Typography color={'#5E5E5E'} fontSize={'1.5rem'} fontWeight={'bold'}>Updates to this privacy policy</Typography>
          <Typography>
            <p>We will review this privacy policy regularly and update it if necessary. Any updates to this privacy policy will be posted on our website and systems.</p>
            <h6>Contact</h6>
            <p>Should you have any questions about this privacy policy or our privacy practices, please contact us using the following details:</p>
            <Stack gap={'1rem'}>
              <BulletPoint title="Data Protection Officer:	Tim Vaughan" />
              <BulletPoint title="Email address:	info@ked.io" />
              <BulletPoint title="Registered Office:	Dawson House, 5 Jewry Street, LONDON EC3N 2EX" />
              <BulletPoint title="Telephone number:	+44 207 971 1250" />
            </Stack>
            <p>You have the right to make a complaint: at any time to the Information Commissioner's Office (ICO), the UK supervisory authority for data protection issues <a href="www.ico.org.uk">(www.ico.org.uk)</a>. We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance.</p>
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PrivacyPolicy;