import React, { Component, ReactElement } from "react";
import InputField from "../InputField";
import { Grid, TextFieldProps, Typography } from "@mui/material";
import "./form.scss";
import DropDownInput from "../DropDownInput";
import DateInput from "../DateInput/DateInput";
import dayjs from "dayjs";
import { validateInput } from "../../utils/validators/Validators";
import { CalendarPickerView } from "@mui/lab";

export interface FormValidators {
  check: any;
  message: string;
  num?: number;
  gender?: string;
  title?: string;
}

export interface FormModel {
  field: string;
  label: string;
  placeholder: string;
  required: boolean;
  validators: FormValidators[];
  value: string | number;
  disabled?: boolean;
  styleClass?: string;
  responsive?: any;
  autoFocus?: any;
  variant?: any;
  size?: any;
  inputProps?: any;
  typeValue?: any;
  type?: any;
  options?: { label: string; value: string; icon?: string }[];
  style?: any;
  className?: string;
  sx?: any;
  disablePast?: boolean;
  disableFuture?: boolean;
  minDate?: any;
  maxDate?: any;
  openTo?: CalendarPickerView;
  views?: CalendarPickerView[];
  inputFormat?: string;
}

export interface FormDataModel {
  [key: string]: string | number | boolean;
}

interface FormProps {
  isFormUpdated?: boolean;
  values: any;
  model: FormModel[];
  testId?: string;
  onChange?: (
    field: string,
    value: string,
    formData: FormDataModel,
    isFormValid: boolean
  ) => void;
  card?: any;
  hasError?: boolean;
  fieldError?: string;
}

export default class Form extends Component<FormProps> {
  state: { formData: FormDataModel; isFormValid: boolean } = {
    formData: {},
    isFormValid: false,
  };

  componentDidMount() {
    this.prepareFormData();
  }

  componentDidUpdate(prevProps: Readonly<FormProps>) {
    const { values, isFormUpdated } = this.props;
    if (
      (!!isFormUpdated && isFormUpdated !== prevProps.isFormUpdated) ||
      (values &&
        Object.keys(values).length > 0 &&
        JSON.stringify(values) !== JSON.stringify(prevProps.values))
    ) {
      this.prepareFormData();
    }
  }

  handleChange = (
    value: string,
    field: string,
    error?: { error: boolean; message: string },
    deleted?: { deletedField: string; DeletedFile: string }
  ) => {
    const formData: FormDataModel = this.state.formData;

    formData[field] = value;
    if (deleted?.deletedField === field) {
      formData[field + "deleted"] = deleted?.DeletedFile;
    }
    formData[field + "Error"] = !!(error && error.error);
    this.setState({
      formData,
      isFormValid: this.validateForm(formData),
    });
    if (this.props.onChange) {
      const isFormValid = this.validateForm(formData);
      this.props.onChange(field, value, formData, isFormValid);
    }
  };

  validateForm = (formData: FormDataModel) => {
    const { model } = this.props;
    let isFormValid = true;
    model.forEach((item) => {
      if (item.required || formData[item.field + "Error"]) {
        isFormValid = isFormValid && !formData[item.field + "Error"];
      }
    });
    return isFormValid;
  };

  getFormData = () => {
    const { formData, isFormValid } = this.state;
    return { formData, isFormValid };
  };

  resetForm = () => {
    this.prepareFormData();
  };

  prepareFormData() {
    const { model, values } = this.props;
    const formData: FormDataModel = {};
    if (values && Object.keys(values).length !== 0) {
      model.forEach((item) => {
        formData[item.field] =
          values &&
          (values[item.field] || values[item.field] === 0) &&
          values[item.field] !== ""
            ? values[item.field]
            : "";
        const error = validateInput(item.validators, values[item.field]);
        formData[item.field + "Error"] =
          (item.required && !(!!values && !!values[item.field])) ||
          (!!values[item.field] && !!(error && error.error));
      });
      this.setState({ formData, isFormValid: this.validateForm(formData) });
    } else {
      model.forEach((item) => {
        formData[item.field] =
          values && values[item.field] ? values[item.field] : "";
        formData[item.field + "Error"] = item.required;
      });

      this.setState({ formData, isFormValid: this.validateForm(formData) });
    }
  }

  renderFormFields() {
    const { model, hasError, fieldError } = this.props;
    const { formData } = this.state;
    const arrayOfFields: ReactElement[] = [];
    model.forEach((item, key) => {
      switch (item.type) {
        case "divider":
          arrayOfFields.push(
            <Grid key={key} xs={12} item sx={{ borderBottom: "1px solid" }} />
          );
          break;
        case "line-break":
          arrayOfFields.push(<Grid key={key} item xs={12} />);
          break;
        case "label":
          arrayOfFields.push(
            <Grid
              key={key}
              {...item.responsive}
              item
              className={"form-group " + item.styleClass}
            >
              <Typography
                variant={item.variant}
                style={item.style}
                sx={item.sx}
              >
                {item.label}
              </Typography>
            </Grid>
          );
          break;
        case "text":
          arrayOfFields.push(
            <Grid
              key={key}
              {...item.responsive}
              item
              className={"form-group " + item.styleClass}
            >
              <InputField
                disabled={item.disabled || false}
                autoFocus={item.autoFocus || false}
                variant={item.variant}
                size={item.size}
                inputProps={item.inputProps || {}}
                hasError={hasError || false}
                field={item.field}
                inputValue={
                  formData[item.field] || formData[item.field] === 0
                    ? (formData[item.field] as string)
                    : ""
                }
                style={item.style}
                typeValue={item.typeValue || ""}
                label={item.label || ""}
                fieldError={fieldError || ""}
                validators={item.validators}
                className={item.className}
                textChange={this.handleChange}
                sx={item.sx}
              />
            </Grid>
          );
          break;
        case "drop-down":
          arrayOfFields.push(
            <Grid
              key={key}
              {...item.responsive}
              item
              className={"form-group " + item.styleClass}
            >
              <DropDownInput
                options={
                  item.options as {
                    label: string;
                    value: string;
                    icon?: string;
                  }[]
                }
                className={""}
                id={""}
                style={item.style}
                labelId={""}
                disabled={item.disabled || false}
                inputProps={item.inputProps || {}}
                hasError={hasError || false}
                field={item.field}
                inputValue={
                  formData[item.field] || formData[item.field] === 0
                    ? (formData[item.field] as string)
                    : ""
                }
                label={item.label || ""}
                fieldError={
                  item.field
                    ? (formData[item.field + "Error"] as boolean)
                    : false
                }
                validators={item.validators}
                textChange={this.handleChange}
                sx={undefined}
              />
            </Grid>
          );
          break;
        case "date":
          arrayOfFields.push(
            <Grid
              key={key}
              {...item.responsive}
              item
              className={"form-group " + item.styleClass}
            >
              <DateInput
                label={item.label}
                validators={item.validators}
                textChange={this.handleChange}
                value={
                  formData[item.field] || formData[item.field] === 0
                    ? dayjs(new Date(formData[item.field] as string))
                    : null
                }
                field={item.field}
                onChange={(e) => e}
                disablePast={item.disablePast}
                disableFuture={item.disableFuture}
                minDate={item.minDate}
                maxDate={item.maxDate}
                placeholder={item.placeholder}
                openTo={item.openTo as CalendarPickerView}
                views={item.views as CalendarPickerView[]}
                inputFormat={item.inputFormat}
                disabled={item.disabled}
                renderInput={(params: TextFieldProps) => (
                  <InputField
                    size={item.size}
                    hasError={hasError || false}
                    inputValue={params.value as string}
                    fieldError={fieldError || ""}
                    onKeyDown={(e) => e.preventDefault()}
                    {...params}
                  />
                )}
              />
            </Grid>
          );
          break;
        default:
          break;
      }
    });

    return arrayOfFields;
  }

  render() {
    return this.renderFormFields();
  }
}
