import React, {
  FC,
  MouseEvent,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Grid, Stack, Typography } from "@mui/material";
import Form, { FormDataModel } from "../../../components/Form";
import { ProfileCompletionForm } from "./ProfileCompletionForm";
import PrimaryButton from "../../../components/Button/PrimaryButton";
import { removeErrorFieldsFromValues } from "../../../utils/validators";
import { images } from "../../../utils/constants/images";
import * as Amplify from "../../../utils/services/amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useDispatch } from "react-redux";
import {
  setAuthentication,
  setNotificationMessage,
} from "../../../utils/redux";
import { agency } from "../../../utils/constants/routes";
import "./ProfileCompletion.scss";
import { identifiers } from "../../../utils/constants/identifiers";
import { tourStep } from "../../../utils/constants/coachMarks";
import { useTour } from "@reactour/tour";
import { isTourCompleted, markTourAsCompleted } from "../../../utils/storage/tours";

const ProfileCompletion: FC = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  let userDetailForm: RefObject<Form | null | undefined> = useRef();
  const { setIsOpen, currentStep, setCurrentStep } = useTour();

  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [buttonActive, setButtonActive] = useState<boolean>(false);
  const [formValue, setFormValue] = useState<any>({});
  const [companyDisabled, setCompanyDisabled] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const user = await Amplify.UserDetail();
      const { attributes } = user as any;
      setFormValue(attributes);
      if (attributes["custom:company"]) {
        setCompanyDisabled(true);
      }
    })();
  }, []);

  const setProfile = async (
    e: MouseEvent<HTMLButtonElement>,
    callback?: () => void
  ) => {
    e.preventDefault();
    setLoading(true);
    const { getFormData } = userDetailForm.current as {
      getFormData: () => { formData: FormDataModel; isFormValid: boolean };
    };
    const { formData, isFormValid } = getFormData();
    const body: FormDataModel = removeErrorFieldsFromValues(formData);
    try {
      setHasError(false);
      if (isFormValid) {
        const user: CognitoUser = await Amplify.UserDetail();
        const data: any = {
          ...body,
          phone_number: ((body.code as string) + body.phone_number) as string,
          "custom:role": "agency",
          "custom:phone_code": body.code as string,
          name: `${body.given_name} ${body.family_name}`,
        };
        delete data.code;
        await Amplify.UpdateUserDetails(data, user);
        await confirmOtp();
      } else {
        setHasError(true);
      }
    } catch (error: any) {
      setLoading(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in updating your details",
          autoHideDuration: 6000,
        })
      );
      if (callback) {
        callback();
      } else {
      }
    }
  };

  const confirmOtp = useCallback(async () => {
    setLoading(true);
    try {
      await Amplify.RefreshSession();
      const user = await Amplify.UserDetail();
      setLoading(false);
      dispatch(
        setAuthentication({
          authenticated: true,
          isProfileComplete: true,
          role: "agency",
          user: user.attributes,
        })
      );
      Navigate(agency.dashboard);
    } catch (e: any) {
      setLoading(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in updating your details",
          autoHideDuration: 6000,
        })
      );
      if (e.response) {
        // setActionMessage(true, "warning", e.response.data.message);
      }
    }
  }, [Navigate, dispatch]);

  useEffect(() => {
    setCurrentStep(tourStep.signup.index);
  }, [setCurrentStep]);

  useEffect(() => {
    if (tourStep.signup.index === currentStep) {
      if (!isTourCompleted('signup')) {
        setIsOpen(true);
      }
    } else if (currentStep === tourStep.signup.steps + tourStep.signup.index) {
      setIsOpen(false);
      markTourAsCompleted('signup');
    }
  }, [currentStep, setIsOpen]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={0}
        >
          <Typography
            variant="h6"
            sx={{
              mb: 1,
              fontSize: 14,
              fontWeight: 500,
              color: "#333333",
            }}
          >
            Step 3/3
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 1,
              fontSize: 24,
              fontWeight: 600,
              color: "#333333",
            }}
            data-tut="sign_up"
          >
            Basic Details
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 5,
              fontSize: 12,
              fontWeight: 400,
              color: "#727272",
            }}
          >
            Please provide your basic details to continue
          </Typography>
        </Stack>
        <img src={images.kedIcon} alt="kedIcon" />
      </Stack>
      <Grid container spacing={2}>
        <Form
          hasError={hasError}
          fieldError={identifiers.field_error as string}
          ref={userDetailForm as RefObject<Form>}
          model={ProfileCompletionForm(companyDisabled)}
          values={formValue}
          onChange={(field, value, formData, isFormValid) =>
            setButtonActive(isFormValid)
          }
        />
      </Grid>
      <PrimaryButton
        disabled={loading || !buttonActive}
        sx={{
          mt: 1,
          background: "#5AB9F9",
          color: "#ffffff",
          fontSize: 16,
          fontWeight: 500,
        }}
        onClick={setProfile}
      >
        {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
        Agree & Continue
      </PrimaryButton>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ mt: 4 }}
      >
        <Typography
          variant="body1"
          sx={{
            color: "#727272",
            fontSize: 12,
            fontWeight: 500,
          }}
          align={"center"}
        >
          By clicking Agree & Continue, you agree to the <a href="/privacy_policy" target={"_blank"} style={{ color: '#21bcff' }}>KED User Agreement, Privacy Policy</a>, and <a href="/privacy_policy#cookie_policy" target={"#cookie_policy"} style={{ color: '#21bcff' }}>Cookie Policy</a>.
        </Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ mt: 4 }}
      >
        <Typography
          variant="body1"
          sx={{
            color: "#727272",
            fontSize: 12,
            fontWeight: 500,
          }}
          align={"center"}
        >
          Not {formValue.email}?{" "}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: 12,
            fontWeight: 400,
            color: "#5AB9F9",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={async () => {
            await Amplify.Logout();
            dispatch(
              setAuthentication({
                authenticated: false,
                isProfileComplete: false,
                user: null,
              })
            );
          }}
        >
          Logout
        </Typography>
      </Stack>
    </>
  );
};

export default ProfileCompletion;
