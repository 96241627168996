import React, { FC, RefObject, useEffect, useRef, useState } from "react";
import { Alert, Grid, Typography } from "@mui/material";
import { CurrentAddressModel } from "./CurrentAddressModel";
import Form, {
  FormDataModel,
} from "../../../../../../../../../components/Form";
import PrimaryButton from "../../../../../../../../../components/Button/PrimaryButton";
import { UCheckProps } from "../UCheckModel";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  setCurrentAddress,
  setPreviousAddress,
  UCheckCurrentAddress,
  UCheckPersonalInformation,
  UCheckPreviousAddresses,
} from "../../../../../../../../../utils/redux";
import { AddressHistory } from "../../../../../../../../../utils/redux/reducer/u-check-slice";
import { removeErrorFieldsFromValues } from "../../../../../../../../../utils/validators";
import { PreviousAddressModel } from "./PreviousAddressModel";

const AddressInformation: FC<UCheckProps> = ({
  activeStep,
  handleBack,
  handleNext,
}) => {
  let CurrentAddressForm: RefObject<Form | null | undefined> = useRef();
  let PreviousAddressForm: RefObject<Form | null | undefined> = useRef();
  const dispatch = useDispatch();

  const uCheckPersonalInformation = useSelector(UCheckPersonalInformation);
  const uCheckCurrentAddress = useSelector(UCheckCurrentAddress);
  const uCheckPreviousAddresses = useSelector(UCheckPreviousAddresses);

  const today = dayjs(new Date().setDate(1));

  const [count, setCount] = useState<number>(0);
  const [previousAddressesValue, setPreviousAddressesValue] =
    useState<FormDataModel>({});
  const [difference, setDifference] = useState<string>();

  useEffect(() => {
    const formValue: any = {};
    uCheckPreviousAddresses.address.forEach((address, i) => {
      Object.keys(address).forEach((key) => {
        formValue[key + "_" + (i + 1)] = address[key as keyof AddressHistory];
      });
    });
    setPreviousAddressesValue(formValue);
    setCount(uCheckPreviousAddresses.address.length);
  }, [uCheckPreviousAddresses.address]);

  const handleCurrentAddress = (
    field: string,
    value: string,
    formData: FormDataModel,
    isFormValid: boolean
  ) => {
    let address: FormDataModel = removeErrorFieldsFromValues(formData);
    let form_valid = isFormValid;
    if (field === "country_code") {
      if (
        value === "GB" ||
        value === "IM" ||
        value === "JE" ||
        value === "GG"
      ) {
        form_valid = false;
      } else {
        let valid = true;
        Object.keys(formData).forEach((key) => {
          if (key.indexOf("postcode") < 0 && key.indexOf("Error") > -1) {
            if (formData[key]) {
              valid = false;
            }
          }
        });
        form_valid = valid;
      }
    }
    dispatch(
      setCurrentAddress({
        address,
        form_valid,
      })
    );
  };

  const handlePreviousAddress = (
    field: string,
    value: string,
    formData: FormDataModel,
    valid: boolean
  ) => {
    const formBody: FormDataModel = removeErrorFieldsFromValues(formData);
    setPreviousAddressesValue(formBody);
    let form_valid = valid;
    const previous_addresses: any[] = Array.from({ length: count }, () => ({}));
    Object.keys(formBody).forEach((key) => {
      if (key.indexOf("label") < 0 && key.indexOf("divider") < 0) {
        const name = key.split("_");
        const index = parseInt(key.split("_")[key.split("_").length - 1]) - 1;
        name.splice(name.length - 1, 1);
        previous_addresses[index][name.join("_") as keyof AddressHistory] =
          formBody[key] as string;
      }
    });
    if (field.indexOf(`country_code_${count}`) > -1) {
      if (
        value === "GB" ||
        value === "IM" ||
        value === "JE" ||
        value === "GG"
      ) {
        form_valid = false;
      } else {
        let valid = true;
        Object.keys(formData).forEach((key) => {
          if (
            key.indexOf(`postcode_${count}`) < 0 &&
            key.indexOf(`_${count}Error`) > -1
          ) {
            if (formData[key]) {
              valid = false;
            }
          }
        });
        form_valid = valid;
      }
    }
    dispatch(
      setPreviousAddress({
        address: previous_addresses,
        form_valid,
      })
    );
  };

  const next = () => {
    if (count === 0) {
      if (
        today.diff(
          uCheckCurrentAddress.address.resident_from_gyear_month,
          "M"
        ) < 60
      ) {
        const previous_address: AddressHistory = {
          address_line1: "",
          address_line2: "",
          address_town: "",
          address_county: "",
          postcode: "",
          country_code: "",
          resident_to_gyear_month:
            uCheckCurrentAddress.address.resident_from_gyear_month,
        };
        const years = today.diff(
          uCheckCurrentAddress.address.resident_from_gyear_month,
          "y"
        );
        const months = today
          .subtract(years, "y")
          .diff(uCheckCurrentAddress.address.resident_from_gyear_month, "M");
        setDifference(
          `Address added for: ${
            years > 0 ? years + " year" + (years > 1 ? "s" : "") : ""
          } ${months > 0 ? months + " month" + (months > 1 ? "s" : "") : ""}`
        );
        dispatch(
          setPreviousAddress({ address: [previous_address], form_valid: false })
        );
      } else {
        if (handleNext) {
          handleNext();
        }
      }
    } else {
      if (
        today.diff(
          uCheckPreviousAddresses.address[count - 1].resident_from_gyear_month,
          "M"
        ) < 60
      ) {
        const previous_address: AddressHistory = {
          address_line1: "",
          address_line2: "",
          address_town: "",
          address_county: "",
          postcode: "",
          country_code: "",
          resident_to_gyear_month:
            uCheckPreviousAddresses.address[count - 1]
              .resident_from_gyear_month,
        };
        const years = today.diff(
          uCheckPreviousAddresses.address[count - 1].resident_from_gyear_month,
          "y"
        );
        const months = today
          .subtract(years, "y")
          .diff(
            uCheckPreviousAddresses.address[count - 1]
              .resident_from_gyear_month,
            "M"
          );
        setDifference(
          `Address added for: ${
            years > 0 ? years + " year" + (years > 1 ? "s" : "") : ""
          } ${months > 0 ? months + " month" + (months > 1 ? "s" : "") : ""}`
        );
        dispatch(
          setPreviousAddress({
            address: [...uCheckPreviousAddresses.address, previous_address],
            form_valid: false,
          })
        );
      } else {
        if (handleNext) {
          handleNext();
        }
      }
    }
  };

  const deleteAddress = () => {
    if (count - 1 === 0) {
      setCount(0);
      dispatch(setPreviousAddress({ address: [], form_valid: true }));
    } else {
      setCount((prevState) => prevState - 1);
      const previous_addresses = JSON.parse(
        JSON.stringify(uCheckPreviousAddresses.address)
      );
      previous_addresses.splice(count - 1, 1);
      dispatch(
        setPreviousAddress({ address: previous_addresses, form_valid: true })
      );
    }
  };

  return (
    <Grid container spacing={2} justifyContent={"space-evenly"}>
      {!!difference && (
        <Grid item xs={12}>
          <Alert icon={false} severity={"success"}>
            {difference}
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{
            fontSize: 18,
            fontWeight: 500,
            color: "#000000",
          }}
        >
          Current Address
        </Typography>
      </Grid>
      <Form
        values={uCheckCurrentAddress.address}
        ref={CurrentAddressForm as RefObject<Form>}
        model={CurrentAddressModel(
          uCheckPersonalInformation,
          uCheckCurrentAddress.address,
          count > 0
        )}
        onChange={handleCurrentAddress}
      />
      {count > 0 && (
        <>
          <Grid item xs={12} textAlign={"end"}>
            <Typography variant="body2">
              * Remove all Previous Addresses to edit your Current Address
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{
                fontSize: 18,
                fontWeight: 500,
                color: "#000000",
              }}
            >
              Previous Addresses
            </Typography>
          </Grid>
          <Form
            values={previousAddressesValue}
            ref={PreviousAddressForm as RefObject<Form>}
            model={PreviousAddressModel(
              uCheckPersonalInformation,
              uCheckCurrentAddress.address,
              uCheckPreviousAddresses.address,
              count
            )}
            onChange={handlePreviousAddress}
          />
          <Grid item xs={12} justifyContent={"flex-end"} textAlign={"end"}>
            <PrimaryButton
              variant={"outlined"}
              color={"error"}
              fullWidth={false}
              onClick={deleteAddress}
            >
              Delete Address
            </PrimaryButton>
          </Grid>
        </>
      )}
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          pt: 2,
          mt: 2,
          borderTop: "1px solid #0000004d",
        }}
        xs={12}
      >
        <PrimaryButton
          variant={"text"}
          fullWidth={false}
          sx={{
            mr: 2,
            background: "white",
            border: "1px solid #5AB9F9",
            color: "#5AB9F9",
            fontSize: 16,
            height: "46px",
            minWidth: "100px",
          }}
          disabled={activeStep === 0}
          onClick={handleBack}
        >
          Go Back
        </PrimaryButton>
        <PrimaryButton
          fullWidth={false}
          sx={{
            minWidth: "100px",
            background: "#1276D3",
            color: "white",
          }}
          disabled={
            !(
              uCheckCurrentAddress.form_valid &&
              uCheckPreviousAddresses.form_valid
            )
          }
          onClick={next}
        >
          Next
        </PrimaryButton>
      </Grid>
    </Grid>
  );
};

export default AddressInformation;
