export default function avatar(name: string) {
  const stringToColor = (str: string) => {
    let hash = 0;
    let i;
    for (i = 0; i < str.length; i += 1) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  };

  let initials = '';
  const nameParts = name.split(' ');
  if (nameParts.length === 1) {
    initials = name[0];
  } else if (nameParts.length >= 2) {
    initials = nameParts
      .slice(0, 2)
      .map(part => part[0])
      .join('');
  }

  return {

    sx: {
      bgcolor: stringToColor(name),
      innerHeight: '0.5rem',
      textTransform: 'uppercase',
      fontSize: '1rem'
    },
    children: initials,
  };
}
