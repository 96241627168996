import React, { useRef, useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";

interface FileInputProps {
  onFilesSelected: (files: File[]) => void;
}

const FileInput: React.FC<FileInputProps> = ({ onFilesSelected }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const newFiles = Array.from(e.target.files);
      const validFiles = newFiles.filter((file) => isFileValid(file));
      const oversizedFiles = newFiles.filter(
        (file) => file.size > 15 * 1024 * 1024
      ); // 15MB in bytes
      const unsupportedFiles = newFiles.filter(
        (file) => !isFileValid(file)
      );

      let errorMessages: string[] = [];

      if (oversizedFiles.length > 0) {
        const fileNames = oversizedFiles.map((file) => file.name).join(", ");
        errorMessages.push(
          `The following files are larger than 15MB and will not be added: ${fileNames}`
        );
      }

      if (unsupportedFiles.length > 0) {
        const fileNames = unsupportedFiles.map((file) => file.name).join(", ");
        errorMessages.push(
          `The following files are unsupported and will not be added: ${fileNames}`
        );
      }

      if (errorMessages.length > 0) {
        setErrorMessage(errorMessages.join(" "));
      } else {
        setErrorMessage(null);
        onFilesSelected(validFiles);
      }
    }
  };

  const isFileValid = (file: File) => {
    // Define allowed extensions for images and videos
    const imageExtensions = ["jpg", "jpeg", "png"];
    const videoExtensions = ["mp4"];

    const extension = file.name.split(".").pop()?.toLowerCase();

    if (extension) {
      if (file.type.startsWith("image/") && imageExtensions.includes(extension)) {
        return true;
      } else if (file.type.startsWith("video/") && videoExtensions.includes(extension)) {
        return true;
      }
    }

    return false;
  };

  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the file input
      fileInputRef.current.click();
    }
  };

  return (
    <div className="file-input">
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: "none" }}
        accept=".jpg, .jpeg, .png, .mp4"
        multiple={true} // Enable multi-select
        onChange={handleFileInputChange}
      />
      <Stack direction={{
        xs: "column",
        md: "row"
      }} gap={"1rem"}>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={openFileDialog}
          startIcon={<AttachFileOutlinedIcon />}
          sx={{
            fontSize: 16,
            fontWeight: 500,
            width: "fit-content",
            background: "#eee",
            border: "1px solid #a5a5a5",
            boxShadow: "none",
            "&:hover": {
              background: "#e5e5e5",
              color: "black",
              boxShadow: "none",
            },
          }}
        >
          Browse
        </Button>
        <Typography sx={{ fontSize: 12, color: "#555", maxWidth: 400 }}>
          Upload images or videos of the issue. Files can be{" "}
          <strong>jpeg, png, jpg, or mp4</strong> formats. You can upload a maximum
          of 5 files, with each file not exceeding 15 MB in size.
        </Typography>
      </Stack>
      {errorMessage && (
        <Stack sx={{marginTop: '1rem'}}>
          <Typography sx={{ color: "red", fontSize: 14 }}>
            {errorMessage}
          </Typography>
        </Stack>
      )}
    </div>
  );
};

export default FileInput;
