import React, { FC, ReactNode, SyntheticEvent, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import VettingDocuments from "./VettingDocuments";
import "./DocumentRepository.scss";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
  className?: string;
}

const TabPanel: FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const DocumentRepository: FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const [value, setValue] = useState<number>(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container
      sx={
        matches
          ? { minHeight: "calc(100vh - 94px)", mb: 3, mt: 3 }
          : { minHeight: "calc(100vh - 124px)", mb: 3, mt: 3 }
      }
      maxWidth={false}
    >
      <Grid container spacing={2} sx={{ mt: 3 }}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 0 }}>
            <Tabs
              value={value}
              onChange={handleChange}
              sx={{ boxShadow: "none" }}
              TabIndicatorProps={{ sx: { display: "none" } }}
            >
              <Tab
                data-tut="candidate_document_repo_tab"
                className={"document-repository-tabs"}
                label={<span>Vetting Docs </span>}
              />
              {/*<Tab*/}
              {/*  className={"document-repository-tabs"}*/}
              {/*  label={<span>Documents </span>}*/}
              {/*/>*/}
            </Tabs>
          </Box>
          <TabPanel className="tabs-1" value={value} index={0}>
            <VettingDocuments />
          </TabPanel>
          {/*<TabPanel value={value} index={1}>*/}
          {/*  /!* <ReferenceForms /> *!/*/}
          {/*  to be done*/}
          {/*</TabPanel>*/}
        </Box>
      </Grid>
    </Container>
  );
};

export default DocumentRepository;
