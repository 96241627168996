import React, { FC } from "react";
import {
  Stack,
} from "@mui/material";
import { AgencyDetailProps } from "../Interfaces/AgencyInterfaces";
import AgencySummary from "./AgencySummary";
import ComplianceOfficersTable from "./ComplianceOfficersTable";

const AgencyDetails: FC<AgencyDetailProps> = ({
  agency,
  onClickAddComplianceOfficer,
  isMobile
}) => {
  return (
    <Stack
      gap={
        {
          xs: '0.5rem',
          md: '2rem'
        }
      }
      padding={
        {
          xs: '0.5rem',
          md: '2rem'
        }
      }
      flexDirection={
        {
          xs: 'column',
          md: 'row'
        }
      }>
      <AgencySummary
        agency={agency}
        onClickAddComplianceOfficer={onClickAddComplianceOfficer}
        isMobile={isMobile}
      />
      <ComplianceOfficersTable
        agency={agency}
        onClickAddComplianceOfficer={onClickAddComplianceOfficer}
        isMobile={isMobile} />
    </Stack>
  );
}


export default AgencyDetails;
