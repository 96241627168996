import React, {
  ChangeEvent,
  Dispatch,
  FC,
  MutableRefObject,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  tableCellClasses,
  styled,
  TableCell,
  Box,
  Stack,
  TablePagination,
  TableFooter,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { images } from "../../../../../utils/constants/images";
import "../DocumentRepository.scss";
import mime from "mime";
import axiosInstance from "../../../../../utils/axios";
import { URLS } from "../../../../../utils/constants/urls";
import axios from "axios";
import { identifiers } from "../../../../../utils/constants/identifiers";
import { ReferenceModel } from "../DocumentRepositoryModel";
import dayjs from "dayjs";
import PrimaryButton from "../../../../../components/Button/PrimaryButton";
import Modal from "../../../../../components/modal/Modal";
import PageLoader from "../../../../../components/PageLoader";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import { Document, Page } from "react-pdf";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import { setNotificationMessage } from "../../../../../utils/redux";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { AnyAction } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "#727272",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#263238",
    fontSize: "14px",
    fontWeight: 600,
    borderTop: "1px solid #DADADA",
    borderBottom: "1px solid #DADADA",
  },
  [`&.${tableCellClasses.head}:first-of-type`]: {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
    maxWidth: "1em",
    width: "1em",
  },

  [`&.${tableCellClasses.head}:last-of-type`]: {
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    maxWidth: "275px",
    paddingLeft: "40px",
    width: "275px",
  },
  [`&.${tableCellClasses.body}:first-of-type`]: {
    borderTopLeftRadius: "12px",
    borderBottomLeftRadius: "12px",
    borderLeft: "1px solid #DADADA",
  },
  [`&.${tableCellClasses.body}:last-of-type`]: {
    borderTopRightRadius: "12px",
    borderBottomRightRadius: "12px",
    borderRight: "1px solid #DADADA",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&": {
    backgroundColor: "white",
    borderRadius: "8px",
    paddingBottom: "15px",
    paddingTop: "15px",
  },
}));

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "form_name_low_search",
    numeric: true,
    disablePadding: false,
    label: "Form Name",
  },
  {
    id: "created_at",
    numeric: true,
    disablePadding: false,
    label: "Upload Date",
  },
];

interface EnhancedTableProps {
  order: boolean;
  loading: boolean;
  orderBy: string;
  onRequestSort: (property: string) => void;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { orderBy, order, onRequestSort, loading } = props;

  const createSortHandler = (property: string) => {
    onRequestSort(property);
  };

  return (
    <StyledTableRow>
      <StyledTableCell></StyledTableCell>
      {headCells.map((headCell) => (
        <StyledTableCell
          key={headCell.id}
          align={headCell.numeric ? "left" : "right"}
          padding={headCell.disablePadding ? "none" : "normal"}
          sortDirection={order ? "asc" : "desc"}
          title={
            loading
              ? "Please wait until all reference forms are fetched"
              : undefined
          }
        >
          <TableSortLabel
            active={orderBy === headCell.id}
            direction={order ? "asc" : "desc"}
            onClick={() => createSortHandler(headCell.id)}
            disabled={loading}
          >
            {headCell.label}
            {orderBy === headCell.id && (
              <Box component="span" sx={visuallyHidden}>
                {order ? "sorted ascending" : "sorted descending"}
                descending
              </Box>
            )}
          </TableSortLabel>
        </StyledTableCell>
      ))}
      <StyledTableCell>Action</StyledTableCell>
    </StyledTableRow>
  );
}

const LinearProgressWithLabel: FC<
  { value: number; reqNumber: number } & LinearProgressProps
> = ({ value, reqNumber, ...props }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          color="success"
          variant="determinate"
          {...props}
          value={(value / reqNumber) * 100}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color={"secondary"}>{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

const Placeholder = ({ children }: { children: ReactNode }) => {
  return <Box>{children}</Box>;
};

const initial_filter_state: {
  index: number;
  page_size: number;
  name: string;
  sort: boolean;
} = {
  index: 0,
  page_size: 10,
  name: "uploaded_on",
  sort: false,
};

const ReferenceForms: FC = () => {
  const fileUploadRef: MutableRefObject<any> = useRef<any>(null);
  const dispatch: Dispatch<AnyAction> = useDispatch();

  const [documentName, setDocumentName] = useState<string>("");
  const [hasError, setHasError] = useState<boolean>(false);
  const [additionalReferenceDocuments, setAdditionalReferenceDocuments] =
    useState<ReferenceModel[]>([]);
  const [filter, setFilter] = useState<{
    index: number;
    page_size: number;
    name: string;
    sort: boolean;
  }>(initial_filter_state);
  const [loading, setLoading] = useState<boolean>(false);
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [document, setDocument] = useState<ReferenceModel | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [percentageProcess, setPercentageProcess] = useState(0);
  const [opacity, setOpacity] = useState("1");
  const [documentType, setDocumentType] = useState<"pdf" | "image">();
  const [documentUrl, setDocumentUrl] = useState<string>();
  const [viewDocument, setViewDocument] = useState(false);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [count, setCount] = useState<number>(0);
  const [type, setType] = useState<
    "employment" | "character" | "academic" | ""
  >("");
  const [dataFetching, setDataFetching] = useState<boolean>(false);

  const handleRequestSort = async (property: string) => {
    const sort = filter.name === property ? !filter.sort : true;
    await fetchData(property, sort);
    setFilter((prevState) => ({
      ...prevState,
      name: property,
      sort: prevState.name === property ? !prevState.sort : true,
      index: 0,
    }));
  };

  const getReferenceForms = async (sort_column: string, sort: boolean) => {
    // const searchQuery = !!search && `&keyword=${search}`;
    const sortQuery =
      !!sort_column && `&asc_sort=${sort}&sort_column=${sort_column}`;
    let page_key = null;
    const form_list: any[] = [];

    try {
      setLoading(true);
      setDataFetching(true);
      do {
        const {
          data,
        }: {
          data: {
            data: ReferenceModel[];
            page_key: string;
            scanned_count: number;
          };
        } = await axiosInstance.post(
          `${URLS.reference_forms}/all/document?page_size=${filter.page_size}${
            sortQuery || ""
          }`,
          page_key
        );
        setLoading(false);
        form_list.push(...data.data);
        if (!!page_key && page_key !== "null") {
          setAdditionalReferenceDocuments((prevState) => [
            ...prevState,
            ...data.data,
          ]);
        } else {
          setAdditionalReferenceDocuments(data.data);
        }
        page_key = JSON.parse(data.page_key);
      } while (!!page_key && page_key !== "null");
      setAdditionalReferenceDocuments(form_list);
      setCount(form_list.length);
      setDataFetching(false);
    } catch (e: any) {
      setLoading(false);
      setDataFetching(false);
      if (await Auth.currentUserInfo()) {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "Unable to get Questionnaire",
          })
        );
      }
    }
  };

  // const delayedQuery = useRef(debounce(getReferenceForms, 800)).current;

  const fetchData = useRef(debounce(getReferenceForms, 100)).current;

  useEffect(() => {
    (async () => {
      await fetchData(initial_filter_state.name, initial_filter_state.sort);
    })();
  }, [fetchData]);

  // const setSearch = async (search: string) => {
  //   setFilter((prevState) => ({ ...prevState, search, index: 0 }));
  //   delayedQuery(search, filter.name, filter.sort);
  // };

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    setIsUploading(true);
    if (event.target.files) {
      let max_size = identifiers.agency_max_file_size as number;
      if (event.target.files[0].size <= max_size) {
        setLoading(true);
        const file: File = event.target.files[0];
        const file_name = file.name.split(".");
        file_name.splice(file.name.split(".").length - 1, 1);
        const formData: {
          name: string;
          type: string;
          directory: string;
          file_name: string;
          document_type: string;
          reference_form_type: string;
          reference_type: string;
        } = {
          type: mime.getType(file.name)
            ? (mime.getType(file.name) as string)
            : file.type,
          name: `${new Date().getTime()}_${file.name}`,
          file_name: file_name.join("."),
          directory: "form",
          document_type: "reference_form",
          reference_form_type: "DOCUMENT",
          reference_type: type as string,
        };
        setDocumentName(file.name);
        setIsUploading(true);
        try {
          const { data } = await axiosInstance.post(URLS.file_upload, formData);
          await uploadFile(
            file,
            data.url,
            mime.getType(file.name)
              ? (mime.getType(file.name) as string)
              : file.type
          );
        } catch (e: any) {
          setIsUploading(false);
          setLoading(false);
          dispatch(
            setNotificationMessage({
              display: true,
              severity: "error",
              message: `Unable to Upload File`,
            })
          );
        }
      } else {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: `You cannot upload file more than ${
              (identifiers.agency_max_file_size as number) / 1000000
            }MB`,
          })
        );
        if (fileUploadRef.current) {
          fileUploadRef.current.value = null;
        }
      }
    }
  };

  const uploadFile = async (file: File, url: string, mime: string) => {
    setOpacity("0.6");
    try {
      const { status } = await axios.put(url, file, {
        headers: { "Content-Type": mime },
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / (progressEvent.total || 100)
          );
          setPercentageProcess(percentCompleted);
        },
      });
      if (status === 200) {
        setOpacity("1");
        setIsUploading(false);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "success",
            message: `File ${documentName} has been uploaded Successfully`,
          })
        );
        setIsUploading(false);
        if (fileUploadRef.current) {
          fileUploadRef.current.value = null;
        }
        setLoading(false);
        setType("");
        setTimeout(() => fetchData(filter.name, filter.sort), 5000);
      }
    } catch (e) {
      setOpacity("1");
      setIsUploading(false);
      setLoading(false);
      setFileLoading(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in uploading document",
        })
      );
    }
  };

  const handleUpload = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!!type) {
      if (fileUploadRef.current) {
        fileUploadRef.current.click();
      }
    } else {
      setHasError(true);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter((prevState) => ({ ...prevState, index: newPage }));
  };

  const download = async (id: string, view: boolean) => {
    try {
      const { data } = await axiosInstance.get(
        `${URLS.document}/${id}?type=reference`
      );
      const type =
        data.mimetype.indexOf("pdf") > -1
          ? "pdf"
          : data.mimetype.indexOf("image") > -1
          ? "image"
          : null;
      if (!!type && view) {
        setDocumentType(type);
        setDocumentUrl(data.url);
        setViewDocument(true);
      } else {
        window.open(data.url, "_blank");
      }
    } catch (e) {
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in downloading document",
        })
      );
    }
  };

  const deleteFile = async (selectedDocument: ReferenceModel) => {
    setDocument(selectedDocument);
    setOpenModal(true);
  };

  const deleteDocuments = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        await axiosInstance.delete(`${URLS.document}/${id}?type=reference`);
        setLoading(false);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "success",
            message: "Document deleted successfully",
          })
        );
        setOpenModal(false);
        setFilter((prevState) => ({ ...prevState, delay: 0 }));
      } catch (e) {
        setLoading(false);
        console.log(e);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "There is an error in deleting document",
          })
        );
        setOpenModal(false);
      }
    },
    [dispatch, setFilter]
  );

  const deleteModalText = useMemo(() => {
    return (
      <Grid container>
        {!!document && (
          <>
            <Grid item>
              <Typography
                variant="h6"
                sx={{
                  marginBottom: "20px",
                  fontSize: 18,
                  fontWeight: 500,
                  color: "#000000",
                  textAlign: "center",
                  letterSpacing: "0.15px",
                }}
              >
                Delete Document Permanently
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  mb: 3,
                  fontSize: 16,
                  fontWeight: 500,
                  color: "#727272",
                }}
              >
                {document.name} will be deleted permanently.
                <br />
                Are you sure?
              </Typography>
            </Grid>
            <Grid container alignItems="right" justifyContent="right">
              <Button
                sx={{
                  border: "none",
                  color: "#727272",
                  fontSize: 16,
                  fontWeight: 500,
                  mr: 2,
                  mt: 1,
                  p: "10px",
                }}
                disabled={loading}
                onClick={async () => {
                  await deleteDocuments(document.id);
                }}
              >
                {loading && <CircularProgress sx={{ mr: 1 }} size={30} />}Delete
                Document
              </Button>
              <PrimaryButton
                disabled={loading}
                fullWidth={false}
                sx={{
                  mt: 1,
                  background: "#5AB9F9",
                  color: "#ffffff",
                  fontSize: 16,
                  fontWeight: 500,
                }}
                onClick={() => {
                  setOpenModal(false);
                  setDocument(null);
                }}
              >
                Cancel
              </PrimaryButton>
            </Grid>
          </>
        )}
      </Grid>
    );
  }, [deleteDocuments, document, loading]);

  const viewModalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          {documentType === "pdf" && (
            <Document
              file={{ url: documentUrl }}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          )}
          {documentType === "image" && (
            <img src={documentUrl} alt={"document"} className={"img-fluid"} />
          )}
        </Grid>
      </Grid>
    );
  }, [documentType, documentUrl, numPages]);

  const openDeleteModal = useMemo(() => {
    return (
      <Modal
        open={openModal}
        setModalClose={setOpenModal}
        children={deleteModalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [openModal, deleteModalText]);

  const openViewModal = useMemo(() => {
    return (
      <Modal
        open={viewDocument}
        setModalClose={setViewDocument}
        children={viewModalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [viewDocument, viewModalText]);

  return (
    <>
      {loading && <PageLoader />}
      <Grid container spacing={2} sx={{ px: 0 }}>
        {openDeleteModal}
        {openViewModal}
        <Grid item xs={12} md={4} sx={{ display: { lg: "none" } }}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            padding="0"
            className="mob-card text-center pt-0"
          >
            <Grid item md={12} className="card-heading-right mb-1">
              Upload Reference Forms
            </Grid>
            <Grid item xs={12} className="dotted-card content-card">
              <Typography variant={"body1"} color={"#727272"} sx={{ mb: 1 }}>
                Reference Form Name
              </Typography>
              <FormControl
                fullWidth
                sx={{
                  textAlign: "start",
                  borderRadius: "8px",
                  ".MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  mb: 3,
                }}
              >
                <Select
                  value={type}
                  sx={{
                    height: 38,
                    py: 2,
                    backgroundColor: "#F6F7FB",
                    overFlow: "hidden",
                    width: "100%",
                    textTransform: "capitalize",
                  }}
                  onChange={(e) => {
                    setType(
                      e.target.value as "employment" | "character" | "academic"
                    );
                    setHasError(false);
                  }}
                  displayEmpty
                  renderValue={
                    !type
                      ? () => (
                          <Placeholder>Select Reference Form Type</Placeholder>
                        )
                      : undefined
                  }
                >
                  {["Employment", "Character", "Academic"].map(
                    (category_data, index) => (
                      <MenuItem value={category_data.toLowerCase()} key={index}>
                        {category_data}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              {hasError && (
                <FormHelperText
                  sx={{
                    color: "red",
                    display: "flex",
                    justifyContent: "center",
                    mb: "16px",
                  }}
                >
                  Select Category for Reference Form
                </FormHelperText>
              )}
              <Button
                variant="contained"
                component="label"
                className="upload-button"
                onClick={handleUpload}
                disabled={loading || hasError || fileLoading}
              >
                Choose File
              </Button>
              <input
                ref={fileUploadRef}
                hidden
                accept={identifiers.accept as string}
                type="file"
                onChange={handleFileUpload}
              />
              <p className="instructions mt-4">
                {/*or drag file in here <br />*/}
                accepted file .doc, .docx and .pdf (max size 2MB)
              </p>
            </Grid>
            {isUploading && (
              <Grid
                item
                className="dotted-card pt-1 text-start pb-1 "
                sx={{ mt: 2 }}
                width={"100%"}
                md={9}
              >
                {documentName}
                <LinearProgressWithLabel
                  variant="determinate"
                  value={percentageProcess}
                  reqNumber={100}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        {/*<Grid item xs={12}>*/}
        {/*  <Grid*/}
        {/*    container*/}
        {/*    sx={{*/}
        {/*      display: "flex",*/}
        {/*      flexDirection: "row",*/}
        {/*      justifyContent: { xs: "space-between", md: "flex-start" },*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <Grid item xs={12} lg={3}>*/}
        {/*      <InputField*/}
        {/*        sx={{ mr: 1 }}*/}
        {/*        placeholder="Search Document..."*/}
        {/*        // value={filter.search}*/}
        {/*        // textChange={setSearch}*/}
        {/*        inputProps={{*/}
        {/*          sx: {*/}
        {/*            p: 1,*/}
        {/*          },*/}
        {/*        }}*/}
        {/*        InputProps={{*/}
        {/*          endAdornment: <SearchIcon />,*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    </Grid>*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
        {!loading && (
          <>
            <Grid item xs={12} md={12} lg={8} paddingRight={3}>
              <Grid
                container
                sx={{
                  borderRadius: "18px",
                  p: 0,
                  backgroundColor: "white",
                }}
              >
                <Grid container className="card-head">
                  <Grid item md={5} className="card-header-text">
                    Uploaded Reference Forms: {count}
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={"mb-3"}>
                  <Grid item xs={12} md={12}>
                    <TableContainer
                      component={Paper}
                      sx={{ boxShadow: "none" }}
                    >
                      <Table
                        sx={{
                          minWidth: 700,
                          borderSpacing: "0 1rem",
                          borderCollapse: "separate",
                        }}
                      >
                        <TableHead>
                          <EnhancedTableHead
                            order={filter.sort}
                            orderBy={filter.name}
                            onRequestSort={handleRequestSort}
                            loading={dataFetching}
                          />
                        </TableHead>
                        <TableBody className={"documents-table-body"}>
                          {additionalReferenceDocuments
                            .slice(
                              filter.index * filter.page_size,
                              filter.index * filter.page_size + filter.page_size
                            )
                            .map((reference_form, index) => (
                              <StyledTableRow
                                className={"documents-table-body-row"}
                                key={index}
                              >
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell
                                  sx={{
                                    width: "250px",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {reference_form.name}
                                </StyledTableCell>
                                <StyledTableCell
                                  sx={{
                                    width: "350px",
                                  }}
                                >
                                  {dayjs(
                                    new Date(reference_form.created_at)
                                  ).format("DD-MM-YYYY")}
                                </StyledTableCell>
                                <StyledTableCell
                                  sx={{ textTransform: "capitalize" }}
                                >
                                  {reference_form.reference_type}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Stack direction={"row"}>
                                    <Button
                                      onClick={() => deleteFile(reference_form)}
                                      sx={{
                                        border: "none",
                                        color: "#727272",
                                        fontSize: 16,
                                        fontWeight: 500,
                                        mr: 2,
                                        mt: 1,
                                        p: "10px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <img
                                        src={images.deleteIcon}
                                        alt="delete"
                                      />
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        download(reference_form.id, false)
                                      }
                                      sx={{
                                        border: "none",
                                        color: "#727272",
                                        fontSize: 16,
                                        fontWeight: 500,
                                        mr: 2,
                                        mt: 1,
                                        p: "10px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <img
                                        src={images.downloadIcon}
                                        alt="download"
                                      />
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        download(reference_form.id, true)
                                      }
                                      sx={{
                                        border: "none",
                                        color: "#727272",
                                        fontSize: 16,
                                        fontWeight: 500,
                                        mr: 2,
                                        mt: 1,
                                        p: "10px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <img
                                        src={images.viewIcon}
                                        alt="view"
                                        className="action-icon"
                                      />
                                    </Button>
                                  </Stack>
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          {additionalReferenceDocuments.length === 0 &&
                            !loading && (
                              <StyledTableRow
                                className={"request-table-body-row"}
                              >
                                <StyledTableCell colSpan={7}>
                                  <Stack textAlign={"center"}>
                                    <img
                                      src={images.emptyIcon}
                                      alt={"no request"}
                                      className={
                                        "vertical-align-middle empty-image mb-2"
                                      }
                                    />
                                    <Stack
                                      direction={"row"}
                                      justifyContent={"center"}
                                    >
                                      <Typography variant={"body2"}>
                                        No Documents Found, Upload To see the
                                        Documents.
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                </StyledTableCell>
                              </StyledTableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              colSpan={6}
                              count={additionalReferenceDocuments.length}
                              rowsPerPage={filter.page_size}
                              page={filter.index}
                              onRowsPerPageChange={(e) => {
                                setFilter((prevState) => ({
                                  ...prevState,
                                  delay: 0,
                                  page_size: parseInt(e.target.value, 10),
                                }));
                              }}
                              onPageChange={handleChangePage}
                              ActionsComponent={TablePaginationActions}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} lg={4}>
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                //className="card-right text-center p-5"
                sx={{
                  background: "#f5fbff",
                  padding: "40px 0 70px 0 ",
                  display: { xs: "none", md: "inline-block" },
                  borderRadius: "10px",
                  textAlign: "center",
                  p: 5,
                }}
              >
                <Grid item xs={12} className="mb-5 card-heading-right mt-5">
                  Upload Reference Forms
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ opacity: `${opacity}` }}
                  className="dotted-card"
                >
                  <Typography
                    variant={"body1"}
                    color={"#727272"}
                    sx={{ mb: 1 }}
                  >
                    Document Name
                  </Typography>
                  <FormControl
                    fullWidth
                    sx={{
                      textAlign: "start",
                      borderRadius: "8px",
                      ".MuiOutlinedInput-notchedOutline": {
                        border: 0,
                      },
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                      mb: 3,
                    }}
                  >
                    <Select
                      value={type}
                      sx={{
                        height: 38,
                        py: 2,
                        backgroundColor: "#F6F7FB",
                        overFlow: "hidden",
                        width: "100%",
                        textTransform: "capitalize",
                      }}
                      onChange={(e) => {
                        setType(
                          e.target.value as
                            | "employment"
                            | "character"
                            | "academic"
                        );
                        setHasError(false);
                      }}
                      displayEmpty
                      renderValue={
                        !type
                          ? () => (
                              <Placeholder>
                                Select Reference Form Type
                              </Placeholder>
                            )
                          : undefined
                      }
                    >
                      {["Employment", "Character", "Academic"].map(
                        (category_data, index) => (
                          <MenuItem
                            value={category_data.toLowerCase()}
                            key={index}
                          >
                            {category_data}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                  {hasError && (
                    <FormHelperText
                      sx={{
                        color: "red",
                        display: "flex",
                        justifyContent: "center",
                        mb: "16px",
                      }}
                    >
                      Select Category for Reference Form
                    </FormHelperText>
                  )}
                  <Button
                    variant="contained"
                    component="label"
                    className="upload-button"
                    onClick={handleUpload}
                    disabled={loading || hasError || fileLoading}
                  >
                    Choose File
                  </Button>
                  <input
                    ref={fileUploadRef}
                    hidden
                    accept={identifiers.accept as string}
                    type="file"
                    size={identifiers.maxFileSize as number}
                    onChange={handleFileUpload}
                  />
                  <p className="instructions mt-4">
                    accepted file .doc, .docx and .pdf (max size 2MB)
                  </p>
                </Grid>
                {isUploading && (
                  <Grid
                    item
                    className="dotted-card pt-3 text-start pb-3 "
                    sx={{ mt: 2 }}
                    width={"100%"}
                  >
                    {documentName}
                    <LinearProgressWithLabel
                      variant="determinate"
                      value={percentageProcess}
                      reqNumber={100}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default ReferenceForms;
