import {
  Box,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { FC, useRef, useState, RefObject, MouseEvent } from "react";
import PrimaryButton from "../../../../components/Button/PrimaryButton";
import Form, { FormDataModel } from "../../../../components/Form";
import { InviteMemberForm } from "./InviteMemberForm";
import { removeErrorFieldsFromValues } from "../../../../utils/validators";
import axiosInstance from "../../../../utils/axios";
import { URLS } from "../../../../utils/constants/urls";
import "./InviteMembers.scss";
import { identifiers } from "../../../../utils/constants/identifiers";
import { setNotificationMessage } from "../../../../utils/redux";
import { useDispatch } from "react-redux";

const InviteForm: FC<any> = ({ getTeamMembers, count, variant }) => {
  const dispatch = useDispatch();
  let InviteMemberFormRef: RefObject<Form | null | undefined> = useRef();
  const theme=useTheme()  
  const isDekstop = useMediaQuery(theme.breakpoints.up("md"));

  const [hasError, setHasError] = useState<boolean>(false);
  const [comments, setComments] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonActive, setButtonActive] = useState<boolean>(false);

  // const [disablePage, setDisablePage] = useState<boolean>(true);

  const handleSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoading(true);
    const { getFormData, resetForm } = InviteMemberFormRef.current as {
      getFormData: () => { formData: FormDataModel; isFormValid: boolean };
      resetForm: () => void;
    };
    const { formData, isFormValid } = getFormData();
    const body: FormDataModel = removeErrorFieldsFromValues(formData);
    try {
      setHasError(false);
      if (isFormValid) {
        await axiosInstance.post(`${URLS.team_members}/invite`, {
          ...body,
          comment: comments,
        });
        setLoading(false);
        resetForm();
        setComments("");
        // setOpen(false);
        await getTeamMembers();
        // }
      } else {
        setHasError(true);
      }
    } catch (e: any) {
      setLoading(false);
      console.log(e?.response?.status);
      if (e?.response?.status === 409) {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: e?.response?.data?.errors[0]?.message,
          })
        );
      } else {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "Unable to Invite member",
          })
        );
      }
    }
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={4}
      padding={2}
    >
      <Stack
        direction="column"
        justifyContent="center"
        // alignItems="center"
        //   spacing={0}
        width="100%"
        sx={{
          alignItems: { xs: "start", sm: "start", md: "center" },
          padding: "0px 25px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: { xs: "24px", sm: "24px", md: "18px" },
            fontWeight: 500,
            color: "#333333",
            lineHeight: "28px",
            letterSpacing: 0.15,
          }}
        >
          Invite Team Members
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontSize: { xs: "14px", sm: "14px", md: "12px" },
            fontWeight: 500,
            color: "#727272",
            lineHeight: "14.06px",
            letterSpacing: 0.15,
            marginTop: { xs: "10px", sm: "10px", md: "5px" },
          }}
        >
          Please fill in the required details for your Inviting Team Members
        </Typography>
      </Stack>
      <Paper
        variant={variant}
        sx={{
          p: 3,
          width: {
            sx: "fit-content",
            sm: "fit-content",
            md: "fit-content",
            lg: "568px",
          },
        }}
      >
        <Grid container spacing={2}>
          <Form
            hasError={hasError}
            fieldError={identifiers.field_error as string}
            ref={InviteMemberFormRef as RefObject<Form>}
            model={InviteMemberForm()}
            values={{}}
            onChange={(field, value, formData, isFormValid) =>
              setButtonActive(isFormValid)
            }
          />
        </Grid>
        <Typography
          variant="h6"
          sx={{
            fontSize: 12,
            fontWeight: 500,
            color: "#727272",
            lineHeight: "14px",
            letterSpacing: 0.15,
            mb: 2,
            mt: 1,
          }}
        >
          Additional Comments (optional)
        </Typography>
        <Box>
          <TextField
            value={comments}
            multiline
            rows={3}
            variant="outlined"
            fullWidth
            placeholder="Type here...."
            onChange={(e: any) => {
              setComments(e.target.value);
            }}
          />
        </Box>
      </Paper>

      <PrimaryButton
        sx={{ mt: 2, background: "#5AB9F9", color: "#ffffff", px: 10 }}
        //className="invite-btn"
        variant="contained"
        fullWidth={false}
        onClick={handleSubmit}
        data-tut={isDekstop?"add_members_btn":''}
        disabled={loading || count === 5 || !buttonActive}
      >
        {loading && <CircularProgress sx={{ mr: 1 }} size={30} />}
        Invite Members
      </PrimaryButton>
    </Stack>
  );
};

export default InviteForm;
