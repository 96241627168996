import { Validators } from '../../../utils/validators';
import { FormModel } from '../../../components/Form';
import { Country, State } from 'country-state-city';
import { ICountry, IState } from 'country-state-city/lib/interface';
import './ProfileCompletion.scss';

const countries: ICountry[] = Country.getAllCountries()
  .map((country, index) => {
    if (country.isoCode === 'GB') {
      return { index: 0, ...country };
    } else if (country.isoCode === 'IE') {
      return { index: 1, ...country };
    }
    return { index: index + 2, ...country };
  })
  .sort((a, b) => a.index - b.index);
const states: IState[] = State.getStatesOfCountry('GB');

export const ProfileCompletionForm = (
  companyDisabled: boolean
): FormModel[] => {
  return [
    {
      label: 'First Name*',
      value: '',
      size: 'small',
      autoFocus: true,
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'given_name',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
        { check: Validators.Name, message: 'Please enter a valid first name' },
      ],
      responsive: { md: 6, xs: 12 },
      required: true,
      style: { background: '#F6F7FB', borderRadius: '8px' },
    },
    {
      label: 'Last Name*',
      value: '',
      size: 'small',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'family_name',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
        { check: Validators.Name, message: 'Please enter a valid last name' },
      ],
      responsive: { md: 6, xs: 12 },
      required: true,
      style: { background: '#F6F7FB', borderRadius: '8px' },
    },
    {
      label: 'Company Name*',
      value: '',
      size: 'small',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'custom:company',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
      ],
      responsive: { md: 6, xs: 12 },
      required: true,
      disabled: companyDisabled,
      style: { background: '#F6F7FB', borderRadius: '8px' },
    },
    {
      label: 'Designation*',
      value: '',
      size: 'small',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'custom:designation',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
      ],
      responsive: { md: 6, xs: 12 },
      required: true,
      style: { background: '#F6F7FB', borderRadius: '8px' },
    },
    {
      label: 'Code*',
      value: '',
      size: 'small',
      type: 'drop-down',
      options: countries.map((country) => ({
        label:
          country.phonecode.indexOf('+') > -1
            ? country.phonecode + ` (${country.isoCode})`
            : '+' + country.phonecode + ` (${country.isoCode})`,
        value:
          country.phonecode.indexOf('+') > -1
            ? country.phonecode
            : '+' + country.phonecode,
        icon: country.isoCode.toLowerCase(),
      })),
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'code',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
      ],
      responsive: { md: 2, xs: 3 },
      required: true,
      style: { background: '#F6F7FB', borderRadius: '8px' },
    },
    {
      label: 'Phone Number*',
      value: '',
      size: 'small',
      type: 'text',
      typeValue: 'tel',
      variant: 'outlined',
      placeholder: '',
      field: 'phone_number',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
        {
          check: Validators.mobile,
          message: 'Please enter a valid phone number',
        },
      ],
      responsive: { md: 4, xs: 9 },
      required: true,
      style: { background: '#F6F7FB', borderRadius: '8px' },
    },
    {
      label: 'Country & State*',
      value: '',
      size: 'small',
      type: 'drop-down',
      options: states.map((state) => ({
        label: state.name,
        value: state.name,
      })),
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'address',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
      ],
      responsive: { lg: 6, sm: 6, xs: 12 },
      required: true,
      style: { background: '#F6F7FB', borderRadius: '8px' },
    },
  ];
};
