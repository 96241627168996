import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { candidate } from "../../../../utils/constants/routes";
import axiosInstance from "../../../../utils/axios";
import { URLS } from "../../../../utils/constants/urls";
import { styled } from "@mui/material/styles";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { RequestDetailsModel } from "../../Agency/RequestDetails/RequestDetailsModel";
import PageLoader from "../../../../components/PageLoader";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import InputField from "../../../../components/InputField";
import { debounce } from "lodash";
import { setNotificationMessage, setUserData } from "../../../../utils/redux";
import { useDispatch, useSelector } from "react-redux";
import { images } from "../../../../utils/constants/images";

import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../utils/constants/coachMarks";
import { CognitoUser } from "amazon-cognito-identity-js";
import * as Amplify from "../../../../utils/services/amplify";
import { User } from "../../../../utils/redux/reducer/authentication-slice";
import { Auth } from "aws-amplify";
import { isTourCompleted, markTourAsCompleted } from "../../../../utils/storage/tours";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F5FBFF",
    color: "#727272",
    border: "none",
    maxWidth: "300px",
    width: "300px",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    color: "#263238",
    fontSize: "14px",
    fontWeight: 600,
  },
  [`&.${tableCellClasses.head}:first-of-type`]: {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
    paddingLeft: "20px",
  },
  [`&.${tableCellClasses.head}:nth-of-type(2)`]: {
    maxWidth: "200px",
    width: "200px",
  },
  [`&.${tableCellClasses.head}:nth-of-type(5)`]: {
    maxWidth: "200px",
    width: "200px",
  },
  [`&.${tableCellClasses.head}:nth-of-type(6)`]: {
    maxWidth: "180px",
    width: "180px",
  },
  [`&.${tableCellClasses.head}:last-of-type`]: {
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    maxWidth: "3em",
    width: "3em",
  },
  [`&.${tableCellClasses.body}:first-of-type`]: {
    borderTopLeftRadius: "12px",
    borderBottomLeftRadius: "12px",
    paddingLeft: "20px",
  },
  [`&.${tableCellClasses.body}:last-of-type`]: {
    borderTopRightRadius: "12px",
    borderBottomRightRadius: "12px",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&": {
    backgroundColor: "#f9fbfc",
    paddingBottom: "15px",
    paddingTop: "15px",
  },
}));

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

interface EnhancedTableProps {
  order: boolean;
  loading: boolean;
  orderBy: string;
  onRequestSort: (property: string) => void;
}

const headCells: readonly HeadCell[] = [
  {
    id: "request_id",
    numeric: true,
    disablePadding: true,
    label: "Request ID",
  },
  {
    id: "company",
    numeric: true,
    disablePadding: false,
    label: "Hiring Organization",
  },
  {
    id: "designation",
    numeric: true,
    disablePadding: false,
    label: "Job Role",
  },
  {
    id: "applied_at",
    numeric: true,
    disablePadding: false,
    label: "Date Applied",
  },
];

function EnhancedTableHead(props: EnhancedTableProps) {
  const { orderBy, order, onRequestSort, loading } = props;

  const createSortHandler = (property: string) => {
    onRequestSort(property);
  };
  return (
    <StyledTableRow>
      {headCells.map((headCell) => (
        <StyledTableCell
          key={headCell.id}
          align={headCell.numeric ? "left" : "right"}
          padding={headCell.disablePadding ? "none" : "normal"}
          sortDirection={order ? "asc" : "desc"}
          title={
            loading ? "Please wait until all requests are fetched" : undefined
          }
        >
          <TableSortLabel
            active={orderBy === headCell.id}
            direction={order ? "asc" : "desc"}
            onClick={() => createSortHandler(headCell.id)}
            disabled={loading}
          >
            {headCell.label}
            {orderBy === headCell.id && (
              <Box component="span" sx={visuallyHidden}>
                {order ? "sorted ascending" : "sorted descending"}
                descending
              </Box>
            )}
          </TableSortLabel>
        </StyledTableCell>
      ))}
      <StyledTableCell>Status</StyledTableCell>
      <StyledTableCell></StyledTableCell>
    </StyledTableRow>
  );
}

const initial_filter_state: {
  search: string;
  category:
    | "designation_low_search"
    | "request_name_low_search"
    | "company_low_search";
  index: number;
  page_size: number;
  name: string;
  sort: boolean;
} = {
  search: "",
  category: "designation_low_search",
  index: 0,
  page_size: 10,
  name: "",
  sort: false,
};

const RequestList: FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDekstop = useMediaQuery(theme.breakpoints.up("md"));
  const Navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const [requests, setRequests] = useState<RequestDetailsModel[]>([]);
  const [filter, setFilter] = useState<{
    search: string;
    category:
      | "designation_low_search"
      | "request_name_low_search"
      | "company_low_search";
    index: number;
    page_size: number;
    name: string;
    sort: boolean;
  }>(initial_filter_state);
  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };
  const [isLoading, setIsLoading] = useState<boolean>();
  const [dataFetching, setDataFetching] = useState<boolean>(false);

  const handleRequestSort = async (property: string) => {
    const sort = filter.name === property ? !filter.sort : true;
    await fetchData(filter.search, filter.category, property, sort);
    setFilter((prevState) => ({
      ...prevState,
      name: property,
      sort: prevState.name === property ? !prevState.sort : true,
      index: 0,
    }));
  };

  const getRequests = async (
    search: string,
    category: string,
    sort_column: string,
    sort: boolean
  ) => {
    const searchQuery =
      !!search && `&keyword=${search}&search_column=${category}`;
    const sortQuery =
      !!sort_column && `&asc_sort=${sort}&sort_column=${sort_column}`;
    let page_key = null;
    const request_list: RequestDetailsModel[] = [];

    try {
      setIsLoading(true);
      setDataFetching(true);
      do {
        const { data } = (await axiosInstance.post(
          `${URLS.candidate_requests}?&page_size=${filter.page_size}${
            sortQuery || ""
          }${searchQuery || ""}`,
          page_key
        )) as { data: any };
        setIsLoading(false);
        request_list.push(...data.data);
        if (!!page_key && page_key !== "null") {
          setRequests((prevState) => [...prevState, ...data.data]);
        } else {
          setRequests(data.data);
        }
        page_key = !!data.page_key ? JSON.parse(data.page_key) : null;
      } while (!!page_key && page_key !== "null");
      setRequests(request_list);
      setDataFetching(false);
    } catch (e: any) {
      setIsLoading(false);
      setDataFetching(false);
      if (await Auth.currentUserInfo()) {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "Unable to Get Request List",
          })
        );
      }
    }
  };
  const { setIsOpen, currentStep, setCurrentStep } = useTour();
  const setCoachMarksValue = useCallback(async () => {
    //if (!!user && user["custom:reference_tutorial"] !== "true") {
    const currentUser: CognitoUser = await Amplify.UserDetail();
    const body = {
      "custom:dashboard": "true",
    };
    await Amplify.UpdateUserDetails(body, currentUser);
    await Amplify.RefreshSession();
    dispatch(setUserData({ ...user, ...body }));
    //}
  }, [user, dispatch]);
  useEffect(() => {
    if (isLoading !== undefined && !isLoading) {
      if (!!user && user["custom:dashboard"] !== "true") {
        setCurrentStep(tourStep.candidate_dashboard.index);
      }
    }
  }, [user, isLoading, setCurrentStep]);

  useEffect(() => {
    if (tourStep.candidate_dashboard.index === currentStep) {
      if (!isTourCompleted('candidate_dashboard')) {
        setIsOpen(true);
      }
    } else if (isDekstop) {
      if (
        currentStep ===
        tourStep.candidate_dashboard.index + tourStep.candidate_dashboard.steps
      ) {
        setIsOpen(false);
        markTourAsCompleted('candidate_dashboard');
        if (!!user && user["custom:dashboard"] !== "true") {
          (async () => {
            await setCoachMarksValue();
          })();
        }
      }
    } else if (!isDekstop) {
      if (
        currentStep ===
        tourStep.candidate_dashboard.index +
          (tourStep.candidate_dashboard.steps - 2)
      ) {
        setIsOpen(false);
        markTourAsCompleted('candidate_dashboard');
        if (!!user && user["custom:dashboard"] !== "true") {
          (async () => {
            await setCoachMarksValue();
          })();
        }
      }
    }
  }, [currentStep, isDekstop, setCoachMarksValue, setIsOpen, user]);

  const delayedQuery = useRef(debounce(getRequests, 800)).current;

  const fetchData = useRef(debounce(getRequests, 100)).current;

  useEffect(() => {
    (async () => {
      await fetchData(
        initial_filter_state.search,
        initial_filter_state.category,
        initial_filter_state.name,
        initial_filter_state.sort
      );
    })();
  }, [fetchData]);

  const navigate = (request: RequestDetailsModel) => {
    Navigate(`${candidate.requests}/${request.request_id}`);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter((prevState) => ({ ...prevState, index: newPage }));
  };

  const setSearch = async (search: string) => {
    setFilter((prevState) => ({ ...prevState, search, index: 0 }));
    delayedQuery(search, filter.category, filter.name, filter.sort);
  };

  const selectCategory = async (e: any) => {
    setFilter((prevState) => ({
      ...prevState,
      search: "",
      category: e.target.value,
      index: 0,
    }));
  };

  return (
    <Container
      maxWidth={false}
      sx={
        matches
          ? { minHeight: "calc(100vh - 114px)", mb: 3 }
          : { minHeight: "calc(100vh - 124px)", mb: 3 }
      }
    >
      {isLoading && <PageLoader />}
      <Grid
        container
        className={"mb-3"}
        sx={{ mt: 4 }}
        justifyContent={matches ? "start" : "space-between"}
      >
        <Grid item xs={4} lg={2} data-tut="sort_dropdown">
          <FormControl fullWidth size={"small"} sx={{ border: "none" }}>
            <Select
              value={filter.category}
              sx={{ height: 39, py: 1, borderColor: "#dadada" }}
              onChange={selectCategory}
              disabled={dataFetching}
              inputProps={{
                title: dataFetching
                  ? "Please wait until all requests are fetched"
                  : undefined,
              }}
            >
              <MenuItem value={"request_name_low_search"}>Request ID</MenuItem>
              <MenuItem value={"company_low_search"}>
                Hiring Organization
              </MenuItem>
              <MenuItem value={"designation_low_search"}>Job Role</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} lg={3} data-tut="search_input">
          <InputField
            sx={{ ml: 1 }}
            placeholder="Search Request..."
            value={filter.search}
            textChange={setSearch}
            inputProps={{
              sx: {
                p: 1,
              },
              title: dataFetching
                ? "Please wait until all requests are fetched"
                : undefined,
            }}
            InputProps={{
              endAdornment: <SearchIcon />,
            }}
            disabled={dataFetching}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} md={12}>
          <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
            <Table
              sx={{
                minWidth: 700,
                borderSpacing: "0 1rem",
                borderCollapse: "separate",
              }}
            >
              <TableHead>
                <EnhancedTableHead
                  order={filter.sort}
                  orderBy={filter.name}
                  onRequestSort={handleRequestSort}
                  loading={dataFetching}
                />
              </TableHead>
              <TableBody className={"request-table-body"}>
                {requests
                  .slice(
                    filter.index * filter.page_size,
                    filter.index * filter.page_size + filter.page_size
                  )
                  .map((request, i) => (
                    <StyledTableRow
                      key={
                        requests.length - (filter.index * filter.page_size + i)
                      }
                      onClick={() => navigate(request)}
                      sx={{ cursor: "pointer" }}
                      className={"request-table-body-row"}
                    >
                      <StyledTableCell>{request.request_name}</StyledTableCell>
                      <StyledTableCell>
                        {request.hiring_organization}
                      </StyledTableCell>
                      <StyledTableCell>{request.job_title}</StyledTableCell>
                      <StyledTableCell>
                        {/*{dayjs(new Date(request.date_applied)).format(*/}
                        {/*  "DD-MM-YYYY"*/}
                        {/*)}*/}
                        {request.date_applied}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          fullWidth={false}
                          sx={{
                            background:
                              request.status === "Ready for Review"
                                ? "#C5EAFD"
                                : request.status === "Approved"
                                ? "#5AB9F9"
                                : request.status === "In Progress"
                                ? "#F8E592"
                                : "#E56D6D",
                            color: "#333333",
                            fontSize: 14,
                            fontWeight: 500,
                            padding: "4px 14px",
                            minWidth: "180px",
                          }}
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                        >
                          {request.status}
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button variant={"text"}>
                          <ChevronRightIcon />
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                {requests.length === 0 && !isLoading && (
                  <StyledTableRow className={"request-table-body-row"}>
                    <StyledTableCell colSpan={7}>
                      <Stack textAlign={"center"}>
                        <img
                          src={images.emptyIcon}
                          alt={"no request"}
                          className={"vertical-align-middle empty-image"}
                        />
                        <Typography variant={"body2"}>
                          You have not applied to any Requests
                        </Typography>
                      </Stack>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={7}
                    count={requests.length}
                    rowsPerPage={filter.page_size}
                    page={filter.index}
                    onRowsPerPageChange={(e) =>
                      setFilter((prevState) => ({
                        ...prevState,
                        index: 0,
                        page_size: parseInt(e.target.value, 10),
                      }))
                    }
                    onPageChange={handleChangePage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RequestList;
