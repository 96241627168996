import { ICountry } from 'country-state-city/lib/interface';
import { Country } from 'country-state-city';
import { FormModel } from '../../../../../../../../../components/Form';
import { Validators } from '../../../../../../../../../utils/validators';
import { registerLocale } from 'i18n-nationality';
import { DrivingLicenceInformation } from '../../../../../../../../../utils/redux/reducer/u-check-slice';
import { UCheckValidators } from '../../../../../../../../../utils/validators/uCheckValidators';

registerLocale(require('i18n-nationality/langs/en.json'));
const countries: ICountry[] = Country.getAllCountries();

export const DrivingLicenceModel: (
  form2Value: DrivingLicenceInformation,
  disabled?:boolean,
) => FormModel[] = (form2Value,disabled) => {
  const form: FormModel[] = [
    {
      label: 'Do you have a UK Driving License? *',
      value: '',
      size: 'small',
      type: 'drop-down',
      options: [
        { label: 'Yes', value: 'y' },
        { label: 'No', value: 'n' },
      ],
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'uk_driving_licence',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
      ],
      responsive: { md: 12, xs: 12 },
      required: true,
      style: { background: '#FFFFFF', borderRadius: '8px' },
      disabled:disabled,
    },
  ];
  if (
    !!form2Value.uk_driving_licence &&
    form2Value.uk_driving_licence === 'y'
  ) {
    form.push(
      {
        label: 'UK Driving License Number*',
        value: '',
        size: 'small',
        type: 'text',
        typeValue: 'text',
        variant: 'outlined',
        placeholder: '',
        field: 'driver_licence_number',
        validators: [
          { check: Validators.required, message: 'This field is mandatory' },
          { check: UCheckValidators.DrivingLicence, message: 'Please enter valid Driving Licence number  ' },
          {
            check: Validators.maxCharLimit,
            message: 'The driving licence number should have a maximum length of 18 characters.',
            num: 18,
          },
        ],
        responsive: { xs: 12, md: 12 },
        required: true,
        style: { background: '#FFFFFF', borderRadius: '8px' },
        disabled:disabled,
      },
      {
        label: 'UK Driving License Date of birth *',
        value: '',
        size: 'small',
        type: 'date',
        typeValue: 'text',
        variant: 'outlined',
        placeholder: '',
        disableFuture: true,
        field: 'driver_licence_dob',
        validators: [
          { check: Validators.required, message: 'This field is mandatory' },
        ],
        responsive: { md: 12, xs: 12 },
        required: true,
        style: { background: '#FFFFFF', borderRadius: '8px' },
        disabled:disabled,
      },
      {
        label: 'UK Driving License Issue Date *',
        value: '',
        size: 'small',
        type: 'date',
        typeValue: 'text',
        variant: 'outlined',
        placeholder: '',
        disableFuture: true,
        field: 'driver_licence_valid_from',
        validators: [
          { check: Validators.required, message: 'This field is mandatory' },
        ],
        responsive: { md: 12, xs: 12 },
        required: true,
        style: { background: '#FFFFFF', borderRadius: '8px' },
        disabled:disabled
      },
      {
        label: 'UK Driving License issue Country *',
        value: '',
        size: 'small',
        type: 'drop-down',
        options: countries.map((country) => ({
          label: country.name,
          value: `${country.isoCode}`,
        })),
        typeValue: 'text',
        variant: 'outlined',
        placeholder: '',
        field: 'driver_licence_issue_country',
        validators: [
          { check: Validators.required, message: 'This field is mandatory' },
        ],
        responsive: { md: 12, xs: 12 },
        required: true,
        style: { background: '#FFFFFF', borderRadius: '8px' },
        disabled:disabled
      }
      // {
      //   label: 'Passport Issue  year *',
      //   value: '',
      //   size: 'small',
      //   type: 'drop-down',
      //   options: year,
      //   typeValue: 'text',
      //   variant: 'outlined',
      //   placeholder: '',
      //   field: 'passport_issue_year',
      //   validators: [
      //     { check: Validators.required, message: 'This field is mandatory' },
      //   ],
      //   responsive: { md: 3, xs: 6,  },
      //   required: true,
      //   style: { background: '#FFFFFF', borderRadius: '8px' },
      // },
      // {
      //     label: 'Do you have a UK Driving License? *',
      //     value: '',
      //     size: 'small',
      //     type: 'drop-down',
      //     options: [
      //       { label: 'Yes', value: 'yes' },
      //       { label: 'No', value: 'no' },
      //     ],
      //     typeValue: 'text',
      //     variant: 'outlined',
      //     placeholder: '',
      //     field: 'uk_driving_licence',
      //     validators: [
      //       { check: Validators.required, message: 'This field is mandatory' },
      //     ],
      //     responsive: { md: 12, xs: 12,  },
      //     required: true,
      //     style: { background: '#FFFFFF', borderRadius: '8px' },
      //   },
    );
  }
  //   if(hasUkLicence==='yes'){
  //    form.push(
  //     {
  //         label: 'Driving License Issue Date *',
  //         value: '',
  //         size: 'small',
  //         type: 'date',
  //         typeValue: 'text',
  //         variant: 'outlined',
  //         placeholder: '',
  //         disableFuture: true,
  //         field: 'licence_issue_month',
  //         validators: [
  //           { check: Validators.required, message: 'This field is mandatory' },
  //         ],
  //         responsive: { md: 12, xs: 12,  },
  //         required: true,
  //         style: { background: '#FFFFFF', borderRadius: '8px' },
  //       },
  //       // {
  //       //   label: 'Driving License Issue  year *',
  //       //   value: '',
  //       //   size: 'small',
  //       //   type: 'drop-down',
  //       //   options: year,
  //       //   typeValue: 'text',
  //       //   variant: 'outlined',
  //       //   placeholder: '',
  //       //   field: 'licence_issue_year',
  //       //   validators: [
  //       //     { check: Validators.required, message: 'This field is mandatory' },
  //       //   ],
  //       //   responsive: { md: 3, xs: 6,  },
  //       //   required: true,
  //       //   style: { background: '#FFFFFF', borderRadius: '8px' },
  //       // },
  //    )
  // }
  // if(hasPassport==='yes'){
  //     form.push(
  //         {
  //             label: 'Is your passport and e-passport? *',
  //             value: '',
  //             size: 'small',
  //             type: 'drop-down',
  //             options: [
  //               { label: 'Yes', value: 'yes' },
  //               { label: 'No', value: 'no' },
  //             ],
  //             typeValue: 'text',
  //             variant: 'outlined',
  //             placeholder: '',
  //             field: 'passport_epassport',
  //             validators: [
  //               { check: Validators.required, message: 'This field is mandatory' },
  //             ],
  //             responsive: { md: 12, xs: 12,  },
  //             required: true,
  //             style: { background: '#FFFFFF', borderRadius: '8px' },
  //           },)}

  //   Do you have a UK Driving License? *
  //   Is your passport and e-passport? *
  //   Driving License Issue Date *
  //   Passport Issue Date *
  return form;
};
