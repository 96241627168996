import React, { FC } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
} from "@mui/material";
import CheckBox from "../../../../../components/CheckBox";
import { RequestReferenceState } from "../../../../../utils/redux/reducer/request-slice";
import { identifiers } from "../../../../../utils/constants/identifiers";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import ReferenceList from "../../../../Common/ReferenceList";

const LinearProgressWithLabel: FC<
  { value: number; reqNumber: number } & LinearProgressProps
> = ({ value, reqNumber, ...props }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          value={(value / reqNumber) * 100}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {`${Math.round(value)}`}/{reqNumber}
        </Typography>
      </Box>
    </Box>
  );
};

const RequiredReference: FC<{
  reference: RequestReferenceState | null;
  setReference: (reference: RequestReferenceState) => void;
  setActive: (value: number) => void;
  active: number;
}> = ({ reference, setActive, active }) => {
  const referenceTypes = [
    {
      type: identifiers.employment as string,
      name: "Employment Reference",
      sub_text: "Add employment references",
      count: 0,
      completed: 0,
      has_invalid_reference: false,
    },
    {
      type: identifiers.character as string,
      name: "Character Reference",
      sub_text: "Add character references",
      count: 0,
      completed: 0,
      has_invalid_reference: false,
    },
    {
      type: identifiers.academic as string,
      name: "Academic Reference",
      sub_text: "Add academic references",
      count: 0,
      completed: 0,
      has_invalid_reference: false,
    },
    {
      type: identifiers.gap as string,
      name: "Employment Gap Reference",
      sub_text: "Add employment gap references",
      count: 0,
      completed: 0,
      has_invalid_reference: false,
    },
  ];

  return (
    <Grid container spacing={2} sx={{ mt: 3 }}>
      <Grid item xs={12} md={12} lg={4}>
        <List
          dense
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            p: 0,
          }}
        >
          {reference?.reference_list.map((referenceType, index) => (
            <Box key={index}>
              <ListItem
                sx={{ px: { xs: 0, md: 3 } }}
                disablePadding
                onClick={() => {
                  setActive(index);
                }}
                data-tut={
                  referenceType.type === identifiers.employment
                    ? "employment_ref"
                    : referenceType.type === identifiers.character
                    ? "character_ref"
                    : referenceType.type === identifiers.academic
                    ? "academic_re"
                    : "employment_gap_ref"
                }
              >
                <ListItemIcon>
                  <CheckBox
                    checked={referenceType.count > 0}
                    disabled
                    type="shield"
                    sx={{ marginBottom: "8px" }}
                    shieldColor={
                      referenceType.has_invalid_reference
                        ? "error"
                        : referenceType.count > 0 &&
                          referenceType.count !== referenceType.completed
                        ? "warning"
                        : referenceType.count === referenceType.completed &&
                          referenceType.count > 0
                        ? "success"
                        : "warning"
                    }
                  />
                </ListItemIcon>
                <ListItemButton>
                  <ListItemText
                    sx={{
                      background:
                        index === active
                          ? "#E3F4FF"
                          : referenceType.has_invalid_reference
                          ? "#F6D3D3"
                          : referenceType.count > 0 &&
                            referenceType.count !== referenceType.completed
                          ? "#FEFBEC"
                          : referenceType.count === referenceType.completed &&
                            referenceType.count > 0
                          ? "#E3F4FF"
                          : "#FFFFFF",
                      border: "1px solid rgba(90, 185, 249, 0.25)",
                      borderRadius: "8px",
                      margin: "0 0 10px 0",
                      padding: {
                        xs: "8px 20px",
                        lg: "8px 8px",
                        xl: "8px 20px",
                      },
                      minWidth: "102px",
                    }}
                    primary={
                      <Typography
                        variant={"body2"}
                        fontWeight={500}
                        color={"#212121"}
                        fontSize={14}
                        textTransform={"capitalize"}
                      >
                        {referenceType.type === "employment_gap"
                          ? `Employment Gap Reference`
                          : `${referenceType.type} Reference`}
                      </Typography>
                    }
                    secondary={
                      <>
                        <span className="lower-text">
                          {referenceType.type === "employment_gap"
                            ? ` Add employment gap reference`
                            : `Add ${referenceType.type} reference`}
                        </span>
                        <LinearProgressWithLabel
                          variant="determinate"
                          value={referenceType.completed}
                          color={
                            index === active
                              ? "primary"
                              : referenceType.count > 0
                              ? referenceType.has_invalid_reference
                                ? "error"
                                : referenceType.count > referenceType.completed
                                ? "warning"
                                : "primary"
                              : "secondary"
                          }
                          reqNumber={referenceType.count}
                        />
                      </>
                    }
                  />
                </ListItemButton>
              </ListItem>
            </Box>
          ))}
        </List>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <ReferenceList role={"agency"} type={referenceTypes[active].type} />
      </Grid>
    </Grid>
  );
};

export default RequiredReference;
