import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import {identifiers } from "../../../../../../../../utils/constants/identifiers";
import {images} from '../../../../../../../../utils/constants/images';

interface StatusProps {
    activeDocument: string;
    onClickNext: () => void;
}

const YotiCompletionStatus: React.FC<StatusProps> = ({ activeDocument, onClickNext }) => {
    return (
        <Stack padding={'2rem'} gap={'1.5rem'} justifyContent={'center'} alignItems={'center'}>
            <Stack width={'9.375rem'} height={'9.375rem'} justifyContent={'center'} alignItems={'center'}
                sx={{
                    background: '#FFF',
                    borderRadius: '1.125rem',
                    boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.15)',
                    position: 'relative',
                }
                }>
                <Box sx={{
                    width: '5.875rem',
                    height: '6.125rem',
                    '& img': {
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        fill: '#01BFFB'
                    }
                }}>
                    <img src={images.YotiScanIcon} alt="YotiScanIcon" />
                </Box>
                <Stack width={'3.75rem'} height={'3.75rem'} padding={'0.5 rem'} justifyContent={'center'} alignItems={'center'}
                    sx={{
                        position: 'absolute',
                        right: '-1.87rem',
                        bottom: '-1rem',
                        borderRadius: '3.75rem',
                        boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.15)',
                        background: "#fff"
                    }}>
                    <Box sx={{
                        width: '2.25rem',
                        height: '2.625rem',
                        overflow: 'hidden',
                        '& img': {
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }
                    }}>
                        <img src={images.YotiKedIcon} alt="YotiKedIcon" />
                    </Box>
                </Stack>
            </Stack>

            <Stack justifyContent={'center'} alignItems={'center'}>
                <Typography sx={{ textAlign: 'center', fontSize: '1.1875rem', fontWeight: '500', lineHeight: '2.45rem' }}>
                    Thank you for completing our your digital identity
                </Typography>
                <Typography sx={{ textAlign: 'center', fontSize: '1rem', lineHeight: '2rem' }}>
                    Please bear with us as we process your data.
                </Typography>
            </Stack>

            {activeDocument === identifiers.dbs && (
                <>
                    <Divider sx={{ width: '100%' }} />
                    <Stack sx={{
                        width: {
                            xs: '100%',
                            md: '43rem'
                        },
                    }}>
                        <Typography sx={{ textAlign: 'center', fontSize: '0.875rem', lineHeight: '1.4375rem' }}>
                            The identity verification process will take up to 10 minutes to process once you submit your identity details. Therefore, after you submit your identity details please wait for 10 minutes.
                        </Typography>
                    </Stack>
                    <Stack>
                        <Button variant="contained" size="large"
                            sx={{
                                backgroundColor: '#5AB9F9',
                                padding: '0.3125rem 1rem',
                                fontSize: '0.75rem',
                                lineHeight: '1.55rem',
                                fontWeight: '800',
                                color: '#FFF',
                                width: 'fit-content',
                                boxShadow: 'none'
                            }}
                            onClick={() => onClickNext()}>
                            Proceed to Next Step
                        </Button>
                    </Stack>
                </>
            )
            }
        </Stack>
    );
}

export default YotiCompletionStatus;
