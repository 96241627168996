import { createSlice } from '@reduxjs/toolkit';

export interface ReferenceState {
  id: string | null;
  name: string | null;
  agency_name: string;
  agency_email: string;
  invalid: boolean;
  type: 'employment' | 'academic' | 'character' |"employment_gap" |null;
  candidate_profile: { [key: string]: string } | null;
  referee_details: {
    [key: string]: string | { from: string; to: string };
  } | null;
  ked_questionnaire: {
    [key: string]: {
      label: string;
      value: string | boolean | number | null;
    };
  };
  additional_questionnaire_forms: { [key: string]: string }[];
  active_index: number;
  referee_comments: string;
  available_routes: string[];
  company: string;
  request_id: string;
  reference_document_forms: {
    agency_id: string;
    id: string;
    name: string;
    type: string;
    is_uploading: boolean;
    selected_file: File | null;
  }[];
  completed: boolean;
  is_declined:boolean;
  reference_document_id: string[];
  is_gap_reference:boolean;
  referee_start_application:boolean;
  referee_application_details:boolean;
  referee_matrix_table:boolean;
  referee_uplaod_yor_own_form:boolean;
  referee_additional_comments:boolean;
}

export const initialReference: ReferenceState = {
  id: null,
  name: null,
  agency_name: '',
  agency_email: '',
  invalid: false,
  referee_details: null,
  type: null,
  candidate_profile: null,
  ked_questionnaire: {},
  additional_questionnaire_forms: [],
  reference_document_forms: [],
  active_index: -1,
  referee_comments: '',
  available_routes: [],
  company: '',
  request_id: '',
  completed: false,
  is_declined: false,
  reference_document_id: [],
  is_gap_reference:false,
  referee_start_application:false,
  referee_application_details:false,
  referee_matrix_table:false,
  referee_uplaod_yor_own_form:false,
  referee_additional_comments:false,
};

const referenceSlice = createSlice({
  name: 'referenceSlice',
  initialState: initialReference,
  reducers: {
    setReferenceData(state, action) {
      console.log(action)
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.agency_name = action.payload.agency_name;
      state.agency_email = action.payload.agency_email;
      state.invalid = action.payload.invalid;
      state.referee_details = action.payload.referee_details;
      state.type = action.payload.type;
      state.candidate_profile = action.payload.candidate_profile;
      state.ked_questionnaire = action.payload.ked_questionnaire;
      state.reference_document_forms = action.payload.reference_document_forms;
      state.additional_questionnaire_forms =
        action.payload.additional_questionnaire_forms;
      state.active_index = action.payload.active_index;
      state.referee_comments = action.payload.referee_comments;
      state.available_routes = action.payload.available_routes;
      state.company = action.payload.company;
      state.request_id = action.payload.request_id;
      state.completed = action.payload.completed;
      state.is_declined = action.payload.is_declined || action.payload.candidate_reference?.is_declined;
      state.reference_document_id = action.payload.reference_document_id;
      state.is_gap_reference=action.payload.is_gap_reference;
      state.referee_start_application=action.payload.referee_start_application;
      state.referee_application_details=action.payload.referee_application_details;
      state.referee_matrix_table=action.payload.referee_matrix_table;
      state.referee_uplaod_yor_own_form=action.payload.referee_uplaod_yor_own_form;
      state.referee_additional_comments=action.payload.referee_additional_comments;

    },
  },
});

export default referenceSlice.reducer;

export const { setReferenceData } = referenceSlice.actions;

export const ReferenceData = (state: { referenceSlice: ReferenceState }) =>
  state.referenceSlice;
