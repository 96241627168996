export const titles = [
  { label: 'Mr', value: 'MR' },
  { label: 'Mrs', value: 'MRS' },
  { label: 'Miss', value: 'MISS' },
  { label: 'Ms', value: 'MS' },
  { label: 'Baron', value: 'BARON' },
  { label: 'Baroness', value: 'BARONESS' },
  { label: 'Brigadier', value: 'BRIGADIER' },
  { label: 'Canon', value: 'CANON' },
  { label: 'Captain', value: 'CAPTAIN' },
  { label: 'Doctor', value: 'DR' },
  { label: 'Duchess', value: 'DUCHESS' },
  { label: 'Duke', value: 'DUKE' },
  { label: 'Esquire', value: 'ESQ' },
  { label: 'Father', value: 'FATHER' },
  { label: 'Honorable', value: 'HON' },
  { label: 'Inspector', value: 'INSPECTOR' },
  { label: 'Lady', value: 'LADY' },
  { label: 'Lord', value: 'LORD' },
  { label: 'Lt Colonel', value: 'LT COL' },
  { label: 'Major', value: 'MAJOR' },
  { label: 'Most Reverend', value: 'MOST REVER' },
  { label: 'Pastor', value: 'PASTOR' },
  { label: 'Professor', value: 'PROFESSOR' },
  { label: 'Rabbi', value: 'RABBI' },
  { label: 'Rev Doctor', value: 'REV DR' },
  { label: 'Reverend', value: 'REVEREND' },
  { label: 'Rt Reverend', value: 'RT REVEREN' },
  { label: 'Sir', value: 'SIR' },
  { label: 'Sister', value: 'SISTER' },
  { label: 'Squadron Leader', value: 'SQUADRON L' },
  { label: 'Wing Commander', value: 'WG CDR' },
];

export const gender = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
];

export const yes_no = [
  { label: 'Yes', value: 'y' },
  { label: 'No', value: 'n' },
];

export const name_type = [
  { label: 'Surname', value: 'surname' },
  { label: 'Forename', value: 'forename' },
];

export const idTypeOne = [
  { label: 'Passport (photo page only)', value: 'Passport (photo page only)' },
  {
    label: 'Driving licence photocard (full or provisional)',
    value: 'Driving licence photocard (full or provisional)',
  },
  {
    label: 'Biometric residence permit (BRP)',
    value: 'Biometric residence permit (BRP)',
  },
  {
    label:
      'National identity card from a country outside the European Economic Area (EEA)',
    value:
      'National identity card from a country outside the European Economic Area (EEA)',
  },
  { label: 'Birth certificate', value: 'Birth certificate' },
  { label: 'Adoption certificate', value: 'Adoption certificate' },
  {
    label: 'Marriage certificate or civil partnership certificate',
    value: 'Marriage certificate or civil partnership certificate',
  },
  { label: 'NHS card', value: 'NHS card' },
  {
    label: 'UK armed forces identity card',
    value: 'UK armed forces identity card',
  },
  {
    label: 'UK firearm or shotgun certificate',
    value: 'UK firearm or shotgun certificate',
  },
];

export const idTypeSecond = [
  {
    label: 'Utility bill, such as gas, electric or phone bill',
    value: 'Utility bill, such as gas, electric or phone bill',
  },
  { label: 'Council tax bill or letter', value: 'Council tax bill or letter' },
  {
    label: 'Driving licence photocard (full or provisional)',
    value: 'Driving licence photocard (full or provisional)',
  },
  {
    label: 'Bank or credit card statement',
    value: 'Bank or credit card statement',
  },
  { label: 'Mortgage statement', value: 'Mortgage statement' },
  {
    label: 'Tenancy agreement (typed, not handwritten)',
    value: 'Tenancy agreement (typed, not handwritten)',
  },
  {
    label: 'Insurance policy document or letter',
    value: 'Insurance policy document or letter',
  },
  { label: 'Payslip or P60', value: 'Payslip or P60' },
  { label: 'P45', value: 'P45' },
  {
    label: 'UK hospital or doctors letter',
    value: 'UK hospital or doctors letter',
  },
  { label: 'UK TV Licence', value: 'UK TV Licence' },
  {
    label: 'University, college or school letter',
    value: 'University, college or school letter',
  },
  {
    label: 'UK Electoral Register Office letter or UK polling card',
    value: 'UK Electoral Register Office letter or UK polling card',
  },
  { label: 'Home Office letter', value: 'Home Office letter' },
  {
    label: 'UK firearm or shotgun certificate',
    value: 'UK firearm or shotgun certificate',
  },
  {
    label: 'UK solicitors letter on headed paper',
    value: 'UK solicitors letter on headed paper',
  },
];
