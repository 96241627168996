import { Box } from "@mui/system";
import React, { FC, ReactNode, useState } from "react";
import Sidebar from "../../common/SideBar";
import MainLayout from "./MainLayout";
import NavBar from "../../common/NavBar";

const AuthenticatedLayout: FC<{ children: ReactNode; title: string }> = ({
  children,
  title,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        direction: "column",
        alignItems: "space-between",
        justifyContent: "center",
      }}
    >
      <Sidebar open={open} setOpen={setOpen} />
      <Box component="main" sx={{ flexGrow: 1 ,width:'100%'}}>
        <NavBar title={title} open={open} setOpen={setOpen} />
        <MainLayout title={title}>{children}</MainLayout>
      </Box>
    </Box>
  );
};

export default AuthenticatedLayout;
