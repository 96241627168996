import React, { ReactNode } from "react";

export interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;

  return <>{value === index && <>{children}</>}</>;
};

export default TabPanel;
