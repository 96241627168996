import React, { FC, useState, RefObject, useRef, useEffect } from "react";
import { Grid } from "@mui/material";
import { PersonalHistoryForm1Model } from "./PersonalHistoryForm1Model";
import { PersonalHistoryForm2Model } from "./PersonalHistoryForm2Model";
import Form, {
  FormDataModel,
} from "../../../../../../../../../components/Form";
import PrimaryButton from "../../../../../../../../../components/Button/PrimaryButton";
import { UCheckProps } from "../UCheckModel";
import { useDispatch, useSelector } from "react-redux";
import {
  setPersonalHistory,
  UCheckPersonalHistory,
  UCheckPersonalInformation,
} from "../../../../../../../../../utils/redux";
import { removeErrorFieldsFromValues } from "../../../../../../../../../utils/validators";
import { OtherNames } from "../../../../../../../../../utils/redux/reducer/u-check-slice";

const PersonalHistory: FC<UCheckProps> = ({
  activeStep,
  handleBack,
  handleNext,
}) => {
  const dispatch = useDispatch();
  let PersonalHistoryForm1: RefObject<Form | null | undefined> = useRef();
  let PersonalHistoryForm2: RefObject<Form | null | undefined> = useRef();

  const uCheckPersonalInformation = useSelector(UCheckPersonalInformation);
  const uCheckPersonalHistory = useSelector(UCheckPersonalHistory);

  const [form2Value, setForm2Value] = useState<FormDataModel>({});
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    const formValue: any = {};
    uCheckPersonalHistory.other_names.forEach((other_name, i) => {
      Object.keys(other_name).forEach((key) => {
        formValue[key + "_" + (i + 1)] = other_name[key as keyof OtherNames];
      });
    });
    setForm2Value({
      has_other_name: uCheckPersonalHistory.has_other_name,
      ...formValue,
    });
    setCount(uCheckPersonalHistory.other_names.length);
  }, [uCheckPersonalHistory.other_names, uCheckPersonalHistory.has_other_name]);

  const onForm1ChangeHandler = (
    field: string,
    value: string,
    formData: FormDataModel,
    isFormValid: boolean
  ) => {
    let formBody: FormDataModel = removeErrorFieldsFromValues(formData);
    if (field === "surname_changed") {
      formBody = {
        ...formBody,
        birth_surname: "",
        birth_forename: "",
        birth_surname_until: "",
      };
    }
    dispatch(
      setPersonalHistory({
        ...uCheckPersonalHistory,
        ...formBody,
        form1_valid:
          field === "surname_changed" && value === "y" ? false : isFormValid,
      })
    );
  };

  const onForm2ChangeHandler = (
    field: string,
    value: string,
    formData: FormDataModel,
    isFormValid: boolean
  ) => {
    if (field === "has_other_name") {
      let other_names = [];
      if (value === "y") {
        other_names.push({
          other_name_type: "",
          forename: "",
          surname: "",
          used_from: "",
          used_to: "",
        });
      } else {
        other_names = [];
      }
      const formBody: FormDataModel = removeErrorFieldsFromValues(formData);
      dispatch(
        setPersonalHistory({
          ...uCheckPersonalHistory,
          ...formBody,
          other_names,
          form2_valid: value !== "y",
        })
      );
    } else {
      const formBody: FormDataModel = removeErrorFieldsFromValues(formData);
      const other_names: any[] = Array.from({ length: count }, () => ({}));
      let valid = true;
      Object.keys(formBody).forEach((key) => {
        const name = key.split("_");
        if (key !== "has_other_name") {
          const index = parseInt(key.split("_")[key.split("_").length - 1]) - 1;
          name.splice(name.length - 1, 1);
          other_names[index][name.join("_")] = formBody[key];
        }
      });
      // Set Form Valid as false if only other name type is set and other details are not yet filled
      other_names.forEach((other_name) => {
        if (Object.keys(other_name).length < 2) {
          valid = false;
        }
      });
      dispatch(
        setPersonalHistory({
          ...uCheckPersonalHistory,
          other_names,
          form2_valid: isFormValid && valid,
        })
      );
    }
  };

  const addName = () => {
    setCount((prevState) => prevState + 1);
    dispatch(
      setPersonalHistory({
        ...uCheckPersonalHistory,
        form2_valid: false,
      })
    );
  };

  const removeName = () => {
    const { getFormData } = PersonalHistoryForm2.current as {
      getFormData: () => { formData: FormDataModel; isFormValid: boolean };
    };
    const { formData } = getFormData();
    let form2_valid = true;
    Object.keys(formData).forEach((key) => {
      if (key.indexOf(`_${count}Error`) < 0 && key.indexOf("Error") > -1) {
        if (formData[key]) {
          form2_valid = false;
        }
      }
    });
    const other_names = JSON.parse(
      JSON.stringify(uCheckPersonalHistory.other_names)
    );
    setCount((prevState) => prevState - 1);
    other_names.splice(count - 1, 1);
    dispatch(
      setPersonalHistory({
        ...uCheckPersonalHistory,
        other_names,
        form2_valid,
      })
    );
  };

  return (
    <Grid container spacing={2} justifyContent={"space-evenly"}>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Form
            values={uCheckPersonalHistory}
            ref={PersonalHistoryForm1 as RefObject<Form>}
            model={PersonalHistoryForm1Model(
              uCheckPersonalInformation,
              uCheckPersonalHistory
            )}
            onChange={onForm1ChangeHandler}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Form
            values={form2Value}
            ref={PersonalHistoryForm2 as RefObject<Form>}
            model={PersonalHistoryForm2Model(
              uCheckPersonalInformation,
              uCheckPersonalHistory,
              count
            )}
            onChange={onForm2ChangeHandler}
          />
          {count > 0 && (
            <Grid
              item
              xs={12}
              sx={{
                display:'flex',
                flexDirection: {xs:'column',md:'row'},
                mt: 1,
                textAlign: "end",
              }}
            >
              <PrimaryButton
                variant={"text"}
                fullWidth={false}
                sx={{
                  mr: 2,
                  background: "white",
                  border: "1px solid #5AB9F9",
                  color: "#5AB9F9",
                  fontSize: 16,
                  height: "46px",
                  mb:{xs:1,md:0},
                  minWidth: {xs:'100%',md:'100px'},
                }}
                onClick={addName}
              >
                Add another name
              </PrimaryButton>
              {count > 1 && (
                <PrimaryButton
                  fullWidth={false}
                  sx={{
                    minWidth: {xs:'100%',md:'100px'},
                    background: "red",
                    color: "white",
                  }}
                  onClick={removeName}
                >
                  Delete name
                </PrimaryButton>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          pt: 2,
          mt: 2,
          borderTop: "1px solid #0000004d",
        }}
        xs={12}
      >
        <PrimaryButton
          variant={"text"}
          fullWidth={false}
          sx={{
            mr: 2,
            background: "white",
            border: "1px solid #5AB9F9",
            color: "#5AB9F9",
            fontSize: 16,
            height: "46px",
            minWidth: "100px",
          }}
          disabled={activeStep === 0}
          onClick={handleBack}
        >
          Go Back
        </PrimaryButton>
        <PrimaryButton
          fullWidth={false}
          sx={{
            minWidth: "100px",
            background: "#1276D3",
            color: "white",
          }}
          disabled={
            !(
              uCheckPersonalHistory.form1_valid &&
              uCheckPersonalHistory.form2_valid
            )
          }
          onClick={handleNext}
        >
          Next
        </PrimaryButton>
      </Grid>
    </Grid>
  );
};

export default PersonalHistory;
