import { Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import PrimaryButton from "../../../../../../../components/Button/PrimaryButton";

const NoOPC: FC<{
  setOpcValue: (isOpcNeeded: boolean, opcCertificate?: boolean) => void;
}> = ({ setOpcValue }) => {
  return (
    <Grid container justifyContent={"center"} lg={12} sx={{ pl: 2 }}>
      <Grid
        md={6}
        xs={11}
        className="pop-up-dotted-card-dbs content-card"
        sx={{ marginTop: "55px", marginBottom: "65px" }}
      >
        <Typography
          variant={"body1"}
          color={"#333333"}
          sx={{
            fontSize: "18px",
            fontWeight: "400",
            letterSpacing: 0.15,
            marginBottom: "10px",
            lineHeight: "28px",
          }}
        >
          In the absence of available checks, we would expect employers to
          obtain as much information as possible in the form of references
          before deciding whether to make an offer of employment.
        </Typography>
      </Grid>
      <Grid
        item
        display={"flex"}
        justifyContent={"center"}
        className="card-footer"
        md={12}
        sx={{ p: 3 }}
      >
        <PrimaryButton
          fullWidth={false}
          sx={{
            width: { xs: "150px", md: "212px" },
            height: "36px",
            background: "#FFFFFF",
            border: " 1px solid #5AB9F9",
            borderRadius: "5px",
            padding: "4px 34px",
            color: "#5AB9F9",
            fontSize: "14px",
            margin: "0px 20px",
          }}
          onClick={() => {
            window.open(
              "https://www.gov.uk/government/publications/criminal-records-checks-for-overseas-applicants",
              "_blank"
            );
          }}
        >
          {/* {loading && <CircularProgress sx={{ mr: 1 }} size={20} />} */}
          Know About OPC
        </PrimaryButton>
        <PrimaryButton
          fullWidth={false}
          sx={{
            width: { xs: "150px", md: "212px" },
            height: "36px",
            background: " #5AB9F9",
            boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
            borderRadius: "6px",
            color: "white",
            ml: 2,
          }}
          onClick={() => {
            setOpcValue(true, true);
          }}
        >
          {/* {loading && <CircularProgress sx={{ mr: 1 }} size={20} />} */}
          Upload Certificate
        </PrimaryButton>
      </Grid>
    </Grid>
  );
};

export default NoOPC;
