import React from "react";
import {
  Stack,
  TableCell,
  TableRow,
  Collapse,
  IconButton,
  Chip,
  Avatar,
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AgencyDetails from "../AgencyDetails/AgencyDetails";
import avatar from "../../../../../utils/Avatar/avatar";
import { AgencyListRowProps } from "../Interfaces/AgencyInterfaces";

const AgencyListRow: React.FC<AgencyListRowProps> = ({
  row,
  isOpen,
  onToggle,
  onClickEditAgency,
  onClickAddComplianceOfficer,
  isMobile
}) => {
  return (
    <React.Fragment>
      <TableRow sx={{ '& > td': { borderBottom: 'none' } }}>
        <TableCell scope="row">
          <Stack flexDirection={'row'} gap={'0.5rem'} alignItems={'center'} >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={onToggle}
            >
              {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <Stack
              onClick={onToggle}
              gap={"0.5rem"}
              direction={"row"}
              alignItems={"center"}
              sx={{ cursor: 'pointer' }}
            >
              <Avatar {...avatar(row.agency_name)} />
              {row.agency_name}
            </Stack>

          </Stack>
        </TableCell>
        {!isMobile &&
          <>
            <TableCell><Chip label={row.compliance_officer_count} /></TableCell>
            <TableCell><Chip label={row.agency_request_count} /></TableCell>
            <TableCell><Chip label={row.agency_candidate_count} /></TableCell>
            <TableCell><Chip
              label={row.agency_dbs_key !== "" ? "Active" : "N/A"}
              color={row.agency_dbs_key !== "" ? "primary" : "default"}
              style={{ color: row.agency_dbs_key !== "" ? "white" : "inherit" }}
            /></TableCell>
          </>
        }
        <TableCell>
          <Stack gap={'0.5rem'} flexDirection={'row'} justifyContent={"end"}>
            <IconButton onClick={onToggle}>
              <VisibilityOutlinedIcon />
            </IconButton>
            <IconButton onClick={() => onClickEditAgency(row.agency_id)}>
              <EditOutlinedIcon />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6} sx={{ backgroundColor: '#F6F6F6' }}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <AgencyDetails
              key={row.agency_id}
              agency={row}
              onClickAddComplianceOfficer={onClickAddComplianceOfficer}
              isMobile={isMobile}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default AgencyListRow;
