import { Box, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { FC } from "react";
import { images } from "../../../../utils/constants/images";
import { TeamMemberInterface } from "./InviteMembers";
import CheckBox from "../../../../components/CheckBox";

const ListItemContainer: FC<{
  member: TeamMemberInterface;
  deleteMember: (value: TeamMemberInterface) => void;
  setOpen: (value: boolean) => void;
}> = ({ member, setOpen, deleteMember }) => {
  const theme=useTheme()  
  const isDekstop = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Box
          sx={{
            width: { xs: "250px", sm: "250px", md: "240px" },
            p: 2,
            borderRadius: "5px",
            backgroundColor: member.id ? "#F5FBFF" : "#F6F7FB",
            display: "flex",
            justifyContent: member.id ? "space-between" : "flex-start",
            alignItems: "center",
          }}
        >
          {member.id ? (
            <Grid container display={'center'} alignItems={'center'}>
              <Grid item xs={10}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: 16,
                    fontWeight: 500,
                    color: "#333333",
                    lineHeight: "28px",
                    letterSpacing: "0.15px",
                  }}
                >
                  {member.name}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: 16,
                    fontWeight: 500,
                    color: "#333333",
                    lineHeight: "28px",
                    letterSpacing: "0.15px",
                    wordBreak: "break-all",
                  }}
                >
                  {member.email}
                </Typography>
              </Grid>
              <Grid item justifyContent={"flex-end"} xs={2}>
                <Box
                  onClick={() => {
                    deleteMember(member);
                  }}
                >
                  <img src={images.deleteWIcon} alt="member-invite" />
                </Box>
              </Grid>
            </Grid>
          ) : (
            <>
              <img src={images.userIcon} alt="member-invite" />
              <Typography
                variant="h6"
                sx={{
                  fontSize: 16,
                  fontWeight: 500,
                  color: "#333333",
                  lineHeight: "28px",
                  letterSpacing: "0.15px",
                  ml: 4,
                  display: { xs: "none", sm: "none", md: "block" },
                }}

              >
                +Add Member
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontSize: 16,
                  fontWeight: 500,
                  color: "#333333",
                  lineHeight: "28px",
                  letterSpacing: "0.15px",
                  ml: 4,
                  display: { xs: "block", sm: "block", md: "none" },
                }}
                data-tut={!isDekstop?"add_members_btn":''}
                onClick={() => {
                  setOpen(true);
                }}
              >
                +Add Member
              </Typography>
            </>
          )}
        </Box>
        <CheckBox checked={!!member.id} disabled type={"circle"} />
      </Stack>
    </Box>
  );
};

export default ListItemContainer;
